import React from "react";
import { CSVLink } from "react-csv";

const ExportClasses = ({classData=null}) => {
    function downloadClassCSVFormat() {
        let headers = [
            {label:"Class Name",key:"className"},
            {label:"Section",key:"section"},
          ];
          return (
            <CSVLink
              data={classData}
              filename={"School_Classes.csv"}
              headers={headers}
            >
            <i className="mdi mdi-download"></i> Export Class Data
            </CSVLink>
          );
    }

    return (
      <div>{downloadClassCSVFormat()}</div>
		);

}

export default ExportClasses;