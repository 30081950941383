import BaseApi from "./baseApi";

class Contact extends BaseApi {
    async getContactList() {
        return await this.API(
            {
                url: "/edvantum/getcontactlist",
                method: "get",
            }
        )
    }

    async createContact(data) {
        return await this.API(
            {
                url: "/edvantum/createcontact",
                data: data,
                method: "post"
            }
        )
    }

    async replyContact(data) {
        return await this.API(
            {
                url: "/edvantum/replycontact",
                data: data,
                method: "post"
            }
        )
    }

    async deleteContact(data) {
        return await this.API(
            {
                url: "/edvantum/deletecontact",
                data: { _id: data },
                method: "post"
            }
        )
    }

    async deleteUserData(data) {
        return await this.API(
            {
                url: "/edvantum/deleteuserdata",
                data: data,
                method: "post"
            }
        )
    }

}

export default Contact;