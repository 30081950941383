import BaseApi from "./baseApi";

class StudentAttendanceApi extends BaseApi {
    async getStudentAttendanceList({id,monthYear}) {
        return await this.API({
            url: `/studentattendance/getstudentattendancebymonthyear?studentId=${id}&monthYear=${monthYear}`,
            method: "get",
        })
    }

    async getAttendaceMontlyCountByYear(id,studentYear) {
        return await this.API({
          url: `/studentattendance/getattendacemontlycountbyyear?studentId=${id}&year=${studentYear}`,
          method: "get",
        })
      }
}

export default StudentAttendanceApi;