import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import React, { useEffect, useState } from "react";
import Loader from "../../../components/loader";
import Grid from "../../../components/dataTable";
import { MultiSelect } from "react-multi-select-component";
import { Form, Button, } from "react-bootstrap";
import BaseApi from "../../../api/baseApi";

const ViewStudentReport = (props) => {
  const [isLoader, setIsLoader] = useState(false);
  const [feeRows, setFeeRows] = useState([]);
  const [feeMonths, setFeeMonths] = useState([]);

  useEffect(() => {
    const _id = props && props.match && props.match.params && props.match.params._id ? props.match.params._id : '';
    if (localStorage.getItem("schoolId")) {
      getDefaultSchoolSettings();
    }
    // if (id) {
    //  selectedStudent(id)
    // }
  }, [])

  async function getDefaultSchoolSettings() {
    setIsLoader(true);
    const baseApi = new BaseApi();
    await baseApi.reorderMonthBySessionStart().then(async (response) => {
      if (response.success && response.data) {
        setFeeMonths(response.data);
      }
    })
    setIsLoader(false);
  }

  const fields = () => {
    let fields = [
      {
        title: "Receipt No",
        field: "admissionNo",
      },
      {
        title: "Paid On",
        field: "name"
      },
      {
        title: "Fee Amount Paid",
        field: "class",
      },
      {
        title: "Status",
        field: "feeStatus"
      },
      // {
      //   name: "action",
      //   title: "Actions ",
      //   render: renderAction,
      // }
    ]
    return fields;
  }

  const attendFields = () => {
    let fields = [
      {
        title: "Date",
        field: "admissionNo",
      },
      {
        title: "Taken by Teacher",
        field: "name"
      }
      // {
      //   name: "action",
      //   title: "Actions ",
      //   render: renderAction,
      // }
    ]
    return fields;
  }

  return (
    <Tabs defaultIndex={0} onSelect={(index) => {
      // console.log(index)
    }
    }>
      <TabList>
        <Tab>Profile</Tab>
        <Tab>Attendance</Tab>
        <Tab>Fees</Tab>
      </TabList>
      {isLoader ? <Loader /> : null}
      <TabPanel>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4" id="reportstyle">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item"><strong>Full Name</strong><span className="reportSpan">1001</span></li>
                      <li className="list-group-item"><strong className="reportSize">Enrolled in Class</strong><span className="reportSpan">XII</span></li>
                      <li className="list-group-item"><strong className="reportSize">Session</strong><span className="reportSpan">2022-2023</span></li>
                      <li className="list-group-item"><strong className="reportSize">Date Of Birth</strong><span className="reportSpan">29-02-1996</span></li>
                      <li className="list-group-item"><strong className="reportSize">Session Start Date</strong><span className="reportSpan">01/04/22</span></li>
                    </ul>
                  </div>
                  <div className="col-md-4" id="reportstyle">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item"><strong>Admisson No</strong><span className="reportSpan">1001</span></li>
                      <li className="list-group-item"><strong>Admisson Date</strong><span className="reportSpan">02/04/2021</span></li>
                      <li className="list-group-item"><strong className="reportSize">Gender</strong><span className="reportSpan">Male</span></li>
                    </ul>
                  </div>
                  <div className="col-md-4" id="reportImg">
                    <div className="detail-report-img">
                      <img src={require("../../../assets/images/faces/face13.jpg")} alt="profile" loading="lazy"/>
                    </div>
                  </div>
                </div>
                <hr></hr>
                {/* Address */}
                <div className="row">
                  <div className="col-md-6" id="reportstyle">
                    <label>Address</label>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item"><strong>Address</strong><span className="reportSpan"># 069</span></li>
                      <li className="list-group-item"><strong className="reportSize">City</strong><span className="reportSpan">Mohali</span></li>
                      <li className="list-group-item"><strong className="reportSize">State</strong><span className="reportSpan">Punjab</span></li>
                      <li className="list-group-item"><strong className="reportSize">Country</strong><span className="reportSpan">India</span></li>
                      <li className="list-group-item"><strong className="reportSize">Pincode</strong><span className="reportSpan">160014</span></li>
                    </ul>
                  </div>
                  <div className="col-md-6" id="reportstyle">
                    <label>Other Details</label>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item"><strong>Concession</strong><span className="reportSpan">Schedule Caste - 1000</span></li>
                      <li className="list-group-item"><strong className="reportSize">Status</strong><span className="reportSpan">ON</span></li>
                      <li className="list-group-item"><strong className="reportSize">Passed Out</strong><span className="reportSpan">07/05/2022</span></li>
                    </ul>
                  </div>
                </div>
                {/* Parents Details */}
                <hr></hr>
                <div className="row">
                  <div className="col-md-6" id="reportstyle">
                    <label>Father Detail</label>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item"><strong>Father Name</strong><span className="reportSpan">Manpreet</span></li>
                      <li className="list-group-item"><strong className="reportSize">Mobile</strong><span className="reportSpan">9876543218</span></li>
                      <li className="list-group-item"><strong className="reportSize">E-mail</strong><span className="reportSpan">manpretSingh245@gmail.com</span></li>
                    </ul>
                  </div>
                  <div className="col-md-6" id="reportstyle">
                    <label>Mother Detail</label>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item"><strong>Mother Name</strong><span className="reportSpan">Jasmine</span></li>
                      <li className="list-group-item"><strong className="reportSize">Mobile No.</strong><span className="reportSpan">8890863347</span></li>
                      <li className="list-group-item"><strong className="reportSize">E-mail</strong><span className="reportSpan">jasmin4568@gmail.com</span></li>
                    </ul>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-sm-3 col-form-label">
                        Class{" "}
                      </label>
                      <div className="col-sm-9">
                        <div className="form-control" >
                          XII{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-sm-3 col-form-label">
                        Session{" "}
                      </label>
                      <div className="col-sm-9">
                        <div className="form-control" >
                          2022-2023{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <Tabs defaultIndex={0} onSelect={(index) => {
                  //  console.log()
                }}>
                  <TabList>
                    <Tab>Present</Tab>
                    <Tab>Absent</Tab>
                    <Tab>Leave</Tab>
                  </TabList>
                  <TabPanel>
                    <Form.Group>
                      <MultiSelect
                        options={feeMonths}
                        labelledBy="Select"
                      // value={schoolSettings.schoolTime[index].satHolidays}
                      // onChange={(e) => {
                      // 	setSelectedHolidays(e);
                      // 	let schoolSettingsFrom = { ...schoolSettings };
                      // 	schoolSettingsFrom.schoolTime[index].satHolidays = e;
                      // 	setSchoolSettings(schoolSettingsFrom);
                      // }}
                      />
                    </Form.Group>
                    <Grid columns={attendFields()}
                    // rows={attendRows} 
                    />
                  </TabPanel>
                  <TabPanel>
                    <Form.Group>
                      <MultiSelect
                        options={feeMonths}
                        labelledBy="Select"
                      // value={schoolSettings.schoolTime[index].satHolidays}
                      // onChange={(e) => {
                      // 	setSelectedHolidays(e);
                      // 	let schoolSettingsFrom = { ...schoolSettings };
                      // 	schoolSettingsFrom.schoolTime[index].satHolidays = e;
                      // 	setSchoolSettings(schoolSettingsFrom);
                      // }}
                      />
                    </Form.Group>
                    <Grid columns={attendFields()}
                    // rows={attendRows} 
                    />
                  </TabPanel>
                  <TabPanel>
                    <Form.Group>
                      <MultiSelect
                        options={feeMonths}
                        labelledBy="Select"
                      // value={schoolSettings.schoolTime[index].satHolidays}
                      // onChange={(e) => {
                      // 	setSelectedHolidays(e);
                      // 	let schoolSettingsFrom = { ...schoolSettings };
                      // 	schoolSettingsFrom.schoolTime[index].satHolidays = e;
                      // 	setSchoolSettings(schoolSettingsFrom);
                      // }}
                      />
                    </Form.Group>
                    <Grid columns={attendFields()}
                    // rows={attendRows} 
                    />
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel>
        <Tabs defaultIndex={0} onSelect={(index) => {
          // console.log(index)
        }}>
          <TabList>
            <Tab>Paid Fee Details</Tab>
            <Tab>Fee Due</Tab>
          </TabList>
          <TabPanel>
            <Grid columns={fields()} rows={feeRows} />
          </TabPanel>
          <TabPanel>
            <Grid columns={fields()} rows={feeRows} />
          </TabPanel>
        </Tabs>
      </TabPanel>
    </Tabs >
  );
}

export default ViewStudentReport;