import React, { useEffect, useState, useRef } from 'react';
import { Form, Image } from 'react-bootstrap';
import bsCustomFileInput from 'bs-custom-file-input';
import StudentApi from "../../../api/studentApi";
import StudentFeeApi from "../../../api/studentFeeApi";
import StudentAttendanceApi from "../../../api/studentAttendanceApi";
import ResultApi from "../../../api/resultApi";
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';
import "react-datetime/css/react-datetime.css";
import "react-tabs/style/react-tabs.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import moment from "moment";
import Grid from "../../../components/dataTable";
import ReactTooltip from "react-tooltip";
import { capitalize } from '../../../helpers/utils';
import PresentCalendarModal from './PresentCalendarModal';
import StudentResult from "../../../components/StudentResult";
import StudentAttendaceBarChart from "../../../components/StudentAttendaceBarChart";
import StudentAttendaceLineChart from "../../../components/StudentAttendaceLineChart";
import StudentAttendaceAreaChart from "../../../components/StudentAttendaceAreaChart";
import StudentAttendacePieChart from "../../../components/StudentAttendacePieChart";
import * as d3 from 'd3';

const ViewStudent = () => {
  let history = useHistory();

  const ChartContainer = ({ children }) => {
    const containerRef = useRef();

    useEffect(() => {
      const drag = d3.drag()
        .on('start', (event, d) => {
          console.log('Drag start');
        })
        .on('drag', (event, d) => {
          console.log('Dragging', event.x, event.y);
          d3.select(containerRef.current)
            .style('left', `${event.x}px`)
            .style('top', `${event.y}px`);
        })
        .on('end', (event, d) => {
          console.log('Drag end');
        });

      const container = d3.select(containerRef.current);
      if (container) {
        container.call(drag);

        // Add zoom behavior
        const zoom = d3.zoom()
          .scaleExtent([0.5, 5]) // Set the minimum and maximum zoom levels
          .on('zoom', (event) => {
            container.style('transform', `scale(${event.transform.k})`);
          });

        container.call(zoom);
      } else {
        console.error('Container not found');
      }
    }, []);

    return (
      <div ref={containerRef} className="chart-container">
        {children}
      </div>
    );
  };

  const [defaultDate, setDefaultDate] = useState(moment().format("YYYY, M"));
  const [studentYear, setStudentYear] = useState(moment().format("YYYY"));
  const [formData, setFormData] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    admissionNo: "",
    admissionDate: "",
    dob: "",
    gender: "",
    photoUrl: "",
    fatherProfile: {
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      primary: "true"
    },
    motherProfile: {
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      primary: "false"
    },
    busId: "",
    concessionId: "",
    address: {
      country: "India",
      state: "",
      city: "",
      pinCode: "",
      address: ""
    },
    classId: "",
    sessionStart: "",
    passedout: "",
    bus: {
      private: true,
      route: null,
      destination: null
    }
  })
  const [studentFee, setStudentFee] = useState([])
  const [studentResults, setStudentResults] = useState([])
  const [studentClassInfo, setstudentClassInfo] = useState({
    classId: "",
    className: "",
    classTeacher: "",
    section: ""
  })
  const [attendaceMontly, setAttendaceMontly] = useState([])
  const [totalAttendance, setTotalAttendance] = useState([])
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const id = new URLSearchParams(history.location.search).get("id");

  useEffect(() => {
    getStudentResult();
  }, []);

  useEffect(() => {
    if (id) {
      selectedStudent(id)
      selectedStudentFee(id)
    }
  }, []);

  useEffect(() => {
    bsCustomFileInput.init();
    getAttendaceMontly();
  }, [id, studentYear]);

  const handleClose = () => {
    setShow(false);
  };

  const paymentDate = (props) => {
    if (props.paymentDate) {
      return <span>{moment(props.paymentDate).format("DD/MM/YYYY")}</span>;
    }
  };

  const renderActionPreviousRec = (props, index) => {
    return (
      <span>
        <ReactTooltip id="Print" type="warning" effect="solid">
          <span>Print</span>
        </ReactTooltip>
        <button
          data-tip
          data-for="Print"
          onClick={() => generateStudentFeePDF(props.student, props._id)}
          type="button"
          className="btn btn-outline-warning mt-1"
          style={{ padding: "8px" }}
        >
          Print
        </button>
      </span>
    );
  };

  const generateStudentFeePDF = (studentId, _id) => {
    const studentFeeApi = new StudentFeeApi();
    studentFeeApi.generateStudentFeePDF(studentId, _id).then((response) => {
      if (response && response.success) {
        window.open(
          "/api/download/" + _id,
          "_blank",
          "location=no,height=700,width=850,scrollbars=yes,status=yes"
        );
      } else {
        toast.error("error in PDF");
      }
    });
  };

  const selectedStudent = async (id) => {
    let Api = new StudentApi();
    await Api.getStudent(id).then(async (response) => {
      if (response.data) {
        setFormData(response.data);
        setstudentClassInfo(response.studentClassInfo);
      } else {
        toast.error(response.msg.toString())
      }
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  const selectedStudentFee = async (id) => {
    let list = [];
    let Api = new StudentFeeApi();
    await Api.feeHistory(id).then(async (response) => {
      if (response.success && response.data && response.data.length) {
        response.data.map((data) => {
          if (data.feeInfo) {
            list.push(data);
          } else {
            list.push(data);
          }
        });
        setStudentFee(list);
      } else {
        setStudentFee([]);
      }
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  const getAttendaceMontly = async () => {
    let Api = new StudentAttendanceApi();
    await Api.getAttendaceMontlyCountByYear(id, studentYear).then(async (response) => {
      if (response.data) {
        setAttendaceMontly(response.data);
      } else {
        toast.error(response.msg.toString())
      }
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  const studentAttendanceList = async (id, monthYear) => {
    let Api = new StudentAttendanceApi();
    await Api.getStudentAttendanceList({ id, monthYear }).then(async (response) => {
      if (response.data && response.data.length) {
        setDefaultDate(moment(response.data[0].dateOfAttendance).format("YYYY, M, D"))
        setStudentYear(moment(response.data[0].dateOfAttendance).format("YYYY"))
        let studentPresent = response.data.filter(data => {
          if (data.statusOfAttendance == "Present") {
            data["id"] = data._id;
            data["title"] = data.statusOfAttendance;
            data["allDay"] = true;
            data["start"] = data.dateOfAttendance;
            data["end"] = data.dateOfAttendance;
            return data
          }
        })
        const studentAbsent = response.data.filter(data => {
          if (data.statusOfAttendance == "Absent") {
            data["id"] = data._id;
            data["title"] = data.statusOfAttendance;
            data["allDay"] = true;
            data["start"] = data.dateOfAttendance;
            data["end"] = data.dateOfAttendance;
            return data
          }
        })
        let total = [...studentPresent, ...studentAbsent]
        setTotalAttendance(total);
      }
      handleShow();
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  const viewAttendance = (month, year) => {
    let id = new URLSearchParams(history.location.search).get("id");
    let monthYear = month + "-" + year
    studentAttendanceList(id, monthYear);
  }

  function monthName(number) {
    let monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    return monthNames[number - 1];
  }

  const displayMonthYear = () => {
    var months = [
      { 'inNumber': 12, 'inMonth': 'December' },
      { 'inNumber': 11, 'inMonth': 'November' },
      { 'inNumber': 10, 'inMonth': 'October' },
      { 'inNumber': 9, 'inMonth': 'September' },
      { 'inNumber': 8, 'inMonth': 'August' },
      { 'inNumber': 7, 'inMonth': 'July' },
      { 'inNumber': 6, 'inMonth': 'June' },
      { 'inNumber': 5, 'inMonth': 'May' },
      { 'inNumber': 4, 'inMonth': 'April' },
      { 'inNumber': 3, 'inMonth': 'March' },
      { 'inNumber': 2, 'inMonth': 'Fabuary' },
      { 'inNumber': 1, 'inMonth': 'January' },
    ];
    var selectedMonths = [];
    var currentSession = moment(new Date(formData.sessionStart)).format("M");
    var currentMonth = new Date().getMonth() + 1;
    if (currentSession) {
      months.map((month) => {
        if (month.inNumber >= currentSession && month.inNumber <= currentMonth) {
          selectedMonths.push(month);
        }
      });
    }
    return (
      <div className="col-md-12">
        {attendaceMontly.map((data, i) => {
          return (
            <Form.Group className="row mb-2" key={"atten_" + i}>
              <div className="col-sm-3">{monthName(data.month)} - {formData.sessionStart ? moment(new Date(formData.sessionStart)).format("YYYY") : moment(new Date()).format("YYYY")}</div>
              <div className="col-sm-3">
                <b>Present : </b><span>{data.presentCount ? data.presentCount : 0}</span>
              </div>
              <div className="col-sm-3">
                <b>Absent : </b><span>{data.absentCount ? data.absentCount : 0}</span>
              </div>
              <div className="col-sm-2">
                <button className='btn btn-primary' onClick={() => viewAttendance(data.month, moment(new Date()).format("YYYY"))}>View</button>
              </div>
            </Form.Group>
          )
        }
        )}
      </div>
    )
  }

  const field = () => {
    const fields = [
      {
        title: "Invoice Number",
        field: "invoiceNumber",
      },
      {
        title: "Paid On",
        render: paymentDate
      },
      {
        title: "Fee Amount Paid",
        field: "paidAmount",
      },
      {
        title: "Actions",
        render: renderActionPreviousRec,
      },
    ];
    return fields;
  };

  const getStudentResult = async () => {
    let studentId = new URLSearchParams(history.location.search).get("id");
    const resultApi = new ResultApi();
    await resultApi.getStudentResult(studentId).then(async (data) => {
      if (data && data.length) {
        setStudentResults(data);
      } else {
        setStudentResults([]);
      }
    })
  }

  return (
    <div>
      <Tabs defaultIndex={0} onSelect={(index) => {
      }}>
        <div >
          <h3 className="page-title mb-3"> {formData.firstName ? capitalize(formData.firstName) : ""} {" "}{formData.lastName ? capitalize(formData.lastName) : ""} {"  Class "} {studentClassInfo.className}{studentClassInfo.section ? "-" + studentClassInfo.section : ""} {"  Admission No. : "} {formData.admissionNo}  </h3>
          <button className='btn btn-primary' style={{ float: "right" }} onClick={() => history.goBack()}><i className="mdi mdi-arrow-left-bold"></i> Back</button>
        </div>
        <TabList>
          <Tab>Personal</Tab>
          <Tab>Parents</Tab>
          <Tab>Address</Tab>
          <Tab>Attendance</Tab>
          <Tab>Result</Tab>
          <Tab>Fee Details</Tab>
          <Tab>Transport</Tab>
          <Tab>Other..</Tab>
        </TabList>

        {/* Personal */}
        <TabPanel>
          {/* ===================================== Student Info ================================== */}
          <div className='card'>
            <div className='card-body'>
              <div className='row mt-3'>
                <div className='col-md-6'>
                  <div className='row'>
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <b className="col-sm-6">Admission No. : </b>
                        <div className="col-sm-6">{formData.admissionNo}</div>
                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <b className="col-sm-6">Admission Date : </b>
                        <div className="col-sm-6">{moment(new Date(formData.admissionDate)).format("DD/MM/YYYY")}</div>
                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <b className="col-sm-6">First Name : </b>
                        <div className="col-sm-6">{formData.firstName}</div>
                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <b className="col-sm-6">Last Name : </b>
                        <div className="col-sm-6">{formData.lastName}</div>
                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <b className="col-sm-6">Date of Birth</b>
                        <div className="col-sm-6">{formData.dob ? (moment(formData.dob).format("DD/MM/YYYY")) : ("")}</div>
                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <b className="col-sm-6">Gender</b>
                        <div className="col-sm-6">{formData.gender}</div>
                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <b className="col-sm-6">Class : </b>
                        <div className="col-sm-6">{studentClassInfo.className}{studentClassInfo.section ? "-" + studentClassInfo.section : ""}</div>
                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <Form.Group className="row">
                        <b className="col-sm-6">Session Start : </b>
                        <div className="col-sm-6">{moment(new Date(formData.sessionStart)).format("DD/MM/YYYY")}</div>
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className='col-md-4 mt-5'>
                  {formData.photoUrl ?
                    <span className="col-sm-12"><Image src={`${formData.photoUrl}`} style={{ height: '200px', width: '200px', borderRadius: '50%' }} thumbnail /></span>
                    :
                    (<span className="col-sm-9"><Image src={"/public/students/dummy.jpeg"} style={{ height: '200px' }} thumbnail /></span>)}
                </div>
              </div>
            </div>
          </div>
        </TabPanel>

        {/* Parents */}
        <TabPanel>
          <div className='card'>
            <div className='card-body'>

              {/* ===================================== Father ================================== */}
              <h4 className="card-title mt-3">Father's Details
                {formData.fatherProfile.primary == "true" &&
                  <span> (Primary)</span>
                }
              </h4>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="row">
                    <b className="col-sm-6">First Name :</b>
                    <div className="col-sm-6">
                      {formData.fatherProfile.firstName}
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="row">
                    <b className="col-sm-6">Last Name : </b>
                    <div className="col-sm-6">
                      {formData.fatherProfile.lastName ? formData.fatherProfile.lastName : ""}
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="row">
                    <b className="col-sm-6">Mobile No. : </b>
                    <div className="col-sm-6">
                      {formData.fatherProfile.mobile}
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="row">
                    <b className="col-sm-6">Email : </b>
                    <div className="col-sm-6">
                      {formData.fatherProfile.email}
                    </div>
                  </Form.Group>
                </div>
              </div>

              <hr />

              {/* ===================================== mother ================================== */}
              <h4 className="card-title mb-3">Mother's Details
                {formData.motherProfile.primary == "true" &&
                  <span> (Primary)</span>
                }
              </h4>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="row">
                    <b className="col-sm-6">First Name : </b>
                    <div className="col-sm-6">
                      {formData.motherProfile.firstName}
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="row">
                    <b className="col-sm-6">Last Name : </b>
                    <div className="col-sm-6">
                      {formData.motherProfile.lastName}
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="row">
                    <b className="col-sm-6">Mobile No. : </b>
                    <div className="col-sm-6">
                      {formData.motherProfile.mobile}
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="row">
                    <b className="col-sm-6">Email : </b>
                    <div className="col-sm-6">
                      {formData.motherProfile.email}
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>

        {/* Address */}
        <TabPanel>
          {/* =================================== Address ================================= */}
          <div className='card'>
            <div className='card-body'>
              <div className="row mt-3">
                <div className="col-md-6">
                  <Form.Group className="row">
                    <b className="col-sm-4">Country : </b>
                    <div className="col-sm-4">{formData.address.country}</div>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="row">
                    <b className="col-sm-4">State : </b>
                    <div className="col-sm-4">{formData.address.state}</div>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="row">
                    <b className="col-sm-4">City : </b>
                    <div className="col-sm-4">{formData.address.city}</div>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="row">
                    <b className="col-sm-4">Pin code : </b>
                    <div className="col-sm-4">{formData.address.pinCode}</div>
                  </Form.Group>
                </div>
              </div>
              <div className='row'>
                <div className="col-md-6">
                  <Form.Group className="row">
                    <b className="col-sm-4">Address : </b>
                    <div className="col-sm-6">{formData.address.address}</div>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>

        {/* Attendance */}
        <TabPanel>
          <div className='card'>
            <div className='card-body'>
              <div className="row mt-3">
                <div className="col-md-6">
                  <Form.Group className="row">
                    <b className="col-sm-4">Session Start : </b>
                    <div className="col-sm-4">
                      {formData.sessionStart ? moment(new Date(formData.sessionStart)).format("DD-MM-YYYY") : ""}
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="row">
                    <b className="col-sm-4">Class Teacher : </b>
                    <div className="col-sm-4">
                      {studentClassInfo?.classTeacher?.firstName} {studentClassInfo?.classTeacher?.lastName}
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="row mt-3">
                {displayMonthYear()}
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className='col-md-3'>
              <ChartContainer>
                <StudentAttendaceBarChart data={attendaceMontly} />
              </ChartContainer>
            </div>
            <div className='col-md-3'>
              <ChartContainer>
                <StudentAttendaceLineChart data={attendaceMontly} />
              </ChartContainer>
            </div>
            <div className='col-md-3'>
              <ChartContainer>
                <StudentAttendaceAreaChart data={attendaceMontly} />
              </ChartContainer>
            </div>
            <div className='col-md-3'>
              <ChartContainer>
                <StudentAttendacePieChart data={attendaceMontly} />
              </ChartContainer>
            </div>
          </div>
        </TabPanel>

        {/* Result */}
        <TabPanel>
          <div className='card'>
            <div className='card-body'>
              <div className="row mt-3">
                <div className="col-md-12">
                  <Form.Group className="row" style={{ display: "inline-block" }}>
                    <b>Session : </b>
                    {moment(new Date(formData.sessionStart)).format("DD-MM-YYYY")}
                  </Form.Group>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <StudentResult data={studentResults} />
                </div>
                <div className="col-md-12">
                  <div className="col-md-2">
                    <div className="grayColorBox"></div>
                    <span> 0% {"<"} 20%</span>
                  </div>
                  <div className="col-md-2">
                    <div className="redColorBox"></div>
                    <span> 20% {"<"} 40%</span>
                  </div>
                  <div className="col-md-2">
                    <div className="orangeColorBox"></div>
                    <span> 40% {"<"} 60%</span>
                  </div>
                  <div className="col-md-2">
                    <div className="yellowColorBox"></div>
                    <span> 60% {"<"} 80%</span>
                  </div>
                  <div className="col-md-2">
                    <div className="greenColorBox"></div>
                    <span> 80% to 100%</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </TabPanel>

        {/* Fee Details */}
        <TabPanel>
          <div className='fees'>
            <Grid
              columns={field()}
              rows={studentFee}
              settings={{
                search: false,
                paging: false,
              }}
            />
          </div>
        </TabPanel>

        {/* Transport */}
        <TabPanel>
          {/* =================================== Bus/Private ============================== */}
          <div className='card'>
            <div className='card-body'>
              <div className="row mt-3">
                <div className="col-md-6">
                  <Form.Group className="row">
                    <b className="col-sm-6">By : </b>
                    <div className="col-sm-6">
                      {formData.bus.private === true ?
                        <span>Private</span> : <span>Bus</span>
                      }
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>

        {/* Other */}
        <TabPanel>
          {/* =================================== other Details ============================== */}
          <div className='card'>
            <div className='card-body'>
              <div className="row mt-3">
                <div className="col-md-12">
                  <Form.Group className="row">
                    <b className="col-sm-2">Concession id</b>
                    <div className="col-sm-10">{formData.concessionId}</div>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="row">
                    <b className="col-sm-3">Status</b>
                    <div className="col-sm-9">{formData.active == true ? "On" : ""}</div>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>
      <PresentCalendarModal show={show} handleClose={handleClose} defaultDate={defaultDate} totalAttendance={totalAttendance} />
    </div >
  );
}

export default ViewStudent;