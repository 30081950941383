import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "../../assets/styles/web/imagehover.min.css";
import "../../assets/styles/web/style.css";
import Dashboard from "../../pages/superadmin/dashboard/dashboard";
import setUpSchool from "../../pages/superadmin/school/addSchool";
import ManageSchool from "../../pages/superadmin/school/manageSchools";
import ChangePassword from "../../pages/superadmin/ChangePassword/ChangePassword";
import ManageContact from "../../pages/superadmin/ManageContact/ManageContact";
import ManageSupport from "../../pages/superadmin/ManageSupport/ManageSupport";
import UpdateAbout from "../../pages/superadmin/UpdateAbout/UpdateAbout";
import UpdateGallery from "../../pages/superadmin/UpdateGallery/UpdateGallery";
import ManageActivities from "../../pages/superadmin/ManageActivities/ManageActivities";
import ManagePerformance from "../../pages/superadmin/ManagePerformance/ManagePerformance";
import AddSchoolForActivities from "../../pages/superadmin/SchoolActivities/AddSchoolForActivities";
import ManageSchoolForActivities from "../../pages/superadmin/SchoolActivities/ManageSchoolForActivities";
import AddStudent from "../../pages/superadmin/ActivityStudents/AddStudent";
import ManageStudent from "../../pages/superadmin/ActivityStudents/ManageStudent";
import ManageClasses from "../../pages/superadmin/ActivityClasses/ManageClasses";

// Coache's pages
import ManageCoaches from "../../pages/superadmin/ManageCoaches/ManageCoaches";
import AddCoachDetails from "../../pages/superadmin/ManageCoaches/addCoachesDetails";
import OnLeaves from "../../pages/superadmin/ManageCoaches/onLeave";

import Menu from "./menu";
import Header from "./header";

const SuperAdminLayout = () => {
  return (
    <React.Fragment>
      <Header />
      <div className="container-fluid page-body-wrapper pt-0">
        <Menu />
        <div className="main-panel">
          <div className="content-wrapper bg-gradient-light">
            <Switch>
              <Route exact path="/">
                <Redirect to="/dashboard" />
              </Route>
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/setupschool" component={setUpSchool} />
              <Route path="/manageschool" component={ManageSchool} />
              <Route path="/changepassword" component={ChangePassword} />
							<Route path="/managecontact" component={ManageContact} />
							<Route path="/managesupport" component={ManageSupport} />
							<Route path="/updateabout" component={UpdateAbout} />
							<Route path="/updategallery" component={UpdateGallery} />
							<Route path="/manageactivities" component={ManageActivities} />
							<Route path="/manageperformance" component={ManagePerformance} />
              {/* <Route path="/manageactivities" component={ManageActivities} /> */}
              <Route
                path="/manageactivityschool/manageactivities"
                component={ManageActivities}
              />
              <Route
                path="/manageactivityschool/classlist"
                component={ManageClasses}
              />
              <Route
                path="/manageactivityschool/addschooldetails"
                component={AddSchoolForActivities}
              />
              <Route
                path="/manageactivityschool/schoollist"
                component={ManageSchoolForActivities}
              />
              <Route
                path="/manageactivityschool/updatestudentdetails"
                component={AddStudent}
              />
              <Route
                path="/manageactivityschool/studentlist"
                component={ManageStudent}
              />

              {/* --- Coaches related routes --- */}
              <Route path="/managecoaches/list" component={ManageCoaches} />
              <Route path="/managecoaches/addcoachdetails" component={AddCoachDetails} />
                <Route 
                  path="/managecoaches/onleave"    
                  component={OnLeaves}
                />

              {/* Coaches related routes */}

            </Switch>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default SuperAdminLayout;
