import React, { useEffect, useState } from "react";
import { Form, Modal, Button } from 'react-bootstrap';
const AssociateTeacherModal = ({data, handleClose, selectClassByModal,classAndSubjects,selectedClassSubjectList,updateClassSubjects  }) =>{
    return (
        <Modal show={data.isModalVisible} onHide={() => handleClose()}>
            <Modal.Header closeButton>
            <h3 className="page-title"> Associate Teacher - {data.selectedTeacher} with Classes & Subjects </h3>
            </Modal.Header>
            <div className="card">
            <div className="card-body">
                <form>
                <div className="row">
                    <div className="col-md-12">
                    <Form.Group className="row">
                        <label className="col-sm-2 col-form-label">Class</label>
                        <div className="col-sm-10">
                        <select className="form-control" name="slectedclass" onChange={(e) => selectClassByModal(e)}>
                            <option value="">-----------</option>
                            {
                            classAndSubjects.classes.map((data) => {
                                return <option value={data.id}>{data.name}</option>
                            })
                            }
                        </select>
                        </div>
                    </Form.Group>
                    </div>
                </div>

                <div className="row">
                    {
                    selectedClassSubjectList.map((data) => {
                        return (
                        <div className="col-sm-6">
                            <Form.Group>
                            <span style={{ cursor: "pointer" }} onClick={() => updateClassSubjects(data.value)}>
                                <i className="mdi mdi-close-circle" style={{ fontSize: "20px", color: "#ff6060" }}></i>
                                <span style={{ fontSize: "20px" }}>{data.label}</span>
                            </span>
                            </Form.Group>
                        </div>
                        )
                    })
                    }
                </div>
                </form>
            </div>
            </div>
            <Modal.Footer style={{ backgroundColor: '#ffffff' }}>
            <Button variant="secondary" onClick={() => handleClose()}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AssociateTeacherModal;