import BaseApi from "./baseApi";

class Class extends BaseApi {
  async getClass(sreachKeyword) {
    return await this.API({
      url: "/class/getclasses",
      method: "get",
      data: {
        sreachKeyword: sreachKeyword,
      }
    });
  }

  async getClassTeacher() {
    return await this.API({
      url: "/class/getclasseteacher",
      method: "get",
    });
  }

  async getDefaultClassesSettings() {
    return await this.API({
      url: "/class/getdefaultclassessettings",
      method: "get",
    });
  }

  async getClassNames() {
    return await this.API({
      url: "/class/getclassnames",
      method: "get",
    });
  }

  async getAllClassNames() {
    return await this.API({
      url: "/class/getallclassnames",
      method: "get",
    });
  }

  async createClass(data) {
    return await this.API({
      url: "/class/createclass",
      data: data,
      method: "post",
    });
  }

  async editClass(data) {
    return await this.API({
      url: "/class/updateclass",
      data: data,
      method: "post",
    });
  }

  async getClassesForFees(termId) {
    return await this.API({
      url: "/class/getclassesforfees",
      method: "get",
    });
  }

  async getClassesByTerm(termId, type = "Array") {
    return await this.API({
      url: "/class/getclassesbyterm",
      params: { termId: termId, responseType: type },
      method: "get",
    });
  }

  async getStudentsByClassId(classId) {
    console.log("classId", classId)
    return await this.API({
      url: "/class/getclassbystudents",
      params: { classId: classId },
      method: "get",
    });
  }

  async deleteClass(data) {
    return await this.API({
      url: "/class/deleteclass",
      data: { _id: data },
      method: "post",
    });
  }

  async updateSubjectList(data) {
    return await this.API({
      url: "/class/updateSubjectList",
      data: data,
      method: "post",
    });
  }

  async getSubjectByClassName(data) {
    return await this.API({
      url: "/class/getsubjectsbyclassname",
      data: data,
      method: "post",
    });
  }

  async updateTimeTable(data) {
    return await this.API({
      url: "/class/updatetimetable",
      data: data,
      method: "post",
    });
  }

  async deleteDaySchedule(data) {
    return await this.API({
      url: "/class/deletedayschedule",
      data: data,
      method: "post",
    });
  }

  async deleteSubjectTimings(_id, index) {
    return await this.API({
      url: '/class/deletesubjecttimings',
      data: { _id: _id, index: index },
      method: 'put',
    });
  }

  async saveClassCsv(data) {
    return await this.API({
      url: '/class/saveclasscsv',
      data: data,
      method: 'post',
    });
  }

  async moveToNextClass() {
    return await this.API({
      url: '/class/movetonextclass',
      method: 'put',
    });
  }

  async getTimeTableByClass(batchId) {
    // classid
    return await this.API({
      url: '/class/getTimeTablebyGroup',
      data: { batchId: batchId },
      method: 'post'
    });
  }

  async updateTimetableSlot(data) {
    return await this.API({
      url: 'class/updateTimetableSlot',
      data: data,
      method: 'post'
    })
  }

  async getSaveGroupTimetable(batchId) {
    return await this.API({
      url: 'class/getSaveGroupTimetable',
      data: { batchId: batchId },
      method: 'post'
    })
  }
}

export default Class;
