import React, { useLayoutEffect } from "react";
const Event = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <section id="about" className="section-padding ">
                <div className="container">
                    <div class="text-center">
                        <h2 className="title__line1">
                            Edvantum Event Management: your trusted partner in creating
                            unforgettable school events and memorable moments!
                        </h2>
                    </div>
                    <div class="container">
                        <div class="card-columns event-card">
                            <div class="card">
                                <a href="#">
                                    <img src={require("../../assets/images/web/annual-function2023.png")} alt="img" />
                                    <div class="card-body">
                                        <h5 class="card-title">Annual function 2023</h5>
                                    </div>
                                </a>
                            </div>
                            <div class="card">
                                <a href="#">
                                    <img src={require("../../assets/images/web/Annual-2023.png")} alt="img" />
                                    <div class="card-body">
                                        <h5 class="card-title">Annual 2023</h5>
                                    </div>
                                </a>
                            </div>
                            <div class="card">
                                <a href="#">
                                    <img src={require("../../assets/images/web/Summercamp.5544525e.png")} alt="img" />
                                    <div class="card-body">
                                        <h5 class="card-title">Summercamp 2023</h5>
                                    </div>
                                </a>
                            </div>
                            <div class="card">
                                <a href="#">
                                    <img src={require("../../assets/images/web/ede3.909f41bf.png")} alt="img" />
                                    <div class="card-body">
                                        <h5 class="card-title">Sports Day / Intra-school tournaments</h5>
                                    </div>
                                </a>
                            </div>
                            <div class="card">
                                <a href="#">
                                    <img src={require("../../assets/images/web/Football.419ca130.png")} alt="img" />
                                    <div class="card-body">
                                        <h5 class="card-title">Interschool Football tournament</h5>
                                    </div>
                                </a>
                            </div>
                            <div class="card">
                                <a href="#">
                                    <img src={require("../../assets/images/web/Sports-Skating.png")} alt="img" />
                                    <div class="card-body">
                                        <h5 class="card-title">Made a record in skating</h5>
                                    </div>
                                </a>
                            </div>
                            <div class="card">
                                <a href="#">
                                    <img src={require("../../assets/images/web/school1.9092a92b.png")} alt="img" />
                                    <div class="card-body">
                                        <h5 class="card-title">Skipper Aryan guides St Joseph's boys</h5>
                                    </div>
                                </a>
                            </div>
                            <div class="card">
                                <a href="#">
                                    <img src={require("../../assets/images/web/sports-Cricket.png")} alt="img" />
                                    <div class="card-body">
                                        <h5 class="card-title">Inter School cricket tournament</h5>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default Event;
