import React, { useLayoutEffect } from "react";
const Activity = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  return (
    <>
      <section id="about">
        <div className="container">
          <div className="row">
            <div className="header-section text-center margin-btm post-heading ">
              <h2 className="title__line1">IN-SCHOOL ACTIVITIES: ENHANCE THE EDUCATIONAL EXPERIENCE FOR STUDENTS
                THROUGH A WIDE
                ARRAY OF IN-SCHOOL EXTRACURRICULAR ACTIVITIES
              </h2>
              <div className="box-auto box-white">
                <p>At Edvantum, we are passionate about enhancing the educational experience for students by
                  offering a wide
                  array of enriching in-school extracurricular activities. Our mission is to empower students
                  to discover
                  their passions, develop essential life skills, and foster a love for learning beyond the
                  classroom.
                </p>
              </div>
            </div>
            <div className="Maximizing">
              <div className="trans">
                <h4>Enhance Value proposition</h4>
                <h5>Collaborate to ensures that our activities complement classroom learning and
                  contribute to a
                  well-rounded education.
                </h5>
                <div className="Reduces-school">
                  <h4>Verified Trainers</h4>
                  <h5>
                    Dedicated instructors who are passionate about their respective fields.
                  </h5>
                </div>
              </div>
              <div className="trans">
                <img
                  src={require("../../assets/images/web/inschool-activities.png")}
                  className="imageCover"
                  alt=""
                  srcSet=""
                />
              </div>
              <div className="trans">
                <h4>Regular Inter-school tournaments</h4>
                <h5>
                  More visibility for the school

                </h5>
                <div className="Reduces-school">
                  <h4> Regular App based performance check</h4>

                  <h5>
                    Performance of each participant monitored through an App based performance management module
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about">
        <div className="container">
          <div className="row">
            <div className="header-section text-center margin-btm post-heading ">
              <h2 className="title__line1">POST-School hour Activities: transforming schools into vibrant centers of
                activity
                and learning, even after the final bell rings</h2>
            </div>
            <div className="Maximizing">
              <div className="trans">
                <h4>Maximizing School Resources</h4>
                <h5>By utilizing school infrastructure during non-school hours, we create a win-win situation,
                  allowing
                  schools to optimize their space and generate additional revenue.</h5>
                <div className="Reduces-school">
                  <h4>Reduces School’s effort</h4>
                  <h5> Defined Curriculum; Maintenance of all infrastructure used is managed by Edvantum</h5>
                </div>
              </div>
              <div className="trans">
                <img
                  src={require("../../assets/images/web/post-school-activities.png")}
                  className="imageCover"
                  alt=""
                  srcSet=""
                />
              </div>
              <div className="trans">
                <h4>Maximizing School Resources</h4>
                <h5>By utilizing school infrastructure during non-school hours, we create a win-win situation,
                  allowing
                  schools to optimize their space and generate additional revenue.</h5>
                <div className="Reduces-school">
                  <h4>Real time App Based Data Sharing</h4>

                  <h5> All enrolments through the Edvantum App ensuring utmost transparency</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Activity;