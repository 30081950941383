import React, { useState, useEffect } from "react";
import { Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import Class from '../../../../api/classApi';

import TimeTableProvider from '../../../../contexts/timeTableContext'

const EditSlots = ({ show, isModalVisible, slotData, restSubTechData, groupID, update }) => {
	// console.log("slotData",slotData)
	// console.log("restSubTechData",restSubTechData)

	let temp = Array.isArray(restSubTechData) && restSubTechData.filter(item => {
		return item.subjectId !== slotData.subjectId
	});
	const arrayUniqueByKey = [...new Map(temp.map(item =>
		[item['subjectName'], item])).values()];
	// console.log("-----temp",arrayUniqueByKey)

	const [otherSubTeachData, setOtherSubTeachData] = useState(arrayUniqueByKey);
	const [formData, setFormData] = useState({
		selectSlot: '',
	});
	// console.log("====slotData",slotData)

	const handleChanges = async (event) => {
		const f = { ...formData }
		const { name, value } = event.target;
		if (name == 'selectSlot') {
			f.selectSlot = value
		}
		setFormData(f);
		// console.log("drop down name--",name)
		// console.log("drop down value--",value)
	}

	const updateSlot = async (e) => {
		e.preventDefault();
		let sendData = {
			classId : slotData.classId,
			dayName: slotData.dayName,
			oldSlotId: slotData.slotId,
			selectedSlotId: formData.selectSlot,
			groupID: groupID
		}
		const ClassApi = new Class();
		await ClassApi.updateTimetableSlot(sendData).then( res => {
			if (res.success) {
				isModalVisible()
				update(res.data);				
			} else {
				toast.error(res.msg);
			}
		}).catch((error) => {
			toast.error(error.toString());
			// console.error('There is an error!', error);
		});
	}

	return (
		<>
			<Modal show={show} onHide={() => isModalVisible()}>
				<Modal.Header closeButton>
					<Modal.Title className="page-title">Change Timetable for {slotData.className} <br></br>{slotData.dayName} {moment(slotData.timeFrom).format('hh:mm A')} {moment(slotData.timeTo).format('hh:mm A')}  </Modal.Title>
				</Modal.Header>

				<Modal.Body style={{ minHeight: "133px", textAlign: "center", backgroundColor: "white" }}>
					<div className="card">
						<div className="card-body">
							<form onSubmit={(e) => updateSlot(e)} className="forms-sample">
								<Form.Group className="row">
									<label className="col-sm-12 col-form-label" style={{ textAlign: 'left' }}>
										Change Subject: {slotData.subjectName} ({slotData.teacherName})
									</label>
									<div className="col-sm-12">
										<select
											className="form-control"
											name="selectSlot"
											onChange={(e) => handleChanges(e)}
										>
											<option value="">-----</option>
											{otherSubTeachData && otherSubTeachData.map((item, i) => (
												<option value={item._id} key={`slot_${i}`}>
													{item.subjectName} ({item.teacherName})
												</option>
											))}
										</select>
									</div>
								</Form.Group>
								<button type="submit" className="btn btn-outline-danger" >
									{/* onClick={() => deleteData()} */}
									Update
								</button>
								<button type="button" className="btn btn-outline-secondary" onClick={() => isModalVisible()}>
									Cancel
								</button>
							</form>
						</div>
					</div>
				</Modal.Body>

				{/* <Modal.Footer style={{ backgroundColor: "white" }}> */}
				{/* </Modal.Footer> */}
			</Modal>
		</>
	)
}

export default EditSlots;