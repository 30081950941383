import React, { useEffect, useState } from 'react';
import MaterialTable from "material-table";
import { Form, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import ExamScheduleApi from '../../../api/examScheduleApi';
import SchoolApi from '../../../api/schoolApi';
import ClassApi from '../../../api/classApi';
import { toast } from "react-toastify";
import moment from 'moment';
import 'moment-timezone';
import Loader from "../../../components/loader";
import PrintExamScheduleModal from './PrintExamScheduleModal';

const ManageExamSchedule = () => {
    useEffect(() => {
        if (localStorage.getItem('schoolId')) {
            getSchool(localStorage.getItem('schoolId'));
        }
        getClassNames();
    }, []);
    if (localStorage.getItem('user_info')) {
        const user = JSON.parse(localStorage.getItem('user_info'));
    }
    const [examSchedulesData, setExamSchedulesData] = useState([]);
    const [termsData, setTermsData] = useState([]);
    const [sessionsData, setSessionsData] = useState([]);
    const [selectedTermId, setSelectedTermId] = useState();
    const [selectedTerm, setSelectedTerm] = useState();
    const [selectedSession, setSelectedSession] = useState();
    const [classNames, setClassNames] = useState();
    const [dataLoader, setDataLoader] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [data, setData] = useState({
        tooltipOpen: false,
        startstyle: {
            color: 'red',
            fontSize: '14px'
        },
        isModalVisible: false,
        isModalVisible2: false,
        selectedExamSchedule: {},
        selectedRoute: '',
    })

    const deleteSchedule = async (id) => {
        setDataLoader(true);
        let examScheduleApi = new ExamScheduleApi();
        await examScheduleApi.deleteExamSchedule(id)
            .then((response) => {
            })
            .catch((error) => {
                console.error('There is ERROR!', error)
            })
        setDataLoader(false);
    }

    const renderAction = (props) => {
        return (
            <span>
                <ReactTooltip id='Edit' type='warning' effect='solid'>
                    <span>Edit</span>
                </ReactTooltip>
                <Link className="nav-link" to={`/editexamschedule/${props._id}`}>
                    <button data-tip data-for="Edit" type="button" className="btn btn-outline-warning" style={{ padding: '8px' }}>
                        <i className="mdi mdi-border-color" style={{ fontSize: '17px' }}></i>
                    </button>
                </Link>

                <ReactTooltip id='Delete' type='error' effect='solid'>
                    <span>Delete</span>
                </ReactTooltip>

                <button data-tip data-for="Delete" onClick={() => deleteSchedule(props._id)} type="button" className="btn btn-outline-danger" style={{ padding: '8px' }}>
                    <i className="mdi mdi-delete" style={{ fontSize: '17px' }}></i>
                </button>
            </span>
        )
    }

    const field = () => {
        const fields = [];
        fields.push({
            title: 'Date',
            field: 'dateOfExam'
        });
        if (classNames && classNames.length) {
            classNames.map(item => {
                fields.push({
                    title: item,
                    field: item,
                })
            })
        }
        return fields;
    }

    const getExamDatesheet = async (term, session) => {
        const examScheduleApi = new ExamScheduleApi();
        await examScheduleApi.getExamDatesheet(term, session).then(async (response) => {
            if (response.data && response.data.length) {
                setExamSchedulesData(response.data);
            } else {
                setExamSchedulesData([])
            }
            return response;
        }).catch((error) => {
        });
    }

    const getClassNames = async () => {
        setDataLoader(true);
        const classApi = new ClassApi();
        await classApi.getClassNames().then(async (response) => {
            if (response.data && response.data.length) {
                setClassNames(response.data);
            } else {
                setClassNames([]);
            }
            return response;
        }).catch((error) => {
        });
        setDataLoader(false);
    }

    const getSchool = async (id) => {
        setDataLoader(true);
        const schoolApi = new SchoolApi();
        await schoolApi.getSchool(id).then(async (response) => {
            if (response && response.data) {
                const termsExams = response.data.termsExams;
                const session = response.data.session;
                if (termsExams && termsExams.length && session && session.startsFromMonth) {
                    setTermsData(termsExams);

                    const sessionData = response.data.session;
                    const currentMonth = new Date().getMonth() + 1;
                    const currentYear = new Date().getFullYear();
                    let sessionEndyear = new Date().getFullYear();
                    if (sessionData.startsFromMonth > sessionData.endsOnMonth) {
                        sessionEndyear = currentYear + 1;
                    }
                    const sessionStartDate = new Date(currentYear, sessionData.startsFromMonth - 1, 1);
                    const sessionEndDate = new Date(sessionEndyear, sessionData.endsOnMonth, 0);
                    const sessionsData = [];

                    sessionsData.push({
                        value: moment(sessionStartDate).subtract(1, 'y').format('DD-MM-YYYY') + ' to ' + moment(sessionEndDate).subtract(1, 'y').format('DD-MM-YYYY'),
                        display: (currentYear - 1) + '-' + (sessionEndyear - 1)
                    })
                    sessionsData.push({
                        value: moment(sessionStartDate).format('DD-MM-YYYY') + ' to ' + moment(sessionEndDate).format('DD-MM-YYYY'),
                        display: currentYear + '-' + sessionEndyear
                    })
                    sessionsData.push({
                        value: moment(sessionStartDate).add(1, 'y').format('DD-MM-YYYY') + ' to ' + moment(sessionEndDate).add(1, 'y').format('DD-MM-YYYY'),
                        display: (currentYear + 1) + '-' + (sessionEndyear + 1)
                    })

                    setSessionsData(sessionsData);
                    setSelectedTermId(termsExams[0]._id);
                    setSelectedTerm(termsExams[0].term);
                    if (currentMonth >= sessionData.startsFromMonth) {
                        setSelectedSession(sessionsData[1].value);
                        getExamDatesheet(termsExams[0]._id, sessionsData[1].value)
                    } else {
                        setSelectedSession(sessionsData[0].value);
                        getExamDatesheet(termsExams[0]._id, sessionsData[0].value)
                    }
                } else {
                    setTermsData([]);
                }
            } else {
                setTermsData([]);
            }
            return response;
        }).catch((error) => {
        });
        setDataLoader(false);
    }

    function onChange(event) {
        const { name, value } = event.target;
        switch (name) {
            case 'term':
                setSelectedTermId(value)
                setSelectedTerm(name);
                getExamDatesheet(value, selectedSession);
                break;
            case 'session':
                setSelectedSession(value)
                getExamDatesheet(selectedTermId, value)
        }
    }

    const handleClose = () => {
        setData(data.selectedClass = "", data.isModalVisible = false)
    }

    const generateExamSchedulePDF = async (term, session) => {
        const examScheduleApi = new ExamScheduleApi();
        examScheduleApi.generateExamDatesheetPDF(selectedTerm, term, session).then(async (response) => {
            if (response.success) {
                window.open(
                    "/api/downloadExam",
                    "_blank",
                    "location=no,height=700,width=850,scrollbars=yes,status=yes"
                );
            } else {
                toast.error("error in PDF");
            }
        });
    };

    return (
        <div>
            <div className="page-header">
                <h3 className="page-title"> Exam Schedule </h3>
                <Link className="nav-link" to="/addexamschedule">
                    <button type="submit" className="btn btn-primary ml-2 btn-fw" style={{ lineHeight: 1.5 }}>Add/Update</button>
                </Link>
            </div>
            {dataLoader ? <Loader /> : null}
            <div className="row">
                <div className="col-md-3">
                    <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">Term: </label>
                        <div className="col-sm-9">
                            <select
                                className="form-control"
                                name="term"
                                onChange={(e) => onChange(e)}
                            >
                                {
                                    termsData.map((resp) => <option key={resp._id} value={resp._id}>{resp.term}</option>)
                                }
                            </select>
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-3">
                    <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">Session</label>
                        <div className="col-sm-9">
                            <select
                                className="form-control"
                                name="session"
                                onChange={(e) => onChange(e)}
                                value={selectedSession}
                            >
                                {
                                    sessionsData.map((resp) => <option key={resp.value} value={resp.value}>{resp.display}</option>)
                                }
                            </select>
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-6 text-right">
                    <button
                        data-tip
                        data-for="Print"
                        onClick={() => {
                            let d = { ...data };
                            d.isModalVisible = true;
                            d.isModalVisible2 = false;
                            setData(d);
                        }}
                        type="button"
                        className="btn btn-outline-warning mt-1"
                        style={{ padding: "8px" }}
                    >
                        Print
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <MaterialTable
                            title=""
                            data={examSchedulesData}
                            columns={field()}
                            options={{ search: false, paging: false, exportButton: true, sorting: false }}
                        />
                    </div>
                </div>
            </div>
            <PrintExamScheduleModal data={data} examSchedulesData={examSchedulesData} selectedSession={selectedSession} selectedTerm={selectedTerm} field={field} handleClose={handleClose} isLoader={isLoader} selectedTermId={selectedTermId} />
        </div>
    )
}

export default ManageExamSchedule;
