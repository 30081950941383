import React, { useEffect, useState } from "react"; 
import { Form, Image, Modal } from 'react-bootstrap';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from "moment";
const mLocalizer = momentLocalizer(moment)
const ViewAttendanceModal = ({show, handleClose,defaultDate,totalAttendance }) => {
    return (
        <Modal show={show} onHide={() => handleClose()}>
            <Modal.Body style={{ background: "white", height: "470px", width: "530px" }}>
            <Calendar
                defaultDate={defaultDate}
                events={totalAttendance}
                localizer={mLocalizer}
                step={60}
                views={['month']}
                eventPropGetter={(events) => {
                const backgroundColor = events.statusOfAttendance == "Present" ? 'green' : 'red';
                return { style: { backgroundColor } }
                }}
            />
            </Modal.Body>
        </Modal>
    )
}

export default ViewAttendanceModal;