import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const StudentAttendancePieChart = ({ data }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    if (!data || data.length === 0) return;

    const width = 300;
    const height = 300;
    const radius = Math.min(width, height) / 2;

    const svg = d3.select(svgRef.current)
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2},${height / 2})`);

    const colorScale = d3.scaleOrdinal(d3.schemeCategory10);

    // Create a pie chart
    const pie = d3.pie()
      .value(d => d.presentCount + (d.absentCount || 0));

    const dataWithAngles = pie(data);

    // Create arcs
    const arc = d3.arc()
      .innerRadius(0)
      .outerRadius(radius);

    // Draw pie slices
    svg.selectAll('path')
      .data(dataWithAngles)
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', (d, i) => colorScale(i))
      .attr('stroke', 'white')
      .style('stroke-width', '2px')
      .style('opacity', 0.8);

    // Display total present percentage on each slice
    svg.selectAll('text')
      .data(dataWithAngles)
      .enter()
      .append('text')
      .attr('transform', d => `translate(${arc.centroid(d)})`)
      .attr('text-anchor', 'middle')
      .attr('font-size', '8px')
      .attr('fill', 'white')
      .text(d => {
        const total = data[d.index].presentCount + (data[d.index].absentCount || 0);
        const percentage = ((data[d.index].presentCount / total) * 100).toFixed(2);
        return `Present: ${percentage}%`;
      });

  }, [data]);

  return (
    <svg ref={svgRef}></svg>
  );
};

export default StudentAttendancePieChart;