import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { SchoolFields } from "./schoolFields";
import activitySchoolApi from "../../../api/activitySchoolApi";
import ActivityApi from "../../../api/activityApi";
import SchoolApi from "../../../api/schoolApi";
import { toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";

const AddSchoolForActivities = () => {
  let history = useHistory();
  const [activities, setActivities] = useState([]);
  const [selectedactivities, setSelectedactivities] = useState([]);
  const [schoolFormData, setSchoolFormData] = useState(SchoolFields);
  const [fileProgress, setFileProgress] = useState({});


  const id = new URLSearchParams(history.location.search).get("id");

  useEffect(() => {
    //clearForm();
    if (id) {
      getActivitySchool(id);
    } else {
      clearForm();
    }
  }, []);

  useEffect(() => {
    getActivities();
  }, []);


  async function uploadLogo(value) {
    let schoolApi = new SchoolApi();
    const data = new FormData();
    data.append("file", value[0]);
    await schoolApi
      .uploadFile(data, (upload) => {
        let pg = { ...fileProgress };
        pg["file"] = Math.round((100 * upload.loaded) / upload.total);
        setFileProgress(pg);
      })
      .then((data) => {
        if (data.fileUrl && data.success) {
          let schoolForm = { ...schoolFormData };
          schoolForm.schoolLogo = data.fileUrl;
          setSchoolFormData(schoolForm);
          toast.success(data.msg);
          delete fileProgress["file"];
        }
      })
      .catch(() => {
        toast.error("Upload failed");
      });
  }

  async function setUpSchool(e) {
    e.preventDefault();
    let ActivitySchoolApi = new activitySchoolApi();

    if (schoolFormData._id) {
      await ActivitySchoolApi.updateActivitySchool({schoolFormData})
        .then((data) => {
          if (data.success) {
            toast.success(data.msg.toString());
            clearForm();
            history.push("/manageactivityschool/schoollist");
          } else {
            toast.error(data.msg);
          }
        })
        .catch((error) => {
          toast.error(error.toString());
          console.error("There was an error!", error);
        });
    } else {
      await ActivitySchoolApi.createActivitySchool({schoolFormData})
        .then((data) => {
          if (data.success) {
            toast.success(data.msg.toString());
            clearForm();
            history.push("/manageactivityschool/schoollist");
          } else {
            toast.error(data.msg);
          }
        })
        .catch((error) => {
          toast.error(error.toString());
          console.error("There was an error!", error);
        });
    }
  }

  function clearForm() {
    let schoolForm = { ...schoolFormData };
    //school fields
    schoolForm._id = "";
    schoolForm.schoolName = "";
    schoolForm.schoolLogo = "";
    // school address
    schoolForm.address.state = "Punjab";
    schoolForm.address.country = "India";
    schoolForm.address.city = "";
    schoolForm.address.pinCode = "";
    schoolForm.address.address = "";
    schoolForm.address.longitude = "";
    schoolForm.address.latitude = "";
    schoolForm.address.allowedRadius = "";
    // admin fields
    schoolForm.adminInfo.firstName = "";
    schoolForm.adminInfo.lastName = "";
    schoolForm.adminInfo.mobile = "";
    schoolForm.adminInfo.email = "";

    setSchoolFormData(schoolForm);
  }

  function onChange(e) {
    let schoolForm = { ...schoolFormData };
    let { name, value } = e.target;
    switch (name) {
      case "schoolName":
        schoolForm.schoolName = value;
        break;
      case "state":
        schoolForm.address.state = value;
        break;
      case "city":
        schoolForm.address.city = value;
        break;
      case "country":
        schoolForm.address.country = value;
        break;
      case "pinCode":
        schoolForm.address.pinCode = value;
        break;
      case "address":
        schoolForm.address.address = value;
        break;
      case "longitude":
        schoolForm.address.longitude = value;
        break;
      case "latitude":
        schoolForm.address.latitude = value;
        break;
      case "allowedRadius":
        schoolForm.address.allowedRadius = value;
        break;
      case "sportsactivitys":
        schoolForm.address.sportsactivitys = value;
        break;

      // admin fields
      case "adminFirstName":
        schoolForm.adminInfo.firstName = value;
        break;
      case "adminLastName":
        schoolForm.adminInfo.lastName = value;
        break;
      case "adminEmail":
        schoolForm.adminInfo.email = value;
        break;
      case "adminMobile":
        schoolForm.adminInfo.mobile = value;
        break;
    }
    setSchoolFormData(schoolForm);
  }

  async function getActivities() {
    let activityApi = new ActivityApi();
    await activityApi
      .getActivities()
      .then((data) => {
        if (data.success) {
          const activity = [];
          for (let i = 0; i < data.data.length; i++) {
            activity.push({
              label: data.data[i].activityName,
              value: data.data[i]._id,
            });
          }
          setActivities(activity);
        } else {
          toast.error(data.msg);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There was an error!", error);
      });
  }

  async function getActivitySchool(id) {
    let activitySchool = new activitySchoolApi();
    await activitySchool
      .getActivitySchoolById(id)
      .then((data) => {
        if (data.success) {
          setSchoolFormData(data.data);
        } else {
          toast.error(data.msg);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There was an error!", error);
      });
  }

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">
          {schoolFormData._id ? "Update" : "Add"} School
        </h3>
      </div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">School Info</h4>
              <form
                className="forms-sample"
                name="reset"
                onSubmit={(e) => setUpSchool(e)}
                id="schooladdform">
                <div className="row">
                    <div className="col-md-12">
                    <Form.Group>
                    <label htmlFor="exampleInputName1">
                      School Name
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </label>
                    <Form.Control
                      type="text"
                      required={true}
                      className="form-control"
                      placeholder="Name"
                      name="schoolName"
                      onChange={(e) => onChange(e)}
                      value={schoolFormData.schoolName}
                    />
                  </Form.Group>
                    </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Country
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="country"
                          onChange={(e) => onChange(e)}
                          value={schoolFormData.address.country}
                          required={true}
                        >
                          <option>India</option>
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        State
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="state"
                          onChange={(e) => onChange(e)}
                          value={schoolFormData.address.state}
                          required={true}
                        >
                          <option value="Andhra Pradesh">Andhra Pradesh</option>
                          <option value="Andaman and Nicobar Islands">
                            Andaman and Nicobar Islands
                          </option>
                          <option value="Arunachal Pradesh">
                            Arunachal Pradesh
                          </option>
                          <option value="Assam">Assam</option>
                          <option value="Bihar">Bihar</option>
                          <option value="Chandigarh">Chandigarh</option>
                          <option value="Chhattisgarh">Chhattisgarh</option>
                          <option value="Dadar and Nagar Haveli">
                            Dadar and Nagar Haveli
                          </option>
                          <option value="Daman and Diu">Daman and Diu</option>
                          <option value="Delhi">Delhi</option>
                          <option value="Lakshadweep">Lakshadweep</option>
                          <option value="Puducherry">Puducherry</option>
                          <option value="Goa">Goa</option>
                          <option value="Gujarat">Gujarat</option>
                          <option value="Haryana">Haryana</option>
                          <option value="Himachal Pradesh">
                            Himachal Pradesh
                          </option>
                          <option value="Jammu and Kashmir">
                            Jammu and Kashmir
                          </option>
                          <option value="Jharkhand">Jharkhand</option>
                          <option value="Karnataka">Karnataka</option>
                          <option value="Kerala">Kerala</option>
                          <option value="Madhya Pradesh">Madhya Pradesh</option>
                          <option value="Maharashtra">Maharashtra</option>
                          <option value="Manipur">Manipur</option>
                          <option value="Meghalaya">Meghalaya</option>
                          <option value="Mizoram">Mizoram</option>
                          <option value="Nagaland">Nagaland</option>
                          <option value="Odisha">Odisha</option>
                          <option value="Punjab">Punjab</option>
                          <option value="Rajasthan">Rajasthan</option>
                          <option value="Sikkim">Sikkim</option>
                          <option value="Tamil Nadu">Tamil Nadu</option>
                          <option value="Telangana">Telangana</option>
                          <option value="Tripura">Tripura</option>
                          <option value="Uttar Pradesh">Uttar Pradesh</option>
                          <option value="Uttarakhand">Uttarakhand</option>
                          <option value="West Bengal">West Bengal</option>
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        City
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="City"
                          name="city"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={schoolFormData.address.city}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Pin code
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Pin code"
                          name="pinCode"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={schoolFormData.address.pinCode}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                  <Form.Group>
                    <label htmlFor="exampleTextarea1">
                      Address
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </label>
                    <textarea
                      className="form-control"
                      rows="4"
                      name="address"
                      required={true}
                      onChange={(e) => onChange(e)}
                      value={schoolFormData.address.address}
                    ></textarea>
                  </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Longitude
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Longitude"
                          name="longitude"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={schoolFormData.address.longitude}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Latitude
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Latitude"
                          name="latitude"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={schoolFormData.address.latitude}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Radius{" "}
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="number"
                          className="form-control"
                          placeholder="Radius"
                          name="allowedRadius"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={schoolFormData.address.allowedRadius}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Select Activities
                        <span style={{ color: "red", fontSize: 14 }}>*</span>{" "}
                      </label>
                      <div className="col-sm-9">
                        <MultiSelect
                          options={activities}
                          labelledBy="Select"
                          value={activities.filter((c) =>
                            schoolFormData.sportsActivityIds.includes(
                              c.value
                            )
                          )}
                          onChange={(e) => {
                            let val = [];
                            e.map(({ value }) => val.push(value));
                            setSelectedactivities(e);
                            let schoolForm = { ...schoolFormData };
                            schoolForm.sportsActivityIds = val;
                            setSchoolFormData(schoolForm);
                          }}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>

                {/* ============== logo/color scheme for schools ============== */}

                <h4 className="card-title">logo for school</h4>
                <div className="row">
                  <div className="col-md-10">
                    <Form.Group>
                      <label>School logo </label>
                      <div className="custom-file">
                        <Form.Control
                          type="file"
                          className="form-control visibility-hidden"
                          id="customFileLang"
                          lang="es"
                          onChange={(e) => {
                            uploadLogo(e.target.files);
                          }}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFileLang"
                        >
                          {schoolFormData.colorScheme &&
                          schoolFormData.schoolLogo
                            ? schoolFormData.colorScheme &&
                              schoolFormData.schoolLogo
                            : "Upload Image"}
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-2">
                    {schoolFormData.schoolLogo ? (
                      <img
                        src={schoolFormData.schoolLogo}
                        style={{ width: 70 }}
                        loading="lazy"
                      />
                    ) : (
                      <img
                        src={require("../../../assets/images/Edvantum.png")}
                        style={{ width: 70 }}
                        loading="lazy"
                      />
                    )}
                  </div>
                </div>

                {/* ============== / logo/color scheme for schools / ============ */}

                {/* ========================================================== */}
                <h4 className="card-title">Admin Info</h4>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        First Name
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="adminFirstName"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={
                            schoolFormData.adminInfo &&
                            schoolFormData.adminInfo.firstName
                          }
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Last Name
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="adminLastName"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={
                            schoolFormData.adminInfo &&
                            schoolFormData.adminInfo.lastName
                          }
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Mobile No.
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="adminMobile"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={
                            schoolFormData.adminInfo &&
                            schoolFormData.adminInfo.mobile
                          }
                          pattern="[0-9]{4,12}"
                          title="Enter vaild mobile number"
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Email
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="email"
                          name="adminEmail"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={
                            schoolFormData.adminInfo &&
                            schoolFormData.adminInfo.email
                          }
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>

                {/* ========================================================== */}
                <div className="row">
                  <div className="col-md-12">
                    <button type="submit" className="btn btn-primary mr-2">
                    {schoolFormData._id ? "Update" : "Submit"}
                  </button>
                  <Link to="/manageactivityschool/schoollist" className="btn btn-light">
                    Cancel
                  </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSchoolForActivities;
