import BaseApi from "./baseApi";

class Activity extends BaseApi {
    async getActivity() {
        return await this.API(
            {
                url: "/activity/getactivity",
                method:"get",
            }
        )
    }
		
		async getActivities() {
        return await this.API(
            {
                url: "/activity/getactivities",
                method:"get",
            }
        )
    }

    async createActivity(data) {
        return await this.API(
            {
                url: "/activity/createactivity",
                data: data,
                method:"post"
            }
        )
    }

    async editActivity(data) {
        return await this.API(
            {
                url: "/activity/updateactivity",
                data: data,
                method:"post"
            }
        )
    }

    async deleteActivity(data) {
        return await this.API(
            {
                url: "/activity/deleteactivity",
                data: { _id: data },
                method:"post"
            }
        )
    }

}

export default Activity;