const dummyData = [
  { 
    admissionNo : 1001,
    class: "Play-Way",
    name: "Chanchal",
    contactNo: 8915796335,
    atttendance: "present" ,
    feeStatus: "paid"
  },
  { admissionNo: 1002,
    class: "Pre-Nursery",
    name: "Manju",
    contactNo: 8493496335,
    atttendance: "present" ,
    feeStatus: "paid"
  },
  { 
    admissionNo :1003,
    class: "Pre-Nursery",
    name: "Manika",
    contactNo:5691557345,
    atttendance: "present" ,
    feeStatus: "unpaid"
  },
  { 
    admissionNo:1004,
    class: "Nursery",
    name: "Arun",
    contactNo: 9455796335,
    atttendance: "absent" ,
    feeStatus: "unpaid"
  },
  { 
    admissionNo:1005,
    class: "UKG",
    name: "Harpreet",
    contactNo: 6315796335,
    atttendance: "present" ,
    feeStatus: "paid"
  },
  { 
    admissionNo :1006,
    class: "I",
    name: "Arun",
    contactNo:4565796335,
    atttendance: "present" ,
    feeStatus: "paid"
  },
  { 
    admissionNo:1007,
    class: "II",
    name: "Suresh",
    contactNo: 8765796335,
    atttendance: "absent" ,
    feeStatus: "paid"
  },
  { 
    admissionNo:1008,
    class: "III",
    name: "Dinesh" ,
    contactNo: 6755796335,
    atttendance: "present" ,
    feeStatus: "paid"   
  },
  { 
    admissionNo:1009,
    class: "IV",
    name: "Rohit",
    contactNo: 6235796335,
    atttendance: "absent" ,
    feeStatus: "unpaid"
  },
  { 
    admissionNo:1010,
    class: "V",
    name: "Kamlesh",
    contactNo: 700796335,
    atttendance: "present" ,
    feeStatus: "paid"

  },
  { 
    admissionNo :1011,
    class: "VI",
    name: "Atif",
    contactNo: 8907796335,
    atttendance: "present" ,
    feeStatus: "paid"
  },
  { admissionNo: 1012,
    class: "VII",
    name: "Manthan",
    contactNo: 5675796335,
    atttendance: "present" ,
    feeStatus: "unpaid"
  },
  { 
    admissionNo:1013,
    class: "VIII",
    name: "Vishal",
    contactNo: 8955796335,
    atttendance: "absent" ,
    feeStatus: "unpaid"

  },
  { 
    admissionNo:1014,
    class: "IX",
    name: "Arjun",
    contactNo: 8005796335,
    atttendance: "present" ,
    feeStatus: "paid"
  },
  { 
    admissionNo:1015,
    class: "X",
    name: "Manjit",
    contactNo: 8775796335,
    atttendance: "leave" ,
    feeStatus: "unpaid"
  },
  { 
    admissionNo:1016,
    class: "XI",
    name: "Mahesh",
    contactNo: 7665796335,
    atttendance: "present" ,
    feeStatus: "paid"
  },
  { 
    admissionNo:1017,
    class: "XII",
    name: "Abhijit",
    contactNo: 2345796335,
    atttendance: "present" ,
    feeStatus: "unpaid"
  },
  { 
    admissionNo:1018,
    class: "XII",
    name: "Komal",
    contactNo: 7895796335,
    atttendance: "leave" ,
    feeStatus: "unpaid"
  },
  { 
    admissionNo:1019,
    class: "XII",
    name: "Monu",
    contactNo: 8005796335,
    atttendance: "present" ,
    feeStatus: "unpaid"
  },
  { 
    admissionNo:1020,
    class: "XII",
    name: "Sonu",
    contactNo: 700796335,
    atttendance: "leave" ,
    feeStatus: "paid"
  },
  { 
    admissionNo:1021,
    class: "XI",
    name: "Mahesh",
    contactNo: 600796335,
    atttendance: "present" ,
    feeStatus: "paid"
  },
  { 
    admissionNo :1022,
    class: "V",
    name: "Sunaina",
    contactNo: 4565796335,
    atttendance: "present" ,
    feeStatus: "paid"
  },
  { 
    admissionNo : 1023,
    class: "VI",
    name: "Sonali",
    contactNo: 9005796335,
    atttendance: "leave" ,
    feeStatus: "paid"
  },
  { 
    admissionNo : 1024,
    class: "IV",
    name: "Ayushman",
    contactNo: 9895796335,
    atttendance: "present" ,
    feeStatus: "unpaid"
  },
  { 
    admissionNo : 1025,
    class: "II",
    name: "Varun",
    contactNo: 9345796335,
    atttendance: "present" ,
    feeStatus: "paid"
  }
];

export default dummyData;