import React, { createContext, useEffect, useState } from "react";
export const GlobalContext = createContext({
  statuses:'',
});
export const GlobalProvider = (props) => {
  const [globalContext, setGlobalContext] = useState({
    statuses:'',
  });

  useEffect(() => {
  }, []);
  return (
    <GlobalContext.Provider value={[globalContext, setGlobalContext]}>
      {props.children}
    </GlobalContext.Provider>
  );
};