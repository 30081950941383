import BaseApi from "./baseApi";

class Gallery extends BaseApi {
    async getGallery() {
        return await this.API(
            {
                url: "/edvantum/getGallery",
                method: "get",
            }
        )
    }

    async updateGallery(data) {
        return await this.API(
            {
                url: "/edvantum/updategallery",
                data: data,
                method: "post"
            }
        )
    }

    async deleteGallery(data) {
        return await this.API(
            {
                url: "/edvantum/deletegallery",
                data: data,
                method: "post"
            }
        )
    }

}

export default Gallery;