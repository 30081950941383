import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import PrivacyPolicy from "../../pages/web/privacyPolicy";
import TermsOfUse from "../../pages/web/termsOfUse";
import RefundAndCancellation from "../../pages/web/refundAndCancellation";
import Home from "../../pages/web/home";
import ForgotPassword from "../../pages/forgotPassword";
import Unsubscribe from "../../pages/unsubscribe";
import SetNewPassword from "../../pages/setNewPassword";
import Parent from "../../pages/web/parent";
import School from "../../pages/web/school";
import Teacher from "../../pages/web/teacher";
import AboutUs from "../../pages/web/aboutUs";
import Activity from "../../pages/web/activity";
import Pricing from "../../pages/web/pricing";
import Event from "../../pages/web/event";
import "../../assets/styles/web/imagehover.min.css";
import "../../assets/styles/web/style-new.css";

const WebLayout = () => {
  const location = useLocation();
  console.log('WebLayout')
  return (
    <React.Fragment>
      {location.pathname == "/forgotpassword" ||
        location.pathname == "/unsubscribe" ||
        location.pathname == "/resetpassword" ? (
        <Switch>
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/unsubscribe" component={Unsubscribe} />
          <Route path="/resetpassword" component={SetNewPassword} />
        </Switch>
      ) : (
        <React.Fragment>
          <Header />
          <Switch>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            <Route path="/home" component={Home} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/terms-of-use" component={TermsOfUse} />
            <Route path="/refund-and-cancellation" component={RefundAndCancellation} />
            <Route path="/parents" component={Parent} />
            <Route path="/schools" component={School} />
            <Route path="/teachers" component={Teacher} />
            <Route path="/about-us" component={AboutUs} />
            <Route path="/activities" component={Activity} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/events" component={Event} />
          </Switch>
          <Footer />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default WebLayout;
