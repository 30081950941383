import React, { createContext } from "react";

const initialisState = {
	"classId": '',
	"className": '',
	"dayName": '',
	"slotId": '',
	"subjectId": '',
	"subjectName": '',
	"teacherId":'',
	"teacherName": '',
	"timeFrom": '',
	"timeTo": '',
}
export const TimeTableContext = createContext({});

export const TimeTableProvider = (props) => {

	return (
		<TimeTableContext.Provider value={initialisState}>
			{props.children}
		</TimeTableContext.Provider>
	)
}