import React, { useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import moment from "moment";
import Grid from "../../../components/dataTable";
const PreviousFeeRecordModal = ({data, handleClose2, rows, feeSchedulesData, monthsList}) =>{
    const paymentSchedules = (props) => {
        if (feeSchedulesData.lateFees) {
          return feeSchedulesData.lateFees.map((item, i) => {
            let pm = 0;
            return (
              <div key={i}>
                {item.months.map((mt, k) => {
                  let mch = props.feeforMonths.filter((f) => {
                    return f == mt.value;
                  });
                  if (mch.length && pm == 0) {
                    pm = 1;
                    return (
                      <p>
                        {feeSchedulesData.lateFees &&
                          feeSchedulesData.lateFees[i] &&
                          feeSchedulesData.lateFees[i].months.map((m, s) => (
                            <span key={k} className="badge badge-info">
                              {m.label}{" "}
                            </span>
                          ))}
                      </p>
                    );
                  }
                })}
              </div>
            );
          });
        }
    };

    const paidForMonth = (props) => {
        let monthsStr = "";
        props.feeforMonths.map((item1, key1) => {
          monthsList.map((item2) => {
            if (item1 === parseInt(item2.value)) {
              let monthName = item2.label.split("---");
              monthsStr +=
                key1 === props.feeforMonths.length - 1
                  ? monthName[0]
                  : monthName[0] + ", ";
            }
          });
        });
        return <span className="badge badge-success">{monthsStr}</span>;
    };

    const pendingFeeMonth = (props) => {
        if (feeSchedulesData.lateFees) {
          let schedule = feeSchedulesData.lateFees.find((item, i) => {
            return item.months.find((mt) => {
              return props.feeforMonths.find((d) => {
                return d == mt.value;
              });
            });
          });
          if (schedule) {
            return schedule.months.map((item, i) => {
              let paid = props.feeforMonths.find((p) => {
                return item.value == p || item.value <= p;
              });
              if (paid == undefined) {
                return <span className="badge badge-danger">{item.label}</span>;
              }
            });
          }
        }
    };

    const notpaid = (props) => {
        let balanceAmount =
          parseInt(props.totalAmount) - parseInt(props.paidAmount);
        let pandingFee =
          parseInt(props.scheduleTotalAmount) - parseInt(props.totalAmount);
        let totalPendingFee =
          parseInt(pandingFee - props.concessionAmount) + parseInt(balanceAmount);
        return (
          <span>
            {totalPendingFee}
            {Math.sign(totalPendingFee) == -1 ? (
              <span className="badge badge-danger">Paid Extra</span>
            ) : null}
          </span>
        );
      };
    
    const paymentType = (props) => {
        if (props.paymentDetails) {
            return <span>{props.paymentDetails.paymentMode}</span>;
        }
    };

    const paymentDate = (props) => {
        if (props.paymentDate) {
            return <span>{moment(props.paymentDate).format("DD-MM-YYYY")}</span>;
        } else {
            return <span></span>;
        }
    };

    const field = () => {
        const fields = [
          {
            title: "Payment Schedule",
            render: paymentSchedules,
          },
          {
            title: "Paid For months",
            render: paidForMonth,
          },
          {
            title: "Fees for Pending Month(s)",
            render: pendingFeeMonth
          },
          {
            title: "Session",
            field: "feeForSession",
          },
          {
            title: "Payment Type",
            render: paymentType,
          },
          {
            title: "Payment Date",
            render: paymentDate,
          },
          {
            title: "Schedule Amount",
            field: "scheduleTotalAmount",
          },
          {
            title: "Concession Amount",
            field: "concessionAmount",
          },
          {
            title: "Late Fees",
            field: "lateFeeAmount",
          },
          {
            title: "Total Payable Fees",
            field: "totalAmount",
          },
          {
            title: "Fees Paid",
            field: "paidAmount",
          },
          {
            title: "Pending Fees",
            render: notpaid,
          },
        ];
        return fields;
    };
    return (
        <Modal
        show={data.isModalVisible2}
        size="xl"
        onHide={() => handleClose2()}
      >
        <Modal.Header closeButton>
          <h3 className="page-title">Previous Fee Records</h3>
        </Modal.Header>
        <div className="card">
          <div className="card-body text-center">
            {rows.length ? (
              <ul
                className="list-group mb-4"
                style={{ width: "40%", marginLeft: "30%" }}
              >
                <li className="list-group-item" style={{ padding: 0 }}>
                  <strong>Name: </strong>
                  {rows[0].students
                    ? rows[0].students.firstName +
                    " " +
                    rows[0].students.lastName
                    : null}
                </li>
                <li className="list-group-item" style={{ padding: 0 }}>
                  <strong>Admission No: </strong>
                  {rows[0].students ? rows[0].students.admissionNo : null}
                </li>
                <li className="list-group-item" style={{ padding: 0 }}>
                  <strong>Class Name: </strong>
                  {rows[0].classes ? rows[0].classes.className : null}
                  {rows.length && rows[0].classes.section
                    ? "-" + rows[0].classes.section
                    : null}
                </li>
              </ul>
            ) : null}

            <Grid columns={field()} rows={rows} />
          </div>
        </div>
        <Modal.Footer style={{ backgroundColor: "#ffffff" }}>
          <div className="row">
            <div className="col-sm-12">
              <span>FEE ONCE PAID IS NOT REFUNDABLE</span>
            </div>
          </div>
          <Button variant="secondary" onClick={() => handleClose2()}>
            Print
          </Button>
          <Button variant="secondary" onClick={() => handleClose2()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default PreviousFeeRecordModal;