import React, { useEffect, useState } from "react";
import Grid from "../../../../components/dataTable";
import ReactTooltip from "react-tooltip";
import dummyData from "../dummyData/dataStudent";
import { Link } from "react-router-dom";

const StudentGrid = () => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    getDummyData()
  }, [])

  const getDummyData = () => {
    setRows(dummyData);
  }

  const fields = () => {
    let fields = [
      {
        title: "Admission No",
        field: "admissionNo",
      },
      {
        title: "Name",
        field: "name"
      },
      {
        title: "Class",
        field: "class",
      },
      {
        title: "Contact No",
        field: "contactNo"
      },
      {
        title: "Attendance",
        field: "atttendance"
      },
      {
        title: "Fee",
        render: renderActionFee,
      },
      {
        name: "action",
        title: "Actions ",
        render: renderAction,
      }
    ]
    return fields;
  }

  const renderActionFee = (props, index) => {
    return (
      <div>
        {
          props.feeStatus === "paid" ?
            <span className="badge badge-success">Paid</span> :
            <span className="badge badge-danger">Unpaid</span>
        }
      </div>
    )
  }

  const renderAction = (props, index) => {
    return (
      <span>
        <ReactTooltip id="ViewReport" type="warning" effect="solid">
          <span>View Detail</span>
        </ReactTooltip>
        <Link className="btn btn-primary" to={`/report/${props.admissionNo}`}>
          Detail
        </Link>
      </span>
    )
  }

  return (
    <Grid columns={fields()} rows={rows} />
  )

}

export default StudentGrid;