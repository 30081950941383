import React, { useLayoutEffect } from "react";
const School = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <section id="schools" className="section-padding ">
            <div className="container">
                <div className="">
                    <div className="header-section text-center margin-btm">
                        <h2 className="title__line">Schools</h2>
                        <p className="tagline">
                            Digital solutions to drive your next level of success <br />
                            Innovative digital solutions to help you grow to next level
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="service-box">
                                <div className="icon-text1">
                                    <h4 className="ser-text">Digitize</h4>
                                    <ul>
                                        <li>Real time Dashboard</li>
                                        <li>Cloud based records</li>
                                        <li>Paperless administration</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-box">
                                <div className="icon-text1">
                                    <h4 className="ser-text">
                                        Comprehensive development (Activities)
                                    </h4>
                                    <ul>
                                        <li>Sporting activities</li>
                                        <li>Performing arts</li>
                                        <li>Sharpen Language and Analytical skills</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-box">
                                <div className="icon-text1">
                                    <h4 className="ser-text">Enabling Growth</h4>
                                    <ul>
                                        <li>Enhance Reach</li>
                                        <li>Wholistic offering</li>
                                        <li>Higher Engagement</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default School;
