import React from "react";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";

const Buses = () => {

  function downloadBusCSVFormat() {
    let headers = [
      { label: "Arriva Time", key: "arrivalTime" },
      { label: "Bus No", key: "busNo" },
      { label: "Capacity", key: "capacity" },
      { label: "Days Of Week", key: "daysOfWeek" },
      { label: "Departure Time", key: "departureTime" },
      { label: "Driver Name", key: "driverName" },
      { label: "Leave From", key: "leaveFrom" },
      { label: "Monitor Name", key: "monitorName" },
      { label: "Route No", key: "routeNo" },
      { label: "Stops BusFees", key: "stopsBusFees" },
      { label: "Stops Destination", key: "stopsDestination" },
    ];

    return (
      <CSVLink
      data={[{
        arrivalTime: "08:15",
        busNo: "1234",
        capacity: "20",
        daysOfWeek: "[4,5,6]",
        departureTime: "06:30",
        driverName: "Ammy",
        leaveFrom: "school",
        monitorName: "Amanda",
        routeNo: "01",
        stopsBusFees: "100",
        stopsDestination: "sector 35"
      }]}
        filename={"Sample_Buses_Import.csv"}
        headers={headers}
      >
        Download &amp; View File Format
      </CSVLink>
    );
  }

  return (
    <>
    <button type="button" className="btn mr-0 ml-0 pr-0 pl-0"></button>
    Import Bulk Data (<code>{downloadBusCSVFormat()}</code>)
    </>
  );

}

export default Buses;