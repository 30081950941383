import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const StudentAttendanceAreaChart = ({ data }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    if (!data || data.length === 0) return;

    const width = 300;
    const height = 300;

    const svg = d3.select(svgRef.current)
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(50,0)`);

    const xScale = d3.scaleBand()
      .domain(data.map(d => d.month))
      .range([0, width - 50])
      .padding(0.1);

    const yScale = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.presentCount + (d.absentCount || 0))])
      .range([height, 0]);

    // Draw Area
    const area = d3.area()
      .x(d => xScale(d.month) + xScale.bandwidth() / 2)
      .y0(height)
      .y1(d => yScale(d.presentCount + (d.absentCount || 0)));

    svg.append('path')
      .data([data])
      .attr('d', area)
      .attr('fill', 'orange')
      .attr('opacity', 0.7);

    // Display total present percentage on each data point
    svg.selectAll('.percentage-text')
      .data(data)
      .enter()
      .append('text')
      .attr('class', 'percentage-text')
      .attr('x', d => xScale(d.month) + xScale.bandwidth() / 2)
      .attr('y', d => yScale(d.presentCount + (d.absentCount || 0)) + 15) // Adjusted the y position
      .attr('text-anchor', 'middle')
      .attr('font-size', '8px')
      .attr('fill', 'black')
      .text(d => {
        const total = d.presentCount + (d.absentCount || 0);
        const percentage = ((d.presentCount / total) * 100).toFixed(2);
        return `Present: ${percentage}%`;
      });

  }, [data]);

  return (
    <svg ref={svgRef}></svg>
  );
};

export default StudentAttendanceAreaChart;