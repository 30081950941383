import React from "react";
import { CSVLink } from "react-csv";

const ExportNonTeachingStaff = ({ NonTeachingStaffData = null }) => {

  function downloadStaffMemberCSVFormat() {
    let headers = [
      { label: "First Name", key: "firstName" },
      { label: "Last Name", key: "lastName" },
      { label: "Mobile", key: "mobile" },
      { label: "Email", key: "email" },
      { label: "Password", key: "password" },
      { label: "Image", key: "photoUrl" },
      { label: "DOB", key: "dob" },
      { label: "Gender", key: "gender" },
      { label: "Country", key: "address.country" },
      { label: "State", key: "address.state" },
      { label: "City", key: "address.city" },
      { label: "Pin Code", key: "address.pinCode" },
      { label: "Address", key: "address.address" },
      { label: "User Role", key: "userRoles.userRole" },
      { label: "Sub Role", key: "userRoles.subRole" },
      { label: "Introduction", key: "introduction" },
    ];

    return (
      <CSVLink
        data={NonTeachingStaffData}
        filename={"Non_Teaching_Staff.csv"}
        headers={headers}
      >
      <i className="mdi mdi-download"></i> Export Non Teaching Staff Data
      </CSVLink>
    );
  }

  return (
    <div>{downloadStaffMemberCSVFormat()}</div>
  );

}

export default ExportNonTeachingStaff;