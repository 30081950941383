import React, { Fragment, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { GlobalProvider } from "../contexts/globalContext";
import SchoolAdminLayout from "../layouts/schooladmin/schoolAdminLayout.jsx";
import SuperAdminLayout from "../layouts/superadmin/superAdminLayout";
import WebLayout from "../layouts/web/webLayout";
import PublicLayout from "../layouts/public/publicLayout";
// import "../assets/styles/web/imagehover.min.css";
// import "../assets/styles/web/style@old.css";
// import "../assets/styles/web/style.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';

import { toast } from "react-toastify";
import AuthApi from "../api/authApi";
import {Helmet} from 'react-helmet-async';

// const WebLayout = React.lazy(() => import("../layouts/web/webLayout"));
// const PublicLayout = React.lazy(() => import("../layouts/public/publicLayout"));
// const SuperAdminLayout = React.lazy(() => import("../layouts/superadmin/superAdminLayout"));
// const SchoolAdminLayout = React.lazy(() => import( "../layouts/schooladmin/schoolAdminLayout"));

const Login = () => {
  let location = useLocation();
  const history = useHistory();
  const [user, setUser] = useState({ email: "", password: "", otp: "" });
  const [isEmailOrMobile, setIsEmailOrMobile] = useState(false);
  // console.log("isEmailOrMobile",isEmailOrMobile)
  const [isPassword, setIsPassword] = useState(false)
  const [isOTP, setIsOTP] = useState(false)

  let loginUser = localStorage.getItem("user_info")
    ? localStorage.getItem("user_info" || "")
    : null;
  loginUser = JSON.parse(loginUser);
  function handleChange(event) {
    const { name, value } = event.target;
    //here set the state and pass
    let c = { ...user };
    if (name === "email") {
      c.email = value;
    } else if (name === "password") {
      c.password = value;
    } else if (name === 'otp') {
      c.otp = value
    }
    setUser(c);
  }

  async function checkEmailOrMobile() {
    if (user.email.includes("@")) {
      let authApi = new AuthApi();
      await authApi
        .checkEmail(user.email)
        .then((res) => {
          if (res.status) {
            setIsEmailOrMobile(true);
            setIsPassword(true);
            let element = document.getElementById("submitBtn")
            element.classList.remove("btn-block")
            return;
          } else if (res.error) {
            toast.error(res.msg.toString());
            let element = document.getElementById("submitBtn")
            element.classList.add("btn-block")
          }


          if (res.success && res.data) {
            localStorage.setItem("token", res.data.accessToken);
            if (res.data.schools.length) {
              localStorage.setItem(
                "schoolColorScheme",
                JSON.stringify(res.data.schools[0].colorScheme)
              );
            }
            let loginUser = { ...user };
            loginUser["role"] = res.data.role;

            let userData = {
              userId: res.data.userId,
              email: res.data.email,
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              role: res.data.role,
              accessToModules: res.data.accessToModules,
              currentSchoolId: res.data.currentSchoolId,
              nonStaff: res.data.nonStaff,
              token: res.data.accessToken,
            };
            if (res.data.schools && res.data.schools.length) {
              localStorage.setItem("schoolId", res.data.schools[0]._id);
              localStorage.setItem("schoolInfo", res.data.schools[0].schoolName);
            }
            localStorage.setItem("user_info", JSON.stringify(userData));
            setUser(loginUser);
          } else {
            toast.error(res.msg);
          }
        })
        .catch((error) => {
          toast.error(error.toString());
        })
    } else {
      let authApi = new AuthApi();
      await authApi
        .checkForMobileAndSendOTP(user.email)
        .then((res) => {
          if (res.success & res.mobile) {
            setIsEmailOrMobile(true);
            setIsOTP(true);
            toast.success(res.msg.toString());
            let element = document.getElementById("submitBtn")
            element.classList.remove("btn-block")
            return;
          } else if (res.error) {
            toast.error(res.msg.toString());
            let element = document.getElementById("submitBtn")
            element.classList.add("btn-block")
          }
        })
        .catch((error) => {
          toast.error(error.toString());
        });
    }
  }

  function hidePassOtpField() {
    let element = document.getElementById("submitBtn")
    element.classList.add("btn-block")
    setIsEmailOrMobile(false)
    setIsOTP(false)
    setIsPassword(false)
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if (isPassword || isOTP) {
      //here send the request
      let sendObj;
      if (isPassword) {
        sendObj = {
          email: user.email,
          password: user.password
        }
      }
      if (isOTP) {
        sendObj = {
          mobile: user.email,
          otp: user.otp
        }
      }
      let authApi = new AuthApi();
      await authApi
        .login(sendObj)
        .then((res) => {
          if (res.success && res.data && res.data.nonStaff) {
            return toast.error(
              "You are not allowed to login. Please contact your admin."
            );
          }
          if (res.success && res.data) {
            localStorage.setItem("token", res.data.accessToken);
            if (res.data.schools.length) {
              localStorage.setItem(
                "schoolColorScheme",
                JSON.stringify(res.data.schools[0].colorScheme)
              );
            }
            let loginUser = { ...user };
            loginUser["role"] = res.data.role;

            let userData = {
              userId: res.data.userId,
              email: res.data.email,
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              role: res.data.role,
              accessToModules: res.data.accessToModules,
              currentSchoolId: res.data.currentSchoolId,
              nonStaff: res.data.nonStaff,
              token: res.data.accessToken,
            };
            if (res.data.schools && res.data.schools.length) {
              localStorage.setItem("schoolId", res.data.schools[0]._id);
              localStorage.setItem("schoolInfo", res.data.schools[0].schoolName);
            }
            localStorage.setItem("user_info", JSON.stringify(userData));
            setUser(loginUser);
            setIsPassword(false);
            setIsOTP(false);
            setIsEmailOrMobile(false)
            history.push("/dashboard");
          } else {
            toast.error(res.msg);
          }
        })
        .catch((error) => {
          toast.error(error.toString());
        });

    } else {
      checkEmailOrMobile();
    }
  }

  let dis = "Login Edvantum Admin and School Admin";
  let keyword = "School Admin, Edvantum, Digiatal Solution for Schools";
  let tt = "EDVANTUM - Login School Admin";
  return (
    <Fragment>
        <Helmet>
          <title>{tt}</title>
          <meta
              name="description"
              content={dis}
          />
          <meta
              name="Keywords"
              content={keyword}
          />
        </Helmet>
      {
        !loginUser || !loginUser.token && !loginUser.role ? (
          location.pathname == "/login" ? (
            <div
              className="d-flex align-items-center auth px-0"
              style={{ height: "45pc" }}
            >
              <div className="row w-100 mx-0">
                <div className="col-lg-4 mx-auto">
                  <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                    <div className="brand-logo mb-3">
                      <Link to="/home">
                        <img
                          src={require("../assets/images/Edvantum.png")}
                          alt="logo"
                          style={{ width: "200px" }}
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <h4>Hello! let's get started</h4>
                    <h6 className="font-weight-light">Sign in to continue.</h6>
                    <Form className="pt-3" onSubmit={(e) => handleSubmit(e)}>
                      <Form.Group className="d-flex search-field">

                      <Form.Control
                          type="text"
                          onChange={(e) => handleChange(e)}
                          placeholder="Login with Email or Mobile"
                          size="lg"
                          name="email"
                          value={user.email ||''}
                          className="h-auto"
                          required={true}
                          disabled={isEmailOrMobile}
                        />
                        {/* <Form.Control
                          type="text"
                          onChange={(e) => handleChange(e)}
                          placeholder="Login with Email or Mobile"
                          size="lg"
                          name="email"
                          className="h-auto"
                          required={true}
                          disabled={isEmailOrMobile}
                        /> */}
                      </Form.Group>
                      {isPassword ?
                        <Form.Group className="d-flex search-field">
                          <Form.Control
                            type="password"
                            onChange={(e) => handleChange(e)}
                            placeholder="Password"
                            value={user.password ||''}
                            size="lg"
                            name="password"
                            className="h-auto"
                          />
                        </Form.Group> : null}
                      {isOTP ?
                        <Form.Group className="d-flex search-field">
                          <Form.Control
                            type="text"
                            onChange={(e) => handleChange(e)}
                            placeholder="Enter OTP"
                            size="lg"
                            name="otp"
                            className="h-auto"
                          />
                        </Form.Group> : null}
                      <div className="mt-3">
                        <Button
                          type="submit"
                          className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" id="submitBtn"
                        >
                        
                          {isEmailOrMobile ? "Login" : "Submit"}
                        </Button>
                        {isEmailOrMobile ? <Button type="button" className="btn btn-primary btn-lg font-weight-medium auth-form-btn cancel-login" id="cancelBtn" variant="secondary" onClick={() => hidePassOtpField()}>Back</Button> :null}
                        
                      </div>
                      <div className="my-2 d-flex justify-content-between align-items-center">
                        <Link
                          to="/forgotpassword"
                          className="auth-link text-black"
                        >
                          Forgot password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <GlobalProvider>
                <WebLayout />
            </GlobalProvider>
          )
        ) : loginUser &&
          (loginUser.role === "schoolAdmin" ||
            loginUser.role === "principal" ||

            loginUser.role === "teacher") ? (
          <GlobalProvider>
              <SchoolAdminLayout />
          </GlobalProvider>
        ) : loginUser && loginUser.role === "superAdmin" ? (
          <GlobalProvider>
              <SuperAdminLayout />
          </GlobalProvider>
        ) : (
          <GlobalProvider>
              <PublicLayout />
          </GlobalProvider>
        )
      }
    </Fragment>
  );
};

export default Login;
