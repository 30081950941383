import BaseApi from "./baseApi";

class Support extends BaseApi {
    async getSupport() {
        return await this.API(
            {
                url: "/edvantum/getsupport",
                method: "get",
            }
        )
    }

    async updateSupport(data) {
        return await this.API(
            {
                url: "/edvantum/updatesupport",
                data: data,
                method: "post"
            }
        )
    }

}

export default Support;