import BaseApi from "./baseApi";

class ResultApi extends BaseApi {
  async saveResults(data) {
    return await this.API({
      url: "/result/saveResults",
      data: data,
      method: "post"
    })
  }

  async getResults(data) {
    return await this.API({
      url: "/result/getresults",
      data: data,
      method: "post"
    })
  }

  async getResultDetails(data) {
    return await this.API({
      url: "/result/getresultDetails",
      data: data,
      method: "post"
    })
  }

  async generateResultPDF(data) {
    return await this.API({
      url: "/result/generateResultPDF",
      data: data,
      method: "post"
    })
  }

  async getStudentResult(studentId) {
    return await this.API({
      url: "/result/getstudentresult",
      data: { studentId },
      method: "post"
    })
  }
}
export default ResultApi;