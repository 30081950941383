import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import bsCustomFileInput from "bs-custom-file-input";
import { HexColorPicker } from "react-colorful";
import { Link, useHistory, useLocation } from "react-router-dom";
import { SchoolFields } from "./schoolFields";
import SchoolApi from "../../../api/schoolApi";
import ClassApi from "../../../api/classApi";
import { toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";
const AddSchool = () => {
  let history = useHistory();
  const id = new URLSearchParams(history.location.search).get("id");
  useEffect(() => {
    //clearForm();
    if (id) {
      getSchool(id);
    } else {
      clearForm();
    }

  }, []);

  const [schoolFormData, setSchoolFormData] = useState(SchoolFields);
  const [fileProgress, setFileProgress] = useState({});

  async function uploadLogo(value) {
    let schoolApi = new SchoolApi();
    const data = new FormData();
    data.append("file", value[0]);
    await schoolApi
      .uploadFile(data, (upload) => {
        let pg = { ...fileProgress };
        pg["file"] = Math.round((100 * upload.loaded) / upload.total);
        setFileProgress(pg);
      })
      .then((data) => {
        if (data.fileUrl && data.success) {
          let schoolForm = { ...schoolFormData };
          if(data.fileUrl.indexOf("https://edvantumdev.76east.com")){
            let arr = data.fileUrl.split('/')
            data.fileUrl = "https://edvantum.com/" + arr[3]+'/' + arr[4]+'/' + arr[5]
          }
          schoolForm.colorScheme.schoolLogo = data.fileUrl;
          setSchoolFormData(schoolForm);
          toast.success(data.msg);
          delete fileProgress["file"];
        }
      })
      .catch(() => {
        toast.error("Upload failed");
      });
  }

  async function setUpSchool(e) {
    e.preventDefault();
    let schoolApi = new SchoolApi();
    await schoolApi
      .create(schoolFormData)
      .then((data) => {
        if (data.success) {
          toast.success(data.msg.toString());
          clearForm();
          history.push("/manageschool");
        } else {
          toast.error(data.msg);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There was an error!", error);
      });
  }

  function clearForm() {
    let schoolForm = { ...schoolFormData };
    //school fields
    schoolForm.schoolName = "";
    schoolForm.databaseName = "";
    schoolForm.subdomain = "";
    schoolForm.address.state = "Punjab";
    schoolForm.address.country = "India";
    schoolForm.address.city = "";
    schoolForm.address.pinCode = "";
    schoolForm.address.address = "";
    schoolForm.address.longitude = "";
    schoolForm.address.latitude = "";
    schoolForm.address.allowedRadius = "";
    // color scheme fields
    schoolForm.colorScheme.backgroundColor = "#181824";
    schoolForm.colorScheme.fontColor = "#44ce42";
    schoolForm.colorScheme.schoolLogo = "";
    // admin fields
    schoolForm.adminInfo.firstName = "";
    schoolForm.adminInfo.lastName = "";
    schoolForm.adminInfo.mobile = "";
    schoolForm.adminInfo.email = "";
    schoolForm.adminInfo.password = "";
    schoolForm._id= "";
    setSchoolFormData(schoolForm);
  }

  function onChange(e) {
    let schoolForm = { ...schoolFormData };
    let { name, value } = e.target;
    switch (name) {
      case "schoolName":
        schoolForm.schoolName = value;
        let id = new URLSearchParams(history.location.search).get("id");
        if (!id && schoolForm.databaseName.length < 17) {
          schoolForm.databaseName = value.toLowerCase().replace(/[^a-z]/g, "");
        }
        break;
      case "state":
        schoolForm.address.state = value;
        break;
      case "city":
        schoolForm.address.city = value;
        break;
      case "country":
        schoolForm.address.country = value;
        break;
      case "pinCode":
        schoolForm.address.pinCode = value;
        break;
      case "address":
        schoolForm.address.address = value;
        break;
      case "longitude":
        schoolForm.address.longitude = value;
        break;
      case "latitude":
        schoolForm.address.latitude = value;
        break;
      case "allowedRadius":
        schoolForm.address.allowedRadius = value;
        break;
      //color Scheme & logo
      case "backgroundColor":
        schoolForm.colorScheme.backgroundColor = value;
        break;
      case "fontColor":
        schoolForm.colorScheme.fontColor = value;
        break;
      // admin fields
      case "adminFirstName":
        schoolForm.adminInfo.firstName = value;
        break;
      case "adminLastName":
        schoolForm.adminInfo.lastName = value;
        break;
      case "adminEmail":
        schoolForm.adminInfo.email = value;
        break;
      case "adminMobile":
        schoolForm.adminInfo.mobile = value;
        break;
      case "adminPassword":
        schoolForm.adminInfo.password = value;
        break;
      case "startsFromMonth":
    }
    setSchoolFormData(schoolForm);
  }

  async function getSchool(id) {
    let schoolApi = new SchoolApi();
    await schoolApi
      .getSchool(id)
      .then((data) => {
        if (data.success) {
          if(data.data.colorScheme.schoolLogo.indexOf("https://edvantumdev.76east.com")){
            let arr = data.data.colorScheme.schoolLogo.split('/')
            data.data.colorScheme.schoolLogo = "https://edvantum.com/" + arr[3]+'/' + arr[4]+'/' + arr[5]
          }
          setSchoolFormData(data.data);
        } else {
          toast.error(data.msg);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There was an error!", error);
      });
  }

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Setup New School</h3>
        <button className="btn btn-primary" onClick={()=>{
          history.push("/setupschool");
          clearForm()
        }}> Setup New School </button>
      </div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">School Info</h4>
              <form className="forms-sample" name='reset' onSubmit={(e) => setUpSchool(e)} id="schooladdform">
                <Form.Group>
                  <label htmlFor="exampleInputName1">
                    School Name{" "}
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </label>
                  <Form.Control
                    type="text"
                    required={true}
                    className="form-control"
                    placeholder="Name"
                    name="schoolName"
                    onChange={(e) => onChange(e)}
                    value={schoolFormData.schoolName}
                  />
                </Form.Group>

                <Form.Group>
                  <label>
                    Database Name{" "}
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </label>
                  <Form.Control
                    type="text"
                    required={true}
                    className="form-control"
                    placeholder="Name"
                    name="databaseName"
                    onChange={(e) => onChange(e)}
                    pattern="[a-z]{3,17}"
                    title="Database length should be minimum 3 and maximum 17, All words should be character"
                    value={schoolFormData.databaseName}
                    disabled={true}
                  />
                </Form.Group>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Country{" "}
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="country"
                          onChange={(e) => onChange(e)}
                          value={schoolFormData.address.country}
                          required={true}
                        >
                          <option>India</option>
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        State{" "}
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="state"
                          onChange={(e) => onChange(e)}
                          value={schoolFormData.address.state}
                          required={true}
                        >
                          <option value="Andhra Pradesh">Andhra Pradesh</option>
                          <option value="Andaman and Nicobar Islands">
                            Andaman and Nicobar Islands
                          </option>
                          <option value="Arunachal Pradesh">
                            Arunachal Pradesh
                          </option>
                          <option value="Assam">Assam</option>
                          <option value="Bihar">Bihar</option>
                          <option value="Chandigarh">Chandigarh</option>
                          <option value="Chhattisgarh">Chhattisgarh</option>
                          <option value="Dadar and Nagar Haveli">
                            Dadar and Nagar Haveli
                          </option>
                          <option value="Daman and Diu">Daman and Diu</option>
                          <option value="Delhi">Delhi</option>
                          <option value="Lakshadweep">Lakshadweep</option>
                          <option value="Puducherry">Puducherry</option>
                          <option value="Goa">Goa</option>
                          <option value="Gujarat">Gujarat</option>
                          <option value="Haryana">Haryana</option>
                          <option value="Himachal Pradesh">
                            Himachal Pradesh
                          </option>
                          <option value="Jammu and Kashmir">
                            Jammu and Kashmir
                          </option>
                          <option value="Jharkhand">Jharkhand</option>
                          <option value="Karnataka">Karnataka</option>
                          <option value="Kerala">Kerala</option>
                          <option value="Madhya Pradesh">Madhya Pradesh</option>
                          <option value="Maharashtra">Maharashtra</option>
                          <option value="Manipur">Manipur</option>
                          <option value="Meghalaya">Meghalaya</option>
                          <option value="Mizoram">Mizoram</option>
                          <option value="Nagaland">Nagaland</option>
                          <option value="Odisha">Odisha</option>
                          <option value="Punjab">Punjab</option>
                          <option value="Rajasthan">Rajasthan</option>
                          <option value="Sikkim">Sikkim</option>
                          <option value="Tamil Nadu">Tamil Nadu</option>
                          <option value="Telangana">Telangana</option>
                          <option value="Tripura">Tripura</option>
                          <option value="Uttar Pradesh">Uttar Pradesh</option>
                          <option value="Uttarakhand">Uttarakhand</option>
                          <option value="West Bengal">West Bengal</option>
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        City{" "}
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="City"
                          name="city"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={schoolFormData.address.city}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Pin code{" "}
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Pin code"
                          name="pinCode"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={schoolFormData.address.pinCode}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <Form.Group>
                  <label htmlFor="exampleTextarea1">
                    Address{" "}
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </label>
                  <textarea
                    className="form-control"
                    rows="4"
                    name="address"
                    required={true}
                    onChange={(e) => onChange(e)}
                    value={schoolFormData.address.address}
                  ></textarea>
                </Form.Group>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Longitude{" "}
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Longitude"
                          name="longitude"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={schoolFormData.address.longitude}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Latitude{" "}
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Latitude"
                          name="latitude"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={schoolFormData.address.latitude}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <Form.Group>
                  <label>
                    Radius <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </label>
                  <Form.Control
                    type="number"
                    className="form-control"
                    placeholder="Radius"
                    name="allowedRadius"
                    required={true}
                    onChange={(e) => onChange(e)}
                    value={schoolFormData.address.allowedRadius}
                  />
                </Form.Group>
                {/* ===================================== logo/color scheme for schools ====================================== */}

                <h4 className="card-title">logo/color scheme for school</h4>
                <div className="row">
                  <div className="col-md-10">
                    <Form.Group>
                      <label>
                        School logo{" "}
                      </label>
                      <div className="custom-file">
                        <Form.Control
                          type="file"
                          className="form-control visibility-hidden"
                          id="customFileLang"
                          lang="es"
                          onChange={(e) => {
                            uploadLogo(e.target.files);
                          }}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFileLang"
                        >
                          {schoolFormData.colorScheme &&
                          schoolFormData.colorScheme.schoolLogo
                            ? schoolFormData.colorScheme &&
                              schoolFormData.colorScheme.schoolLogo
                            : "Upload Image"}
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-2">
                  {schoolFormData.colorScheme.schoolLogo?(
                    <img
                      src={
                        schoolFormData.colorScheme.schoolLogo
                      }
                      style={{ width: 70 }}
                      loading="lazy"
                    />
                    ):(
                    <img
                      src={
                        require('../../../assets/images/Edvantum.png')
                      }
                      style={{ width: 70 }}
                      loading="lazy"
                    />
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-4 col-form-label">
                        Background color{" "}
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="color code"
                          name="backgroundColor"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={
                            schoolFormData.colorScheme &&
                            schoolFormData.colorScheme.backgroundColor
                          }
                        />
                        <HexColorPicker
                          color={
                            schoolFormData.colorScheme &&
                            schoolFormData.colorScheme.backgroundColor
                          }
                          onChange={(e) => {
                            let schoolForm = { ...schoolFormData };
                            schoolForm.colorScheme.backgroundColor = e;
                            setSchoolFormData(schoolForm);
                          }}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-4 col-form-label">
                        Font color{" "}
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="color code"
                          name="fontColor"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={
                            schoolFormData.colorScheme &&
                            schoolFormData.colorScheme.fontColor
                          }
                        />
                        <HexColorPicker
                          color={
                            schoolFormData.colorScheme &&
                            schoolFormData.colorScheme.fontColor
                          }
                          onChange={(e) => {
                            let schoolForm = { ...schoolFormData };
                            schoolForm.colorScheme.fontColor = e;
                            setSchoolFormData(schoolForm);
                          }}
                          name="fontColor"
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>

                {/* ===================================== / logo/color scheme for schools / ====================================== */}

                {/* ========================================================== */}
                <h4 className="card-title">Admin Info</h4>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        First Name{" "}
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="adminFirstName"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={
                            schoolFormData.adminInfo &&
                            schoolFormData.adminInfo.firstName
                          }
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Last Name{" "}
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="adminLastName"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={
                            schoolFormData.adminInfo &&
                            schoolFormData.adminInfo.lastName
                          }
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Mobile No.{" "}
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="adminMobile"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={
                            schoolFormData.adminInfo &&
                            schoolFormData.adminInfo.mobile
                          }
                          pattern="[0-9]{4,12}"
                          title="Enter vaild mobile number"
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Email{" "}
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="email"
                          name="adminEmail"
                          required={true}
                          onChange={(e) => onChange(e)}
                          value={
                            schoolFormData.adminInfo &&
                            schoolFormData.adminInfo.email
                          }
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                {schoolFormData._id == "" ? (
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">
                          Password{" "}
                          <span style={{ color: "red", fontSize: 14 }}>*</span>
                        </label>
                        <div className="col-sm-9">
                          <Form.Control
                            type="password"
                            name="adminPassword"
                            required={true}
                            onChange={(e) => onChange(e)}
                            value={
                              schoolFormData.adminInfo &&
                              schoolFormData.adminInfo.password
                            }
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                ) : null}
                {/* ========================================================== */}
                <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>
                <Link to="/manageschool" className="btn btn-light">
                  Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddSchool;