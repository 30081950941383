import React, { useEffect, useState } from "react";
import CSVReader from "react-csv-reader";
import { Link, useLocation } from "react-router-dom";
import { CSVLink } from "react-csv";
import moment from "moment";
import CSVERRORDATAModal from "../csvDownloadPopup";
import { toast } from "react-toastify";
import { Form, Modal, Button } from "react-bootstrap";
import StaffApi from "../../api/staffMember";
import Loader from "../loader";

const Users = () => {
  let location = useLocation();
  const [isChooseFileDisplay, setIsChooseFileDisplay] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [errorRecords, setErrorRecords] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [IsRulesShow, setIsRulesShow] = useState(false);
  let headers = [];
  if (location.pathname == "/class4employees") {
    headers = [
      { label: "First Name", key: "firstName" },
      { label: "Last Name", key: "lastName" },
      { label: "Mobile", key: "mobile" },
      { label: "Email", key: "email" },
      { label: "Password", key: "password" },
      { label: "Image", key: "image" },
      { label: "DOB", key: "dob" },
      { label: "Gender", key: "gender" },
      { label: "Country", key: "country" },
      { label: "State", key: "state" },
      { label: "City", key: "city" },
      { label: "Pin Code", key: "pinCode" },
      { label: "Address", key: "address" },
      { label: "User Role", key: "userRole" },
      { label: "Sub Role", key: "subRole" },
      { label: "Introduction", key: "introduction" },
      { label: "Error", key: "error" },
    ];
  } else {
    headers = [
      { label: "First Name", key: "firstName" },
      { label: "Last Name", key: "lastName" },
      { label: "Mobile", key: "mobile" },
      { label: "Email", key: "email" },
      { label: "Password", key: "password" },
      { label: "DOB", key: "dob" },
      { label: "Image", key: "image" },
      { label: "Gender", key: "gender" },
      { label: "Country", key: "country" },
      { label: "State", key: "state" },
      { label: "City", key: "city" },
      { label: "Pin Code", key: "pinCode" },
      { label: "Address", key: "address" },
      { label: "User Role", key: "userRole" },
      { label: "Class Teacher", key: "className" },
      { label: "Section", key: "section" },
      { label: "Subject Head", key: "subjectName" },
      { label: "Introduction", key: "introduction" },
      { label: "Error", key: "error" },
    ];
  }
  function downloadStaffCSVFormat() {
    let headers = [];
    if (location.pathname == "/class4employees") {
      headers = [
        { label: "First Name", key: "firstName" },
        { label: "Last Name", key: "lastName" },
        { label: "Mobile", key: "mobile" },
        { label: "Email", key: "email" },
        { label: "Password", key: "password" },
        { label: "Image", key: "image" },
        { label: "DOB", key: "dob" },
        { label: "Gender", key: "gender" },
        { label: "Country", key: "country" },
        { label: "State", key: "state" },
        { label: "City", key: "city" },
        { label: "Pin Code", key: "pinCode" },
        { label: "Address", key: "address" },
        { label: "User Role", key: "userRole" },
        { label: "Sub Role", key: "subRole" },
        { label: "Introduction", key: "introduction" },
      ];
    } else {
      headers = [
        { label: "First Name", key: "firstName" },
        { label: "Last Name", key: "lastName" },
        { label: "Mobile", key: "mobile" },
        { label: "Email", key: "email" },
        { label: "Password", key: "password" },
        { label: "Image", key: "image" },
        { label: "DOB", key: "dob" },
        { label: "Gender", key: "gender" },
        { label: "Country", key: "country" },
        { label: "State", key: "state" },
        { label: "City", key: "city" },
        { label: "Pin Code", key: "pinCode" },
        { label: "Address", key: "address" },
        { label: "User Role", key: "userRole" },
        { label: "Class Teacher", key: "className" },
        { label: "Section", key: "section" },
        { label: "Subject Head", key: "subjectName" },
        { label: "Introduction", key: "introduction" },
      ];
    }
    return (
      <Modal show={IsRulesShow} onHide={() => setIsRulesShow(false)}>
        <Modal.Header style={{ backgroundColor: "#af0000" }} closeButton>
          <Modal.Title style={{ color: "beige" }}>Warning!</Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{
            minHeight: "133px",
            textAlign: "center",
            backgroundColor: "white",
          }}
        >
          <ul className="list-group list-group-flush">
            <li className="list-group-item text-left">
              First upload the Classes & Subject data then upload the staff
              data.
            </li>
            <li className="list-group-item text-left">
              If country column is blank then it takes India automatically
            </li>
            <li className="list-group-item text-left">
              DOB date format should be YYYY-MM-DD
            </li>
          </ul>
        </Modal.Body>

        <Modal.Footer style={{ backgroundColor: "white" }}>
          <CSVLink
            data={[
              {
                firstName: "Mukesh",
                lastName: "Sharma",
                mobile: "8989898989",
                email: "mukeshsharma@gmail.com",
                password: "123456",
                image: "8989898989.jpg",
                dob: "1995-09-17",
                gender: "Male",
                country: "India",
                state: "Punjab",
                city: "Mohali",
                pinCode: "177001",
                address: "Sco-1990",
                userRole: "teacher",
                className: "II",
                section: "A",
                subjectName: "Hindi",
                introduction: "test intro",
              },
              {
                firstName: "Rohen",
                lastName: "Sharma",
                mobile: "8989898988",
                email: "Rohen@gmail.com",
                password: "123456",
                image: "8989898988.jpg",
                dob: "1999-09-15",
                gender: "Male",
                country: "India",
                state: "Punjab",
                city: "Mohali",
                pinCode: "177001",
                address: "Sco-1990",
                userRole: "principal",
                className: "I",
                section: "A",
                subjectName: "English",
                introduction: "test intro",
              },
              {
                firstName: "Seema",
                lastName: "Sharma",
                mobile: "8123898988",
                email: "Seema@gmail.com",
                password: "123456",
                image: "8123898988.jpg",
                dob: "1990-09-23",
                gender: "Female",
                country: "India",
                state: "Punjab",
                city: "Mohali",
                pinCode: "177001",
                address: "Sco-1998",
                userRole: "nonTeaching",
                subRole: "Driver",
                className: "",
                section: "",
                subjectName: "",
                introduction: "test intro",
              },
            ]}
            filename={
              location.pathname == "/class4employees"
                ? "School_Sample_NonTeaching_Staff_Import.csv"
                : "School_Sample_Staff_Import.csv"
            }
            headers={headers}
            className="btn btn-sm btn-primary"
          >
            <i className="mdi mdi-download"></i> Download Sample CSV
          </CSVLink>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() => setIsRulesShow(false)}
            style={{ padding: "16px" }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
  async function isModal2() {
    setIsModal(!isModal);
  }
  async function uploadCsvFile(staffData) {
    let firstNameColumnIndex = -1;
    let lastNameColumnIndex = -1;
    let mobileColumnIndex = -1;
    let emailColumnIndex = -1;
    let passwordColumnIndex = -1;
    let photoUrlColumnIndex = -1;
    let dobColumnIndex = -1;
    let genderColumnIndex = -1;
    let countryColumnIndex = -1;
    let stateColumnIndex = -1;
    let cityColumnIndex = -1;
    let pinCodeColumnIndex = -1;
    let addressColumnIndex = -1;
    let userRoleColumnIndex = -1;
    let subRoleColumnIndex = -1;
    let classNameColumnIndex = -1;
    let sectionColumnIndex = -1;
    let subjectColumnIndex = -1;
    let introductionColumnIndex = -1;
    let imported = [];
    let mobiles = [];
    let firstRowIndex = -1;
    staffData.every(async (row, index) => {
      if (firstNameColumnIndex == -1 && row.indexOf("First Name") > -1) {
        firstNameColumnIndex = row.indexOf("First Name");
      }
      if (lastNameColumnIndex == -1 && row.indexOf("Last Name") > -1) {
        lastNameColumnIndex = row.indexOf("Last Name");
      }
      if (emailColumnIndex == -1 && row.indexOf("Email") > -1) {
        emailColumnIndex = row.indexOf("Email");
      }
      if (mobileColumnIndex == -1 && row.indexOf("Mobile") > -1) {
        mobileColumnIndex = row.indexOf("Mobile");
      }
      if (passwordColumnIndex == -1 && row.indexOf("Password") > -1) {
        passwordColumnIndex = row.indexOf("Password");
      }
      if (photoUrlColumnIndex == -1 && row.indexOf("Image") > -1) {
        photoUrlColumnIndex = row.indexOf("Image");
      }
      if (dobColumnIndex == -1 && row.indexOf("DOB") > -1) {
        dobColumnIndex = row.indexOf("DOB");
      }
      if (genderColumnIndex == -1 && row.indexOf("Gender") > -1) {
        genderColumnIndex = row.indexOf("Gender");
      }
      if (countryColumnIndex == -1 && row.indexOf("Country") > -1) {
        countryColumnIndex = row.indexOf("Country");
      }
      if (stateColumnIndex == -1 && row.indexOf("State") > -1) {
        stateColumnIndex = row.indexOf("State");
      }
      if (cityColumnIndex == -1 && row.indexOf("City") > -1) {
        cityColumnIndex = row.indexOf("City");
      }
      if (pinCodeColumnIndex == -1 && row.indexOf("Pin Code") > -1) {
        pinCodeColumnIndex = row.indexOf("Pin Code");
      }
      if (addressColumnIndex == -1 && row.indexOf("Address") > -1) {
        addressColumnIndex = row.indexOf("Address");
      }
      if (userRoleColumnIndex == -1 && row.indexOf("User Role") > -1) {
        userRoleColumnIndex = row.indexOf("User Role");
      }
      if (subRoleColumnIndex == -1 && row.indexOf("Sub Role") > -1) {
        subRoleColumnIndex = row.indexOf("Sub Role");
      }
      if (classNameColumnIndex == -1 && row.indexOf("Class Teacher") > -1) {
        classNameColumnIndex = row.indexOf("Class Teacher");
      }
      if (sectionColumnIndex == -1 && row.indexOf("Section") > -1) {
        sectionColumnIndex = row.indexOf("Section");
      }
      if (subjectColumnIndex == -1 && row.indexOf("Subject Head") > -1) {
        subjectColumnIndex = row.indexOf("Subject Head");
      }
      if (introductionColumnIndex == -1 && row.indexOf("Introduction") > -1) {
        introductionColumnIndex = row.indexOf("Introduction");
      }

      if (firstRowIndex === -1) {
        firstRowIndex = index + 1;
      }

      if (index >= firstRowIndex) {
        let firstName = row[firstNameColumnIndex];
        let lastName = row[lastNameColumnIndex];
        let email = row[emailColumnIndex];
        let mobile = row[mobileColumnIndex];
        let password = row[passwordColumnIndex];
        let photoUrl = row[photoUrlColumnIndex];
        let dob = new Date(row[dobColumnIndex]);
        let gender = row[genderColumnIndex];
        let country = row[countryColumnIndex];
        let city = row[cityColumnIndex];
        let state = row[stateColumnIndex];
        let pinCode = row[pinCodeColumnIndex];
        let address = row[addressColumnIndex];
        let introduction = row[introductionColumnIndex];
        let className = row[classNameColumnIndex];
        let section = row[sectionColumnIndex];
        let subjectName = row[subjectColumnIndex];
        let userRole = row[userRoleColumnIndex];
        let subRole = row[subRoleColumnIndex];
        if (!firstName && !mobile) {
          // blank row
          console.log("blank row");
          return false;
        }
        if (!firstName) {
          toast("First Name is missing in line number " + (index + 1), {
            type: "error",
          });
          imported = [];
          return false;
        } else {
          firstName = firstName.trim();
        }
        if (lastName) {
          lastName = lastName.trim();
        }
        if (!mobile) {
          toast("Mobile is missing in line number " + (index + 1), {
            type: "error",
          });
          imported = [];
          return false;
        } else {
          mobile = mobile.trim();
          mobiles.push(mobile);
        }
        if (!gender) {
          toast("Gender is missing in line number " + (index + 1), {
            type: "error",
          });
          imported = [];
          return false;
        } else {
          gender = gender.trim();
        }
        if (!country) {
          country = "India";
        }
        if (!state) {
          state = "Punjab";
        }
        if (pinCode) {
          pinCode = pinCode.trim();
        }
        if (address) {
          address = address.trim();
        }
        if (!userRole) {
          toast("User Role is missing in line number " + (index + 1), {
            type: "error",
          });
          imported = [];
          return false;
        } else {
          if (userRole == "Class Teacher") {
            userRole = "teacher"
          } else if (userRole == "Subject Teacher") {
            userRole = "teacher"
          } else {
            userRole = userRole.trim();
          }

        }
        if (photoUrl) {
          photoUrl = window.location.origin + "/public/staff/" + photoUrl;
        }
        if (location.pathname == "/class4employees") {
          imported.push({
            firstName,
            lastName,
            email,
            mobile,
            dob,
            gender,
            password,
            photoUrl,
            address: {
              country: country,
              state: state,
              city: city,
              pinCode: pinCode,
              address: address,
            },
            userRole,
            subRole,
            className,
            section,
            subjectName,
            introduction,
          });
        } else {
          imported.push({
            firstName,
            lastName,
            email,
            mobile,
            dob,
            gender,
            password,
            photoUrl,
            address: {
              country: country,
              state: state,
              city: city,
              pinCode: pinCode,
              address: address,
            },
            userRole,
            className,
            section,
            subjectName,
            introduction,
          });
        }
      }
    });
    saveStaffCsv(imported);
  }

  async function saveStaffCsv(imported) {
    if (window.confirm("Are your sure for upload the class csv file ?")) {
      let Api = new StaffApi();
      await Api.saveStaffCsv(imported)
        .then(async (response) => {
          if (response.success) {
            toast.success(response.msg);
            if (response.data && response.data.length) {
              setIsModal(true);
              setErrorRecords(response.data);
            } else if (response.data.length == 0) {
              window.location.reload(true);
            }
          } else if (response.error) {
            toast.error(response.msg.toString());
          }
        })
        .catch((error) => {
          toast.error(error.toString());
          console.error("There is an error!", error);
        });
    }
  }

  function uploadStaffCsv() {
    return (
      <CSVReader
        onFileLoaded={(staffData) => uploadCsvFile(staffData)}
        inputStyle={{ fontSize: "12px" }}
      />
    );
  }

  return (
    <div className="row">
      <CSVERRORDATAModal
        show={isModal}
        isModal={isModal2}
        filename={
          location.pathname == "/class4employees"
            ? "Data_Error_NonTeachingStaff_Import.csv"
            : "Data_Error_Staff_Import.csv"
        }
        headers={headers}
        errorRecords={errorRecords}
      />
      {isLoader ? <Loader /> : null}
      <div className="col-md-10 textRight">
        <a href="javascript:void(0)" onClick={() => setIsChooseFileDisplay(!isChooseFileDisplay)}>
          <><i className="mdi mdi-apple-keyboard-caps"></i> Import Staff Member Data </>
        </a>
        <a className="text-sm">
          <a href="javascript:void(0)" onClick={(e) => setIsRulesShow(true)}>
            (Download Sample CSV)
          </a>{" "}
        </a>
        {downloadStaffCSVFormat()}
      </div>
      <div className="col-md-5 text-sm">
        <div>{isChooseFileDisplay ? uploadStaffCsv() : null}</div>
      </div>
    </div>
  );
};

export default Users;
