import BaseApi from "./baseApi";
class Category extends BaseApi {
    async getCategory() {
        return await this.API(
            {
                url: "/category/getcategories",
                method: "get",
            }
        )
    }

    async createCategory(data) {
        return await this.API(
            {
                url: "/category/createcategory",
                data: data,
                method: "post",
            }
        )
    }

    async editCategory(data) {
        return await this.API(
            {
                url: "/category/updatecategory",
                data: data,
                method: "post",
            }
        )
    }

    async deleteCategory(data) {
        return await this.API({
            url: "/category/deletecategory",
            data: { _id: data},
            method: "post",
        })
    }
} 

export default Category;