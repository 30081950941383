import React, { Component } from 'react';
import './App.scss';
import { withRouter } from 'react-router-dom';
// import SettingsPanel from './modules/SettingsPanel';
import { UserProvider } from "./contexts/userContext";
import Login from "./pages/login.jsx";
import { useLocation } from "react-router-dom";
//import "../src/App.css";
// import "./assets/styles/web/imagehover.css";
// import "./assets/styles/web/style.css";
function App() {
  const location = useLocation();
  const pathName = location.pathname;
  return (
    <>
      <UserProvider>
        <Login />
      </UserProvider>
    </>
  );
}

export default App;