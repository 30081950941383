import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Form, Image } from 'react-bootstrap';
import { toast } from "react-toastify";
import CoachApi from "../../../api/coachApi";
import ActivitySchool from "../../../api/activitySchoolApi";
import Datetime from "react-datetime";
import moment from "moment";
import Grid from "../../../components/dataTable";
import ReactTooltip from "react-tooltip";
import DeleteModal from "../../../components/deleteAlertPopup";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  height: "44px",
  padding: "3px",
  borderBottom: "solid",
  background: isDragging ? "lightgreen" : "White",
  ...draggableStyle,
});

const AddCoachDetails = () => {
  let history = useHistory();

  const [fileProgress, setFileProgress] = useState({});
  const [schoolData, setSchoolData] = useState([]);
  const [schoolActivitiesData, setSchoolActivitiesData] = useState([]);
  const [selectedSchoolId, setselectedSchoolId] = useState();
  const [coachId, setCoachId] = useState("");
  const [rows, setRows] = useState([]);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [statusMsg, setStatusMsg] = useState({
    msg: "",
    buttonName: "",
  });

  const [data, setData] = useState({
    startDate: new Date(),
    color: '#ffffff',
    fontcolor: '#ffffff',
    startstyle: {
      color: 'red',
      fontSize: '14px'
    }
  });

  const [formData, setFormData] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    photoUrl: "",
    bio: "",
    userRoles: {
      userRole: "coach",
    },
    address: {
      country: "India",
      state: "",
      city: "",
      pinCode: "",
      address: ""
    }
  })

  const [formData2, setFormData2] = useState({
    coachId: coachId,
    slots: {
      schoolId: "",
      activityId: "",
      durationDays: "",
      feesPerMonth: "",
      timeSlots: []
    }
  })

  const [formData3, setFormData3] = useState({
    timeFrom: "",
    timeTo: "",
    days: []
  });

  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    const id = new URLSearchParams(history.location.search).get("id");
    if (id) {
      selectedCoach(id)
    }
  }, []);

  useEffect(() => {
    getActivitySchool();
  }, []);

  useEffect(() => {
    getSchoolActivities();
  }, [selectedSchoolId]);

  useEffect(() => {
    getList();
  }, [coachId]);

  const getList = async () => {
    if (coachId) {
      let Api = new CoachApi();
      await Api.getCoachDetails({ coachId }).then(async (response) => {
        if (response.data && response.data.length) {
          setRows(response.data[0].slots)
        } else {
          setRows([]);
        }
      })
        .catch((error) => {
          toast.error(error.toString());
        });
    }
  };

  const selectedCoach = async (id) => {
    let Api = new CoachApi();
    await Api.getCoach(id).then(async (response) => {
      if (response.data) {
        setFormData(response.data[0]);
        setCoachId(response.data[0]._id);
      } else {
        setFormData();
        setCoachId();
      }
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  const getActivitySchool = async () => {
    let ActivitySchoolApi = new ActivitySchool();
    await ActivitySchoolApi.getActivitySchoolList().then((response) => {
      if (response.data) {
        setSchoolData(response.data);
      } else {
        setSchoolData([]);
      }
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  const getSchoolActivities = async () => {
    if (selectedSchoolId) {
      let ActivitySchoolApi = new ActivitySchool();
      await ActivitySchoolApi.getSchoolAllActivity({ selectedSchoolId }).then(async (response) => {
        if (response.data && response.data.length) {
          setSchoolActivitiesData(response.data[0].activitiesData);
        } else {
          setSchoolActivitiesData([]);
        }
      }).catch((error) => {
        toast.error(error.toString());
      });
    }
  }

  async function uploadprofile(value) {
    let Api = new CoachApi();
    const data = new FormData();
    data.append("file", value[0]);
    await Api
      .uploadFile(data, (upload) => {
        let pg = { ...fileProgress };
        pg["file"] = Math.round((100 * upload.loaded) / upload.total);
        setFileProgress(pg);
      })
      .then((data) => {
        if (data.fileUrl && data.success) {
          let form = { ...formData };
          form.photoUrl = data.fileUrl;
          setFormData(form);
          toast.success(data.msg);
          delete fileProgress["file"];
        }
      })
      .catch(() => {
        toast.error("Upload failed");
      });
  }

  const handleChanges = (e) => {
    let FormData = { ...formData };
    let { name, value } = e.target;
    switch (name) {
      case "firstName":
        FormData.firstName = value;
        break;
      case "lastName":
        FormData.lastName = value;
        break;
      case "mobile":
        FormData.mobile = value;
        break;
      case "email":
        FormData.email = value;
        break;
      case "photoUrl":
        FormData.photoUrl = value;
        break;
      case "bio":
        FormData.bio = value;
        break;
      case "address":
        FormData.address.address = value;
        break;
      case "city":
        FormData.address.city = value;
        break;
      case "pinCode":
        FormData.address.pinCode = value;
        break;
      case "state":
        FormData.address.state = value;
        break;
      case "country":
        FormData.address.country = value;
        break;
    }
    setFormData(FormData);
  };

  const handleChanges2 = (e) => {
    let FormData2 = { ...formData2 };
    let { name, value } = e.target;
    switch (name) {
      case "schoolId":
        FormData2.slots.schoolId = value;
        setselectedSchoolId(value);
        break;
      case "activityId":
        FormData2.slots.activityId = value;
        break;
      case "durationDays":
        FormData2.slots.durationDays = value;
        break;
      case "feesPerMonth":
        FormData2.slots.feesPerMonth = value;
        break;
    }
    setFormData2(FormData2);
  };

  const handleChanges3 = (e) => {
    let { name, value } = e.target;
    let FormData3 = { ...formData3 };
    switch (name) {
      case "days":
        let foundDay = FormData3.days.indexOf(value);
        if (foundDay <= -1) {
          FormData3.days.push(value);
        } else {
          FormData3.days.splice(foundDay, 1);
        }
        break;
      case "timeFrom":
        FormData3.timeFrom = moment(value).format("HH:mm");
        break;
      case "timeTo":
        FormData3.timeTo = moment(value).format("HH:mm");
        break;
    }
    setFormData3(FormData3);
  };

  const cleanFields = () => {
    const clean = {
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      bio: "",
      photoUrl: "",
      address: {
        country: "",
        state: "",
        city: "",
        pinCode: "",
        address: ""
      }
    }
    setFormData(clean)
  }

  const cleanFields2 = () => {
    const clean2 = {
      coachId: coachId,
      slots: {
        schoolId: "",
        activityId: "",
        durationDays: "",
        feesPerMonth: "",
        timeSlots: []
      }
    }
    setFormData2(clean2)
  }

  const cleanFields3 = () => {
    const clean3 = {
      timeFrom: "",
      timeTo: "",
      days: []
    }
    setFormData3(clean3)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    let Api = new CoachApi();
    let suc;
    if (formData._id) {
      suc = await Api.editCoach({
        formData
      })
      if (suc.error) {
        toast.error(suc.msg)
      } else {
        toast.success(suc.msg);
        setCurrentTab(1);
      }
    } else {
      suc = await Api.addCoach({
        formData
      });
      if (suc.error) {
        toast.error(suc.msg)
      } else {
        toast.success(suc.msg)
        setCoachId(suc.data._id)
        setCurrentTab(1);
        cleanFields();
      }
    }
  }

  const handleSubmit2 = async (e) => {
    e.preventDefault()
    let Api = new CoachApi();
    console.log("formData2", formData2)
    formData2.coachId = coachId
    if (formData2.slots.timeSlots.length != 0) {
      let suc = await Api.addCoachDetails({
        formData2
      });
      if (suc.error) {
        toast.error(suc.msg)
      } else {
        toast.success(suc.msg)
        getList();
        cleanFields2();
        formData3.days.checked = false
        formData3.timeFrom = ""
        formData3.timeTo = ""
      }
    } else {
      toast.error("Please add Time Slot.")
    }
  }

  // const handleAddMore = () => {
  //   let d = { ...formData2 }
  //   if (formData3.days != "" && formData3.timeFrom != "" && formData3.timeTo != "") {
  //     d.slots.timeSlots.push(formData3);
  //     setFormData2(d);
  //   } else {
  //     toast.error("All Fields are required.")
  //   }
  //   cleanFields3();
  // };

  const handleAddMore = () => {
    const isOverlap = formData2.slots.timeSlots.some((slot) => {
      const startTime = moment(slot.timeFrom, 'HH:mm');
      const endTime = moment(slot.timeTo, 'HH:mm');
      const newStartTime = moment(formData3.timeFrom, 'HH:mm');
      const newEndTime = moment(formData3.timeTo, 'HH:mm');

      return (
        (newStartTime.isSameOrAfter(startTime) && newStartTime.isBefore(endTime)) ||
        (newEndTime.isSameOrAfter(startTime) && newEndTime.isBefore(endTime))
      );
    });

    if (isOverlap) {
      toast.error('Time slot already added, Please select a different time!');
    } else if (formData3.days !== "" && formData3.timeFrom !== "" && formData3.timeTo !== "") {
      let updatedFormData2 = { ...formData2 };
      updatedFormData2.slots.timeSlots.push(formData3);
      setFormData2(updatedFormData2);
      cleanFields3();
    } else {
      toast.error('All Fields are required.');
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const time = reorder(
      formData2.slots,
      result.source.index,
      result.destination.index
    );
    formData2((formData2.slots = time));
  };

  const removeItem = (index) => {
    let d = { ...formData2 };
    d.slots.timeSlots.splice(index, 1);
    setFormData2(d);
  };

  const deleteSlot = async () => {
    let Api = new CoachApi();
    isVisible2();
    await Api.deleteSlot(formData2.rowPropsId, coachId)
      .then((response) => {
        toast.success(response.msg.toString());
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    getList();
  };

  const isVisible2 = (id = null) => {
    let d = { ...formData2 };
    if (id) {
      d.rowPropsId = id;
    }
    setIsModalVisible2(!isModalVisible2);
    setFormData2(d);
  };

  const ShowSchoolName = (props) => {
    return (
      <span>
        {props.schoolName}
      </span>
    );
  }

  const ShowTimeSlots = (props) => {
    return props.timeSlots.map((data, index) => {
      return (
        <span>
          <span>
            {data && data.days.map(day => day.charAt(0).toUpperCase() + day.slice(1)).join(", ")} -
            {data.timeFrom} to {data.timeTo}
          </span>
          <br />
        </span>
      )
    })
  }

  const showActivityName = (props) => {
    return (
      <span>
        {props.activityName}
      </span>
    );
  };

  const showDurationDays = (props) => {
    return (
      <span>
        {props.durationDays}
      </span>
    );
  };

  const showFeesPerMonth = (props) => {
    return (
      <span>
        {props.feesPerMonth}
      </span>
    );
  };

  const renderAction = (props) => {
    return (
      <span
        style={{
          display: "block",
          width: "76px",
        }}
      >
        <ReactTooltip id="Delete" type="error" effect="solid">
          <span>Delete</span>
        </ReactTooltip>
        <button
          onClick={() => {
            isVisible2(props._id);
          }}
          data-tip
          data-for="Delete"
          type="button"
          className="btn btn-outline-danger"
          style={{ padding: "8px" }}
        >
          <i className="mdi mdi-delete" style={{ fontSize: "17px" }}></i>
        </button>
      </span>
    );
  };

  const field = () => {
    const fields = [
      {
        title: "School Name",
        render: ShowSchoolName
      },
      {
        title: "Time Slots",
        render: ShowTimeSlots
      },
      {
        title: "Activity Name",
        render: showActivityName
      },
      {
        title: "Duration Days",
        render: showDurationDays
      },
      {
        title: "Fees Per Month",
        render: showFeesPerMonth
      },
      {
        name: "action",
        title: "Actions ",
        render: renderAction,
      }
    ];
    return fields;
  };

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Add Coach Details </h3>
      </div>
      <Tabs selectedIndex={currentTab} onSelect={(index) => setCurrentTab(index)}>

        <TabList>
          <Tab>Personal Information</Tab>
          <Tab>School and Activities</Tab>
        </TabList>

        {/* Personal Information */}
        <TabPanel>
          <div className='card'>
            <div className="card-body">
              <h4 className="card-title">Coach Info</h4>
              <form onSubmit={(e) => handleSubmit(e)} className="forms-sample">
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">First Name <span style={data.startstyle}>*</span></label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          required={true}
                          onChange={(e) => handleChanges(e)}
                          value={formData.firstName}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Last Name</label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          onChange={(e) => handleChanges(e)}
                          value={formData.lastName}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Mobile No. <span style={data.startstyle}>*</span> </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="number"
                          name="mobile"
                          required={true}
                          onChange={(e) => handleChanges(e)}
                          value={formData.mobile}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Email</label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="email"
                          name="email"
                          onChange={(e) => handleChanges(e)}
                          value={formData.email}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label"> Photo <span style={data.startstyle}>*</span></label>
                      <div className="col-sm-6">
                        <Form.Control
                          type="file"
                          className="form-control"
                          id="customFileLang"
                          lang="es"
                          name="photo"
                          onChange={(e) => uploadprofile(e.target.files)}
                        />
                      </div>
                      <div className="col-sm-3">
                        <Form.Group className="row">
                          <label className="col-sm-3"></label>
                          {formData.photoUrl ? <span className="col-sm-9">
                            <Image src={`${formData.photoUrl}`} style={{ height: '40px' }} thumbnail />
                          </span> : (<span className="col-sm-9"><Image src={window.location.protocol + "//" + window.location.hostname + "/public/students/dummy.jpeg"} style={{ height: '200px' }} thumbnail /></span>)}
                        </Form.Group>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label"> Bio</label>
                      <div className="col-sm-9">
                        <Form.Group>
                          <textarea
                            className="form-control"
                            id="exampleTextarea1"
                            rows="4"
                            name="bio"
                            onChange={(e) => handleChanges(e)}
                            value={formData.bio}
                          ></textarea>
                        </Form.Group>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Country</label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="country"
                          onChange={(e) => handleChanges(e)}
                          value={formData.address && formData.address.country ? formData.address.country : ""}
                        >
                          <option value="">-----------</option>
                          <option>India</option>
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">State</label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="state"
                          onChange={(e) => handleChanges(e)}
                          value={formData.address && formData.address.state ? formData.address.state : ""}
                        >
                          <option value="">------------</option>
                          <option value="Andhra Pradesh">
                            Andhra Pradesh
                          </option>
                          <option value="Andaman and Nicobar Islands">
                            Andaman and Nicobar Islands
                          </option>
                          <option value="Arunachal Pradesh">
                            Arunachal Pradesh
                          </option>
                          <option value="Assam">Assam</option>
                          <option value="Bihar">Bihar</option>
                          <option value="Chandigarh">Chandigarh</option>
                          <option value="Chhattisgarh">Chhattisgarh</option>
                          <option value="Dadar and Nagar Haveli">
                            Dadar and Nagar Haveli
                          </option>
                          <option value="Daman and Diu">
                            Daman and Diu
                          </option>
                          <option value="Delhi">Delhi</option>
                          <option value="Lakshadweep">Lakshadweep</option>
                          <option value="Puducherry">Puducherry</option>
                          <option value="Goa">Goa</option>
                          <option value="Gujarat">Gujarat</option>
                          <option value="Haryana">Haryana</option>
                          <option value="Himachal Pradesh">
                            Himachal Pradesh
                          </option>
                          <option value="Jammu and Kashmir">
                            Jammu and Kashmir
                          </option>
                          <option value="Jharkhand">Jharkhand</option>
                          <option value="Karnataka">Karnataka</option>
                          <option value="Kerala">Kerala</option>
                          <option value="Madhya Pradesh">
                            Madhya Pradesh
                          </option>
                          <option value="Maharashtra">Maharashtra</option>
                          <option value="Manipur">Manipur</option>
                          <option value="Meghalaya">Meghalaya</option>
                          <option value="Mizoram">Mizoram</option>
                          <option value="Nagaland">Nagaland</option>
                          <option value="Odisha">Odisha</option>
                          <option value="Punjab">Punjab</option>
                          <option value="Rajasthan">Rajasthan</option>
                          <option value="Sikkim">Sikkim</option>
                          <option value="Tamil Nadu">Tamil Nadu</option>
                          <option value="Telangana">Telangana</option>
                          <option value="Tripura">Tripura</option>
                          <option value="Uttar Pradesh">
                            Uttar Pradesh
                          </option>
                          <option value="Uttarakhand">Uttarakhand</option>
                          <option value="West Bengal">West Bengal</option>
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">City</label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="exampleInputCity1"
                          placeholder="Location"
                          name="city"
                          onChange={(e) => handleChanges(e)}
                          value={formData.address && formData.address.city ? formData.address.city : ""}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Pin code</label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="exampleInputName12"
                          placeholder="Pin code"
                          name="pinCode"
                          onChange={(e) => handleChanges(e)}
                          value={formData.address && formData.address.pinCode ? formData.address.pinCode : ""}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label"> Address</label>
                      <div className="col-sm-9">
                        <Form.Group>
                          <textarea
                            className="form-control"
                            id="exampleTextarea1"
                            rows="4"
                            name="address"
                            onChange={(e) => handleChanges(e)}
                            value={formData.address && formData.address.address ? formData.address.address : ""}
                          ></textarea>
                        </Form.Group>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className='text-right'>
                  <button type="submit" className="btn btn-primary mr-2"> {formData._id ? "Update" : "Add"}</button>
                </div>
              </form>
            </div>
          </div>
        </TabPanel>

        {/* School and Activities */}
        <TabPanel>
          <div className='card'>
            <div className='card-body'>
              <h4 className="card-title">Select School and Activities</h4>
              <form className="forms-sample" onSubmit={handleSubmit2}>
                <div className='row'>
                  <div className="col-md-3">
                    <Form.Group className="row">
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="schoolId"
                          value={formData2.slots.schoolId}
                          onChange={handleChanges2}
                          required
                        >
                          <option value="">-----</option>
                          {schoolData.map((data) => (
                            <option key={data._id} value={data._id}>
                              {data.schoolName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group className="row">
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="activityId"
                          value={formData2.slots.activityId}
                          onChange={handleChanges2}
                          required
                        >
                          <option value="">-----</option>
                          {schoolActivitiesData.map((data) => (
                            <option key={data._id} value={data._id}>
                              {data.activityName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group className="row">
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="durationDays"
                          placeholder="Duration Days"
                          onChange={(e) => handleChanges2(e)}
                          value={formData2.slots.durationDays}
                          required
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group className="row">
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="feesPerMonth"
                          placeholder="Fees Per Month"
                          onChange={(e) => handleChanges2(e)}
                          value={formData2.slots.feesPerMonth}
                          required
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <h4 className="card-title mt-4">Select Days and Time Slot</h4>
                <div className='row'>
                  <div className="col-md-2">
                    <Form.Group>
                      <div className="form-check form-check-success">
                        <label className="form-check-label">
                          <input
                            name="days"
                            type="checkbox"
                            className="form-check-input"
                            value="monday"
                            checked={formData3.days.indexOf("monday") > -1 ? true : false}
                            onChange={(e) => handleChanges3(e)}
                          />
                          Monday
                          <i className="input-helper"></i>
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-2">
                    <Form.Group>
                      <div className="form-check form-check-success">
                        <label className="form-check-label">
                          <input
                            name="days"
                            type="checkbox"
                            className="form-check-input"
                            value="tuesday"
                            checked={formData3.days.indexOf("tuesday") > -1 ? true : false}
                            onChange={(e) => handleChanges3(e)}
                          />
                          Tuesday
                          <i className="input-helper"></i>
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-2">
                    <Form.Group>
                      <div className="form-check form-check-success">
                        <label className="form-check-label">
                          <input
                            name="days"
                            type="checkbox"
                            className="form-check-input"
                            value="wednessday"
                            checked={formData3.days.indexOf("wednessday") > -1 ? true : false}
                            onChange={(e) => handleChanges3(e)}
                          />
                          Wednesday
                          <i className="input-helper"></i>
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-2">
                    <Form.Group>
                      <div className="form-check form-check-success">
                        <label className="form-check-label">
                          <input
                            name="days"
                            type="checkbox"
                            className="form-check-input"
                            value="thursday"
                            checked={formData3.days.indexOf("thursday") > -1 ? true : false}
                            onChange={(e) => handleChanges3(e)}
                          />
                          Thursday
                          <i className="input-helper"></i>
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-2">
                    <Form.Group>
                      <div className="form-check form-check-success">
                        <label className="form-check-label">
                          <input
                            name="days"
                            type="checkbox"
                            className="form-check-input"
                            value="friday"
                            checked={formData3.days.indexOf("friday") > -1 ? true : false}
                            onChange={(e) => handleChanges3(e)}
                          />
                          Friday
                          <i className="input-helper"></i>
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-2">
                    <Form.Group>
                      <div className="form-check form-check-success">
                        <label className="form-check-label">
                          <input
                            name="days"
                            type="checkbox"
                            className="form-check-input"
                            value="saturday"
                            checked={formData3.days.indexOf("saturday") > -1 ? true : false}
                            onChange={(e) => handleChanges3(e)}
                          />
                          Saturday
                          <i className="input-helper"></i>
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10">
                    <Form.Group className="row">
                      <div className="col-sm-4">
                        <Form.Group>
                          <Datetime
                            dateFormat={false}
                            name="timeFrom"
                            value={moment(formData3.timeFrom, "HH:mm")}
                            onChange={(value) => {
                              let e = {};
                              e["target"] = { "name": "timeFrom", "value": value };
                              handleChanges3(e)
                            }}
                            required
                          />
                        </Form.Group>
                      </div>
                      <div className="col-sm-1 mt-3 pl-4"> to </div>
                      <div className="col-sm-4">
                        <Form.Group>
                          <Datetime
                            dateFormat={false}
                            name="timeTo"
                            value={moment(formData3.timeTo, "HH:mm")}
                            onChange={(value) => {
                              let e = {};
                              e["target"] = { "name": "timeTo", "value": value };
                              handleChanges3(e)
                            }}
                            required
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-1">
                        <button
                          type="button"
                          className="btn btn-link"
                          style={{ fontSize: "25px" }}
                          onClick={handleAddMore}
                        >
                          +
                        </button>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="col-md-12">
                  <Form.Group className="row">
                    <div className="col-sm-8">
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {formData2.slots && formData2.slots.timeSlots && formData2.slots.timeSlots.map((item, index) => (
                                <Draggable
                                  key={`timeSlots-${index}`}
                                  draggableId={`timeSlots-${index}`}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <span>
                                        <span className="col-3">
                                          {item && item.days.map(day => day.charAt(0).toUpperCase() + day.slice(1)).join(", ")}
                                        </span>
                                        -
                                        <span className="col-3">
                                          {item.timeFrom}{" "}
                                        </span>
                                        <span className="col-3">
                                          {item.timeTo}{" "}
                                        </span>
                                        <span className="float-right">
                                          <button
                                            data-tip
                                            data-for="Delete"
                                            type="button"
                                            className="btn btn-outline-danger"
                                            style={{ padding: "8px" }}
                                            onClick={() => removeItem(index)}
                                          >
                                            <i
                                              className="mdi mdi-delete"
                                              style={{ fontSize: "17px" }}
                                            ></i>
                                          </button>
                                        </span>
                                      </span>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </Form.Group>
                </div>
                <div className='text-right'>
                  <button type="submit" className="btn btn-primary mr-2"> {formData._id ? "Update" : "Add"}</button>
                </div>
              </form>
            </div>
          </div>

          <div className="card mt-5">
            <Grid
              columns={field()}
              rows={rows}
              settings={{
                search: false,
                paging: false,
                exportButton: false,
                sorting: true
              }}
            />
          </div>
        </TabPanel>

      </Tabs>
      <DeleteModal
        show={isModalVisible2}
        isModalVisible2={isVisible2}
        deleteData={deleteSlot}
      />
    </div >
  );
}
export default AddCoachDetails;