import React, { useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import Loader from "../../../components/loader";
import Grid from "../../../components/dataTable";
const FeeDefaulterList = ({data, handleClose3, isLoader, rows3, feeSchedules, defaulterField}) => {
    

    
    return (
        <Modal
        show={data.isDefaulterModalVisible}
        size="xl"
        onHide={() => handleClose3()}>
            <Modal.Header closeButton>
            <h3 className="page-title">Fee Defaulters List</h3>
            </Modal.Header>
            <div className="card">
            <div className="card-body">
                {isLoader ? <Loader /> : null}
                <Grid columns={defaulterField()} rows={rows3} />
            </div>
            </div>
            <Modal.Footer style={{ backgroundColor: "#ffffff" }}>

            </Modal.Footer>
        </Modal>
    )
}
export default FeeDefaulterList;
