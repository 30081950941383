import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Class from '../../../api/classApi';
import StaffMemberApi from '../../../api/staffMember';
import moment from 'moment';
import Loader from "../../../components/loader";
import BaseApi from "../../../api/baseApi";
import AllClassTimeTable from "../../../components/allClassTimeTable";
import ClassSidebarTimeTable from "../../../components/classSidebarTimeTable";
import SchoolApi from "../../../api/schoolApi";
import StatusModal from '../../../components/alertPopup';
import { jsPDF } from "jspdf";

const AutoTimeTable = () => {
	const [formData, setFormData] = useState({
		selectedClass: '',
		classId: '',
		dayName: '',
		subjectId: '',
		subjectName: '',
		timeFrom: '',
		timeTo: '',
		teacherId: '',
		subjectTimingsId: '',
		daysOfWeek: [],
		showBatchTimetable: []
	});

	const [staffTeachersList, setStaffTeachersList] = useState([]);
	const [rows, setRows] = useState([]);
	const [dataLoader, setDataLoader] = useState(false);
	const [editCheck, setEditCheck] = useState(false);
	const [selectedClassID, setSelectedClassID] = useState('');
	const [workingDays, setWorkingDays] = useState([]);
	const [slots, setSlots] = useState([]);
	const [showBatchDropDown, setShowBatchDropDown] = useState([]);
	const [statusMsg, setStatusMsg] = useState({
		msg: "",
		buttonName: ""
	});
	const [modalVisible,setModalVisible] = useState(false)
	const [checkValidTime, setCheckValidTime] = useState(false)

	useEffect(() => {
		getSchoolSettings(localStorage.getItem("schoolId"));
		getStaffMembersByRole('teacher,principal');
		if (localStorage.getItem("schoolId")) {
			getDefaultSchoolSettings();
		}
	}, []);

	const exportToPdf = async () => {
		var pdf = new jsPDF('l', 'pt', "a4");  // For landscape
		let pWidth = pdf.internal.pageSize.width;
		let srcWidth = document.getElementById('exportTimeTableDiv').scrollWidth;
		let margin = 10;
		let scale = (pWidth - margin * 2) / srcWidth;

		pdf.html(document.getElementById('exportTimeTableDiv'), {
			x: margin,
			y: margin,
			html2canvas: {
				scale: scale,
				allowTaint: true,
				backgroundColor: 'white'
			},
			callback: async function (doc) {
				let pageInfo = doc.internal.getCurrentPageInfo();
				let totalPages = doc.internal.getNumberOfPages();
				if (totalPages > 0 && totalPages > pageInfo.pageNumber) {
					for (let p = totalPages; p > pageInfo.pageNumber; p--) {
						doc.deletePage(p)
					}
				}
				doc.save('TimeTableReport.pdf');
			}
		});
	}

	async function getSchoolSettings(id) {
		let schoolApi = new SchoolApi();
		await schoolApi.getSchool(id).then(async (res) => {
			if (res.success) {
				if(res.data.slotsTime.length > 0 && res.data.slotsTime[0].classIds.length > 0){
					setSelectedClassID(res.data.slotsTime._id)
					setShowBatchDropDown(res.data.slotsTime)
				}
			}
		})
	}

	async function getDefaultSchoolSettings() {
		setDataLoader(true);
		const baseApi = new BaseApi();
		await baseApi.getDefaultSchoolSettings().then(async (response) => {
			if (response.success && response.data) {
				setWorkingDays(response.data.workingDays);
			}
		})
		setDataLoader(false);
	}

	const getStaffMembersByRole = async (role) => {
		setDataLoader(true);
		const staffMemberApi = new StaffMemberApi();
		await staffMemberApi.getStaffMembersByRole(role).then(async (response) => {
			if (response.data && response.data.length) {
				setStaffTeachersList(response.data);
			} else {
				setStaffTeachersList([])
			}
			return response;
		}).catch((error) => {
			console.error('There is an error!', error);
		});
		setDataLoader(false);
	}

	const handleChanges = (event) => {
		const f = { ...formData }
		const { name, value } = event.target;
		if (name === 'className') {
			getSubjectsForClass(value);
			return;
		} else if (name === 'subject') {
			const valueArr = value.split('-');
			f.subjectId = valueArr[0];
			f.subjectName = valueArr[1];
			if (editCheck) {
				//
			} else {
				f.timeFrom = '';
				f.timeTo = '';
			}
		} else if (name === 'dayName') {
			loadDayData(value)
			return;
		} else if (name === 'timeFrom') {
			const check = validateSlotTime(name, value, f.subjectTimingsId);
			if (check) {
				f.timeFrom = value
			} else {
				f.timeFrom = '';
				alert('Time is in beween existing slot');
			}
			if (f.teacherId) {
				f.teacherId = '';
			}
			f.timeTo = '';
		} else if (name === 'timeTo') {
			const selectedTimeSeconds = moment(value, 'HH:mm');
			if (f.timeFrom) {
				const timeFrom = moment(f.timeFrom, 'HH:mm');
				if (timeFrom.isSameOrAfter(selectedTimeSeconds)) {
					alert('Time To less than Time From');
				} else {
					const check = validateSlotTime(name, value, f.subjectTimingsId);
					if (check) {
						f.timeTo = value
					} else {
						f.timeTo = '';
						alert('Time is in beween existing slot');
					}
					if (f.teacherId) {
						f.teacherId = '';
					}
				}
			} else {
				alert('Choose Time From First');
			}
		} else if (name === 'teacher') {
			if (f.timeFrom && f.timeTo) {
				const check = validateTeacherTime(value)
				if (check) {
					f.teacherId = value;
				} else {
					f.teacherId = '';
				}
			} else {
				if (f.timeFrom) {
					alert('Please select Time To');
				} else if (f.timeTo) {
					alert('Please select Time From');
				} else {
					alert('Please select Time First');
				}
			}
		} else if (name === 'classFilter') {
			setSelectedClassID(value);
			getSaveGroupTimetable(value);
		}
		setFormData(f);
	}

	const getSaveGroupTimetable = async (value) => {
		setDataLoader(true);
		const ClassApi = new Class();
		await ClassApi.getSaveGroupTimetable(value).then(async (response) => {
			if (response.success) {
				let flag = false
				response.data.map(item => {
					if (item.timeTable.length > 0) {
						setRows(response.data)
						setSlots(response.slots)
						flag = true
					}
				})
				if (flag == false) toast.error("Timetable is not set for classes.");
			} else {
				toast.error(response.msg);
			}
		}).catch((error) => {
			toast.error(error.toString());
		});
		setDataLoader(false)
	}

	const validateTime = async()=>{
		let response = true;
		if (rows && rows.length) {
            loop1: for (let row of rows) {
                loop2: for (let tt of row.timeTable) {
					loop3: for (let i = 0;i < slots.length; i++) {
						console.log('tt.subjectsTiming[i]',tt.subjectsTiming[i])
							if (tt.subjectsTiming[i].timeFrom != slots[i].from || tt.subjectsTiming[i].timeTo != slots[i].to) {
								setStatusMsg({
									msg:row.className +" - " + row.section + " 's Time Slot:"+ moment(tt.subjectsTiming[i].timeFrom).format("HH:mm:ss") +" : " + moment(tt.subjectsTiming[i].timeTo).format("HH:mm:ss") + " In Manual Mode is different from " + moment(slots[i].from).format("HH:mm:ss") +" : " + moment(slots[i].to).format("HH:mm:ss") +" In Automatic Mode On " + tt.dayName,
									buttonName: "Ok"
								  })
								setModalVisible(true)
								return false;
							}
					}
                }
            }
        }
		return response;
	}

	const handleClose = async () => {
		setModalVisible(false)
		// setStatusMsg("")
		setStatusMsg({
			msg: "",
			buttonName: ""
		});
	}

	const getTimeTableByCheck = async () => {
		await handleClose()
		setCheckValidTime(true)
	}
	
	useEffect(()=>{
		if(checkValidTime){
			getTimeTableByClass(selectedClassID)
		}
	},[checkValidTime])

	const getTimeTableByClass = async (selectedClassID) => {
		let check = true;
		if(!checkValidTime){
			check = await validateTime()
		}
		if(check || checkValidTime){
			setDataLoader(true);
			const ClassApi = new Class();
			await ClassApi.getTimeTableByClass(selectedClassID).then(async (response) => {
				if (response.success) {
					toast.success(response.msg);
					setRows(response.data)
					setSlots(response.slots)
				} else {
					if (response.msg == "TypeError: Cannot set property 'timeFrom' of undefined") {
						toast.error("Slots and teachers alloted to selected batch is not equal.");
					} else {
						toast.error(response.msg);
					}
				}
			}).catch((error) => {
				toast.error(error.toString());
			});
			setDataLoader(false)
		}
		setCheckValidTime(false)
	}

	const loadDayData = (value) => {
		let f = { ...formData }
		f.dayName = value;
		f.subjectId = '';
		f.timeFrom = '';
		f.timeTo = '';
		f.daysOfWeek = [];
		let selectedCls = rows.find(o => o._id === f.classId);
		if (selectedCls && selectedCls.timeTable) {
			let selectedDay = selectedCls.timeTable.find(o => o.dayName === value);
			if (selectedDay && selectedDay.subjectsTiming) {
				f.daysOfWeek = selectedDay.subjectsTiming
			}
		}
		setFormData(f);
	}

	const validateTeacherTime = (teacherId) => {
		let response = true;
		if (rows && rows.length) {
			loop1: for (let row of rows) {
				loop2: for (let tt of row.timeTable) {
					loop3: for (let st of tt.subjectsTiming) {
						if (st.teacherId === teacherId && tt.dayName === formData.dayName) {
							response = validateSlotTimeForTeacher(formData, st);
							if (response === false) {
								const time = st.timeFrom + ' to ' + st.timeTo;
								alert('Teacher is already assigned class ' + row.className + '-' + row.section + ' at ' + tt.dayName + ' from ' + time);
								break loop1;
							}
						}
					}
				}
			}
		}
		return response;
	}

	const validateSlotTimeForTeacher = (formData, compareData) => {
		const formStartTimeSeconds = moment(formData.timeFrom, 'HH:mm');
		const formEndTimeSeconds = moment(formData.timeTo, 'HH:mm');;
		const compareStartTimeSeconds = moment(compareData.timeFrom, 'HH:mm');
		const compareEndTimeSeconds = moment(compareData.timeTo, 'HH:mm');
		if (editCheck && formData.subjectTimingsId) {
			formData.daysOfWeek.map((item) => {
				if (item._id === compareData._id && compareData._id === formData.subjectTimingsId) {
					return true;
				} else {
					return false;
				}
			})
		} else if (formStartTimeSeconds.isBetween(compareStartTimeSeconds, compareEndTimeSeconds, undefined, '[)')) {
			return false;
		} else if (formEndTimeSeconds.isBetween(compareStartTimeSeconds, compareEndTimeSeconds, undefined, '(]')) {
			return false;
		} else if (moment(formStartTimeSeconds).isBefore(compareStartTimeSeconds) && moment(formEndTimeSeconds).isAfter(compareStartTimeSeconds)) {
			return false;
		}
		return true;
	}

	const validateSlotTime = (name, selectedTime, subjectTimingsId) => {
		const selectedTimeSeconds = moment(selectedTime, 'HH:mm');
		let flag = true;
		formData.daysOfWeek.map((data) => {
			const startTimeSeconds = moment(data.timeFrom, 'HH:mm');
			const endTimeSeconds = moment(data.timeTo, 'HH:mm');
			if (name === 'timeFrom' && selectedTimeSeconds.isSame(startTimeSeconds)) {
				flag = false;
			}
			if (name === 'timeTo' && selectedTimeSeconds.isSame(endTimeSeconds)) {
				flag = false;
			}
			if (selectedTimeSeconds.isBetween(startTimeSeconds, endTimeSeconds)) {
				flag = false;
			}
			if (editCheck && subjectTimingsId && subjectTimingsId === data._id) {
				flag = true;
			}
		})
		return flag;
	}

	const getSubjectsForClass = (id) => {
		let f = { ...formData };
		f.classId = id;
		f.subjectId = '';
		f.timeFrom = '';
		f.timeTo = '';
		f.dayName = '';
		f.daysOfWeek = [];
		setFormData(f);
	}

	return (
		<div>
			<div className="page-header">
				<h3 className="page-title"> Auto Time Tables </h3>
				<button
					onClick={exportToPdf}
					type="button"
					className="btn btn-outline-warning mt-1"
					style={{ padding: "8px" }}
				>
					Print
				</button>
			</div>
			{dataLoader ? <Loader /> : null}
			<div className="row">
				<div className="col-lg-12 grid-margin stretch-card">
					<div className="card">
						<div className="card-body">
							<nav aria-label="breadcrumb">
								<Form.Group className="row ml-1">
									<label className="col-sm-2 col-form-label">Select Class</label>
									{/* <div className="col-sm-5">
										<select className="form-control" name="classFilter" onChange={handleChanges} value={formData && formData.selectedClass !== '' ? formData.selectedClass : ''}>
											{rows.map((data,index) => <option key={index} value={data._id}>{data.className} {data.section}</option>)}
										</select>
									</div> */}
									<div className="col-sm-5">
										<select className="form-control" name="classFilter" onChange={handleChanges} >
											<option value="">---</option>
											{showBatchDropDown.map((data, index) => <option key={index} value={data._id}>{data.groupName}</option>)}
										</select>
									</div>
									<button type="button" className="btn btn-primary ml-2 btn-fw" style={{ lineHeight: 1.5 }} onClick={() => getTimeTableByClass(selectedClassID)}>Generate Auto-Timetable</button>
								</Form.Group>
							</nav>

							<div className="container py-7" id='exportTimeTableDiv'>
								<div className="box">
									{rows ? <ClassSidebarTimeTable allClassData={rows}></ClassSidebarTimeTable> : null}
									{rows ? <AllClassTimeTable
										workingDays={workingDays}
										allClassData={rows}
										staffTeachersList={staffTeachersList}
										slots={slots}
										groupID={selectedClassID}></AllClassTimeTable> : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<StatusModal show={modalVisible} isModalVisible2={handleClose} deleteData={getTimeTableByCheck} body={statusMsg} />
		</div>
	)
}

export default AutoTimeTable;
