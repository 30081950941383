import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SchoolApi from "../../../api/schoolApi";
import AuthApi from "../../../api/authApi";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import Grid from "../../../components/dataTable";
import Loader from "../../../components/loader";
import { Form, Modal } from 'react-bootstrap';

const ManageSchools = () => {
  useEffect(() => {
    getListOfSchools();
  }, []);

  const [rows, setRows] = useState([]);
  const [isArchiveList, setIsArchiveList] = useState(false);
  const [dataLoader, setDataLoader] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState({});
  const [resetPassword, setResetPassword] = useState({password: "", conformPassword:"", matched: ""})
  const [isActive, setIsActive] = useState(false)
  
  async function getListOfSchools(status="Active") {
    setDataLoader(true);
    let schoolApi = new SchoolApi();
    await schoolApi
      .getSchools(status)
      .then((respose) => {
        if (respose.data) {
          setRows(respose.data);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There was an error!", error);
      });
    setDataLoader(false);
  }

  async function getSchoolAdminAccess(schoolId,userRole="superAdmin"){
    let authApi = new AuthApi();
    await authApi
      .getSchoolAdminAccess(schoolId,userRole)
      .then((respose) => {
        if (respose.success && respose.data) {

          localStorage.setItem("token", respose.data.accessToken);
          let userData = {
            userId:respose.data._id,
            email: respose.data.email,
            firstName: respose.data.firstName,
            lastName: respose.data.lastName,
            role: respose.data.role,
            accessToModules: respose.data.accessToModules,
            currentSchoolId: respose.data.currentSchoolId,
            nonStaff: respose.data.nonStaff 
          };

          if(respose.data.schools && respose.data.schools.length){
            localStorage.setItem("schoolId",respose.data.schools[0]._id);
            localStorage.setItem("schoolInfo",respose.data.schools[0].schoolName);
          }

          localStorage.setItem("user_info", JSON.stringify(userData));

          const win = window.open("/dashboard", "_blank");
          win.focus();
        } else {
          toast.error(respose.msg);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There was an error!", error);
      });
  }

  async function deactiveOrActiveSchool(schoolId,status){
    let schoolApi = new SchoolApi();
    if (status=="Archive" && (!window.confirm("Are you sure for deactive this school ?"))) {
        return;
    }
    await schoolApi
      .deactiveOrActiveSchool(schoolId,status)
      .then((respose) => {
        if (respose.success) {
          toast.success(respose.msg);
          let st =  status =="Archive"? 'Active':'Archive';
          getListOfSchools(st);
        } else {
          toast.error(respose.msg);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There was an error!", error);
      });
  }

  function deactiveAction(props, index){
    let status =  isArchiveList ? 'Active':'Archive';
    return(
      <span>
        <ReactTooltip id='activate' type='error' effect='solid'>
            <span>Activate/Deactive</span>
          </ReactTooltip>  
        <span  data-tip data-for="activate" className="form-check form-check-success" style={{display:'inline-flex'}}>
          <label className="form-check-label">
            <input type="checkbox" className="form-check-input" onChange={(e)=>deactiveOrActiveSchool(props._id,status)} 
            defaultChecked={isArchiveList?false:true} checked={isArchiveList?false:true}/>
            <i className="input-helper"></i>
          </label>
        </span>
      </span>
    )
 
}

  function renderAction(props, index) {
    return (
      <span>
        <ReactTooltip id="Edit" type="warning" effect="solid">
          <span>Edit</span>
        </ReactTooltip>
        <Link to={"setupschool?id="+props._id}>
          <button
            data-tip
            data-for="Edit"
            type="button"
            className="btn btn-outline-warning"
            style={{ padding: "8px" }}
          >
            <i
              className="mdi mdi-border-color"
              style={{ fontSize: "17px" }}
            ></i>
          </button>
        </Link>

        <ReactTooltip id="User" effect="solid">
          <span>Access school admin</span>
        </ReactTooltip>
        <button
          data-tip
          data-for="User"
          type="button"
          className="btn btn-outline-primary"
          style={{ padding: "8px" }}
          onClick={(e)=> getSchoolAdminAccess(props._id,'superAdmin')
          }
        >
          <i className="mdi mdi-account-key" style={{ fontSize: "17px" }}></i>
        </button>

        <ReactTooltip id="Key" effect="solid">
          <span>Reset Password for School Admin</span>
        </ReactTooltip>
        <button
          data-tip
          data-for="Key"
          type="button"
          className="btn btn-outline-light"
          style={{ padding: "8px" }}
          onClick={()=> {
            setSelectedSchool(props);
            setIsActive(!isActive);
          }}
        >
          <i className="mdi mdi-key-variant" style={{ fontSize: "17px" }}></i>
        </button>
      </span>
    );
  }

  const isModalVisible = () => {
    setSelectedSchool({});
    setIsActive(!isActive);
  }

  const resetPasswordData = async() => {
    let c = {...resetPassword};
    let authApi = new AuthApi();
    if(c.password && c.conformPassword){
        await authApi.resetPasswordByAdmin({password: c.password, school: selectedSchool})
            .then((response) => {
                if(response.success){
                    toast.success(response.msg);
                    isModalVisible();
                    c.password = ""
                    c.conformPassword = ""
                    setResetPassword(c)
                } else {
                    toast.error(response.msg);
                }
            })
            .catch((error)=> {
                toast.error(error);
            })
    } else {
        toast.error("All fields are required");
    }
  }

  function handleChange(event) {
    const { name, value } = event.target;
    //here set the state and pass
    let c = { ...resetPassword };
    if(name == "conformPassword") {
      c.conformPassword = value;
      if(c.conformPassword != c.password){
        c.matched = "no"
      } else {
        c.matched = ""
      }
    } else if(name == "password"){
      c.password = value;
      if(c.conformPassword != c.password){
        c.matched = "no"
      } else {
        c.matched = ""
      }
    }
    setResetPassword(c);
}

  const columns = [
    {
        // name: "",
        // field: "Deactive",
        name: "_id",
        title: "",
        width: "0%",
        align:"center",
        render: deactiveAction,
    },
    {
      title: "School ",
      field: "schoolName",
    },
    {
      field: "principal",
      title: "Principal ",
    },
    {
      field:"allowedRadius",
      title:"Radius"
    },
    {
      title: "Admin",
      field: "schoolAdmin",
    },   
    {
      field: "city",
      title: "City ",
    },
    {
      name: "action",
      title: "Actions ",
      render: renderAction,
    },
  ];

  return (
    <div>
      {dataLoader ? <Loader /> : null}
      <div className="page-header">
        <h3 className="page-title"> Manage Schools </h3>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
              <nav aria-label="breadcrumb">
                  <div className="row">
                    <div className="col">
                          <button 
                          type="button"
                          className="btn btn-outline-success float-right"
                          style={{padding:'8px', margin:"5px"}}
                          onClick={(e)=>{
                            let status = isArchiveList ? false: true;
                            let statusTxt = isArchiveList?'Active':'Archive';
                            setIsArchiveList(status);
                            getListOfSchools(statusTxt);
                          }}
                          >
                             {isArchiveList?'Active List':'Archive List'} 
                          </button> 
                    </div>
                  </div> 
                                            
              </nav> 
            <Grid 
            columns={columns} 
            rows={rows} />
          </div>
        </div>
      </div>
      {/* ======================================================================== */}

      <Modal show={isActive} onHide={() => isModalVisible()}>
        <Modal.Header  style={{backgroundColor:"#0062ff"}} closeButton>
          <Modal.Title style={{color: "beige"}}>Reset Password!</Modal.Title>
        </Modal.Header>
        
        <Modal.Body style={{minHeight:"133px", textAlign:"center", backgroundColor:"white"}}>
          <span >You can reset password of School - AF School</span>
          {/* ==================================================================================== */}


          <Form className="pt-3">
            <Form.Group className="d-flex search-field">
              <Form.Control
                  type="password"
                  onChange={(e) => handleChange(e)}
                  placeholder="New Password"
                  size="lg"
                  name="password"
                  className="h-auto"
                  required={true}
              />
            </Form.Group>
            
            <Form.Group className="d-flex search-field">
              <Form.Control
                  type="password"
                  onChange={(e) => handleChange(e)}
                  placeholder="Confirm New password"
                  size="lg"
                  name="conformPassword"
                  className="h-auto"
                  required={true}
              />
            </Form.Group>
            <span style={{color: "red", fontSize:"12px"}} hidden={resetPassword.matched == "no"? false:true}>
                The password confirmation does not match
            </span>
            <div className="mt-3">
            {/* <Link className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" to="/dashboard">SIGN IN</Link> */}
            </div>
          </Form>


          {/* ==================================================================================== */}
        </Modal.Body>
        
        <Modal.Footer style={{backgroundColor:"white"}}>
          <button type="button" className="btn btn-outline-primary" onClick={()=> resetPasswordData() }>
            <i className="mdi mdi-key-variant" style={{ fontSize: "17px" }}></i> Reset Password
          </button>
          <button type="button" className="btn btn-outline-secondary"  onClick={() => isModalVisible()} style={{padding:'16px'}}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      {/* ======================================================================== */}
    </div>
  );
};
export default ManageSchools;