import BaseApi from "./baseApi";

class AuthApi extends BaseApi {
    async login(user) {
        return await this.API(
            {
                url: "/auth/login",
                data: user,
                method: "post",
            }
        )
    }

    async checkEmail(email) {
        return await this.API(
            {
                url:"/auth/checkEmail",
                data:{email},
                method: "post"
            }
        )
    }
    async checkForMobileAndSendOTP(mobile) {
        return await this.API(
            {
                url:"/auth/otplogin",
                data:{mobile},
                method: "post"
            }
        )
    }

    async checkForParentMobileAndSendOTP(mobile) {
        return await this.API(
            {
                url:"/auth/otpparentlogin",
                data:{mobile},
                method: "post"
            }
        )
    }

    async getSchoolAdminAccess(schoolId,userRole) {
        return await this.API(
            {
                url: "/auth/getschooladminaccess",
                data: {schoolId,userRole},
                method: "post",
            }
        )
    }

    async forgotPassword(email) {
        return await this.API(
            {
                url: "/auth/forgotpassword",
                data: {email: email},
                method: "post"
            }
        )    
    }

    async resetPassword(data) {
        return await this.API(
            {
                url: "/auth/resetpassword",
                data: data,
                method: "post"
            }
        )
    }

    async resetPasswordByAdmin(data) {
        return await this.API(
            {
                url: "/school/setpassword",
                data: data,
                method: "post"
            }
        )
    }

    async changePassword(data) {
        return await this.API(
            {
                url: '/auth/changepassword',
                data: data,
                method: 'post'
            }
        )
    }
} 

export default AuthApi;
