import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import Grid from "../../../components/dataTable";
import SchoolApi from '../../../api/schoolApi';
import Loader from "../../../components/loader";
import ClassApi from '../../../api/classApi';
import ResultApi from '../../../api/resultApi';
import { jsPDF } from "jspdf";

const ManageResults = () => {
  const [terms, setTerms] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState();
  const [selectedSession, setSelectedSession] = useState();
  const [classes, setClasses] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState();
  const [dataLoader, setDataLoader] = useState(false);
  const [result, setResult] = useState([]);
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    if (localStorage.getItem('schoolId')) {
      getSchool(localStorage.getItem('schoolId'));
    }
  }, []);

  useEffect(() => {
    getSelectedClass();
  }, [selectedTerm]);

  useEffect(() => {
    getResultDetails();
  }, [selectedTerm, selectedClassId, selectedSession]);

  const exportToPdf = async () => {
    let pdf = new jsPDF('p', 'pt', 'a4');
    let pWidth = pdf.internal.pageSize.width;
    let srcWidth = document.getElementById('exportResultDiv').scrollWidth;
    let margin = 10;
    let scale = (pWidth - margin * 2) / srcWidth;

    pdf.html(document.getElementById('exportResultDiv'), {
      x: margin,
      y: margin,
      html2canvas: {
        scale: scale,
        allowTaint: true,
        backgroundColor: 'white'
      },
      callback: async function (doc) {
        let pageInfo = doc.internal.getCurrentPageInfo();
        let totalPages = doc.internal.getNumberOfPages();
        if (totalPages > 0 && totalPages > pageInfo.pageNumber) {
          for (let p = totalPages; p > pageInfo.pageNumber; p--) {
            doc.deletePage(p)
          }
        }
        doc.save('ResultReport.pdf');
      }
    });
  }

  const getSelectedClass = async () => {
    const classApi = new ClassApi();
    await classApi.getClassesByTerm(selectedTerm, "ArrayOfObjects").then(async (response) => {
      if (response.data && response.data.length) {
        setClasses(response.data);
      } else {
        setClasses([]);
      }
    })
  }

  const getSchool = async (id) => {
    setDataLoader(true);
    const schoolApi = new SchoolApi();
    await schoolApi.getSchool(id).then(async (response) => {
      if (response && response.data) {
        const termsExams = response.data.termsExams;
        const session = response.data.session;
        if (termsExams && termsExams.length && session && session.startsFromMonth) {
          setTerms(termsExams);
          setSelectedTerm(termsExams[0]._id);

          const sessionData = response.data.session;
          const currentMonth = new Date().getMonth() + 1;
          const currentYear = new Date().getFullYear();
          let sessionEndyear = new Date().getFullYear();
          if (sessionData.startsFromMonth > sessionData.endsOnMonth) {
            sessionEndyear = currentYear + 1;
          }
          const sessionStartDate = new Date(currentYear, sessionData.startsFromMonth - 1, 1);
          const sessionEndDate = new Date(sessionEndyear, sessionData.endsOnMonth, 0);
          const sessionsData = [];

          sessionsData.push({
            value: moment(sessionStartDate).subtract(1, 'y').format('DD-MM-YYYY') + ' to ' + moment(sessionEndDate).subtract(1, 'y').format('DD-MM-YYYY'),
            display: (currentYear - 1) + '-' + (sessionEndyear - 1)
          })
          sessionsData.push({
            value: moment(sessionStartDate).format('DD-MM-YYYY') + ' to ' + moment(sessionEndDate).format('DD-MM-YYYY'),
            display: currentYear + '-' + sessionEndyear
          })
          sessionsData.push({
            value: moment(sessionStartDate).add(1, 'y').format('DD-MM-YYYY') + ' to ' + moment(sessionEndDate).add(1, 'y').format('DD-MM-YYYY'),
            display: (currentYear + 1) + '-' + (sessionEndyear + 1)
          })

          if (currentMonth >= sessionData.startsFromMonth) {
            setSelectedSession(sessionsData[1].value);
          } else {
            setSelectedSession(sessionsData[0].value);
          }
        } else {
          setTerms([]);
          setSelectedTerm([]);
        }
      } else {
        setTerms([]);
        setSelectedTerm([]);
      }
      return response;
    }).catch((error) => {
      console.error('There is an error!', error);
    });
    setDataLoader(false);
  }

  function onChange(event) {
    const { name, value } = event.target;
    switch (name) {
      case 'term':
        setSelectedTerm(value)
        break;
      case 'class':
        let cls = classes.length && classes.find((item) => item._id.toString() == value.toString());
        if (cls) {
          setSelectedClassId(cls._id)
        }
        break;
    }
  }

  const StudentHyperLink = (props) => {
    return (
      <span>
        <Link to={`/viewstudent?id=${props._id}`}>
          {props.firstName} {props.lastName}
        </Link>
      </span>
    );
  };

  const getSubjectMarks = (props, subjects) => {
    var totalMarks = 0
    props.marks.forEach((marks) => {
      // if (marks.subjectId == subjects._id) {
      if (marks.subjectId == subjects.subject) {
        totalMarks += parseInt(marks.marksObtained + marks.practicalMarks + marks.assignmentMarks)
      }
    })
    return (
      <span>{totalMarks}</span>
    )
  };

  const getTotalMarks = (props) => {
    var totalMarks = 0
    let thirdArray = props.marks.filter((elem) => {
      return subjects.some((ele) => {
        // return ele._id == elem.subjectId;
        return ele.subject == elem.subjectId;
      });
    });

    // props.marks.forEach((marks) => {
    //   totalMarks += parseInt(marks.marksObtained + marks.practicalMarks + marks.assignmentMarks)
    // })
    thirdArray.forEach((marks) => {
      totalMarks += parseInt(marks.marksObtained + marks.practicalMarks + marks.assignmentMarks)
    })
    return (
      <span>{totalMarks}</span>
    )
  };

  const getTotalPercentage = (props) => {
    var subjectMarks = 0
    // props.totalMarks.forEach((totalMarks) => {
    //   subjectMarks += parseInt(totalMarks.marks + totalMarks.assignment + totalMarks.practical)
    // })
    subjects.forEach((totalMarks) => {
      subjectMarks += parseInt(totalMarks.marks + totalMarks.assignment + totalMarks.practical)
    })

    var obtainedTotalMarks = 0
    let thirdArray1 = props.marks.filter((elem) => {
      return subjects.some((ele) => {
        return ele.subject == elem.subjectId;
      });
    });

    // props.marks.forEach((marks) => {
    //   obtainedTotalMarks += parseInt(marks.marksObtained + marks.practicalMarks + marks.assignmentMarks)
    // })

    thirdArray1.forEach((marks) => {
      obtainedTotalMarks += parseInt(marks.marksObtained + marks.practicalMarks + marks.assignmentMarks)
    })

    var totalPercentage = obtainedTotalMarks / subjectMarks * 100
    return (
      <span>{(totalPercentage.toFixed(2)) + "%"}</span>
    )
  };

  const fields = [
    {
      title: "Student Name",
      render: StudentHyperLink,
    }
  ];

  subjects.map((subject, i) => {
    fields.push({
      title: subject.subjectName,
      render: props => getSubjectMarks(props, subject)
    })
  })
  fields.push({
    title: "Total Marks",
    render: getTotalMarks
  })
  fields.push({
    title: "Percentage",
    render: getTotalPercentage
  })

  const getResultDetails = async () => {
    const resultApi = new ResultApi();
    if (selectedTerm && selectedClassId && selectedSession) {
      await resultApi.getResultDetails({ selectedTerm, selectedClassId, selectedSession }).then(async (response) => {
        if (response.success) {
          if (response.data != "") {
            // response.data[0].subjects.map((o1, i) => {
            //   let subMarks = response.data[0].totalMarks[i].marks + response.data[0].totalMarks[i].practical + response.data[0].totalMarks[i].assignment
            //   o1.subjectName += "(" + subMarks + ")"
            // })
            let selectedTermName = terms.filter(val => val._id == selectedTerm)[0].term
            let classname = classes.filter(val => val._id == selectedClassId)[0].className
            let newSubject = response.data[0].courseData.filter(val => val.className == classname && val.term == selectedTermName)[0].subjects.map((o1, i) => {
              let subMarks = o1.marks + o1.practical + o1.assignment;
              let subjectname = response.data[0].subjects.filter(val => val._id == o1.subject)[0].subjectName;
              o1.subjectName = subjectname + " (" + subMarks + ")"
              return o1;
            })
            setResult(response.data);
            // setSubjects(response.data[0].subjects);
            setSubjects(newSubject)
          } else {
            setResult([]);
            setSubjects([]);
          }
        }
      })
    }
  }

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Results </h3>
        <button
          onClick={exportToPdf}
          type="button"
          className="btn btn-outline-warning"
          style={{ padding: "8px" }}
        >
          Print
        </button>
      </div>
      {dataLoader ? <Loader /> : null}
      <div className="row" id='exportResultDiv'>
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <form className="forms-sample">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="card-title"> Results Data</h4>
                  </div>
                  <div className="col-md-6 text-right">
                    <Link className="nav-link" to="/addresults">
                      <button type="submit" className="btn btn-primary">Add</button>
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <Form.Group className="row">
                      <label className="col-sm-4 col-form-label">Term<span style={{ color: "red" }}>*</span></label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          id="term"
                          name="term"
                          onChange={(e) => onChange(e)}
                        >
                          <option>-------</option>
                          {terms.map((resp) => <option key={"term_" + resp._id} value={resp._id}>{resp.term}</option>)}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group className="row">
                      <label className="col-sm-4 col-form-label">Session</label>
                      <label className="col-sm-8 col-form-label">{selectedSession}</label>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <Form.Group className="row">
                      <label className="col-sm-4 col-form-label">Class<span style={{ color: "red" }}>*</span></label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          id="class"
                          name="class"
                          onChange={(e) => onChange(e)}
                        >
                          <option>-------</option>
                          {classes.map((resp) => <option key={"className_" + resp._id} value={resp._id}>
                            {resp.className}{resp && resp.section ? "-" : ""}{resp.section}</option>)}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </form>
              {result.length ? (
                <Grid
                  columns={fields}
                  rows={result}
                  settings={{
                    search: false,
                    paging: false,
                    exportButton: false,
                    sorting: false
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageResults;