const dummyTeacherData = [
  { 
    name: "Chanchal",
    subject: "Maths",
    headOfClass: "Play-Way",
    contactNo: 8915796335,
    atttendance: "present",
  },
  {
    name: "Manju",
    subject: "Science",
    headOfClass: "Pre-Nursery",
    contactNo: 8493496335,
    atttendance: "present"
  },
  { 
    name: "Manika",
    subject: "Hindi",
    headOfClass: "Pre-Nursery",
    contactNo:5691557345,
    atttendance: "present"
  },
  { 

    name: "Arun",
    subject: "English",
    headOfClass: "Nursery",
    contactNo: 9455796335,
    atttendance: "absent"
  },
  { 

    name: "Harpreet",
    subject: "Punjabi",
    headOfClass: "UKG",
    contactNo: 6315796335,
    atttendance: "present"
  },
  { 

    name: "Arun",
    subject: "Arts",
    headOfClass: "I",
    contactNo:4565796335,
    atttendance: "present"
  },
  { 

    name: "Suresh",
    subject: "Maths",
    headOfClass: "II",
    contactNo: 8765796335,
    atttendance: "absent"
  },
  { 

    name: "Dinesh" ,
    subject: "English",
    headOfClass: "III",
    contactNo: 6755796335,
    atttendance: "present"
  },
  { 

    name: "Rohit",
    subject: "Social Science",
    headOfClass: "IV",
    contactNo: 6235796335,
    atttendance: "absent"
  },
  { 

    name: "Kamlesh",
    subject: "Drawing",
    headOfClass: "V",
    contactNo: 700796335,
    atttendance: "present"

  },
  { 

    name: "Atif",
    subject: "Environment",
    headOfClass: "VI",
    contactNo: 8907796335,
    atttendance: "present"
  },
  {
    name: "Manthan",
    subject: "SocialScience",
    headOfClass: "VII",
    contactNo: 5675796335,
    atttendance: "present"
  },
  { 

    name: "Vishal",
    subject: "Hindi",
    headOfClass: "VIII",
    contactNo: 8955796335,
    atttendance: "absent"

  },
  { 

    name: "Arjun",
    subject: "Physical Science",
    headOfClass: "IX",
    contactNo: 8005796335,
    atttendance: "present"
  },
  { 

    name: "Manjit",
    subject: "Maths",
    headOfClass: "X",
    contactNo: 8775796335,
    atttendance: "leave"
  },
  { 

    name: "Mahesh",
    subject: "Physical Science",
    headOfClass: "XI",
    contactNo: 7665796335,
    atttendance: "present"
  },
  { 

    name: "Abhijit",
    subject: "Science",
    headOfClass: "XII",
    contactNo: 2345796335,
    atttendance: "present"
  },
  { 

    name: "Komal",
    subject: "Maths",
    headOfClass: "XII",
    contactNo: 7895796335,
    atttendance: "leave"
  },
  { 

    name: "Monu",
    subject: "English",
    headOfClass: "XII",
    contactNo: 8005796335,
    atttendance: "present"
  },
  { 

    name: "Sonu",
    subject: "English",
    headOfClass: "XII",
    contactNo: 700796335,
    atttendance: "leave"
  },
  { 

    name: "Mahesh",
    subject: "Maths",
    headOfClass: "XI",
    contactNo: 600796335,
    atttendance: "present"
  },
  { 

    name: "Sunaina",
    subject: "Social Science",
    headOfClass: "V",
    contactNo: 4565796335,
    atttendance: "present"
  },
  { 

    name: "Sonali",
    subject: "Punjabi",
    headOfClass: "VI",
    contactNo: 9005796335,
    atttendance: "leave"
  },
  { 

    name: "Ayushman",
    subject: "Chemistry",
    headOfClass: "IV",
    contactNo: 9895796335,
    atttendance: "present"
  },
  { 

    name: "Varun",
    subject: "Physics",
    headOfClass: "II",
    contactNo: 9345796335,
    atttendance: "present"
  }
];

export default dummyTeacherData;