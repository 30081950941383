import BaseApi from "./baseApi";

class StudentFeeApi extends BaseApi {
    async getStudentFeeList(data) {
        return await this.API(
            {
                url: "/studentfee/studentfeeslist",
                data: data,
                method: "post",
            }
        )
    }

    async getStudentFeeDetails(data, sessionYear) {
        return await this.API({
            url: `/studentfee/getstudentfeedetails/${data}/${sessionYear}`,
            method: "get"
        })
    }

    async submitFees(data) {
        return await this.API({
            url: '/studentfee/addpaidfeedata',
            data: data,
            method: "post"
        })
    }

    async feeHistory(data) {
        return await this.API({
            url: `/studentfee/getfeehistory/${data}`,
            method: "get"
        });
    }

    async getFeeDefaultersList() {
        return await this.API({
            url: `/studentfee/getfeedefaulters`,
            method: 'get'
        });
    }

    async generateStudentFeePDF(studentId, id) {
        return await this.API({
            url: `/studentfee/generatestudentfeepdf/${studentId}/${id}`,
            method: 'get'
        });
    }

    async getStudentFeeChequesList(data) {
        return await this.API({
            url: `/studentfee/getstudentfeechequeslist`,
            method: 'get'
        })
    }

    async updateStudentFeeChequePayment(data) {
        return await this.API({
            url: `/studentfee/updatestudentfeechequepayment`,
            data: data,
            method: 'put'
        })
    }
    async saveFeeDataCsv(data) {
        return await this.API(
            {
                url: "/studentfee/savefeedatacsv",
                data: data,
                method: "post"
            }
        )
    }
}

export default StudentFeeApi;