import React, { useState } from "react";
import CSVReader from "react-csv-reader";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import StudentApi from "../../api/studentApi";
import { Modal, Button } from 'react-bootstrap';
import CSVERRORDATAModal from "../csvDownloadPopup";
import Loader from "../loader";
const Students = (filters) => {
  const [isModal, setIsModal] = useState(false);
  const [errorRecords, setErrorRecords] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [IsRulesShow, setIsRulesShow] = useState(false);
  const [studentsData, setStudentsData] = useState([]);

  let headers = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Admission No", key: "admissionNo" },
    { label: "Admission Date", key: "admissionDate" },
    { label: "DOB", key: "dob" },
    { label: "Gender", key: "gender" },
    { label: "Image", key: "photoUrl" },
    { label: "Passed Out Date", key: "passedout" },
    { label: "F Name", key: "fatherName" },
    { label: "F Mobile", key: "fatherMobile" },
    { label: "F Email", key: "fatherEmail" },
    { label: "F Primary", key: "fatherPrimary" },
    { label: "M Name", key: "motherName" },
    { label: "M Mobile", key: "motherMobile" },
    { label: "M Email", key: "motherEmail" },
    { label: "M Primary", key: "motherPrimary" },
    { label: "Session Start", key: "sessionStart" },
    { label: "Address", key: "studentAddress" },
    { label: "Pin Code", key: "pinCode" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Country", key: "country" },
    { label: "Is Bus", key: "isbus" },
    { label: "Route", key: "route" },
    { label: "Destination", key: "destination" },
    { label: "Class Name", key: "className" },
    { label: "Section", key: "section" },
  ];

  const getStudentsData = async () => {
    let Api = new StudentApi();
    await Api.exportStudents()
      .then(async (response) => {
        if (response.data && response.data.length) {
          setStudentsData(response.data);
        } else {
          setStudentsData([]);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There is an error!", error);
      });
  };

  function downloadStudentsCSVFormat() {
    let headers = [
      { label: "First Name", key: "firstName" },
      { label: "Last Name", key: "lastName" },
      { label: "Admission No", key: "admissionNo" },
      { label: "Admission Date", key: "admissionDate" },
      { label: "DOB", key: "dob" },
      { label: "Gender", key: "gender" },
      { label: "Image", key: "photoUrl" },
      { label: "Passed Out Date", key: "passedout" },
      { label: "F Name", key: "fatherName" },
      { label: "F Mobile", key: "fatherMobile" },
      { label: "F Email", key: "fatherEmail" },
      { label: "F Primary", key: "fatherPrimary" },
      { label: "M Name", key: "motherName" },
      { label: "M Mobile", key: "motherMobile" },
      { label: "M Email", key: "motherEmail" },
      { label: "M Primary", key: "motherPrimary" },
      { label: "Session Start", key: "sessionStart" },
      { label: "Address", key: "studentAddress" },
      { label: "Pin Code", key: "pinCode" },
      { label: "City", key: "city" },
      { label: "State", key: "state" },
      { label: "Country", key: "country" },
      { label: "Is Bus", key: "isbus" },
      { label: "Route", key: "route" },
      { label: "Destination", key: "destination" },
      { label: "Class Name", key: "className" },
      { label: "Section", key: "section" },
    ];

    return (
      <Modal show={IsRulesShow} onHide={() => setIsRulesShow(false)}>
        <Modal.Header style={{ backgroundColor: "#af0000" }} closeButton>
          <Modal.Title style={{ color: "beige" }}>Warning!</Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{
            minHeight: "133px",
            textAlign: "center",
            backgroundColor: "white",
          }}
        >
          <ul className="list-group list-group-flush">
            <li className="list-group-item text-left">Your download is ready, click download button or cancel.</li>
          </ul>
        </Modal.Body>

        <Modal.Footer style={{ backgroundColor: "white" }}>
          <CSVLink
            data={studentsData}
            filename={"School_Students_Data.csv"}
            headers={headers}
            className="btn btn-sm btn-primary"
            style={{ padding: "16px" }}
            onClick={() => setIsRulesShow(false)}
          >
            Download
          </CSVLink>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() => setIsRulesShow(false)}
            style={{ padding: "16px" }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  async function isModal2() {
    setIsModal(!isModal);
  }

  return (
    <div>
      <CSVERRORDATAModal
        show={isModal}
        isModal={isModal2}
        filename={"Data_Error_Students_Import.csv"}
        headers={headers}
        errorRecords={errorRecords}
      />
      {isLoader ? <Loader /> : null}

      <a href="javascript:void(0)" onClick={(e) => { setIsRulesShow(true); getStudentsData(); }}>
        <i className="mdi mdi-download"></i>Export Students Data
      </a>
      {downloadStudentsCSVFormat()}
    </div>
  );
};

export default Students;
