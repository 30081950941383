import BaseApi from "./baseApi";

class NonTeachingStaffRoles extends BaseApi {
    async getRole() {
        return await this.API(
            {
                url: "/nonteachingstaffroles/getroles",
                method:"get",
            }
        )
    }

    async createRole(data) {
        return await this.API(
            {
                url: "/nonteachingstaffroles/createrole",
                data: data,
                method:"post"
            }
        )
    }

    async editRole(data) {
        return await this.API(
            {
                url: "/nonteachingstaffroles/updaterole",
                data: data,
                method:"post"
            }
        )
    }

    async deleteRole(data) {
        return await this.API(
            {
                url: "/nonteachingstaffroles/deleterole",
                data: { _id: data },
                method:"post"
            }
        )
    }

}

export default NonTeachingStaffRoles;