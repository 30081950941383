import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Form, Modal, Button } from "react-bootstrap";
import Grid from "../../../components/dataTable";
import Class from "../../../api/classApi";
import StudentFeeApi from "../../../api/studentFeeApi";
import SchoolApi from "../../../api/schoolApi";
import BaseApi from "../../../api/baseApi";
import StudentsFeeData from "../../../components/bulkupload/studentsFeeData";
import moment from "moment";
import Loader from "../../../components/loader";
import Concession from "../../../api/concessionApi";
import { MultiSelect } from "react-multi-select-component";
import PreviousFeeRecord from "./PreviousFeeRecord";
import FeeReceiptModal from "./FeeReceiptModal";
import FeeDefaulterList from "./FeeDefaulterList";

const StudentsFees = () => {
  const [data, setData] = useState({
    startstyle: {
      color: "red",
      fontSize: "14px",
    },
    isModalVisible: false,
    isModalVisible2: false,
    selectedstudent: {},
  });

  const [rows, setRows] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [searchStduent, setSearchStduent] = useState({
    admissionNo: "",
    studentName: "",
    classes: "",
    concessionId: "",
    session: "",
    paymentSchedule: [],
    paymentMode: "",
    feeStatus: "",
    month: "",
  });
  const [rows3, setRows3] = useState([]);
  const [classList, setClassList] = useState([]);
  const [sessionsData, setSessionsData] = useState([]);
  const [monthsList, setMonthsList] = useState([]);
  const [monthsGrouping, setMonthsGrouping] = useState([]);
  const [feeSchedules, setFeeSchedules] = useState();
  const [isPageLoaded, setIsPageLoaded] = useState(true);
  const [isModalLoaded, setIsModalLoaded] = useState(true);
  const [clist, setCList] = useState([]);
  const [selectedSession, setSelectedSession] = useState();

  useEffect(() => {
    getClassList();
    getList();
    reorderMonthBySessionStart();
    getConcessionList();
    if (localStorage.getItem("schoolId")) {
      getSchool(localStorage.getItem("schoolId"));
    }
  }, []);

  // =============================================
  
  const getConcessionList = async () => {
    let clist = new Concession();
    await clist.getConcession().then(async (response) => {
      if (response.data && response.data.length) {
        setCList(response.data);
      } else {
        setCList([])
      }
    }).catch((error) => {
      toast.error(error.toString());
    })
  }

  const getSchool = async (id) => {
    const schoolApi = new SchoolApi();
    setIsLoader(true);
    await schoolApi
      .getSchool(id)
      .then(async (response) => {
        if (response && response.data) {
          const session = response.data.session;
          if (response.data.feeSchedules) {
            setFeeSchedules(response.data.feeSchedules);
          }

          const currentMonth = new Date().getMonth() + 1;
          if (session && session.startsFromMonth) {
            const sessionData = response.data.session;
            const currentYear = new Date().getFullYear();
            let sessionEndyear = new Date().getFullYear();
            if (sessionData.startsFromMonth > sessionData.endsOnMonth) {
              sessionEndyear = currentYear + 1;
            }
            const sessionStartDate = new Date(
              currentYear,
              sessionData.startsFromMonth - 1,
              1
            );
            const sessionEndDate = new Date(
              sessionEndyear,
              sessionData.endsOnMonth,
              0
            );
            const sessionsData = [];

            sessionsData.push({
              value:
                moment(sessionStartDate).subtract(1, "y").format("DD-MM-YYYY") +
                "to" +
                moment(sessionEndDate).subtract(1, "y").format("DD-MM-YYYY"),
              display: currentYear - 1 + "-" + (sessionEndyear - 1),
            });
            sessionsData.push({
              value:
                moment(sessionStartDate).format("DD-MM-YYYY") +
                "to" +
                moment(sessionEndDate).format("DD-MM-YYYY"),
              display: currentYear + "-" + sessionEndyear,
            });
            sessionsData.push({
              value:
                moment(sessionStartDate).add(1, "y").format("DD-MM-YYYY") +
                "to" +
                moment(sessionEndDate).add(1, "y").format("DD-MM-YYYY"),
              display: currentYear + 1 + "-" + (sessionEndyear + 1),
            });
            let searchData = { ...searchStduent };
            if (currentMonth >= session.startsFromMonth) {
              searchData.session = sessionsData[1].value;
              setSelectedSession(sessionsData[1].value);
            } else {
              searchData.session = sessionsData[1].value;
              setSelectedSession(sessionsData[0].value);
            }
            setSearchStduent(searchData);
            setSessionsData(sessionsData);
          }
          const grpMonths = [];
          response.data.feeSchedules.lateFees.find((item) => {
            let strGrp = [];
            item.months.map((item2) => {
              strGrp.push(parseInt(item2.value));
            });
            grpMonths.push(strGrp);
          });
          setMonthsGrouping(grpMonths);
        }
        return response;
      })
      .catch((error) => {
      });
    setIsLoader(false);
  };

  const getClassList = async () => {
    setIsLoader(true);
    let clist = new Class();
    await clist
      .getClass()
      .then((response) => {
        if (response.data && response.data.length) {
          setClassList(response.data);
        } else {
          setClassList([]);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    setIsLoader(false);
  };

  const getList = async () => {
    const list = new StudentFeeApi();
    setIsLoader(true);
    await list.getStudentFeeList(searchStduent).then((response) => {
      if (response.data && response.data.length) {
        setRows(response.data);
      } else if (response.error) {
        if (!isPageLoaded) {
          toast.error(response.msg);
        }
        setRows([]);
      } else {
        setRows([]);
      }
      setIsLoader(false);
      setIsPageLoaded(false)
    });
  };

  const reorderMonthBySessionStart = async () => {
    const baseApi = new BaseApi();
    setIsLoader(true);
    await baseApi.reorderMonthBySessionStart().then((response) => {
      if (response.success) {
        setMonthsList(response.data);
      } else {
        setMonthsList([]);
      }
    });
    setIsLoader(false);
  };

  // =============================================

  const renderAction = (props, index) => {
    return (
      <span>
        <ReactTooltip id="PayNow" effect="solid">
          <span>Payable Fee</span>
        </ReactTooltip>
        <Link to={`/submitstudentfee?id=${props._id}`}>
          <button
            data-tip
            data-for="PayNow"
            type="button"
            className="btn btn-outline-success"
            style={{ padding: "8px" }}
          >
            Payable Fee
          </button>
        </Link>
        <ReactTooltip id="FeeRecords" type="warning" effect="solid">
          <span>Fee Records</span>
        </ReactTooltip>
        <button
          data-tip
          data-for="FeeRecords"
          onClick={() => {
            let d = { ...data };
            d.isModalVisible2 = true;
            d.selectedstudent = props;
            setData(d);
            feeHistory(props._id);
          }}
          type="button"
          className="btn btn-outline-warning mt-1"
          style={{ padding: "8px" }}
        >
          Fee Records
        </button>
      </span>
    );
  };

  const handleClose = () => {
    let d = { ...data };
    d.selectedstudent = {};
    d.isModalVisible = false;
    setData(d);
  };

  const handleClose2 = () => {
    let d = { ...data };
    d.selectedstudent = {};
    d.isModalVisible2 = false;
    setData(d);
  };

  const handleClose3 = () => {
    let d = { ...data };
    d.isDefaulterModalVisible = false;
    setData(d);
  };

  const StudentHyperLink = (props) => {
    return (
      <span>
        <Link to={`/addstudent?id=${props._id}`}>
          {props.firstName} {props.lastName}
        </Link>
      </span>
    );
  };

  const ClassHyperLink = (props) => {
    return (
      <span>
        {props.classInfo ? props.classInfo.className : "class missing"}{" "}
        {props.classInfo &&
          props.classInfo.section != undefined &&
          props.classInfo.section
          ? "-" + props.classInfo.section
          : ""}
      </span>
    );
  };

  const paidData = (props) => {
    return <span>{props.feeInfo ? props.feeInfo.paidAmount : "Not Paid"}</span>;
  };

  const paidDate = (props) => {
    return (
      <span>
        {props.feeInfo
          ? moment(props.feeInfo.paymentDate).format("DD-MM-YYYY")
          : "--/--/----"}
      </span>
    );
  };

  const pendingFee = (props) => {
    return (
      <span>
        {props.feeInfo
          ? Number(props.feeInfo.totalAmount) - Number(props.feeInfo.paidAmount)
          : ""}
      </span>
    );
  };

  // =========================================================
  const feeHistory = async (id) => {
    const detail = new StudentFeeApi();
    await detail.feeHistory(id).then((response) => {
      if (response.data) {
        setRows2(response.data);
      } else if (response.error) {
        toast.error(response.msg);
      } else {
        setRows2([]);
      }
    });
  };

  const notpaid = (props) => {
    let balanceAmount =
      parseInt(props.totalAmount) - parseInt(props.paidAmount);
    let pandingFee =
      parseInt(props.scheduleTotalAmount) - parseInt(props.totalAmount);
    let totalPendingFee =
      parseInt(pandingFee - props.concessionAmount) + parseInt(balanceAmount);
    return (
      <span>
        {Number.isNaN(totalPendingFee) ? 0 : totalPendingFee.toString().replace("-", "")}
        {Math.sign(totalPendingFee) == -1 ? (
          <span className="badge badge-danger">Paid Extra</span>
        ) : null}
      </span>
    );
  };

  const paymentType = (props) => {
    if (props.paymentDetails) {
      return <span>{props.paymentDetails.paymentMode}</span>;
    }
  };

  const pendingFeeMonth = (props) => {
    if (feeSchedules.lateFees && props.feeforMonths) {
      let schedule = feeSchedules.lateFees.find((item, i) => {
        return item.months.find((mt) => {
          return props.feeforMonths.find((d) => {
            return d == mt.value;
          });
        });
      });
      if (schedule) {
        return schedule.months.map((item, i) => {
          let paid = props.feeforMonths.find((p) => {
            return item.value == p || item.value <= p;
          });
          if (paid == undefined) {
            return <span className="badge badge-danger">{item.label}</span>;
          }
        });
      }
    }
  };

  const studentName = (props) => {
    if (props.students && props.students._id) {
      return (
        <span>
          {props.students.firstName} {props.students.lastName}
        </span>
      );
    }
  };

  const paymentSchedule = (props) => {
    if (feeSchedules.lateFees && props.feeforMonths) {
      return feeSchedules.lateFees.map((item, i) => {
        let pm = 0;
        return (
          <ul className="list-group" key={"list-group"+i}>
            {item.months.map((mt, k) => {
              let mch = props.feeforMonths.filter((f) => {
                return f == mt.value;
              });
              if (mch.length && pm == 0) {
                pm = 1;
                return (
                  <li className="list-group-item">
                    {feeSchedules.lateFees &&
                      feeSchedules.lateFees[i] &&
                      feeSchedules.lateFees[i].months.map((m, s) => (
                        <span key={"badge-info"+k} className="badge badge-info">
                          {m.label}
                        </span>
                      ))}
                  </li>
                );
              }
            })}
          </ul>
        );
      });
    }
  };

  const className = (props) => {
    if (props.classes && props.classes._id) {
      return (
        <span>
          {props.classes.className}-
          {props.classes.section ? props.classes.section : ""}
        </span>
      );
    }
  };

  const paymentDate = (props) => {
    if (props.paymentDate) {
      return <span>{moment(props.paymentDate).format("DD-MM-YYYY")}</span>;
    } else {
      return <span></span>;
    }
  };

  const studentContactDetial = (props) => {
    if (
      props.students &&
      props.students.fatherProfile &&
      props.students.fatherProfile.primary
    ) {
      let mobile = "Mobile:" + props.students.fatherProfile.mobile;
      let emailLink = "mailTo:" + props.students.fatherProfile.email;
      return (
        <span>
          {mobile}
          <br />
          <span>
            Email: <a href={emailLink}>{props.students.fatherProfile.email}</a>
          </span>
        </span>
      );
    } else if (
      props.students &&
      props.students.motherProfile &&
      props.students.motherProfile.primary
    ) {
      return (
        <span>
          {"Mobile:" + props.students.motherProfile.mobile}
          <br />
          {"Email:" + props.students.motherProfile.email}
        </span>
      );
    }
  };

  const renderActionPreviousRec = (props, index) => {
    return (
      <span>
        <ReactTooltip id="Print" type="warning" effect="solid">
          <span>Print</span>
        </ReactTooltip>
        <button
          data-tip
          data-for="Print"
          onClick={() => {
            let d = { ...data };
            d.isModalVisible = true;
            d.isModalVisible2 = false;
            setData(d);
          }}
          type="button"
          className="btn btn-outline-warning mt-1"
          style={{ padding: "8px" }}
        >
          Print
        </button>
      </span>
    );
  };

  const monthsPreviosuRecord = (props) => {
    let monthsStr = "";
    if (props.feeforMonths) {
      props.feeforMonths.map((item1, key1) => {
        monthsList.map((item2) => {
          if (item1 === parseInt(item2.value)) {
            monthsStr +=
              key1 === props.feeforMonths.length - 1
                ? item2.label
                : item2.label + ", ";
          }
        });
      });
      return <span className="badge badge-success">{monthsStr}</span>;
    }
  };

  const feeScheduleMonths = (props) => {
    const x = [];
    props.feeforMonths.map((item1) => {
      monthsGrouping.map((item2) => {
        item2.map((item3) => {
          if (item1 === item3) {
            if (x.every((item) => item2.indexOf(item) !== -1)) {
              x.push(item2);
              return;
            } else {
            }
          }
        });
      });
    });
    let dataStr = "";
    if (x && x.length) {
      monthsList.map((item1) => {
        x.map((item2) => {
          item2.map((item3, key3) => {
            if (parseInt(item1.value) === parseInt(item3)) {
              dataStr = dataStr + item1.label + ", ";
            }
          });
        });
      });
    }
    return <span>{dataStr}</span>;
  };

  const field = () => {
    const fields = [
      {
        title: "Admission no.",
        field: "students.admissionNo",
      },
      {
        title: "Student Name",
        field: "fullName",
      },
      {
        title: "Class",
        field: "classes.className",
        render: className,
      },
      {
        title: "Payment Type",
        field: "paymentDetails.paymentMode",
      },
      {
        title: "Payment Schedule",
        render: paymentSchedule,
      },
      {
        title: "Paid For months",
        render: monthsPreviosuRecord,
      },
      {
        title: "Fees for Pending Month(s)",
        render: pendingFeeMonth,
      },
      {
        title: "Session",
        field: "feeForSession",
      },
      {
        title: "Schedule Amount",
        field: "scheduleTotalAmount",
      },
      {
        title: "Concession Amount",
        field: "concessionAmount",
      },
      {
        title: "Late Fees",
        field: "lateFeeAmount",
      },
      {
        title: "Total Payable Fees",
        field: "totalAmount",
      },
      {
        title: "Fees Paid",
        field: "paidAmount",
      },
      {
        title: "Pending Fees",
        render: notpaid,
      },
      {
        title: "Payment Date",
        render: paymentDate,
      },

      {
        title: "Actions",
        render: renderActionPreviousRec,
      },
    ];
    return fields;
  };

  const getFeeDefaultersList = async (id) => {
    setIsLoader(true);
    const detail = new StudentFeeApi();
    await detail.getFeeDefaultersList().then((response) => {
      if (response.data) {
        setRows3(response.data);
      } else if (response.error) {
        if (!isModalLoaded) {
          toast.error(response.msg);
        }
      } else {
        setRows3([]);
      }
      setIsLoader(false);
      setIsModalLoaded(false);
    });
  };

  const defPendingFeeMonth = (props) => {
    if (feeSchedules.lateFees && props.pendingFeeMonth) {
      return monthsList.map((mt) => {
        return props.pendingFeeMonth.map((d) => {
          return (
            <>
              {d == mt.value ? (
                <span className="badge badge-danger">{mt.label}</span>
              ) : (
                ""
              )}
            </>
          );
        });
      });
    }
  };
  const defaulterField = () => {
    const fields = [
      {
        title: "Admission No.",
        field: "students.admissionNo",
      },
      {
        title: "Student",
        field: "fullName",
      },
      {
        title: "Class",
        field: "className",
      },
      {
        title: "Payment Schedule",
        render: paymentSchedule,
      },
      {
        title: "Fees Paid for Months",
        render: monthsPreviosuRecord,
      },
      {
        title: "Fees for Pending Month(s)",
        render: defPendingFeeMonth,
      },
      {
        title: "Schedule Amount",
        field: "scheduleTotalAmount",
      },
      {
        title: "Concession Amount",
        field: "concessionAmount",
      },
      {
        title: "Late Fees",
        field: "lateFeeAmount",
      },
      {
        title: "Total Payable Fees",
        field: "totalAmount",
      },
      {
        title: "Fees Paid",
        field: "paidAmount",
      },
      {
        title: "Pending Fees",
        render: notpaid,
      },
      {
        title: "Contact",
        render: studentContactDetial,
      },
    ];
    return fields;
  };

  const fields = () => {
    let b = [
      {
        title: "Admission No.",
        field: "admissionNo",
      },
      {
        title: "Student",
        render: StudentHyperLink,
      },
      {
        title: "Class",
        render: ClassHyperLink,
      },
      {
        name: "action",
        title: "Actions ",
        render: renderAction,
      },
    ];
    return b;
  };

  // =========================================================

  const handleSearch = (e) => {
    e.preventDefault();
    getList();
  }; 

  const handleChanges = (e) => {
    let searchData = { ...searchStduent };
    let { name, value } = e.target;
    switch (name) {
      case "admissionNo":
        searchData.admissionNo = value;
        break;
      case "studentName":
        searchData.studentName = value;
        break;
      case "class":
        searchData.classes = value;
        break;
      case "concessionId":
        searchData.concessionId = value;
        break;
      case "session":
        searchData.session = value;
        setSelectedSession(value);
        break;
      case "paymentMode":
        searchData.paymentMode = value;
        break;
      case "paymentSchedule":
        searchData.paymentSchedule = value;
        break;
      case "feeStatus":
        searchData.feeStatus = value;
        break;
      case "month":
        searchData.month = value;
        break;
    }
    setSearchStduent(searchData);
  };

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Students Fees </h3>
      </div>
      {isLoader ? <Loader /> : null}
      <div className="row">
        {/* =========================================== */}
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <form className="forms-sample" onSubmit={(e) => handleSearch(e)}>
                <h4 className="card-title">Search Student</h4>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Student Name
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="studentName"
                          onChange={(e) => handleChanges(e)}
                          value={searchStduent.studentName}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Admission #
                      </label>
                      <div className="col-sm-9">
                        <Form.Control
                          type="text"
                          name="admissionNo"
                          onChange={(e) => handleChanges(e)}
                          value={searchStduent.admissionNo}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Class</label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="class"
                          onChange={(e) => handleChanges(e)}
                          value={searchStduent.classes}
                        >
                          <option value="">------------</option>
                          {classList.map((e) => {
                            return (
                              <option key={e._id} value={e._id}>
                                {e.className}{" "}
                                {e.section ? "-" + e.section : e.section}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Concession</label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="concessionId"
                          onChange={(e) =>
                            handleChanges(e)}
                          value={searchStduent.concessionId}
                        >
                          <option value="">------------</option>
                          {
                            clist.map((data) => {
                              if (data.active) {
                                return (
                                  <option key={data._id} value={data._id}>{data.concessionType} - {data.concessionAmount}{data.concessionPercent ? "%" : ""}</option>
                                )
                              }
                            })
                          }
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Session</label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="session"
                          onChange={(e) => handleChanges(e)}
                          value={selectedSession}
                        >
                          {sessionsData.map((resp) => (
                            <option key={resp.value} value={resp.value}>
                              {resp.display}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Payment Mode</label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="paymentMode"
                          onChange={(e) => handleChanges(e)}
                          value={searchStduent.paymentMode}
                        >
                          <option value="">Select method</option>
                          <option value="cash">Cash</option>
                          <option value="cheque">Cheque</option>
                          <option value="card">Card</option>
                          <option value="onlinePayment">Online Payment</option>
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Payment Schedule
                      </label>
                      <div className="col-sm-9">
                        <MultiSelect
                          options={monthsList}
                          value={searchStduent.paymentSchedule}
                          name="paymentSchedule"
                          labelledBy="Select"
                          onChange={(e) => {
                            let paymentScheduleMonths = { ...searchStduent };
                            paymentScheduleMonths.paymentSchedule = e;
                            setSearchStduent(paymentScheduleMonths);
                          }}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">Fee Staus</label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="feeStatus"
                          onChange={(e) => handleChanges(e)}
                          value={searchStduent.feeStatus}
                        >
                          <option value="">Select method</option>
                          <option value="paid">Paid</option>
                          <option value="unpaid">Unpaid</option>
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{ float: "right" }}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* =========================================== */}
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="row mb-2">
              <div className="col-md-6 import">
                <StudentsFeeData />
              </div>
              <div className="col-md-6 addBtn">
                <button
                  onClick={() => {
                    let d = { ...data };
                    d.isDefaulterModalVisible = true;
                    d.selectedstudent = getFeeDefaultersList();
                    setData(d);
                  }}
                  type="button"
                  className="btn btn-outline-primary"
                  style={{ padding: "8px" }}
                >
                  Fees defaulters list
                </button>
              </div>
            </div>
            <Grid columns={fields()} rows={rows} />
          </div>
        </div>
      </div>
      {/* ================================= */}
      <FeeReceiptModal data={data} isLoader={isLoader} handleClose={handleClose}/>
      
      {/* ================================= */}
      {/* ================================= */}
      <PreviousFeeRecord data={data} handleClose2={handleClose2} isLoader={isLoader} rows2={rows2} field={field} />
     
      {/* ================================= */}
      <FeeDefaulterList data={data} handleClose3={handleClose3} isLoader={isLoader} rows3={rows3} feeSchedules={feeSchedules} defaulterField={defaulterField}/>
      {/* ================================= */}
    </div>
  );
};

export default StudentsFees;