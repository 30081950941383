import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import bsCustomFileInput from "bs-custom-file-input";
import { HexColorPicker } from "react-colorful";
import { Link, useHistory, useLocation } from "react-router-dom";
import { SchoolFields, SchoolSettings } from "./schoolFields";
import SchoolApi from "../../../api/schoolApi";
import ClassApi from "../../../api/classApi";
import BaseApi from "../../../api/baseApi";
import { toast } from "react-toastify";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { MultiSelect } from "react-multi-select-component";
import moment from "moment";
import Loader from "../../../components/loader";
import { loadschool } from "../../../redux/reducer/getSchoolReducer";
import { useDispatch, useSelector } from "react-redux";
import CourseStructure from "../../../api/courseStructureApi";

const SchoolAdminSettings = () => {
  let history = useHistory();
  const id = new URLSearchParams(history.location.search).get("id");
  const [dataLoader, setDataLoader] = useState(false);
  const [schoolFormData, setSchoolFormData] = useState(SchoolFields);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [fileProgress, setFileProgress] = useState({});
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [schoolSettings, setSchoolSettings] = useState(SchoolSettings);
  const [disableSettingTab, setDisableSettingTab] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState([]);
  const [classes, setClasses] = useState([]);
  const [classesPerGroup, setClassesPerGroup] = useState([]);
  const [checkForSchoolDataSlot, setCheckForSchoolDataSlot] = useState(true);
  const [selectedClassesForSchoolTime, setSelectedClassesForSchoolTime] =
    useState([]);
  const [selectedClassesForSlotTime, setSelectedClassesForSlotTime] =
    useState([]);
  const [selectedClassesForExamTerms, setSelectedClassesForExamTerms] =
    useState([]);
  const [selectedHolidays, setSelectedHolidays] = useState([]);
  const [selectedSatHolydays, setSelectedSatHolydays] = useState([]);
  const [canParentMessageForComplaint, setCanParentMessageForComplaint] =
    useState(SchoolSettings.canParentMessageForComplaint);
  const [
    canParentMessageForCommunication,
    setCanParentMessageForCommunication,
  ] = useState(SchoolSettings.canParentMessageForCommunication);
  const [canParentMessageForHomework, setCanParentMessageForHomework] =
    useState(SchoolSettings.canParentMessageForHomework);
  const [satHolidaysList, setSatHolidaysList] = useState([]);
  const [monthsList, setMonthsList] = useState([]);
  const [moveDisabled, setMoveDisabled] = useState(false);
  const [countOfSlots, setCountOfSlots] = useState(0);
  const [slots, setSlots] = useState([]);
  const [slot, setSlot] = useState({ from: new Date(), to: new Date() })
  const [break1, setBreak1] = useState({ from: new Date(), to: new Date() })
  const [break2, setBreak2] = useState({ from: new Date(), to: new Date() })
  let [checkForTerm, setCheckForTerm] = useState(false);

  const dispatch = useDispatch();
  const schoolData = useSelector((state) => state && state.schoolReducer.schoolList && state.schoolReducer.schoolList.data);
  useEffect(() => {
    if (localStorage.getItem("schoolId")) {
      getDefaultSchoolSettings(schoolData);
    }
  }, [schoolData]);

  useEffect(() => {
    dispatch(loadschool(localStorage.getItem("schoolId")));
  }, [dispatch]);

  async function getDefaultSchoolSettings(schoolData) {
    setDataLoader(true);
    const baseApi = new BaseApi();
    await baseApi.getDefaultSchoolSettings().then(async (response) => {
      if (response.success && response.data) {
        setSatHolidaysList(response.data.satHolidays);
        getSchool(schoolData, response.data.satHolidays)
      }
    })
    setDataLoader(false);
  }

  async function uploadLogo(value) {
    let schoolApi = new SchoolApi();
    const data = new FormData();
    data.append("file", value[0]);
    await schoolApi
      .uploadFile(data, (upload) => {
        let pg = { ...fileProgress };
        pg["file"] = Math.round((100 * upload.loaded) / upload.total);
        setFileProgress(pg);
      })
      .then((data) => {
        if (data.fileUrl && data.success) {
          let schoolForm = { ...schoolFormData };
          if (data.fileUrl.indexOf("https://edvantumdev.76east.com")) {
            let arr = data.fileUrl.split('/')
            data.fileUrl = "https://edvantumdev.76east.com/" + arr[3] + '/' + arr[4] + '/' + arr[5]
          }
          schoolForm.colorScheme.schoolLogo = data.fileUrl;
          setSchoolFormData(schoolForm);
          toast.success(data.msg);
          delete fileProgress["file"];
        }
      })
      .catch(() => {
        toast.error("Upload failed");
      });
  }

  async function setUpSchool(e) {
    e.preventDefault();
    let schoolApi = new SchoolApi();
    await schoolApi
      .create(schoolFormData)
      .then((data) => {
        if (data.success) {
          toast.success(data.msg.toString());
          let colorChange = {};
          colorChange.backgroundColor =
            schoolFormData.colorScheme.backgroundColor;
          colorChange.fontColor = schoolFormData.colorScheme.fontColor;
          let schoolColorScheme = localStorage.setItem(
            "schoolColorScheme",
            JSON.stringify(colorChange)
          );
          history.push("/settings");
        } else {
          toast.error(data.msg);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  }

  function onChange(e) {
    let schoolForm = { ...schoolFormData };
    let schoolSettingsFrom = { ...schoolSettings };
    let { name, value } = e.target;
    switch (name) {
      case "schoolName":
        schoolForm.schoolName = value;
        let id = new URLSearchParams(history.location.search).get("id");
        if (!id && schoolForm.databaseName.length < 17) {
          schoolForm.databaseName = value.toLowerCase().replace(/[^a-z]/g, "");
        }
        break;
      case "state":
        schoolForm.address.state = value;
        break;
      case "city":
        schoolForm.address.city = value;
        break;
      case "country":
        schoolForm.address.country = value;
        break;
      case "pinCode":
        schoolForm.address.pinCode = value;
        break;
      case "address":
        schoolForm.address.address = value;
        break;
      case "longitude":
        schoolForm.address.longitude = value;
        break;
      case "latitude":
        schoolForm.address.latitude = value;
        break;
      case "allowedRadius":
        schoolForm.address.allowedRadius = value;
        break;
      //color Scheme & logo
      case "backgroundColor":
        schoolForm.colorScheme.backgroundColor = value;
        break;
      case "fontColor":
        schoolForm.colorScheme.fontColor = value;
        break;
      // admin fields
      case "adminFirstName":
        schoolForm.adminInfo.firstName = value;
        break;
      case "adminLastName":
        schoolForm.adminInfo.lastName = value;
        break;
      case "adminEmail":
        schoolForm.adminInfo.email = value;
        break;
      case "adminMobile":
        schoolForm.adminInfo.mobile = value;
        break;
      case "adminPassword":
        schoolForm.adminInfo.password = value;
        break;
      case "startsFromMonth":
        // school Settings
        schoolSettingsFrom.session.startsFromMonth = value;
        break;
      case "endsOnMonth":
        schoolSettingsFrom.session.endsOnMonth = value;
        break;
      case "casual":
        schoolSettingsFrom.leaves.casual = value;
        break;
      case "sick":
        schoolSettingsFrom.leaves.sick = value;
        break;
      case "short":
        schoolSettingsFrom.leaves.short = value;
        break;
      case "enteryTime":
        schoolSettingsFrom.schoolTime.enteryTime = value;
        break;
      case "halfTime":
        schoolSettingsFrom.schoolTime.halfTime = value;
        break;
      case "shortLeaveTime":
        schoolSettingsFrom.schoolTime.shortLeaveTime = value;
        // slots
        break;
      case "groupName":
        schoolSettingsFrom.slotsTime.groupName = value;
        break;
      case "startTime":
        schoolSettingsFrom.slotsTime.startTime = value;
        break;
      case "endTime":
        schoolSettingsFrom.slotsTime.endTime = value;
        break;
      case "break1":
        schoolSettingsFrom.slotsTime.break1 = value;
        break;
      case "break2":
        schoolSettingsFrom.slotsTime.break2 = value;
        break;
      case "latePaymentFixed":
        schoolSettingsFrom.feeSchedules.lastPaymentFeeType = value;
      case "latePaymentPerDay":
        schoolSettingsFrom.feeSchedules.lastPaymentFeeType = value;
      case "lateFeeAmount":
        schoolSettingsFrom.feeSchedules.lateFeeAmount = value;
    }
    setSchoolFormData(schoolForm);
  }

  function reorderMonthsListBySessionStartDate(monthsList, sessionDat) {
    if (monthsList && monthsList.length && sessionDat.endsOnMonth && sessionDat.startsFromMonth) {
      const data = [];
      if (sessionDat.startsFromMonth > 1) {
        for (let i = sessionDat.startsFromMonth - 1; i < 12; i++) {
          data.push(monthsList[i]);
        }
        for (let i = 0; i < sessionDat.endsOnMonth; i++) {
          data.push(monthsList[i]);
        }
      } else {

      }
    }
  }

  const reorderMonthBySessionStart = async () => {
    const baseApi = new BaseApi();
    await baseApi.reorderMonthBySessionStart().then(response => {
      if (response.success) {
        setMonthsList(response.data);
      } else {
        setMonthsList([]);
      }
    })
  }

  async function getSchool(schoolData, satHolidays) {
    if (schoolData) {
      schoolData = JSON.parse(JSON.stringify(schoolData))
      let classOprions = [];
      let classApi = new ClassApi();
      await classApi.getClass().then(async (data) => {
        data &&
          data.data.map((item) => {
            let className = item.className;
            let section = item.section ? item.section : "";
            let clsSect = className;
            if (section) {
              clsSect = className + "-" + section;
            }
            classOprions.push({ label: clsSect, value: item._id });
          });
        setClasses(classOprions);
        let arr = [];
        arr.push(classOprions)
        setClassesPerGroup(arr)
      });
      reorderMonthBySessionStart();
      setSchoolFormData(schoolData);
      setCanParentMessageForHomework(schoolData.canParentMessageForHomework);
      setCanParentMessageForCommunication(schoolData.canParentMessageForCommunication);
      setCanParentMessageForComplaint(schoolData.canParentMessageForComplaint);
      let satholi = schoolData.schoolTime && schoolData.schoolTime.forEach((item, index) => {
        let returnObj = [];
        let result = item.satHolidays.filter((o1) =>
          satHolidays.some((o2) =>
            parseInt(o1) === parseInt(o2.value)
              ? returnObj.push(o2)
              : null
          )
        );
        item["satHolidays"] = returnObj;
        let returnClassesObj = [];
        classOprions.filter((ob1) =>
          item.classIds.some((ob2) =>
            ob1.value.toString() === ob2.toString()
              ? returnClassesObj.push(ob1)
              : null
          )
        );
        item["classIds"] = returnClassesObj;
      });
      let sltTime = [];
      schoolData && schoolData.slotsTime &&
        schoolData.slotsTime.forEach((item, index) => {
          let returnClassesObj = [];
          classOprions.filter((ob1) =>
            item.classIds.some((ob2) =>
              ob1.value.toString() === ob2.toString()
                ? returnClassesObj.push(ob1)
                : null
            )
          );
          item["classIds"] = returnClassesObj;
        });
      if (schoolData.schoolTime == undefined) {
        schoolData = Object.assign({}, schoolData);
        schoolData["schoolTime"] = [
          {
            classIds: [],
            enteryTime: "",
            halfTime: "",
            shortLeaveTime: "",
            satHolidays: [],
          }
        ];
      }
      if (schoolData.slotsTime == "" || schoolData.slotsTime == undefined) {
        schoolData["slotsTime"] = [
          {
            classIds: [],
            startTime: "",
            groupName: "",
            endTime: "",
            break1: { from: "", to: "" },
            break2: { from: "", to: "" },
            slots: [{ from: "", to: "" }],
          }
        ];
      }
      if (schoolData.termsExams == undefined) {
        schoolData["termsExams"] = [
          {
            classIds: [],
            term: 1,
          }
        ];
      }

      if (schoolData.teacherTime == "" || schoolData.teacherTime == undefined) {
        schoolData["teacherTime"] = {
          summerTime: {
            fromDate: new Date('2023-04-01T02:30:00.000Z'), toDate: new Date('2023-09-30T02:30:00.000Z'), schoolTime: new Date('2023-09-30T02:30:00.000Z'), onTime: new Date('2023-09-30T02:10:00.000Z'),
            shortLeave: { from: new Date('2023-09-30T02:10:00.000Z'), to: new Date('2023-09-30T03:10:00.000Z') },
            halfDay: { from: new Date('2023-09-30T03:10:00.000Z'), to: new Date('2023-09-30T05:30:00.000Z') },
            absent: new Date('2023-09-30T05:30:00.000Z')
          },
          winterTime: {
            fromDate: new Date('2023-10-01T02:30:00.000Z'), toDate: new Date('2024-03-31T02:30:00.000Z'), schoolTime: new Date('2023-09-30T02:40:00.000Z'), onTime: new Date('2023-09-30T02:20:00.000Z'),
            shortLeave: { from: new Date('2023-09-30T02:10:00.000Z'), to: new Date('2023-09-30T03:30:00.000Z') },
            halfDay: { from: new Date('2023-09-30T03:20:00.000Z'), to: new Date('2023-09-30T05:40:00.000Z') },
            absent: new Date('2023-09-30T05:40:00.000Z')
          }
        }

      }
      let returnTermsObj = [];
      schoolData.termsExams &&
        schoolData.termsExams.forEach((term, i) => {
          let returnTermsObj = [];
          classOprions.filter((obj1) =>
            term && term.classIds.some((obj2) => {
              return obj2 != null && obj1.value.toString() === obj2.toString()
                ? returnTermsObj.push(obj1)
                : null
            }
            )
          );
          term["classIds"] = returnTermsObj;
        });

      setSchoolSettings(schoolData);
      setIsPageLoading(false);
    }
  }

  useEffect(() => {
    let schoolData = { ...schoolSettings }
    if (classes.length > 0 && schoolData.slotsTime && schoolData.slotsTime[0] && schoolData.slotsTime[0].groupName != "" && checkForSchoolDataSlot) {
      let tempClassesPerGroup = classesPerGroup
      for (let j = 0; ((j < schoolData.slotsTime.length)); j++) {
        let totalArr = []
        for (let i = 0; ((i < schoolData.slotsTime.length)); i++) {
          if (i != j) {
            let singleArray = classes.filter(val => {
              return schoolData.slotsTime[i].classIds.some((ele) => {
                return ele.value == val.value;
              });
            })

            totalArr = Array.from([...totalArr, ...singleArray]
              .reduce((m, o) => m.set(o.value, o), new Map)
              .values()
            );
          }
        }
        let netArray = classes.filter(val => {
          return !totalArr.some((ele) => {
            return ele.value == val.value;
          });
        })
        let newNetArray = netArray.sort((a, b) => {
          return classes.findIndex(p => p.value === a.value) - classes.findIndex(p => p.value === b.value);
        });
        if (tempClassesPerGroup[j]) {
          tempClassesPerGroup[j] = newNetArray
        } else {
          tempClassesPerGroup.push(newNetArray)
        }
        setClassesPerGroup(tempClassesPerGroup)
        setCheckForSchoolDataSlot(false)
      }
    }
  }, [classes, schoolSettings])

  async function saveSettings(e) {
    e.preventDefault();
    if (!localStorage.getItem("schoolId")) {
      toast.success("Some thing went wrog in last saved school");
      return;
    }
    let schoolSettingsForm = { ...schoolSettings };

    if (schoolSettingsForm.slotsTime.length == 1 && schoolSettingsForm.slotsTime[0].classIds.length == 0 && schoolSettingsForm.slotsTime[0].groupName == "" && schoolSettingsForm.slotsTime[0].slots.length == 1 && schoolSettingsForm.slotsTime[0].slots[0].from == "" && schoolSettingsForm.slotsTime[0].slots[0].to == "") {
    } else {
      for (let i = 0; i < schoolSettingsForm.slotsTime.length; i++) {
        let str = ""
        if (i == 0) {
          str = "Ist Group"
        } else if (i == 1) {
          str = "IInd Group"
        } else if (i == 2) {
          str = "IIIrd Group"
        } else {
          str = (i + 1) + " th Group"
        }
        if (schoolSettingsForm.slotsTime[i].classIds.length == 0) {
          alert("Please Select Classes in " + str + " In Time Slots/ Period")
          return;
        }
        if (schoolSettingsForm.slotsTime[i].groupName == "") {
          alert("Please Enter Group Name in " + str + " In Time Slots/ Period")
          return;
        }
        for (let j = 0; j < schoolSettingsForm.slotsTime[i].slots.length; j++) {
          let str1 = ""
          if (j == 0) {
            str1 = "Ist slot"
          } else if (j == 1) {
            str1 = "IInd slot"
          } else if (j == 2) {
            str1 = "IIIrd slot"
          } else {
            str1 = (i + 1) + " th slot"
          }
          if (schoolSettingsForm.slotsTime[i].slots[j].from == '') {
            alert("Please Enter From value in " + str1 + " in " + str + " In Time Slots/ Period")
            return;
          }
          if (schoolSettingsForm.slotsTime[i].slots[j].to == '') {
            alert("Please Enter To value in " + str1 + " in " + str + " In Time Slots/ Period")
            return;
          }
        }
      }
    }

    let thirdArray = schoolSettingsForm.termsExams.filter((elem, ind) => {
      return schoolSettingsForm.termsExams.some((ele, index) => {
        return ind != index && parseInt(ele.term) == parseInt(elem.term);
      });
    });
    if (thirdArray.length > 0) {
      alert("Please Select Different Terms for Exams")
      return;
    }
    schoolSettingsForm._id = localStorage.getItem("schoolId");
    schoolSettingsForm.canParentMessageForHomework = canParentMessageForHomework;
    schoolSettingsForm.canParentMessageForComplaint = canParentMessageForComplaint;
    schoolSettingsForm.canParentMessageForCommunication = canParentMessageForCommunication;
    setSchoolFormData(schoolSettingsForm);
    // return;
    let schoolApi = new SchoolApi();
    await schoolApi
      .saveSettings(schoolSettingsForm)
      .then((data) => {
        if (data.success) {
          toast.success(data.msg.toString());
        } else {
          toast.error(data.msg);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  }
  function renderSchoolTimings() {
    return (
      schoolSettings.schoolTime &&
      schoolSettings.schoolTime.map((item, index) => {
        return (
          <div className="border pt-4 pb-2 pl-4 pr-4 mb-4" key={index}>
            <Form.Group className="row">
              <label className="col-sm-3 col-form-label">Select Classes </label>
              <div className="col-sm-9">
                <MultiSelect
                  options={classes}
                  labelledBy="Select"
                  value={schoolSettings.schoolTime[index].classIds}
                  onChange={(e) => {
                    setSelectedClassesForSchoolTime(e);
                    let schoolSettingsFrom = { ...schoolSettings };
                    schoolSettingsFrom.schoolTime[index].classIds = e;
                    setSchoolSettings(schoolSettingsFrom);
                  }}
                />
              </div>
            </Form.Group>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="row">
                  <label className="col-sm-3 col-form-label">
                    Entry Time{" "}
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </label>
                  <div className="col-sm-9">
                    {!isPageLoading ? (
                      <Datetime
                        dateFormat={false}
                        initialValue={
                          schoolSettings.schoolTime &&
                          schoolSettings.schoolTime[index].enteryTime
                        }
                        onChange={(e) => {
                          let schoolSettingsFrom = { ...schoolSettings };
                          schoolSettingsFrom.schoolTime[index].enteryTime = moment(e);
                          setSchoolSettings(schoolSettingsFrom);
                        }}
                        value={moment(schoolSettings.schoolTime && schoolSettings.schoolTime[index].enteryTime)}
                      />
                    ) : null}
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="row">
                  <label className="col-sm-3 col-form-label">Half Time </label>
                  <div className="col-sm-9">
                    {!isPageLoading ? (
                      <Datetime
                        dateFormat={false}
                        initialValue={
                          schoolSettings.schoolTime &&
                          schoolSettings.schoolTime[index].halfTime
                        }
                        onChange={(e) => {
                          let schoolSettingsFrom = { ...schoolSettings };
                          schoolSettingsFrom.schoolTime[index].halfTime = moment(e);
                          setSchoolSettings(schoolSettingsFrom);
                        }}
                        value={moment(schoolSettings.schoolTime && schoolSettings.schoolTime[index].halfTime)}
                      />
                    ) : null}
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="row">
                  <label className="col-sm-3 col-form-label">
                    Saturday holidays{" "}
                  </label>
                  <div className="col-sm-9">
                    <MultiSelect
                      options={satHolidaysList}
                      labelledBy="Select"
                      value={schoolSettings.schoolTime[index].satHolidays}
                      onChange={(e) => {
                        setSelectedHolidays(e);
                        let schoolSettingsFrom = { ...schoolSettings };
                        schoolSettingsFrom.schoolTime[index].satHolidays = e;
                        setSchoolSettings(schoolSettingsFrom);
                      }}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-2">
                {index ? (
                  <button
                    type="button"
                    onClick={() => {
                      let scSt = { ...schoolSettings };
                      scSt.schoolTime.splice(index, 1);
                      setSchoolSettings(scSt);
                    }}
                    className="btn btn-danger"
                  >
                    <span className="mdi mdi-minus"></span>
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        );
      })
    );
  }

  function renderTeacherTimings() {
    return (
      <div className="border pt-4 pb-2 pl-4 pr-4 mb-4">
        <div className="row">
          {/* Summer time */}
          <div className="col-md-6">
            <Form.Group className="row">
              <label className="col-sm-4 col-form-label">Summer Time</label>
              <div className="col-sm-4">
                {!isPageLoading ? (
                  <Datetime
                    dateFormat={true}
                    timeFormat={false}
                    onChange={(e) => {
                      let schoolSettingsFrom = { ...schoolSettings };
                      schoolSettingsFrom.teacherTime.summerTime.fromDate = moment(e);
                      setSchoolSettings(schoolSettingsFrom);
                    }}
                    value={moment(schoolSettings?.teacherTime?.summerTime.fromDate)}
                  />
                ) : null}
              </div>
              <div className="col-sm-4">
                {!isPageLoading ? (
                  <Datetime
                    dateFormat={true}
                    timeFormat={false}
                    onChange={(e) => {
                      let schoolSettingsFrom = { ...schoolSettings };
                      schoolSettingsFrom.teacherTime.summerTime.toDate =
                        moment(e);
                      setSchoolSettings(schoolSettingsFrom);
                    }}
                    value={moment(schoolSettings?.teacherTime?.summerTime.toDate)}
                  />
                ) : null}
              </div>
            </Form.Group>
            <Form.Group className="row">
              <label className="col-sm-4 col-form-label">School Time</label>
              <div className="col-sm-8">
                {!isPageLoading ? (
                  <Datetime
                    value={moment(schoolSettings.teacherTime && schoolSettings.teacherTime.summerTime.schoolTime)}
                    dateFormat={false}
                    initialValue={
                      schoolSettings.teacherTime &&
                      schoolSettings.teacherTime.summerTime.schoolTime
                    }
                    onChange={(e) => {
                      let schoolSettingsFrom = { ...schoolSettings };
                      schoolSettingsFrom.teacherTime.summerTime.schoolTime = moment(e);
                      setSchoolSettings(schoolSettingsFrom);
                    }}
                  />
                ) : null}
              </div>
            </Form.Group>
            <Form.Group className="row">
              <label className="col-sm-4 col-form-label">On Time</label>
              <div className="col-sm-8">
                {!isPageLoading ? (
                  <Datetime
                    dateFormat={false}
                    value={moment(schoolSettings.teacherTime && schoolSettings.teacherTime.summerTime.onTime)}
                    initialValue={
                      schoolSettings.teacherTime &&
                      schoolSettings.teacherTime.summerTime.onTime
                    }
                    onChange={(e) => {
                      let schoolSettingsFrom = { ...schoolSettings };
                      schoolSettingsFrom.teacherTime.summerTime.onTime = moment(e);
                      setSchoolSettings(schoolSettingsFrom);
                    }}
                  />
                ) : null}
              </div>
            </Form.Group>
            <Form.Group className="row">
              <label className="col-sm-4 col-form-label">Short Leave</label>
              <div className="col-sm-4">
                {!isPageLoading ? (
                  <Datetime
                    value={moment(schoolSettings.teacherTime && schoolSettings.teacherTime.summerTime.shortLeave.from)}
                    dateFormat={false}
                    initialValue={
                      schoolSettings.teacherTime &&
                      schoolSettings.teacherTime.summerTime.shortLeave.from
                    }
                    onChange={(e) => {
                      let schoolSettingsFrom = { ...schoolSettings };
                      schoolSettingsFrom.teacherTime.summerTime.shortLeave.from = moment(e);
                      setSchoolSettings(schoolSettingsFrom);
                    }}
                  />
                ) : null}
              </div>
              <div className="col-sm-4">
                {!isPageLoading ? (
                  <Datetime
                    dateFormat={false}
                    initialValue={
                      schoolSettings.teacherTime &&
                      schoolSettings.teacherTime.summerTime.shortLeave.to
                    }
                    onChange={(e) => {
                      let schoolSettingsFrom = { ...schoolSettings };
                      schoolSettingsFrom.teacherTime.summerTime.shortLeave.to = moment(e);
                      setSchoolSettings(schoolSettingsFrom);
                    }}
                    value={moment(schoolSettings.teacherTime && schoolSettings.teacherTime.summerTime.shortLeave.to)}
                  />
                ) : null}
              </div>
            </Form.Group>
            <Form.Group className="row">
              <label className="col-sm-4 col-form-label">Half Day</label>
              <div className="col-sm-4">
                {!isPageLoading ? (
                  <Datetime
                    dateFormat={false}
                    initialValue={
                      schoolSettings.teacherTime &&
                      schoolSettings.teacherTime.summerTime.halfDay.from
                    }
                    onChange={(e) => {
                      let schoolSettingsFrom = { ...schoolSettings };
                      schoolSettingsFrom.teacherTime.summerTime.halfDay.from = moment(e);
                      setSchoolSettings(schoolSettingsFrom);
                    }}
                    value={moment(schoolSettings.teacherTime && schoolSettings.teacherTime.summerTime.halfDay.from)}
                  />
                ) : null}
              </div>
              <div className="col-sm-4">
                {!isPageLoading ? (
                  <Datetime
                    dateFormat={false}
                    initialValue={
                      schoolSettings.teacherTime &&
                      schoolSettings.teacherTime.summerTime.halfDay.to
                    }
                    onChange={(e) => {
                      let schoolSettingsFrom = { ...schoolSettings };
                      schoolSettingsFrom.teacherTime.summerTime.halfDay.to = moment(e);
                      setSchoolSettings(schoolSettingsFrom);
                    }}
                    value={moment(schoolSettings.teacherTime && schoolSettings.teacherTime.summerTime.halfDay.to)}
                  />
                ) : null}
              </div>
            </Form.Group>
            <Form.Group className="row">
              <label className="col-sm-4 col-form-label">Absent</label>
              <div className="col-sm-8">
                {!isPageLoading ? (
                  <Datetime
                    dateFormat={false}
                    initialValue={
                      schoolSettings.teacherTime &&
                      schoolSettings.teacherTime.summerTime.absent
                    }
                    onChange={(e) => {
                      let schoolSettingsFrom = { ...schoolSettings };
                      schoolSettingsFrom.teacherTime.summerTime.absent = moment(e);
                      setSchoolSettings(schoolSettingsFrom);
                    }}
                    value={moment(schoolSettings.teacherTime && schoolSettings.teacherTime.summerTime.absent)}
                  />
                ) : null}
              </div>
            </Form.Group>
          </div>

          {/* winter time */}
          <div className="col-md-6">
            <Form.Group className="row">
              <label className="col-sm-4 col-form-label">Winter Time</label>
              <div className="col-sm-4">
                {!isPageLoading ? (
                  <Datetime
                    dateFormat={true}
                    timeFormat={false}
                    onChange={(e) => {
                      let schoolSettingsFrom = { ...schoolSettings };
                      schoolSettingsFrom.teacherTime.winterTime.fromDate = moment(e);
                      setSchoolSettings(schoolSettingsFrom);
                    }}
                    value={moment(schoolSettings?.teacherTime?.winterTime.fromDate)}
                  />
                ) : null}
              </div>
              <div className="col-sm-4">
                {!isPageLoading ? (
                  <Datetime
                    dateFormat={true}
                    timeFormat={false}
                    onChange={(e) => {
                      let schoolSettingsFrom = { ...schoolSettings };
                      schoolSettingsFrom.teacherTime.winterTime.toDate = moment(e);
                      setSchoolSettings(schoolSettingsFrom);
                    }}
                    value={moment(schoolSettings?.teacherTime?.winterTime.toDate)}
                  />
                ) : null}
              </div>
            </Form.Group>
            <Form.Group className="row">
              <label className="col-sm-4 col-form-label">School Time</label>
              <div className="col-sm-8">
                {!isPageLoading ? (
                  <Datetime
                    dateFormat={false}
                    initialValue={
                      schoolSettings.teacherTime &&
                      schoolSettings.teacherTime.winterTime.schoolTime
                    }
                    onChange={(e) => {
                      let schoolSettingsFrom = { ...schoolSettings };
                      schoolSettingsFrom.teacherTime.winterTime.schoolTime = moment(e);
                      setSchoolSettings(schoolSettingsFrom);
                    }}
                    value={moment(schoolSettings.teacherTime && schoolSettings.teacherTime.winterTime.schoolTime)}
                  />
                ) : null}
              </div>
            </Form.Group>
            <Form.Group className="row">
              <label className="col-sm-4 col-form-label">On Time</label>
              <div className="col-sm-8">
                {!isPageLoading ? (
                  <Datetime
                    dateFormat={false}
                    initialValue={
                      schoolSettings.teacherTime &&
                      schoolSettings.teacherTime.winterTime.onTime
                    }
                    onChange={(e) => {
                      let schoolSettingsFrom = { ...schoolSettings };
                      schoolSettingsFrom.teacherTime.winterTime.onTime = moment(e);
                      setSchoolSettings(schoolSettingsFrom);
                    }}
                    value={moment(schoolSettings.teacherTime && schoolSettings.teacherTime.winterTime.onTime)}
                  />
                ) : null}
              </div>
            </Form.Group>
            <Form.Group className="row">
              <label className="col-sm-4 col-form-label">Short Leave</label>
              <div className="col-sm-4">
                {!isPageLoading ? (
                  <Datetime
                    dateFormat={false}
                    initialValue={
                      schoolSettings.teacherTime &&
                      schoolSettings.teacherTime.winterTime.shortLeave.from
                    }
                    onChange={(e) => {
                      let schoolSettingsFrom = { ...schoolSettings };
                      schoolSettingsFrom.teacherTime.winterTime.shortLeave.from = moment(e);
                      setSchoolSettings(schoolSettingsFrom);
                    }}
                    value={moment(schoolSettings.teacherTime && schoolSettings.teacherTime.winterTime.shortLeave.from)}
                  />
                ) : null}
              </div>
              <div className="col-sm-4">
                {!isPageLoading ? (
                  <Datetime
                    dateFormat={false}
                    initialValue={
                      schoolSettings.teacherTime &&
                      schoolSettings.teacherTime.winterTime.shortLeave.to
                    }
                    onChange={(e) => {
                      let schoolSettingsFrom = { ...schoolSettings };
                      schoolSettingsFrom.teacherTime.winterTime.shortLeave.to = moment(e);
                      setSchoolSettings(schoolSettingsFrom);
                    }}
                    value={moment(schoolSettings.teacherTime && schoolSettings.teacherTime.winterTime.shortLeave.to)}
                  />
                ) : null}
              </div>
            </Form.Group>
            <Form.Group className="row">
              <label className="col-sm-4 col-form-label">Half Day</label>
              <div className="col-sm-4">
                {!isPageLoading ? (
                  <Datetime
                    dateFormat={false}
                    initialValue={
                      schoolSettings.teacherTime &&
                      schoolSettings.teacherTime.winterTime.halfDay.from
                    }
                    onChange={(e) => {
                      let schoolSettingsFrom = { ...schoolSettings };
                      schoolSettingsFrom.teacherTime.winterTime.halfDay.from = moment(e);
                      setSchoolSettings(schoolSettingsFrom);
                    }}
                    value={moment(schoolSettings.teacherTime && schoolSettings.teacherTime.winterTime.halfDay.from)}
                  />
                ) : null}
              </div>
              <div className="col-sm-4">
                {!isPageLoading ? (
                  <Datetime
                    dateFormat={false}
                    initialValue={
                      schoolSettings.teacherTime &&
                      schoolSettings.teacherTime.winterTime.halfDay.to
                    }
                    onChange={(e) => {
                      let schoolSettingsFrom = { ...schoolSettings };
                      schoolSettingsFrom.teacherTime.winterTime.halfDay.to = moment(e);
                      setSchoolSettings(schoolSettingsFrom);
                    }}
                    value={moment(schoolSettings.teacherTime && schoolSettings.teacherTime.winterTime.halfDay.to)}
                  />
                ) : null}
              </div>
            </Form.Group>
            <Form.Group className="row">
              <label className="col-sm-4 col-form-label">Absent</label>
              <div className="col-sm-8">
                {!isPageLoading ? (
                  <Datetime
                    dateFormat={false}
                    initialValue={
                      schoolSettings.teacherTime &&
                      schoolSettings.teacherTime.winterTime.absent
                    }
                    onChange={(e) => {
                      let schoolSettingsFrom = { ...schoolSettings };
                      schoolSettingsFrom.teacherTime.winterTime.absent = moment(e);
                      setSchoolSettings(schoolSettingsFrom);
                    }}
                    value={moment(schoolSettings.teacherTime && schoolSettings.teacherTime.winterTime.absent)}
                  />
                ) : null}
              </div>
            </Form.Group>
          </div>
        </div>
      </div>
    )
  }

  function addSlot(e) {
    let { index } = e
    let schoolSettingsTo = { ...schoolSettings };
    let slotLength = schoolSettingsTo.slotsTime[index].slots.length;
    if (slotLength > 0) {
      let slotData = schoolSettingsTo.slotsTime[index].slots[slotLength - 1];
      schoolSettingsTo.slotsTime[index].slots.push({ from: moment(slotData.to).add(1, "m"), to: "" })
    } else {
      schoolSettingsTo.slotsTime[index].slots.push({ from: "", to: "" })
    }
    setSchoolSettings(schoolSettingsTo);
  }

  function removeSlot(e) {
    let { index, slotIndex } = e
    let schoolSettingsTo = { ...schoolSettings };
    let slotsArray = schoolSettingsTo.slotsTime[index].slots;
    slotsArray.splice(slotIndex, 1);
    schoolSettingsTo.slotsTime[index].slots = slotsArray;
    setSchoolSettings(schoolSettingsTo);
  }

  function renderSlotTimings() {
    return (schoolSettings &&
      schoolSettings.slotsTime &&
      classesPerGroup.length > 0 &&
      schoolSettings.slotsTime.map((item, index) => {
        return (
          <div className="border pt-4 pb-2 pl-4 pr-4 mb-4" key={index}>
            <Form.Group className="row">
              <label className="col-sm-2 col-form-label">Select Classes</label>
              <div className="col-sm-9">
                {
                  classesPerGroup[index] ?
                    <MultiSelect
                      options={classesPerGroup[index]}
                      labelledBy="Select"
                      value={schoolSettings.slotsTime[index].classIds}
                      onChange={(e) => {
                        let schoolSettingsFrom = { ...schoolSettings };
                        let tempClassesPerGroup = classesPerGroup
                        if (e.length == tempClassesPerGroup[index].length && index == 0 && schoolSettingsFrom.slotsTime.length > 1) {
                          alert("Some Classes are Selected in Another Groups. Please delete other Group")
                          return;
                        }
                        for (let i = 0; ((i < schoolSettingsFrom.slotsTime.length)); i++) {
                          if (i != index) {
                            let singleArray = e.filter(val => {
                              return schoolSettingsFrom.slotsTime[i].classIds.some((ele) => {
                                return ele.value == val.value;
                              });
                            })
                            if (singleArray.length > 0) {
                              alert('This class is already selected in ' + (i + 1) + ' group')
                              return;
                            }
                          }
                        }
                        let totalArr = []
                        let diffArray = []
                        if (schoolSettingsFrom.slotsTime[index].classIds.length > e.length) {
                          diffArray = schoolSettingsFrom.slotsTime[index].classIds.filter(val => {
                            return !e.some((ele) => {
                              return ele.value == val.value;
                            });
                          })
                        }
                        schoolSettingsFrom.slotsTime[index].classIds = e;
                        setSchoolSettings(schoolSettingsFrom);
                        let netArray = []
                        for (let i = 0; ((i < schoolSettingsFrom.slotsTime.length)); i++) {
                          if (i != index) {
                            if (diffArray.length > 0) {
                              let newArray = Array.from([...tempClassesPerGroup[i], ...diffArray]
                                .reduce((m, o) => m.set(o.value, o), new Map)
                                .values()
                              );
                              netArray = newArray.sort((a, b) => {
                                return classes.findIndex(p => p.value === a.value) - classes.findIndex(p => p.value === b.value);
                              });
                            } else {
                              netArray = tempClassesPerGroup[i].filter(val => {
                                return !schoolSettingsFrom.slotsTime[index].classIds.some((ele) => {
                                  return ele.value == val.value;
                                });
                              })
                            }
                            tempClassesPerGroup[i] = netArray
                            setClassesPerGroup(tempClassesPerGroup)
                          }
                        }
                      }}
                      hasSelectAll={index == 0 ? true : false}
                    /> : null
                }
              </div>
            </Form.Group>

            {/* Group name input  */}

            <Form.Group className="row">
              <label className="col-sm-2 col-form-label">Group Name</label>
              <div className="col-sm-8">
                <Form.Control
                  type="text"
                  value={schoolSettings && schoolSettings.slotsTime && schoolSettings.slotsTime[index].groupName}
                  initialvalue={
                    schoolSettings.slotsTime &&
                    schoolSettings.slotsTime[index].groupName
                  }
                  onChange={(e) => {
                    let schoolSettingsFrom = { ...schoolSettings };
                    let exitGroup = schoolSettingsFrom.slotsTime.length && schoolSettingsFrom.slotsTime.filter((slot) =>
                      slot.groupName.toLowerCase() == e.target.value.toLowerCase())
                    if (exitGroup.length) {
                      toast.error(`${e.target.value} is already exits`);
                    } else {
                      schoolSettingsFrom.slotsTime[index].groupName = e.target.value;
                      setSchoolSettings(schoolSettingsFrom);
                    }

                  }}
                />
              </div>
            </Form.Group>

            {/* start time & end time  */}

            <Form.Group className="row">
              <label className="col-sm-2 col-form-label">Start Time</label>
              <div className="col-sm-3">
                {!isPageLoading ? (
                  <Datetime
                    dateFormat={false}
                    value={moment(schoolSettings && schoolSettings.slotsTime && schoolSettings.slotsTime[index].startTime)}
                    initialvalue={
                      schoolSettings.slotsTime &&
                      schoolSettings.slotsTime[index].startTime
                    }
                    onChange={(e) => {
                      let schoolSettingsFrom = { ...schoolSettings };
                      schoolSettingsFrom.slotsTime[index].startTime = moment(e);
                      setSchoolSettings(schoolSettingsFrom);
                    }}
                  />
                ) : null}
              </div>
              <label className="col-sm-2 col-form-label text-center">End Time</label>
              <div className="col-sm-3">
                {!isPageLoading ? (
                  <Datetime
                    dateFormat={false}
                    value={moment(schoolSettings && schoolSettings.slotsTime && schoolSettings.slotsTime[index].endTime)}
                    initialvalue={
                      schoolSettings.slotsTime &&
                      schoolSettings.slotsTime[index].endTime
                    }
                    onChange={(e) => {
                      let schoolSettingsTo = { ...schoolSettings };
                      if (moment(schoolSettings.slotsTime[index].startTime).format("HH:mm:ss") > moment(e).format("HH:mm:ss")
                        || (moment(schoolSettings.slotsTime[index].startTime).format("HH:mm:ss") == moment(e).format("HH:mm:ss"))) {
                        toast.error(`Please select Greater than start time`);
                      } else {
                        schoolSettingsTo.slotsTime[index].endTime = moment(e);
                        setSchoolSettings(schoolSettingsTo);
                      }
                    }}
                  />
                ) : null}
              </div>
            </Form.Group>

            {/* break inputs  */}

            <Form.Group className="row">
              <label className="col-sm-2 col-form-label">Break 1</label>
              <div className="col-sm-4">
                {!isPageLoading ? (
                  <Datetime
                    dateFormat={false}
                    value={moment(schoolSettings && schoolSettings.slotsTime && schoolSettings.slotsTime[index].break1.from)}
                    initialvalue={
                      schoolSettings.slotsTime &&
                      schoolSettings.slotsTime[index].break1.from
                    }
                    onChange={(e) => {
                      let schoolSettingsTo = { ...schoolSettings };
                      schoolSettingsTo.slotsTime[index].break1.from = moment(e);
                      setSchoolSettings(schoolSettingsTo);
                    }}
                  />
                ) : null}
              </div>

              <div className="col-sm-4">
                {!isPageLoading ? (
                  <Datetime
                    dateFormat={false}
                    value={moment(schoolSettings && schoolSettings.slotsTime && schoolSettings.slotsTime[index].break1.to)}
                    initialvalue={
                      schoolSettings.slotsTime &&
                      schoolSettings.slotsTime[index].break1.to
                    }
                    onChange={(e) => {
                      let schoolSettingsTo = { ...schoolSettings };
                      if (moment(schoolSettings.slotsTime[index].break1.from).format("HH:mm:ss") > moment(e).format("HH:mm:ss")
                        || (moment(schoolSettings.slotsTime[index].break1.from).format("HH:mm:ss") == moment(e).format("HH:mm:ss"))) {
                        toast.error(`Please select Greater than start time`);
                      } else {
                        schoolSettingsTo.slotsTime[index].break1.to = moment(e);
                        setSchoolSettings(schoolSettingsTo);
                      }
                    }}
                  />
                ) : null}
              </div>
            </Form.Group>

            <Form.Group className="row">
              <label className="col-sm-2 col-form-label">
                Break 2
                <span style={{ color: "#b8c2c7", fontSize: 14 }}> (Optional) </span>
              </label>
              <div className="col-sm-4">
                {!isPageLoading ? (
                  <Datetime
                    dateFormat={false}
                    value={moment(schoolSettings && schoolSettings.slotsTime && schoolSettings.slotsTime[index].break2.from)}
                    initialvalue={
                      schoolSettings.slotsTime &&
                      schoolSettings.slotsTime[index].break2.from
                    }
                    onChange={(e) => {
                      let schoolSettingsTo = { ...schoolSettings };
                      schoolSettingsTo.slotsTime[index].break2.from = moment(e);
                      setSchoolSettings(schoolSettingsTo);
                    }}
                  />
                ) : null}
              </div>

              <div className="col-sm-4">
                {!isPageLoading ? (
                  <Datetime
                    dateFormat={false}
                    value={moment(schoolSettings && schoolSettings.slotsTime && schoolSettings.slotsTime[index].break2.to)}
                    initialvalue={
                      schoolSettings.slotsTime &&
                      schoolSettings.slotsTime[index].break2.to
                    }
                    onChange={(e) => {
                      let schoolSettingsTo = { ...schoolSettings };
                      if (moment(schoolSettings.slotsTime[index].break2.from).format("HH:mm:ss") > moment(e).format("HH:mm:ss")
                        || (moment(schoolSettings.slotsTime[index].break2.from).format("HH:mm:ss") == moment(e).format("HH:mm:ss"))) {
                        toast.error(`Please select Greater than start time`);
                      } else {
                        schoolSettingsTo.slotsTime[index].break2.to = moment(e);
                        setSchoolSettings(schoolSettingsTo);
                      }
                    }}
                  />
                ) : null}
              </div>
            </Form.Group>

            {/* slots input  */}
            {item.slots.map((slot, slotIndex) => {
              return (
                <Form.Group className="row" key={"slot_" + slotIndex}>
                  <label className="col-sm-2 col-form-label">
                    Slot {slotIndex + 1}
                  </label>
                  <div className="col-sm-3">
                    {!isPageLoading ? (
                      <Datetime
                        dateFormat={false}
                        value={moment(schoolSettings.slotsTime[index].slots[slotIndex].from)}
                        onChange={(e) => {
                          let schoolSettingsFrom = { ...schoolSettings };
                          var break1From = moment(schoolSettingsFrom.slotsTime[index].break1.from).format("HH:mm:ss")
                          var break1To = moment(schoolSettingsFrom.slotsTime[index].break1.to).format("HH:mm:ss")
                          if (moment(e).format("HH:mm:ss") == break1From || moment(e).format("HH:mm:ss") == break1To) {
                            toast.error(`Time already exits in break`);
                          } else {
                            schoolSettingsFrom.slotsTime[index].slots[slotIndex].from = moment(e);
                            setSchoolSettings(schoolSettingsFrom);
                          }
                        }}
                      />
                    ) : null}
                  </div>

                  <div className="col-sm-3">
                    {!isPageLoading ? (
                      <Datetime
                        dateFormat={false}
                        value={moment(schoolSettings.slotsTime[index].slots[slotIndex].to)}
                        onChange={(e) => {
                          let schoolSettingsTo = { ...schoolSettings };
                          var break1From = moment(schoolSettingsTo.slotsTime[index].break1.from).format("HH:mm:ss")
                          var break1To = moment(schoolSettingsTo.slotsTime[index].break1.to).format("HH:mm:ss")
                          if (moment(e).format("HH:mm:ss") == break1To || moment(e).format("HH:mm:ss") == break1From) {
                            toast.error(`Time already exits in break`);
                          }
                          if (moment(schoolSettings.slotsTime[index].slots[slotIndex].from).format("HH:mm:ss") > moment(e).format("HH:mm:ss")
                            || (moment(schoolSettings.slotsTime[index].slots[slotIndex].from).format("HH:mm:ss") == moment(e).format("HH:mm:ss"))) {
                            toast.error(`Please select Greater than start time`);
                          } else {
                            schoolSettingsTo.slotsTime[index].slots[slotIndex].to = moment(e);
                            setSchoolSettings(schoolSettingsTo);
                          }
                        }}
                      />
                    ) : null}
                  </div>
                  <div className="col-sm-2">
                    {slotIndex == 0 ?

                      <button
                        type="button"
                        onClick={() => addSlot({ index, slotIndex })}
                        className="btn btn-primary"
                      >
                        <span className="mdi mdi-plus"></span>
                      </button>
                      : <button
                        type="button"
                        onClick={() => removeSlot({ index, slotIndex })}
                        className="btn btn-danger"
                      >
                        <span className="mdi mdi-minus"></span>
                      </button>
                    }
                  </div>
                </Form.Group>
              )
            })}

            {/* plus button  */}

            <div className="row">
              <div className="col-md-2">
                {index ? (
                  <button
                    type="button"
                    onClick={() => {
                      let scSt = { ...schoolSettings };
                      scSt.slotsTime.splice(index, 1);
                      // delete scSt.slotsTime[index];
                      setSchoolSettings(scSt);
                    }}
                    className="btn btn-danger"
                  >
                    <span className="mdi mdi-minus"></span>
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        );
      })
    );
  }

  function renderExamTermsHtml() {
    return (
      schoolSettings &&
      schoolSettings.termsExams &&
      schoolSettings.termsExams.map((item, index) => {
        return (
          <div className="row" key={index}>
            <div className="col-md-5">
              <Form.Group className="row">
                <label className="col-sm-3 col-form-label">Select term </label>
                <div className="col-sm-9">
                  <select
                    className="form-control"
                    name="term"
                    onChange={(e) => {
                      let schoolSettingsFrom = { ...schoolSettings };
                      schoolSettingsFrom.termsExams[index].term =
                        e.target.value;
                      setSchoolSettings(schoolSettingsFrom);
                    }}
                    value={schoolSettings.termsExams[index].term}
                  >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select>
                </div>
              </Form.Group>
            </div>
            <div className="col-md-5">
              <Form.Group className="row">
                <label className="col-sm-3 col-form-label">
                  Select for Classes{" "}
                </label>
                <div className="col-sm-9">
                  <MultiSelect
                    options={classes}
                    labelledBy="Select"
                    value={schoolSettings.termsExams[index].classIds}
                    onChange={(e) => {
                      setSelectedClassesForExamTerms(e);
                      let schoolSettingsFrom = { ...schoolSettings };
                      schoolSettingsFrom.termsExams[index].classIds = e;
                      setSchoolSettings(schoolSettingsFrom);
                    }}
                  />
                </div>
              </Form.Group>
            </div>

            <div className="col-md-2">
              {index ? (
                <button
                  type="button"
                  onClick={async () => {
                    await checkTermInCourseAndResult(schoolSettings.termsExams[index].term)
                    if (checkForTerm) {
                      let scSt = { ...schoolSettings };
                      scSt.termsExams.splice(index, 1);

                      // delete scSt.termsExams[index];
                      setSchoolSettings(scSt);
                    }
                  }}
                  className="btn btn-danger"
                >
                  <span className="mdi mdi-minus"></span>
                </button>
              ) : null}
            </div>
          </div>
        );
      })
    );
  }

  function renderFeeScheduleHtml() {
    if (schoolSettings && schoolSettings.feeSchedules && schoolSettings.feeSchedules.lateFees && schoolSettings.feeSchedules.lateFees.length) {
      return schoolSettings.feeSchedules.lateFees.map((item, index) => {
        return (
          <div className="row" key={index}>
            <div className="col-md-4">
              <Form.Group className="row">
                <label className="col-sm-3 col-form-label">
                  Select fee months{" "}
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </label>
                <div className="col-sm-9">
                  <MultiSelect
                    options={monthsList}
                    labelledBy="Select"
                    value={schoolSettings.feeSchedules.lateFees[index].months}
                    onChange={(e) => {
                      setSelectedMonth(e);
                      let schoolSettingsFrom = { ...schoolSettings };
                      schoolSettingsFrom.feeSchedules.lateFees[index].months = e;
                      setSchoolSettings(schoolSettingsFrom);
                    }}
                  />
                </div>
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Group className="row">
                <label className="col-sm-4 col-form-label">
                  Last fee Date{" "}
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </label>
                <div className="col-sm-8">
                  {!isPageLoading ? (
                    <Datetime
                      dateFormat={true}
                      timeFormat={false}
                      onChange={(e) => {
                        let schoolSettingsFrom = { ...schoolSettings };
                        schoolSettingsFrom.feeSchedules.lateFees[index].lastFeeDate =
                          moment(e);
                        setSchoolSettings(schoolSettingsFrom);
                      }}
                      value={moment(schoolSettings.feeSchedules.lateFees[index].lastFeeDate)}
                    />
                  ) : null}
                </div>
              </Form.Group>
            </div>
            <div className="col-md-1">
              {index ? (
                <button
                  type="button"
                  onClick={() => {
                    let scSt = { ...schoolSettings };
                    scSt.feeSchedules.lateFees.splice(index, 1);
                    setSchoolSettings(scSt);
                  }}
                  className="btn btn-danger"
                >
                  <span className="mdi mdi-minus"></span>
                </button>
              ) : null}
            </div>
          </div>
        );
      });
    }
  }

  const checkTermInCourseAndResult = async (term) => {
    let CourseStructureApi = new CourseStructure();
    await CourseStructureApi.checkTermInCourseAndResults({ term }).then(async (response) => {
      if (!response.success) {
        toast.error(response.msg);
        // return false;
        checkForTerm = false;
        await setCheckForTerm(checkForTerm)
      } else {
        // return true;
        checkForTerm = true;
        await setCheckForTerm(checkForTerm)
      }
    })
  }

  return (
    <div>
      <Tabs defaultIndex={1}>
        <TabList>
          <Tab>Update School Detail</Tab>
          <Tab>Update School Settings</Tab>
        </TabList>
        {dataLoader ? <Loader /> : null}
        <TabPanel>
          <div className="row">
            <div className="col-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">School Info</h4>
                  <form
                    className="forms-sample"
                    onSubmit={(e) => setUpSchool(e)}
                  >
                    <Form.Group>
                      <label htmlFor="exampleInputName1">
                        School Name{" "}
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <Form.Control
                        type="text"
                        required={true}
                        className="form-control"
                        placeholder="Name"
                        name="schoolName"
                        onChange={(e) => onChange(e)}
                        value={schoolFormData.schoolName}
                      />
                    </Form.Group>

                    <Form.Group>
                      <label>
                        Database Name{" "}
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <Form.Control
                        type="text"
                        required={true}
                        className="form-control"
                        placeholder="Name"
                        name="databaseName"
                        onChange={(e) => onChange(e)}
                        pattern="[a-z]{3,17}"
                        title="Database length should be minimum 3 and maximum 17, All words should be character"
                        value={schoolFormData.databaseName}
                        disabled={true}
                      />
                    </Form.Group>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group className="row">
                          <label className="col-sm-3 col-form-label">
                            Country{" "}
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </label>
                          <div className="col-sm-9">
                            <select
                              className="form-control"
                              name="country"
                              onChange={(e) => onChange(e)}
                              value={schoolFormData.address.country}
                              required={true}
                            >
                              <option>India</option>
                            </select>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group className="row">
                          <label className="col-sm-3 col-form-label">
                            State{" "}
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </label>
                          <div className="col-sm-9">
                            <select
                              className="form-control"
                              name="state"
                              onChange={(e) => onChange(e)}
                              value={schoolFormData.address.state}
                              required={true}
                            >
                              <option value="Andhra Pradesh">
                                Andhra Pradesh
                              </option>
                              <option value="Andaman and Nicobar Islands">
                                Andaman and Nicobar Islands
                              </option>
                              <option value="Arunachal Pradesh">
                                Arunachal Pradesh
                              </option>
                              <option value="Assam">Assam</option>
                              <option value="Bihar">Bihar</option>
                              <option value="Chandigarh">Chandigarh</option>
                              <option value="Chhattisgarh">Chhattisgarh</option>
                              <option value="Dadar and Nagar Haveli">
                                Dadar and Nagar Haveli
                              </option>
                              <option value="Daman and Diu">
                                Daman and Diu
                              </option>
                              <option value="Delhi">Delhi</option>
                              <option value="Lakshadweep">Lakshadweep</option>
                              <option value="Puducherry">Puducherry</option>
                              <option value="Goa">Goa</option>
                              <option value="Gujarat">Gujarat</option>
                              <option value="Haryana">Haryana</option>
                              <option value="Himachal Pradesh">
                                Himachal Pradesh
                              </option>
                              <option value="Jammu and Kashmir">
                                Jammu and Kashmir
                              </option>
                              <option value="Jharkhand">Jharkhand</option>
                              <option value="Karnataka">Karnataka</option>
                              <option value="Kerala">Kerala</option>
                              <option value="Madhya Pradesh">
                                Madhya Pradesh
                              </option>
                              <option value="Maharashtra">Maharashtra</option>
                              <option value="Manipur">Manipur</option>
                              <option value="Meghalaya">Meghalaya</option>
                              <option value="Mizoram">Mizoram</option>
                              <option value="Nagaland">Nagaland</option>
                              <option value="Odisha">Odisha</option>
                              <option value="Punjab">Punjab</option>
                              <option value="Rajasthan">Rajasthan</option>
                              <option value="Sikkim">Sikkim</option>
                              <option value="Tamil Nadu">Tamil Nadu</option>
                              <option value="Telangana">Telangana</option>
                              <option value="Tripura">Tripura</option>
                              <option value="Uttar Pradesh">
                                Uttar Pradesh
                              </option>
                              <option value="Uttarakhand">Uttarakhand</option>
                              <option value="West Bengal">West Bengal</option>
                            </select>
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group className="row">
                          <label className="col-sm-3 col-form-label">
                            City{" "}
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </label>
                          <div className="col-sm-9">
                            <Form.Control
                              type="text"
                              className="form-control"
                              placeholder="City"
                              name="city"
                              required={true}
                              onChange={(e) => onChange(e)}
                              value={schoolFormData.address.city}
                            />
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group className="row">
                          <label className="col-sm-3 col-form-label">
                            Pin code{" "}
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </label>
                          <div className="col-sm-9">
                            <Form.Control
                              type="text"
                              className="form-control"
                              placeholder="Pin code"
                              name="pinCode"
                              required={true}
                              onChange={(e) => onChange(e)}
                              value={schoolFormData.address.pinCode}
                            />
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                    <Form.Group>
                      <label htmlFor="exampleTextarea1">
                        Address{" "}
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <textarea
                        className="form-control"
                        rows="4"
                        name="address"
                        required={true}
                        onChange={(e) => onChange(e)}
                        value={schoolFormData.address.address}
                      ></textarea>
                    </Form.Group>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group className="row">
                          <label className="col-sm-3 col-form-label">
                            Longitude{" "}
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </label>
                          <div className="col-sm-9">
                            <Form.Control
                              type="text"
                              className="form-control"
                              placeholder="Longitude"
                              name="longitude"
                              required={true}
                              onChange={(e) => onChange(e)}
                              value={schoolFormData.address.longitude}
                            />
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group className="row">
                          <label className="col-sm-3 col-form-label">
                            Latitude{" "}
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </label>
                          <div className="col-sm-9">
                            <Form.Control
                              type="text"
                              className="form-control"
                              placeholder="Latitude"
                              name="latitude"
                              required={true}
                              onChange={(e) => onChange(e)}
                              value={schoolFormData.address.latitude}
                            />
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                    <Form.Group>
                      <label>
                        Radius{" "}
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        placeholder="Radius"
                        name="allowedRadius"
                        required={true}
                        onChange={(e) => onChange(e)}
                        value={schoolFormData.address.allowedRadius}
                      />
                    </Form.Group>
                    {/* ===================================== logo/color scheme for schools ====================================== */}

                    <h4 className="card-title">logo/color scheme for school</h4>
                    <div className="row">
                      <div className="col-md-10">
                        <Form.Group>
                          <label>
                            School logo{" "}
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </label>
                          <div className="custom-file">
                            <Form.Control
                              type="file"
                              className="form-control visibility-hidden"
                              id="customFileLang"
                              lang="es"
                              onChange={(e) => {
                                uploadLogo(e.target.files);
                              }}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="customFileLang"
                            >
                              {schoolFormData.colorScheme &&
                                schoolFormData.colorScheme.schoolLogo
                                ? schoolFormData.colorScheme &&
                                schoolFormData.colorScheme.schoolLogo
                                : "Upload Image"}
                            </label>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-2">
                        {schoolFormData.colorScheme.schoolLogo ? (
                          <img
                            src={schoolFormData.colorScheme.schoolLogo}
                            style={{ width: 70 }}
                            loading="lazy"
                          />
                        ) : (
                          <img
                            src={require("../../../assets/images/Edvantum.png")}
                            style={{ width: 70 }}
                            loading="lazy"
                          />
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group className="row">
                          <label className="col-sm-4 col-form-label">
                            Background color{" "}
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </label>
                          <div className="col-sm-8">
                            <Form.Control
                              type="text"
                              className="form-control"
                              placeholder="color code"
                              name="backgroundColor"
                              required={true}
                              onChange={(e) => onChange(e)}
                              value={
                                schoolFormData.colorScheme &&
                                schoolFormData.colorScheme.backgroundColor
                              }
                            />
                            <HexColorPicker
                              color={
                                schoolFormData.colorScheme &&
                                schoolFormData.colorScheme.backgroundColor
                              }
                              onChange={(e) => {
                                let schoolForm = { ...schoolFormData };
                                schoolForm.colorScheme.backgroundColor = e;
                                setSchoolFormData(schoolForm);
                              }}
                            />
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group className="row">
                          <label className="col-sm-4 col-form-label">
                            Font color{" "}
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </label>
                          <div className="col-sm-8">
                            <Form.Control
                              type="text"
                              className="form-control"
                              placeholder="color code"
                              name="fontColor"
                              required={true}
                              onChange={(e) => onChange(e)}
                              value={
                                schoolFormData.colorScheme &&
                                schoolFormData.colorScheme.fontColor
                              }
                            />
                            <HexColorPicker
                              color={
                                schoolFormData.colorScheme &&
                                schoolFormData.colorScheme.fontColor
                              }
                              onChange={(e) => {
                                let schoolForm = { ...schoolFormData };
                                schoolForm.colorScheme.fontColor = e;
                                setSchoolFormData(schoolForm);
                              }}
                              name="fontColor"
                            />
                          </div>
                        </Form.Group>
                      </div>
                    </div>

                    {/* ===================================== / logo/color scheme for schools / ====================================== */}

                    {/* ========================================================== */}
                    <h4 className="card-title">Admin Info</h4>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group className="row">
                          <label className="col-sm-3 col-form-label">
                            First Name{" "}
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </label>
                          <div className="col-sm-9">
                            <Form.Control
                              type="text"
                              name="adminFirstName"
                              required={true}
                              onChange={(e) => onChange(e)}
                              value={
                                schoolFormData.adminInfo &&
                                schoolFormData.adminInfo.firstName
                              }
                            />
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group className="row">
                          <label className="col-sm-3 col-form-label">
                            Last Name{" "}
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </label>
                          <div className="col-sm-9">
                            <Form.Control
                              type="text"
                              name="adminLastName"
                              required={true}
                              onChange={(e) => onChange(e)}
                              value={
                                schoolFormData.adminInfo &&
                                schoolFormData.adminInfo.lastName
                              }
                            />
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group className="row">
                          <label className="col-sm-3 col-form-label">
                            Mobile No.{" "}
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </label>
                          <div className="col-sm-9">
                            <Form.Control
                              type="text"
                              name="adminMobile"
                              required={true}
                              onChange={(e) => onChange(e)}
                              value={
                                schoolFormData.adminInfo &&
                                schoolFormData.adminInfo.mobile
                              }
                              pattern="[0-9]{4,12}"
                              title="Enter vaild mobile number"
                            />
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group className="row">
                          <label className="col-sm-3 col-form-label">
                            Email{" "}
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </label>
                          <div className="col-sm-9">
                            <Form.Control
                              type="email"
                              name="adminEmail"
                              required={true}
                              onChange={(e) => onChange(e)}
                              value={
                                schoolFormData.adminInfo &&
                                schoolFormData.adminInfo.email
                              }
                            />
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                    {schoolFormData._id == "" ? (
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group className="row">
                            <label className="col-sm-3 col-form-label">
                              Password{" "}
                              <span style={{ color: "red", fontSize: 14 }}>
                                *
                              </span>
                            </label>
                            <div className="col-sm-9">
                              <Form.Control
                                type="password"
                                name="adminPassword"
                                required={true}
                                onChange={(e) => onChange(e)}
                                value={
                                  schoolFormData.adminInfo &&
                                  schoolFormData.adminInfo.password
                                }
                              />
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    ) : null}
                    {/* ========================================================== */}
                    <button type="submit" className="btn btn-primary mr-2">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="row">
            <div className="col-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <form
                    className="forms-sample"
                    onSubmit={(e) => saveSettings(e)}
                  >
                    <h4 className="card-title">School Session</h4>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group className="row">
                          <label className="col-sm-3 col-form-label">
                            Session From Month{" "}
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </label>
                          <div className="col-sm-9">
                            <select
                              className="form-control"
                              name="startsFromMonth"
                              onChange={(e) => onChange(e)}
                              value={
                                schoolSettings.session &&
                                schoolSettings.session.startsFromMonth
                              }
                              required={true}
                            >
                              {monthsList.map((item, i) => {
                                return (
                                  <option key={i} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group className="row">
                          <label className="col-sm-3 col-form-label">
                            Session To Month{" "}
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </label>
                          <div className="col-sm-9">
                            <select
                              className="form-control"
                              name="endsOnMonth"
                              onChange={(e) => onChange(e)}
                              value={
                                schoolSettings.session &&
                                schoolSettings.session.endsOnMonth
                              }
                              required={true}
                            >
                              {monthsList.map((item, i) => {
                                return (
                                  <option key={i} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                    <h4 className="card-title">Teacher School Timings </h4>
                    {renderTeacherTimings()}
                    <h4 className="card-title">School Timings </h4>
                    {renderSchoolTimings()}
                    <div
                      className="row mb-2"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        padding: "0",
                      }}
                    >
                      <div className="col-md-2">
                        <button
                          type="button"
                          onClick={() => {
                            let scSt = { ...schoolSettings };
                            scSt.schoolTime.push({
                              classIds: [],
                              enteryTime: "",
                              halfTime: "",
                              shortLeaveTime: "",
                              satHolidays: [],
                            });
                            setSchoolSettings(scSt);
                          }}
                          className="btn btn-primary"
                        >
                          <span className="mdi mdi-plus"></span>
                        </button>
                      </div>
                    </div>

                    <h4 className="card-title">Time Slots / Periods</h4>
                    {renderSlotTimings()}
                    <div
                      className="row mb-2"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        padding: "0",
                      }}
                    >
                      <div className="col-md-2">
                        <button
                          type="button"
                          onClick={() => {
                            let scSt = { ...schoolSettings };
                            if (scSt.slotsTime != undefined) {
                              if (scSt.slotsTime[0].classIds.length == classes.length) {
                                alert("Please Unselect All Select option In First Group")
                                return;
                              }

                              scSt.slotsTime.push({
                                classIds: [],
                                startTime: "",
                                groupName: "",
                                endTime: "",
                                break1: { from: "", to: "" },
                                break2: { from: "", to: "" },
                                slots: [{ from: "", to: "" }],
                              });

                              let tempClassesPerGroup = classesPerGroup
                              let totalArr = []
                              for (let i = 0; ((i < scSt.slotsTime.length)); i++) {
                                let singleArray = classes.filter(val => {
                                  return scSt.slotsTime[i].classIds.some((ele) => {
                                    return ele.value == val.value;
                                  });
                                })
                                totalArr = Array.from([...totalArr, ...singleArray]
                                  .reduce((m, o) => m.set(o.value, o), new Map)
                                  .values()
                                );
                              }

                              let netArray = classes.filter(val => {
                                return !totalArr.some((ele) => {
                                  return ele.value == val.value;
                                });
                              })

                              tempClassesPerGroup.push(netArray)
                              setClassesPerGroup(tempClassesPerGroup)
                            } else {
                              scSt["slotsTime"] = [
                                {
                                  classIds: [],
                                  startTime: "",
                                  groupName: "",
                                  endTime: "",
                                  break1: { from: "", to: "" },
                                  break2: { from: "", to: "" },
                                  slots: [{ from: "", to: "" }],
                                }
                              ];
                            }
                            setSchoolSettings(scSt);
                          }}
                          className="btn btn-primary"
                        >
                          <span className="mdi mdi-plus"></span>
                        </button>
                      </div>
                    </div>

                    <h4 className="card-title">Terms for Exams</h4>
                    {renderExamTermsHtml()}
                    <div
                      className="row mb-2"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        padding: "0",
                      }}
                    >
                      <div className="col-md-2">
                        <button
                          type="button"
                          onClick={() => {
                            let scSt = { ...schoolSettings };
                            if (scSt.termsExams.length == 4) {
                              alert('All terms are selected')
                              return;
                            }
                            scSt.termsExams.push({
                              classIds: [],
                              term: 1,
                            });
                            setSchoolSettings(scSt);
                          }}
                          className="btn btn-primary"
                        >
                          <span className="mdi mdi-plus"></span>
                        </button>
                      </div>
                    </div>
                    <h4 className="card-title">School Leave Type</h4>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group className="row">
                          <label className="col-sm-3 col-form-label">
                            Total Casual Leave{" "}
                          </label>
                          <div className="col-sm-9">
                            <Form.Control
                              type="number"
                              name="casual"
                              onChange={(e) => onChange(e)}
                              value={
                                schoolSettings.leaves &&
                                schoolSettings.leaves.casual
                              }
                            />
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group className="row">
                          <label className="col-sm-3 col-form-label">
                            Total Sick Leave{" "}
                          </label>
                          <div className="col-sm-9">
                            <Form.Control
                              type="number"
                              name="sick"
                              required={true}
                              onChange={(e) => onChange(e)}
                              value={
                                schoolSettings.leaves &&
                                schoolSettings.leaves.sick
                              }
                            />
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group className="row">
                          <label className="col-sm-3 col-form-label">
                            Short Leave Hours{" "}
                          </label>
                          <div className="col-sm-9">
                            <Form.Control
                              type="number"
                              name="shortLeaveTime"
                              onChange={(e) => {
                                let schoolSettingsFrom = { ...schoolSettings };
                                schoolSettingsFrom.leaves.shortLeaveTime =
                                  e.target.value;
                                setSchoolSettings(schoolSettingsFrom);
                              }}
                              value={
                                schoolSettings.leaves &&
                                schoolSettings.leaves.shortLeaveTime
                              }
                            />
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group className="row">
                          <label className="col-sm-3 col-form-label">
                            Total Short Leave{" "}
                          </label>
                          <div className="col-sm-9">
                            <Form.Control
                              type="number"
                              name="short"
                              onChange={(e) => onChange(e)}
                              value={
                                schoolSettings.leaves &&
                                schoolSettings.leaves.short
                              }
                            />
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                    <h4 className="card-title">Payment schedule</h4>
                    <div className="row">
                      <div className="col-md-4">
                        <Form.Group className="row">
                          <label className="col-sm-12 col-form-label">Choose late Payment Fee Type<span style={{ color: "red", fontSize: 14 }}>*</span></label>
                          <label className="col-sm-6 col-form-label">
                            <input
                              type="radio"
                              className="form-check-input mt-0 col-sm-10"
                              onChange={onChange}
                              name="latePaymentFixed"
                              value="fixed"
                              checked={schoolSettings.feeSchedules.lastPaymentFeeType === 'fixed'}
                            />
                            <i className="input-helper"></i>
                            Fixed
                          </label>
                          <label className="col-sm-6 col-form-label">
                            <input
                              type="radio"
                              className="form-check-input mt-0 col-sm-10"
                              onChange={onChange}
                              name="latePaymentPerDay"
                              // value="amount"
                              // checked={!data.fixed}
                              value="perDay"
                              checked={schoolSettings.feeSchedules.lastPaymentFeeType === 'perDay'}
                            />
                            <i className="input-helper"></i>
                            Per Day
                          </label>
                        </Form.Group>
                      </div>
                      <div className="col-md-4">
                        <Form.Group className="row">
                          <label className="col-sm-12 col-form-label">Late Payment Fee<span style={{ color: "red", fontSize: 14 }}>*</span></label>
                          <Form.Control
                            type="number"
                            name="lateFeeAmount"
                            required={true}
                            onChange={onChange}
                            value={schoolSettings.feeSchedules.lateFeeAmount}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    {renderFeeScheduleHtml()}
                    <div
                      className="row mb-2"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        padding: "0",
                      }}
                    >
                      <div className="col-md-2">
                        <button
                          type="button"
                          onClick={() => {
                            let scSt = { ...schoolSettings };
                            scSt.feeSchedules.lateFees.push({
                              months: [],
                              lastFeeDate: "",
                            });
                            setSchoolSettings(scSt);
                          }}
                          className="btn btn-primary"
                        >
                          <span className="mdi mdi-plus"></span>
                        </button>
                      </div>
                    </div>
                    <h4 className="card-title">
                      Activate reply option for Parents (on Mobile App)
                    </h4>
                    <div className="row">
                      <div className="col-md-4">
                        <Form.Group className="row">
                          <div className="form-check">
                            <label className="form-check-label">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name="canParentMessageForCommunication"
                                onClick={(e) =>
                                  setCanParentMessageForCommunication(
                                    !canParentMessageForCommunication
                                  )
                                }
                                value={canParentMessageForCommunication}
                                checked={canParentMessageForCommunication}
                              />
                              <i className="input-helper"></i>
                              Communication
                            </label>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-4">
                        <Form.Group className="row">
                          <div className="form-check">
                            <label className="form-check-label">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name="canParentMessageForHomework"
                                onClick={() => {
                                  setCanParentMessageForHomework(
                                    !canParentMessageForHomework
                                  );
                                }}
                                value={canParentMessageForHomework}
                                checked={canParentMessageForHomework}
                              />
                              <i className="input-helper"></i>
                              Homework
                            </label>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-4">
                        <Form.Group className="row">
                          <div className="form-check">
                            <label className="form-check-label">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name="canParentMessageForComplaint"
                                onClick={() => {
                                  setCanParentMessageForComplaint(
                                    !canParentMessageForComplaint
                                  );
                                }}
                                value={canParentMessageForComplaint}
                                checked={canParentMessageForComplaint}
                              />
                              <i className="input-helper"></i>
                              Complaints
                            </label>
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <button type="submit" className="btn btn-primary mr-2">
                        Submit
                      </button>
                    </div>
                    <div className="col-md-6 text-right">
                      <button type="button" disabled={moveDisabled} className="btn btn-success" onClick={() => moveToNextClass()}>
                        Students Move To Next Class
                      </button>
                    </div>

                  </form>

                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );

  async function moveToNextClass() {
    if (window.confirm("Are you sure for move Students next class!")) {
      let classApi = new ClassApi();
      await classApi.moveToNextClass().then(async (data) => {
        if (data.success) {
          toast.success(data.msg);
          setMoveDisabled(true);
        } else {
          toast.error(data.msg)
        }
      });
    }
  }
};
export default SchoolAdminSettings;