import React, { useContext, useEffect, useState } from "react";
import MaterialTable from "material-table";
import { Form, Modal, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from "react-toastify";
import CourseStructure from "../../../api/courseStructureApi";
import Class from "../../../api/classApi";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the `items look a bit nicer
  userSelect: "none",
  height: "44px",
  padding: "3px",
  borderBottom: "solid",
  // change background colour if dragging
  background: isDragging ? "lightgreen" : "White",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const AddCourseStructure = () => {
  let history = useHistory();
  const [data, setData] = useState({
    startstyle: {
      color: "red",
      fontSize: "14px",
    },
    leftspace: {
      marginLeft: "54px",
    },
    _id: "",
    className: "",
    term: "",
    marks: "",
    practical: "",
    assignment: "",
    subject: "",
    syllabus: "",
    fromDate: "",
    toDate: "",
    subjects: [],
  });
  const [classData, setClassData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [termsList, setTermsList] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);
  const [editSubjectId, setEditSubjectId] = useState("");
  let [check,setCheck] = useState(false);
  const [years, setYears] = useState([]);
  const months = [
    "",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]; //jan starts from index 1

  useEffect(() => {
    getList();
    getClassSubjects(1);
    setYears(getYearsList(10));
    const id = new URLSearchParams(history.location.search).get("id");
    if (id) {
      selectedCourse(id);
    }
  }, []);

  // ================================================== coding part =========================================
  const selectedCourse = async (id) => {
    let d = { ...data };
    let CourseApi = new CourseStructure();
    let courseData = await CourseApi.getCourseStructure(id);
    if (courseData.success) {
      d._id = courseData.data._id;
      d.className = courseData.data.className;
      d.term = courseData.data.term;
      d.subjects = courseData.data.subjects;
      d.fromDate = courseData.data.fromDate;
      d.toDate = courseData.data.toDate;

      setData(d);
      getClassSubjects(courseData.data.className);
    } else {
      toast.error(data.msg);
    }
  };
  const getList = async () => {
    setDataLoader(true);
    let ClassApi = new Class();
    await ClassApi.getAllClassNames()
      .then(async (response) => {
        if (response.data && response.data.length) {
          setClassData(response.data);
        } else {
          setClassData([]);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There is an error!", error);
      });
    setDataLoader(false);
  };

  const getClassSubjects = async (value) => {
    setDataLoader(true);
    let dt = { className: value };
    let ClassApi = new Class();
    await ClassApi.getSubjectByClassName(dt)
      .then(async (response) => {
        if (
          response.data &&
          response.data.subjectsData &&
          response.data.subjectsData.length
        ) {
          setSubjectData(response.data.subjectsData);
        } else {
          setSubjectData([]);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There is an error!", error);
      });
    setDataLoader(false);
  };
  const getTermsListForSchool = async (value) => {
    setDataLoader(true);
    let classId = value;
    let CourseApi = new CourseStructure();
    let courseData = CourseApi.getTermslistFromSchool(classId)
      .then(async (response) => {
        if (response.data) {
          setTermsList(response.data);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There is an error!", error);
      });
    setDataLoader(false);
  };

  const getYearsList = (maxOffset) => {
    let minOffset = 0;
    let thisYear = new Date().getFullYear();
    let allYears = [];
    for (let x = 0; x <= maxOffset; x++) {
      allYears.push(thisYear - x);
    }
    return allYears;
  };

  const handleChanges = async (event) => {
    let c = { ...data };
    const { name, value } = event.target;
    if (name == "className") {
      c.className = value;
      setData(c);
      getClassSubjects(value);
      getTermsListForSchool(value);
      getSubjectsAccToClassAndTerm({className: c.className,term: c.term})
    } else if (name == "term") {
      c.term = value;
      setData(c);
      await getSubjectsAccToClassAndTerm({className: c.className,term: value})
    } else if (name == "marks") {
      if(value > 100){
        alert("Marks value is greater than 100")
      }else{
        c.marks = value;
        c.practical = "";
        c.assignment = "";
      }
      setData(c);
    } else if (name == "practical") {
      if(value > 100){
        alert("Practical value is greater than 100")
        return;
      }
      if((100 - (c.marks)) < value  ){
        alert("Practical Value should be less than " + (100 - (c.marks)))
        return
      }
      c.practical = value;
      c.assignment = "";
      setData(c);
    } else if (name == "assignment") {
      if((100 - (parseInt(c.marks) + parseInt(c.practical))) < value  ){
        alert("Assignment Value should be less than " + (100 - (parseInt(c.marks) + parseInt(c.practical))))
      }else{
        c.assignment = value;
      }
      setData(c);
    } else if (name == "subject") {
      c.subject = value;
      c.marks = "";
      c.practical = "";
      c.assignment = "";
      c.syllabus = ""
      setData(c);
      setEditSubjectId("")
    } else if (name == "syllabus") {
      c.syllabus = value;
      setData(c);
    } else if (name == "fromDate") {
      c.fromDate = value;
      c.toDate = "";
      setData(c);
    } else if (name == "toDate") {
      let g1 = new Date(c.fromDate);
      let g2 = new Date(value);
      if (g1.getTime() > g2.getTime()){
        alert ("To Date is less than From Date")
      }else{
        c.toDate = value;
      }
      setData(c);
    }
  };

  const getSubjectsAccToClassAndTerm = async (query) => {
    setDataLoader(true);
    let CourseStructureApi = new CourseStructure();
    await CourseStructureApi.getFilteredCourses(query).then(async (response) => {
      if(query.term != ''){
        if (response.data && response.data.length > 0 ) {
          let d = { ...data };
          d._id = response.data[0]._id;
          d.className = response.data[0].className;
          d.term = response.data[0].term;
          d.subjects = response.data[0].subjects;
          d.fromDate = response.data[0].fromDate.split('T')[0];
          d.toDate = response.data[0].toDate.split('T')[0];  
          setData(d);
        } else {
          let d = { ...data };
          d._id = "";
          d.marks = "";
          d.term = query.term;
          d.practical = "";
          d.assignment = "";
          d.subject = "";
          d.syllabus = "";
          d.fromDate = "";
          d.toDate = "";
          d.subjects = [];
          setData(d);
  
        }
      }
    })
    setDataLoader(false)
  }
  
  const checkSubjectInResult =  async (subjectId) =>{
    let CourseStructureApi = new CourseStructure();
    await CourseStructureApi.checkCourseSubjectPresentInResult({subjectId, className: data.className,term: data.term }).then(async (response) => {
      if(!response.success){
        toast.error(response.msg);
        check = false;
        await setCheck(check)
      }else{
        check = true;
        await setCheck(check)
      }
    })
  } 

  const handleSubmit = async (event) => {
    event.preventDefault();
    let { _id, className, term, fromDate, toDate, subjects } = data;
    if(subjects.length == 0){
      alert("Please Add Some Subjects")
    }else{
      let CourseStructureApi = new CourseStructure();
      if (data._id) {
        await CourseStructureApi.editCourseStructure({
          _id,
          className,
          term,
          fromDate,
          toDate,
          subjects,
        })
          .then((data) => {
            if (data.success) {
              toast.success(data.msg.toString());
              cleanFields();
            } else {
              toast.error(data.msg);
            }
          })
          .catch((error) => {
            toast.error(error.toString());
          });
      } else {
        await CourseStructureApi.createCourseStructure({
          className,
          term,
          fromDate,
          toDate,
          subjects,
        })
          .then((data) => {
            if (data.success) {
              toast.success(data.msg.toString());
            } else {
              toast.error(data.msg);
            }
          })
          .catch((error) => {
            toast.error(error.toString());
          });
      }
    }
  };

  const cleanFields = () => {
    let d = { ...data };
    d._id = "";
    d.className = "";
    d.term = "";
    d.marks = "";
    d.practical = "";
    d.assignment = "";
    d.subject = "";
    d.syllabus = "";
    d.fromDate = "";
    d.toDate = "";
    d.subjects = [];
    setData(d);
  };

  const handleAddMore = (event) => {
    let d = { ...data };
    if (d.subject != "" && d.marks != "" && d.syllabus != "") {
      let subjectExists = d.subjects.find((o) => o.subject === data.subject);
      if(editSubjectId != ""){
        d.subjects.map(val => {
          if(val._id == editSubjectId){
            val.marks = data.marks;
            val.practical =  data.practical;
            val.assignment=  data.assignment;
            val.syllabus = data.syllabus;
          }
          return val;
        })
        d.marks = "";
        d.practical = "";
        d.assignment = "";
        d.subject = "";
        d.syllabus = "";
        setData(d);
        setEditSubjectId("")
        return;
      }
      if (!subjectExists) {
        let newObj = {
          subject: data.subject,
          marks: data.marks,
          practical: data.practical,
          assignment: data.assignment,
          syllabus: data.syllabus,
        };
        d.subjects.push(newObj);
        setData(d);
      }
    }
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const subjects = reorder(
      data.subjects,
      result.source.index,
      result.destination.index
    );

    data((data.subjects = subjects));
  };

  const removeItem = (index) => {
    let d = { ...data };
    d.subjects.splice(index, 1);
    setData(d);
  };

  const showSubjectName = (subId) => {
    let obj = subjectData && subjectData.find((o) => o._id == subId);
    if (obj && obj.subjectName) return obj.subjectName;
    else return "--";
  };

  // =================================================/ coding part /========================================

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">Master Data </h3>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <form className="forms-sample" onSubmit={handleSubmit}>
                <h4 className="card-title">Add Course Structure</h4>
                <div className="row">
                  <div className="col-md-7">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Class<span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="className"
                          value={data.className}
                          onChange={handleChanges}
                          required
                        >
                          <option value="">-----</option>
                          {classData.map((name) => (
                            <option key={name} value={name}>
                              {name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-7">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Term<span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="term"
                          value={data.term}
                          onChange={handleChanges}
                          required
                        >
                          <option value="">-----</option>
                          {termsList.map((t) => (
                            <option key={t} value={t}>
                              {t}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group className="row">
                      <label className="col-sm-1 col-form-label">
                        Period<span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-4" style={data.leftspace}>
                        <Form.Group>
                          <Form.Control
                            type="date"
                            name="fromDate"
                            value={data.fromDate}
                            onChange={handleChanges}
                            required
                          />
                        </Form.Group>
                      </div>
                      <div className="col-sm-1 mt-3 pl-4"> to </div>
                      <div className="col-sm-4">
                        <Form.Group>
                          <Form.Control
                            type="date"
                            name="toDate"
                            value={data.toDate}
                            onChange={handleChanges}
                            required
                          />
                        </Form.Group>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group className="row">
                      <label className="col-sm-5 col-form-label">
                        Subject<span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-7">
                        <select
                          className="form-control"
                          name="subject"
                          value={data.subject}
                          onChange={handleChanges}
                        >
                          <option value="">-----</option>
                          {subjectData.map((dt) => (
                            <option key={dt._id} value={dt._id}>
                              {dt.subjectName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group className="row">
                      <label className="col-sm-4 col-form-label">
                        Marks<span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-8">
                        <Form.Control
                          type="text"
                          name="marks"
                          value={data.marks}
                          onChange={handleChanges}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group className="row">
                    <label className="col-sm-5 col-form-label">Practical</label>
                      <div className="col-sm-7">
                        <Form.Control
                          type="text"
                          name="practical"
                          value={data.practical}
                          onChange={handleChanges}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group className="row">
                    <label className="col-sm-5 col-form-label">Assignment</label>
                      <div className="col-sm-7">
                        <Form.Control
                          type="text"
                          name="assignment"
                          value={data.assignment}
                          onChange={handleChanges}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-5">
                    <Form.Group className="row">
                      <label className="col-sm-3 col-form-label">
                        Syllabus<span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-9">
                        <Form.Group>
                          <textarea
                            className="form-control"
                            id="exampleTextarea1"
                            rows="4"
                            name="syllabus"
                            required={false}
                            onChange={handleChanges}
                            value={data.syllabus}
                          ></textarea>
                        </Form.Group>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-1">
                    <button
                      type="button"
                      className="btn btn-link"
                      style={{ fontSize: "25px" }}
                      onClick={handleAddMore}
                    >
                      +
                    </button>
                  </div>
                </div>

                <div className="col-md-12">
                  <Form.Group className="row">
                    <div className="col-sm-12">
                      {/* ================================================================================== */}
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {data.subjects.map((item, index) => (
                                <Draggable
                                  key={item.subject}
                                  draggableId={item.subject}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <span>
                                        <span className="col-3">
                                          {showSubjectName(item.subject)}{" "}
                                        </span>
                                        <span className="col-3">
                                          {item.marks}{" "}
                                        </span>
                                        <span className="col-3">
                                          {item.practical}{" "}
                                        </span>
                                        <span className="col-3">
                                          {item.assignment}{" "}
                                        </span>
                                        <span className="col-3">
                                          {item.syllabus}{" "}
                                        </span>
                                        <span className="float-right">
                                          <button onClick={async() => {
                                            await checkSubjectInResult(item.subject)
                                            if(check){
                                              let d = { ...data };
                                              d.marks = item.marks;
                                              d.practical = item.practical;
                                              d.assignment = item.assignment;
                                              d.subject = item.subject;
                                              d.syllabus = item.syllabus;
                                              setData(d);
                                              if(item._id){
                                                setEditSubjectId(item._id)
                                              }else{
                                                setEditSubjectId("")
                                              }
                                            }
                                           

                                          }} data-tip data-for="Edit" type="button" className="btn btn-outline-warning" style={{ padding: '8px' }}>
                                            <i className="mdi mdi-border-color" style={{ fontSize: '17px' }}></i>
                                          </button>
                                          <button
                                            data-tip
                                            data-for="Delete"
                                            type="button"
                                            className="btn btn-outline-danger"
                                            style={{ padding: "8px" }}
                                            onClick={async() =>{
                                              await checkSubjectInResult(item.subject)
                                              if(check){
                                                removeItem(index)
                                              }
                                              } 
                                            }
                                          >
                                            <i
                                              className="mdi mdi-delete"
                                              style={{ fontSize: "17px" }}
                                            ></i>
                                          </button>
                                        </span>
                                      </span>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </Form.Group>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <button
                      type="submit"
                      className="btn btn-primary ml-2 btn-fw"
                      style={{ lineHeight: 1.5, float: "right" }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCourseStructure;