import React, { useLayoutEffect } from "react";
const RefundAndCancellation = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div>
            <section id="termsofuse" className="section-padding ">
                <div className="container">
                    <div className="header-section text-center margin-btm">
                        <h2 className="title__line">Refund & Cancellation</h2>
                    </div>
                    <div className="blog_card_content">
                        <h3>Cancellation or Refund Policy:</h3>
                        <span>Once Payee has made the successful payment, cannot cancel or claim it for refund in any circumstance.</span>
                    </div>
                    <div className="blog_card_content">
                        <h3>Before and after school activities :</h3>
                        <span>Please read the subscription terms and conditions carefully before subscribing to any of the subscription plans, as once you have subscribed, you cannot change or cancel your subscription plan. Once you subscribe and make the required payment for any subscriptions, it shall be final and there cannot be any changes or modifications to the same and neither will there be any refund.</span>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default RefundAndCancellation;