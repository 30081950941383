import BaseApi from "./baseApi";

class Subject extends BaseApi {
    async getSubject() {
        return await this.API(
            {
                url: "/subject/getsubjects",
                method:"get",
            }
        )
    }

    async getSubjectHead() {
        return await this.API(
            {
                url: "/subject/getsubjecthead",
                method:"get",
            }
        )
    }

    async createSubject(data) {
        return await this.API(
            {
                url: "/subject/createsubject",
                data: data,
                method:"post"
            }
        )
    }

    async editSubject(data) {
        return await this.API(
            {
                url: "/subject/updatesubject",
                data: data,
                method:"post"
            }
        )
    }

    async deleteSubject(data) {
        return await this.API(
            {
                url: "/subject/deletesubject",
                data: { _id: data },
                method:"post"
            }
        )
    }

    async getSubjectById(id) {
        return await this.API(
            {
                url: `/subject/getsubject/${id}`,
                method:"post"
            }
        )
    }

    async getSubjectByTeacherId(id){
        return await this.API(
            {
                url: `/subject/getsubjectbyteacherid/${id}`,
                method:"post"
            }
        )
    }

    async getTeacherBySubjectId(classId, subjectId) {
        return await this.API(
            {
                url: `/subject/getteacherbysubjectid/${classId}/${subjectId}`,
                method: 'get'
            }
        )
    }

    async saveSubjectCsv(data){
        return await this.API(
            {
                url: `/subject/savesubjectcsv`,
                method:"post",
                data:data
            }
        )
    }
    
}

export default Subject;