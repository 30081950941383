import React, { useLayoutEffect } from "react";
const Parent = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  return (
    <section id="parents" className="section-padding ">
      <div className="container">
        <div className="">
          <div className="header-section text-center margin-btm">
            <h2 className="title__line">Parents</h2>
            <p className="tagline">
              Parental involvement in education is key to successful outcomes!
            </p>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="service-box">
                <div className="icon-text1">
                  <h4 className="ser-text">
                    Actively Engage in Child’s education (Engagement)
                  </h4>
                  <ul>
                    <li>Real-time Updates</li>
                    <li>Digital Diary</li>
                    <li>Progress Report</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="service-box">
                <div className="icon-text1">
                  <h4 className="ser-text">
                    Participate anytime from anywhere (Digitization)
                  </h4>
                  <ul>
                    <li>Interact with teachers</li>
                    <li>On-line fee management</li>
                    <li>Leave management</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="service-box">
                <div className="icon-text1">
                  <h4 className="ser-text">
                    Child’s all-round development (Activities)
                  </h4>
                  <ul>
                    <li>Sporting activities</li>
                    <li>Performing Arts</li>
                    <li>Sharpen language &amp; analytical skills</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
};
export default Parent;
