import React, { useLayoutEffect } from "react";
const TermsOfUse = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    const myInlineStyles = {
        marginLeft: '20px',
        paddingLeft: '10px'
    };
    return (
        <div>
            <section id="contact">
                <div class="container">
                    <div class="row">
                        <div class="header-section text-center margin-btm col-12 mt-5">
                            <h2 class="title__line">Terms of Use</h2>
                        </div>
                        <div class="col-md-12">
                            <h3>Effective date: 1st Dec, 2023</h3>
                            <p>
                                Welcome to Edvantum, here in after referred to as Edvantum or Licensor. Please read on to learn the Terms & Conditions that govern your use of our website(s), products, applications, documents and videos (the "PRODUCT"). If you have any questions, comments, or concerns regarding these terms or the Product, please contact us at <a href="mailto:info@edvantum.com">info@edvantum.com</a>.
                            </p>
                            <p>
                                You are expected to agree to and accept all the Terms & Conditions, or you don't have the right to use the PRODUCT. Using the Product in any way means that you agree to all of these terms, and these terms will remain in effect while you use the Product. These Terms include the provisions in this document, as well as those in our Privacy Policy document.
                            </p>

                            <h3>Will any changes occur in these Terms & Conditions?</h3>
                            <p>
                                We at Edvantum are attempting continually to enhance our Product, so these Terms may need to change alongside the adaption of new Product and hence maintain all authority to change the Terms whenever needed. In such an event, we will do our best to communicate to you by drawing a notice on our site, or notifying in the app. However, you are in charge of comprehending what the present Terms are and self-responsible to stay acknowledged about the Terms of Product.
                            </p>
                            <p>
                                On the off chance that you don't concur with the new Terms, at that point you will never again have the complete authority to utilize the Product. Besides from the changes made by us as depicted above, no other alteration or adjustment of these Terms will be viable unless in composing and marked by both you and us.
                            </p>

                            <h3>Does Edvantum take care of my privacy?</h3>
                            <p>
                                Edvantum takes the privacy of its users very seriously. You can refer our detailed Edvantum Privacy Policy document on our website.
                            </p>

                            <h3>What are the prerequisites of using Edvantum Product?</h3>
                            <p>
                                By using the Website or the Mobile App, you represent and warrant that:
                            </p>
                            <ul>
                                <li>
                                    You are 18 years of age or older and that your use of the Website/App and / or Product shall not violate any applicable law or regulation.
                                </li>
                                <li>
                                    All registration information you submit is truthful and accurate and that you agree to maintain the accuracy of such information.
                                </li>
                                <li>
                                    Your utilization of this Website/App should be subjected to the accompanying confinements: you may not alter any substance of the Website or App, including however not constrained to, any open show, portrayal, execution, deal, rental and evaluating of the Product; you may not decompile, figure out, or dismantle the substance; you may not expel any copyright, trademark enrollment, or other restrictive notification from the Website or the App.
                                </li>
                                <li>
                                    You will not:
                                    <ul>
                                        <li>Advertise or sell the Product (whether or not for profit), for commercial purposes of any kind; or</li>
                                        <li>Use the Website or App and / or Product in any way that is unlawful, or harms Edvantum or any other person or entity, as determined by Edvantum at its sole discretion.</li>
                                    </ul>
                                </li>
                                <li>
                                    You won't post, submit, transfer, disseminate, or generally transmit or make accessible any product or other PC records that contain an infection or other hurtful segment, or generally debilitate or harm the Website/App and/or Product or any associated organize, or generally interfere with any individual or substance's utilization or delight in the Website/App and/or Product.
                                </li>
                                <li>
                                    You will not engage in any form of antisocial, disrupting, or destructive acts, including "flaming", "spamming", "flooding", "trolling" and "phishing" as those terms are commonly understood and used on the Internet.
                                </li>
                                <li>
                                    You will not delete or modify any content of the Website or App and / or Product, including but not limited to, legal notices, disclaimers or proprietary notices such as copyright or trademark symbols, logos, that you do not own or do not have express permission to modify.
                                </li>
                                <li>
                                    All information, content and material contained in our website, app, user manuals, help resources and social media channels and / or Product are Edvantum intellectual property. All trademarks, Product marks, trade names, and trade dress are proprietary to Edvantum. No information, content or material from the Website or the App and / or Product may be copied, reproduced, republished, uploaded, posted, transmitted or distributed in any way without Edvantum express written permission.
                                </li>
                            </ul>
                            <h3>Your use of the Product is subject to the following additional restrictions:</h3>
                            <p>
                                You represent, warrant, and agree that you will not contribute any Content or User Submission (each of those terms is defined below) or otherwise use the Product or interact with the Product in a manner that:
                            </p>
                            <ul>
                                <li>
                                    Infringes or violates the intellectual property rights or any other rights of anyone else (including Edvantum);
                                </li>
                                <li>
                                    Is harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable;
                                </li>
                                <li>
                                    Jeopardizes the security of your Edvantum account or anyone else's;
                                </li>
                                <li>
                                    Attempts, in any manner, to obtain the password, account, or other security information from any other user or copy & store any significant portion of the content;
                                </li>
                                <li>
                                    Violates the security of any computer network, or cracks any passwords or security encryption codes. Or run any processes that run or are activated while you are not logged into the Product, or that otherwise interfere with the proper working of the Product;
                                </li>
                                <li>
                                    "Crawls", "scrapes" or "spiders" any page, data, or portion of or relating to the Product or Content (through use of manual or automated means).
                                </li>
                                <li>
                                    Decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the Product.
                                </li>
                                <li>
                                    A violation of any of the foregoing is grounds for termination of your right to use or access the Product.
                                </li>
                            </ul>

                            <h3>What are my rights in Edvantum?</h3>
                            <p>
                                The information displayed in any form or available on or through the Product, including, but not limited to, text, graphics, data, articles, photos, images, illustrations, User Submissions and so forth (all of the foregoing, the "Content") are protected by copyright and/or other intellectual property laws. You promise to abide by all copyright notices, trademark rules, information, and restrictions contained in any content you access through the Product, and you won't use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell or otherwise exploit for any purpose any Content not owned by you, (i) without the prior consent of the owner of that Content or (ii) in a way that violates someone else's (including Edvantum') rights. You understand that Edvantum owns the Product. You won't modify, publish, transmit, participate in the transfer or sale of, reproduce (except as expressly provided in this Section), create derivative works based on, or otherwise exploit any of the Product. The Product may allow you to copy or download certain Content; please remember that just because this functionality exists, doesn't mean that all the restrictions above don't apply; they do!
                            </p>

                            <h3>Do I have to grant any licenses to Edvantum or to other users?</h3>
                            <ul>
                                <li>
                                    Anything you post, upload, share, store, or otherwise provide through the Product is your "User Submission." Some User Submissions are viewable by other users. In order to display your User Submissions on the Product, and to allow other users to view them (where applicable), you grant us certain rights in those User Submissions. Please note that all of the following licenses are subject to our Privacy Policy to the extent they relate to User Submissions that are also your personally-identifiable information.
                                </li>
                                <li>
                                    You retain all ownership rights in your User Submissions. For all User Submissions, you hereby grant Edvantum a license to translate, modify (for technical purposes, for example making sure your content is viewable on an iPhone as well as a computer) and reproduce and otherwise act with respect to such User Submissions, in each case to enable us to operate the Product, as described in more detail below.
                                </li>
                                <li>
                                    If you share a User Submission only in a manner that only certain specified users can view (for example, a private message to one or more other users) (a "Limited Audience User Submission"), then you grant Edvantum the licenses above, as well as a license to display, perform, and distribute your Limited Audience User Submission for the sole purpose of making that Limited Audience User Submission accessible to such other specified users, and providing the Product necessary to do so. Also, you grant such other specified users a license to access that Limited Audience User Submission, and to use and exercise all rights in it, as permitted by the functionality of the Product.
                                </li>
                                <li>
                                    If you share a User Submission publicly on the Product and/or in a manner that more than just you or certain specified users can view, or if you provide us (in a direct email or otherwise) with any feedback, suggestions, improvements, enhancements, and/or feature requests relating to the Product (each of the foregoing, a "Public User Submission"), then you grant Edvantum the licenses above, as well as a license to display, perform, and distribute your Public User Submission for the purpose of making that Public User Submission accessible to all Edvantum users and providing the Product necessary to do so, as well as all other rights necessary to use and exercise all rights in that Public User Submission in connection with the Product and/or otherwise in connection with Edvantum business, provided that Edvantum will try to notify you if it uses your Public User Submission for any reason other than displaying it on the Product. Also, you grant all other users of the Product a license to access that Public User Submission, and to use and exercise all rights in it, as permitted by the functionality of the Product.
                                </li>
                                <li>
                                    You agree that the licenses you grant are royalty-free, perpetual, sublicensable, irrevocable, and worldwide. When you delete your Edvantum account, we will stop displaying your User Submissions (other than Public User Submissions, which may remain fully available) to other users (if applicable), but you understand and agree that it may not be possible to completely delete that content from Edvantum records, and that your User Submissions may remain viewable elsewhere to the extent that they were copied or stored by other users.
                                </li>
                                <li>
                                    Finally, you understand and agree that Edvantum, in performing the required technical steps to provide the Product to our users (including you), may need to make changes to your User Submissions to conform and adapt those User submissions to the technical requirements of connection networks, devices, Product, or media, and the foregoing licenses include the rights to do so.
                                </li>
                            </ul>

                            <h3>Who is responsible for what I see and do on the Product?</h3>
                            <p>
                                Any information or content publicly posted or privately transmitted through the Product is the sole responsibility of the person from whom such content originated, and you access all such information and content at your own risk, and we aren't liable for any errors or omissions in that information or content or for any damages or loss you might suffer in connection with it. We cannot control and have no duty to take any action regarding how you may interpret and use the Content or what actions you may take as a result of having been exposed to the Content, and you hereby release us from all liability for you having acquired or not acquired Content through the Product. We can't guarantee the identity of any users with whom you interact in using the Product and are not responsible for which users gain access to the Product.
                            </p>
                            <br />
                            <p>
                                You are responsible for all Content you contribute, in any manner, to the Product, and you represent and warrant you have all rights necessary to do so, in the manner in which you contribute it. You will keep all your registration information accurate and current. You are responsible for all your activity in connection with the Product.
                            </p>
                            <br />
                            <p>
                                Your interactions with organizations and/or individuals found on or through the Product, including payment and delivery of goods or Product, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such organizations and/or individuals. You should make whatever investigation you feel necessary or appropriate before proceeding with any online or offline transaction with any of these third parties. You agree that Licensor shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings.
                            </p>

                            <h3>Will Edvantum ever change the Product?</h3>
                            <p>
                                We're continually endeavoring to enhance our Product, so they may change after some time. We may suspend or seize any piece of the Product, or we may present new highlights or force confines on specific highlights or limit access to parts or the greater part of the Product. We'll attempt to give you a see when we roll out a material improvement to the Product that would antagonistically influence you, yet this isn't generally down to earth. Likewise, we claim all authority to expel any content from the Product whenever, for any reason (counting, however not restricted to, on the off chance that somebody affirms you contributed that content infringing upon these Terms), in our sole circumspection, and without take note.
                            </p>

                            <h3>Does Edvantum cost anything?</h3>
                            <p>
                                The Licensor is providing the Edvantum school management product suite for FREE for certain kind of businesses in certain parts of the world and this may change from time to time. Any new features or enhancements or services may attract additional charges for using them, which is anyway optional to subscribe to. All the latest offers and pricing can be checked out on the apps or on website pricing section or you can write to us to <a href="mailto:info@edvantum.com">info@edvantum.com</a>.
                            </p>

                            <h3>What if I want to stop using Edvantum?</h3>
                            <p>
                                You're free to do that at any time, by contacting us at <a href="mailto:info@edvantum.com">info@edvantum.com</a>; please refer to our privacy policy, as well as the licenses above, to understand how we treat information you provide to us after you have stopped using our Product.
                                If you have paid for an annual contract with us, any fees already paid are non-refundable.
                                Licensor is also free to terminate (or suspend access to) your use of the Product or your account, for any reason in our discretion, including your breach of these Terms. Licensor has the sole right to decide whether you are in violation of any of the restrictions set forth in these Terms.
                                Account termination may result in destruction of any Content associated with your account, so bear that in mind before you decide to terminate your account. We will try to provide advance notice to you prior to our terminating your account so that you are able to retrieve any important User Submissions you may have stored in your account (to the extent allowed by law and these Terms), but we may not do so if we determine it would be impractical, illegal, not in the interest of someone's safety or security, or otherwise harmful to the rights or property of Licensor.
                                If you have deleted your account by mistake, contact us immediately at <a href="mailto:info@edvantum.com">info@edvantum.com</a> - we will try to help, but unfortunately, we can't promise that we can recover or restore anything.
                                Provisions that, by their nature, of these Terms shall survive termination. By way of example, all of the following will survive termination: any obligation you have to pay us or indemnify us, any limitations on our liability, any terms regarding ownership of intellectual property rights, and terms regarding disputes between us.
                            </p>

                            <h3>Terms & Conditions</h3>
                            <br />
                            <h4>1. INDEMNIFICATION</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        You agree to indemnify and hold harmless Licensor, its affiliates, officers, directors, employees, consultants, licensors, agents, and representatives from any and all third party claims, losses, liability, damages, and/or costs (including reasonable lawyer fees and costs) arising from your access to or use of Product, violation of these Terms of Use, or infringement, or infringement by any other user of his/her/its account, of any intellectual property or other right of any person or entity.
                                    </li>
                                </ul>
                            </div>

                            <h4>2. LIMITATION OF LIABILITY</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        In no event, shall Licensor, or any of its directors, officers, employees, agents or content or service providers (collectively, the "Protected Entities") be liable for any direct, indirect, special, incidental, consequential, exemplary or punitive damages arising from, or directly or indirectly related to, the use of, or the inability to use the Website or the App or the content, materials and functions related thereto, the Product, user's provision of information via the Website, lost business, even if such Protected Entity has been advised of the possibility of such damages.
                                    </li>
                                    <li>
                                        In no event shall the total aggregate liability of the Protected Entities to a user for all damages, losses, and causes of action (whether in contract or tort, including, but not limited to, negligence or otherwise) arising from these Terms of Use or a user's use of the Website/App or the Product exceed the total price actually paid by such user for booking/reserving an Accommodation through the Website/App.
                                    </li>
                                </ul>
                            </div>

                            <h4>3. INTELLECTUAL PROPERTY RIGHTS</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        All rights to the Product, its contents and Documentation provided therewith, including title, ownership rights and Intellectual Property Rights therein shall remain the sole and exclusive property of the Licensor. Licensee shall not own any Intellectual Property Rights with respect to the Product, customization, training material and accompanying Documentation, including Licensor's confidential information under this Agreement. Licensee shall own all Intellectual Property Rights with respect to data and other relevant information provided by Licensee.
                                    </li>
                                    <li>
                                        Notwithstanding anything contained in this Agreement or otherwise, nothing in this Agreement shall be construed as a transfer in the Intellectual Property Rights of the Licensor to the Licensee.
                                    </li>
                                    <li>
                                        The Licensee shall not use the Intellectual Property Rights of the Licensor without the prior written consent of the Licensor. Contravention of this provision shall be construed as a material breach of this Agreement.
                                    </li>
                                </ul>
                            </div>

                            <h4>4. DISCLAIMER OF WARRANTIES</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        Licensee hereby expressly agrees and acknowledges that, except as provided in this Agreement, the Product is provided "as is" and "as available" without any representations, conditions, warranties or covenants whatsoever with respect to the Product, including without limitation, any express, statutory or implied representations, warranties or conditions of merchantability, merchantable quality, satisfactory quality or fitness for a particular purpose, or arising otherwise in law or from a course of dealing or usage of trade, all of which are expressly disclaimed and excluded. The Licensor does not warrant that the Product shall be error free or will perform in an uninterrupted manner.
                                    </li>
                                </ul>
                            </div>

                            <h4>5. ARBITRATION</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        In the event of any dispute arising out of or in relation to this Agreement, the Parties shall try and resolve the dispute amicably in good faith through negotiations. In case the dispute is not resolved within a reasonable time, the Parties agree to submit the same for arbitration to a sole arbitrator, mutually appointed by the Parties. In the event that the Parties cannot agree upon a sole arbitrator, each Party shall appoint an arbitrator and the two arbitrators shall mutually appoint the third presiding arbitrator. The arbitration proceedings shall be governed by the provisions of the Arbitration and Conciliation Act, 1996, as amended. Arbitration proceedings shall be conducted in the English language and the venue shall be Hyderabad, Telangana, India. The award of the arbitration proceedings shall be final and binding on the Parties.
                                    </li>
                                </ul>
                            </div>

                            <h4>6. GOVERNING LAW AND JURISDICTION</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        This Agreement shall be governed by and construed under the laws of India. Subject to arbitration clause above, any dispute arising out of or in relation to this Agreement shall be submitted to the sole jurisdiction of the courts of law at Hyderabad, Telangana, India.
                                    </li>
                                </ul>
                            </div>

                            <h4>7. FORCE MAJEURE</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        Without limiting the foregoing, under no circumstances shall Licensor be held liable for any damage or loss due to deficiency in performance of the Product resulting directly or indirectly from acts of nature, forces, or causes beyond its reasonable control, including, without limitation, internet failures, computer equipment failures, telecommunication equipment failures, power failures, internet outage, or any other government regulations, floods, storms, electrical failure, civil disturbances, riots, etc.
                                    </li>
                                </ul>
                            </div>

                            <h4>8. NOTICES</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        Any and all notices that either Party is required or may desire to give to the other Party hereunder, shall be given by addressing the communication to the address set forth at the start of this Agreement, and shall be served by speed post or registered post. Any change to the above-mentioned address shall be informed to other Party in writing, within thirty (30) days.
                                    </li>
                                </ul>
                            </div>

                            <h4>9. ASSIGNMENT</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        The Licensor shall assign this Agreement and its rights and obligations hereunder in whole, but not in part, to any corporation or other entity with or into which the Company may hereafter merge or consolidate or to which the Company may transfer all or substantially all of its assets, if in any such case said corporation or other entity shall by operation of law or expressly in writing assume all obligations of the Company hereunder as fully as if it had been originally made a party hereto; the Licensor may not otherwise assign this Agreement or its rights and obligations hereunder.
                                    </li>
                                </ul>
                            </div>

                            <h4>10. SEVERABLILITY</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        The provisions of this Agreement shall be deemed severable, and the invalidity or unenforceability of any provision (or part thereof) of this Agreement shall in no way affect the validity or enforceability of any other provision (or remaining part thereof).
                                    </li>
                                </ul>
                            </div>

                            <h4>11. WAIVER</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        No delay or failure of either Party in exercising any right and no partial or single exercise of any right shall be deemed to constitute a waiver of that right or any other rights under this agreement
                                    </li>
                                </ul>
                            </div>

                            <h4>12. AMENDMENT</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        This Agreement cannot be altered, or modified except by a written agreement or addendum duly signed by authorized representatives of the Parties.
                                    </li>
                                </ul>
                            </div>

                            <h4>13. ENTIRE AGREEMENT</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        This Agreement contains the entire agreement and understandings by and between the Parties with respect to the covenants herein described, and no representations, promises, agreements or understandings, written or oral, not herein contained shall be of any force or effect.
                                    </li>
                                </ul>
                            </div>

                            <h4>14. THIRD PARTY LINKS</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        The Website or the App may contain links belonging to third parties and the user acknowledges that Licensor does not endorse any of these third party links or the Product or goods provided by way of these links. Licensor will not be liable for the nature of the content provided on any of these third party links or the privacy practices followed by these third parties. Licensor does not provide any warranty with regard to the materials posted on such third party websites or the Product offered by them and disclaims all responsibility for the quality of the Product offered by any of these linked sites.
                                    </li>
                                </ul>
                            </div>

                            <h4>15. THIRD PARTY CONTROL</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        The flow of data over the internet depends in large part on the performance of connections and Product provided by third parties. Failure of such third parties to perform their Product correctly can disrupt internet sessions, thereby interrupting the Product. Licensor cannot guarantee such events will not occur, and disclaims any and all liability, claims or losses resulting from or relating to such events.
                                    </li>
                                </ul>
                            </div>

                            <h4>16. GRIEVANCE REDRESSAL</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        If you believe that your rights have been violated by any Product or have any other grievance due to use of the Website and/or App, contact us at <a href="mailto:info@edvantum.com">info@edvantum.com</a> to redress the same.
                                    </li>
                                </ul>
                            </div>

                            <h4>17. CONFIDENTIALITY</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        The Licensor shall keep all information including documentation, methods, processes, data of the Users shared with respect to the Agreement as Proprietary of the Licensee and treat the same as confidential and shall not disclose such information to any third party, except, disclose pertinent aspects of the Agreement to government and / or regulatory authorities, as required by law.
                                    </li>
                                    <li>
                                        Licensor shall not disclose, provide or otherwise make available the Product to any person other than the employees of Licensor whose use of or access to the Product is necessary to the continuity of the Product under this agreement.
                                    </li>
                                </ul>
                            </div>

                            <h4>18. DELIVERY</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        The Product (Web and Mobile applications including any enhancements, upgrades or updates) shall be transmitted by the Licensor to the Licensee through Play store / App store /Edvantum.com website.
                                    </li>
                                </ul>
                            </div>

                            <h4>19. TERM OF THE AGREEMENT</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        The Terms of this Agreement shall commence from the date of subscription (signup) and continue to be in force till the time licensee uses the product or services of Licensor.
                                    </li>
                                    <li>
                                        Either Party may terminate this Agreement in the event of the other Party is adjudicated bankrupt or becomes insolvent, makes any assignment for the benefit of creditors, proceedings are instituted by the other party seeking relief, reorganization or rearrangement under any laws relating to insolvency, bankruptcy or similar laws of any jurisdiction, a receiver, liquidator or trustee is appointed in respect of any property or assets of the other party or an order is made for the liquidation, dissolution or winding up of the other party
                                    </li>
                                    <li>
                                        The Licensor reserves its right to terminate this Agreement with notice of thirty (30) days, in the event that the Licensee makes illegal or unauthorized use of the Product as referred in this agreement.
                                    </li>
                                    <li>
                                        Upon termination or expiration of this Agreement, Licensee shall cease all use of the Product, and all related documentation including all confidential information in Licensee's possession and shall so certify in writing to the Licensor forthwith / immediately.
                                    </li>
                                </ul>
                            </div>

                            <h4>20. LICENSE</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        Grant of license : Subject to the terms and conditions of this Agreement Licensor hereby grants Licensee a non-exclusive, non-transferable, revocable license to use the Product for the Term. The Licensee shall be provided access to the Product, and the Licensee may authorize its employees, authorized users and/or franchisees/franchisors to access it. Licensee shall be responsible for use or misuse of the Product by its Authorized Users. The Licensee understands and acknowledges that the Licensee shall not have the right to sub-license or re-distribute the Product to third parties, without prior written permission of the licensor. Nothing in this Agreement shall be considered as a grant of title or ownership of the Product to the Licensee.
                                    </li>
                                    <li>
                                        Scope of license : In order to use the Product, you may be required to Signup for a User Account with password (User Account). At the time of signup, you shall be required to share information which may include personal information (Personal Information) including but not limited to information regarding Licensee's e-mail ID, name and complete address, institution details, and other relevant details. Licensee agrees that any registration information provided by the User shall always be duly verified accurate, correct and complete.
                                    </li>
                                    <li>
                                        Obligations of Licensee : Use of the Product is based on Licensee obtaining and maintaining access to the Internet, and all equipment necessary for proper operation of the Product. Licensee is required to maintain and use secure passwords, if any, for the access and use of the Product. Licensee is responsible for all activities that occur under Licensee's account on the Product. Licensee shall be solely responsible for ensuring the security and confidentiality of such user names and passwords, if any. Licensee undertakes to prevent unauthorized access to, or use of, the Product and shall notify Licensor immediately of any such unauthorized use or other known breach of security. Licensee shall report to Licensor immediately and stop immediately any copying or distribution of the Product that is known or suspected by Licensee. Licensee shall abide by the Privacy Policy on Licensor's website (https://www.edvantum.com/) and Licensor reserves the right to update such policy as set forth therein. Any use of the Product in violation of Licensor's Privacy Policy shall be a material breach of this Agreement. Licensee shall be solely responsible for providing all relevant data required for the proper operation of the Product. Licensee shall be solely responsible for the accuracy, quality, integrity, reliability, appropriateness, and intellectual property ownership or right to use such data. Licensor is under no obligation and shall not be liable to review such data for accuracy, acceptability or potential liability. Licensee grants to Licensor all necessary licenses in and to such data solely as necessary for Licensor to provide the Product to Licensee.
                                    </li>
                                    <li>
                                        Product Support : Licensor will provide support for the Product during the business hours (9:00 AM till 6:00 PM in IST) on working days (Monday till Friday in IST, except Holidays as decided by the Licensor). Support shall be in the form of Chat or Email and the Licensor will try to respond on the same Business Day.
                                    </li>
                                    <li>
                                        Supported Devices : The Product can be used only on those devices which are permitted by Android and iOS in consonance with their operating system and Software Development Kit (SDK) updates.
                                    </li>
                                    <li>
                                        Notifications: Push notifications delivery is dependent on device settings. The settings might vary from device to device. The Licensor is not responsible for non-delivery/delay of notifications.
                                    </li>
                                    <li>
                                        Updates / Upgrades : In the event of any bug in the product (as determined by the Licensor), Licensor agrees to release updates to the existing apps for no extra charge in the subsequent releases. In the event that the Licensor launches new updates in the App store / Play store i.e., new features on top of existing system, Licensee may subscribe to such upgrades at an additional fee as intimated by the licensor from time to time.
                                    </li>
                                </ul>
                            </div>

                            <h4>21. PAYMENT</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        The Licensor is providing all the features in Edvantum school management product suite (both the school and parent facing apps) for FREE. There is no need of making any payment to use the product.
                                    </li>
                                    <li>
                                        The Licensor shall launch additional products or services or new features in the same product. Some of these may be premium/paid services. Subscribing to such products/services/features is OPTIONAL.
                                    </li>
                                </ul>
                            </div>

                            <h4>22. CANCELLATION/REFUND POLICY</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        The Licensor is providing a facility in the parent facing app to pay the school fee online. Paid fee in this manner is however is directly deposited into the corresponding school's gateway account. Edvantum is in no way involved with the amounts thus paid by the parents and settled by the payment gateway and hence carries no obligation towards any cancellations and will not get into the refunds processing, if any by the schools.
                                    </li>
                                    <li>
                                        The Licensor doesn't deal with any refunds/cancelations being a school management app and hence hasn't added any such policies/clauses on its website
                                    </li>
                                </ul>
                            </div>

                            <h4>23. CURRENT, GRADUATED and ARCHIVED DATA</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        All the Data is stored on the cloud (AWS by Amazon and Firebase by Google) and is governed by the policies of these cloud providers.
                                    </li>
                                    <li>
                                        Access to Graduation and Archived Data Store is a complimentary feature offered by the Licensor currently and can be made a premium/paid feature in future to bear the costs of maintaining the same on the cloud storage.
                                    </li>
                                    <li>
                                        If a school doesn't want to continue using the product, they can download the data as permitted in the product. Licensee will get a week's time (7 days) for this purpose beyond the subscription expiry date before the Licensee's(school) account is set for deletion.
                                    </li>
                                    <li>
                                        Data deletion by the Licensee or its authorized users is permanent in nature and it can't be restored.
                                    </li>
                                    <li>
                                        Franchisors shall obtain an Acceptance/NOC from the individual schools for accessing their data either in aggregate or granular form.
                                    </li>
                                </ul>
                            </div>

                            <h4>24. TRAINING, ONBOARDING, SUPPORT and MAINTENANCE</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        Licensor shall provide some or all of these resources required for a smooth onboarding of the users in the app itself - Explainer screens, FAQs, Demo/Training Videos.
                                    </li>
                                    <li>
                                        No manual training or user manual or presentations are needed for using the product and hence shall not be entertained.
                                    </li>
                                    <li>
                                        One time bulk upload of Children Profiles, using the data provided by the Licensee in the format given by the Licensor will be done. Any additional such requests shall attract charges as decided by the Licensor.
                                    </li>
                                    <li>
                                        Licensor will provide support for any queries arising from Product usage by the Licensee or the school staff during the business hours (9:00 AM till 6:00 PM in IST) on working days (Monday till Friday, except Holidays as per Licensor's Policy).
                                    </li>
                                    <li>
                                        Support shall be in the form of Chat or Email and the Licensor shall try to respond on the same Business Day.
                                    </li>
                                    <li>
                                        In the event of any bug in the product (determined as a bug by the Licensor), Licensor agrees to release updates to the existing apps for no extra charge in the subsequent releases.
                                    </li>
                                </ul>
                            </div>
                            <h4>24. TRAINING, ONBOARDING, SUPPORT and MAINTENANCE</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        Licensor shall provide some or all of these resources required for a smooth onboarding of the users in the app itself - Explainer screens, FAQs, Demo/Training Videos.
                                    </li>
                                    <li>
                                        No manual training or user manual or presentations are needed for using the product and hence shall not be entertained.
                                    </li>
                                    <li>
                                        One time bulk upload of Children Profiles, using the data provided by the Licensee in the format given by the Licensor will be done. Any additional such requests shall attract charges as decided by the Licensor.
                                    </li>
                                    <li>
                                        Licensor will provide support for any queries arising from Product usage by the Licensee or the school staff during the business hours (9:00 AM till 6:00 PM in IST) on working days (Monday till Friday, except Holidays as per Licensor's Policy).
                                    </li>
                                    <li>
                                        Support shall be in the form of Chat or Email and the Licensor shall try to respond on the same Business Day.
                                    </li>
                                    <li>
                                        In the event of any bug in the product (determined as a bug by the Licensor), Licensor agrees to release updates to the existing apps for no extra charge in the subsequent releases.
                                    </li>
                                </ul>
                            </div>

                            <h4>25. PRODUCT ENHANCEMENTS</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        In the event that the Licensor launches new updates in the App store / Play store i.e., new features on top of existing system, Licensee may subscribe to such new features paying a fee as intimated by the licensor during the launch.
                                    </li>
                                    <li>
                                        Wherever Licensor agreed to white-label the Parents-facing app, it is subject to approval by the Play store / App store either during a new release or subsequent upgrade version release.
                                    </li>
                                    <li>
                                        Licensor is not liable to refund any cost incurred and collected from the Licensee for white labeling and also can't not be held responsible in that eventuality.
                                    </li>
                                    <li>
                                        Any customization of features by the Licensor for a Licensee is chargeable and is decided by the Licensor and agreed by the Licensee.
                                    </li>
                                </ul>
                            </div>

                            <h4>26. THIRD PARTY INTEGRATIONS</h4>
                            <div style={myInlineStyles}>
                                <ul>
                                    <li>
                                        CCTV Access - While Edvantum gives Schools an option to share the CCTV live links with the parents, Edvantum is in no measure responsible for the working, quality and correctness of the same. Schools have to ensure that they are sharing the correct links after thorough testing and verification
                                    </li>
                                    <li>
                                        Payment Gateway Integration - Once the school registers with the Payment Gateways that are integrated with Edvantum, schools shall directly work with the concerned Payment Gateway company in case of any issues with the setup, working, transactions and settlements
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default TermsOfUse;