import React, { createContext } from "react";
import ReactDOM from "react-dom";
//import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import History from "./helpers/history";
import { Router } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HelmetProvider } from "react-helmet-async";

import configureStore from "./redux/configure";
import { Provider } from 'react-redux';
// import "./assets/styles/web/imagehover.css";
// import "./assets/styles/web/style.css";
const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Router history={History}>
      <Provider store={store}>
        <App />
        <ToastContainer />
      </Provider>
      </Router>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);


serviceWorker.unregister();