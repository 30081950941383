import BaseApi from "./baseApi";

class Performance extends BaseApi {
    async getPerformances() {
        return await this.API(
            {
                url: "/performance/getperformances",
                method:"get",
            }
        )
    }

    async createPerformance(data) {
        return await this.API(
            {
                url: "/performance/createperformance",
                data: data,
                method:"post"
            }
        )
    }

    async editPerformance(data) {
        return await this.API(
            {
                url: "/performance/updateperformance",
                data: data,
                method:"post"
            }
        )
    }

    async deletePerformance(data) {
        return await this.API(
            {
                url: "/performance/deleteperformance",
                data: { _id: data },
                method:"post"
            }
        )
    }
    
}

export default Performance;