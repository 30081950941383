const StudentFields = {
  _id: "",
  firstName: "",
  lastName: "",
  dob: "",
  gender: "",
  photoUrl: "",
  mobile: "",
  ParentsDetail: {
    firstName: "",
    lastName: "",
  },
  schoolId: "",
  classId: "",
  className: "",
  sportsActivityIds: "",
  address: {
    country: "India",
    state: "",
    city: "",
    pinCode: "",
    address: ""
  },

};

const classes = [{
    className: 'Play-way',
    index: 0
},
{
    className: 'Pre-nursery',
    index: 1
},
{
    className: 'Nursery',
    index: 2
},
{
    className: 'LKG',
    index: 3
},
{
    className: 'UKG',
    index: 4
},
{
    className: 'I',
    index: 5
},
{
    className: 'II',
    index: 6
},
{
    className: 'III',
    index: 7
},
{
    className: 'IV',
    index: 8
},
{
    className: 'V',
    index: 9
},
{
    className: 'VI',
    index: 10
}, {
    className: 'VII',
    index: 11
}, {
    className: 'VIII',
    index: 12
}, {
    className: 'IX',
    index: 13
},
{
    className: 'X',
    index: 14
},
{
    className: 'XI',
    index: 15
},
{
    className: 'XII',
    index: 16
}];

module.exports = {
  StudentFields,
  classes
}
