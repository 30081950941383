import React, { useEffect, useState } from "react";
import CSVReader from "react-csv-reader";
import { CSVLink } from "react-csv";
import moment from "moment";
//import { toast } from "react-toastify";
import { toast } from "react-toastify";
import { Form, Modal, Button } from "react-bootstrap";
import FeeDataApi from "../../api/feeDataApi";
const FeeData = () => {
  const [isChooseFileDisplay, setIsChooseFileDisplay] = useState(false);
  const [IsRulesShow, setIsRulesShow] = useState(false);
  function downloadFeeDataCSVFormat() {
    let headers = [
      { label: "Class Name", key: "className" },
      { label: "Session", key: "session" },
      { label: "Bus Fee", key: "busFee" },
    ];
    return (
      <Modal show={IsRulesShow} onHide={() => setIsRulesShow(false)}>
        <Modal.Header style={{ backgroundColor: "#af0000" }} closeButton>
          <Modal.Title style={{ color: "beige" }}>Warning!</Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{
            minHeight: "133px",
            textAlign: "center",
            backgroundColor: "white",
          }}
        >
          <ul className="list-group list-group-flush">
            <li className="list-group-item text-left">
              First upload the Classes data & Students data then upload the Fee Data .
            </li>
            <li className="list-group-item text-left">
              Please insert other Fees with Title & Value after Session Column.
            </li>
            <li className="list-group-item text-left">
              Any Date format should be MM-DD-YYYY
            </li>
          </ul>
        </Modal.Body>

        <Modal.Footer style={{ backgroundColor: "white" }}>
          <CSVLink
            data={[{ className: "II", session: "2021-2022", busFee: "2500" }]}
            filename={"School_Sample_Fee_Data_Import.csv"}
            headers={headers}
            className="btn btn-sm btn-primary"
          >
            <i className="mdi mdi-download"></i> Download Sample CSV
          </CSVLink>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() => setIsRulesShow(false)}
            style={{ padding: "16px" }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  async function uploadCsvFile(feeData) {
    let classNameColumnIndex = -1;
    let sessionColumnIndex = -1;
    let particularColumnIndex = -1;
    let amountColumnIndex = -1;
    let imported = [];
    let feesDetails = [];
    let firstRowIndex = -1;
    feeData.every(async (row, index) => {
      let totalAmount = 0;
      let feesDetails = [];
      row.forEach(function (column, columnIndex) {
        if (columnIndex > 1) {
          let obj = {};
          if (index >= firstRowIndex) {
            obj["particular"] = feeData[0][columnIndex];
            obj["amount"] = row[columnIndex];
            if (row[columnIndex]) {
              totalAmount += parseInt(row[columnIndex]);
            }

            feesDetails.push(obj);
          }
        }
      });
      if (classNameColumnIndex == -1 && row.indexOf("Class Name") > -1) {
        classNameColumnIndex = row.indexOf("Class Name");
      }
      if (sessionColumnIndex == -1 && row.indexOf("session") > -1) {
        sessionColumnIndex = row.indexOf("Section");
      }
      if (firstRowIndex === -1) {
        firstRowIndex = index + 1;
      }

      if (index >= firstRowIndex) {
        let className = row[classNameColumnIndex];
        let session = row[sessionColumnIndex];
        if (!className) {
          // blank row
          return false;
        }
        if (!className) {
          toast("Class Name is missing in line number " + (index + 1), {
            type: "error",
          });
          imported = [];
          return false;
        } else {
          className = className.trim();
        }
        if (!session) {
          let year = new Date().getFullYear();
          session = year + "-" + parseInt(year + 1);
        }
        imported.push({
          className,
          session,
          feesDetails,
          totalAmount,
        });
      }
    });
    saveFeeDataCsv(imported);
  }

  async function saveFeeDataCsv(imported) {
    if (window.confirm("Are your sure for upload the fee data csv file ?")) {
      let Api = new FeeDataApi();
      await Api.saveFeeDataCsv(imported)
        .then(async (response) => {
          toast.success("Csv file is save successfully");
          window.location.reload(false);
        })
        .catch((error) => {
          toast.error(error.toString());
          console.error("There is an error!", error);
        });
    }
  }

  function uploadFeeDataCsv() {
    return (
      <CSVReader
        onFileLoaded={(studentsData) => uploadCsvFile(studentsData)}
        inputStyle={{ fontSize: "12px" }}
      />
    );
  }

  return (
    <>
      <div className="col-md-12 textRight">
        <a href="javascript:void(0)" onClick={() => setIsChooseFileDisplay(!isChooseFileDisplay)}>
          <><i className="mdi mdi-apple-keyboard-caps"></i> Import Fee Data </>
        </a>
        <a className="text-sm" href="javascript:void(0)" onClick={(e) => setIsRulesShow(true)}>
          (Download Sample CSV)
        </a>
        {downloadFeeDataCSVFormat()}
      </div>
      <div className="col-md-4 text-sm">
        {downloadFeeDataCSVFormat()}
      </div>
      <div className="col-md-12 text-sm">
        <div>{isChooseFileDisplay ? uploadFeeDataCsv() : null}</div>
      </div>
    </>
  );
};

export default FeeData;
