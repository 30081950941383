import axios from "axios";
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL
class BaseApi {
  API = async function (
    options,
    authRequired = true,
    onUploadPrgress = undefined,
    onDownloadPrgress = undefined
  ) {
    let token = localStorage.getItem("token");
    let authHeader = "";

    if (authRequired && token) {
       authHeader = `Bearer ${token}`; /// Add header
    }

    let schoolId="";
    if(localStorage.getItem("schoolId")){
     schoolId = localStorage.getItem("schoolId");
    }

    const client = axios.create({
      baseURL: API_URL,
      headers: { authorization: authHeader,schoolId:schoolId},
      onUploadProgress: onUploadPrgress,
      onDownloadProgress: onDownloadPrgress,
    });

    const onSuccess = function (response) {
      return response.data;
    };

    const onError = function (error) {
      if (error.response) {
        toast(error.response.data.error_description, { type: "error" });
        // Request was made but server responded with something
        // other than 2xx
        if (error.response.status == 401 && authRequired) {
          localStorage.removeItem("user");
          window.location.reload();
        }
      } else {
        // Something else happened while setting up the request
        // triggered the error
      }
      return Promise.reject(error.response || error.message);
    };
    return client(options).then(onSuccess).catch(onError);
  };
  async uploadFile(data, onUploadPrgress) {
    return await this.API(
      { url: "/upload", data, method: "post" },
      true,
      onUploadPrgress
    );
  }
  toUTC(date) {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  }

  async getDefaultSchoolSettings() {
    return await this.API({
      url: '/getdefaultschoolsettings',
      method: 'get',
    });
  }

  async reorderMonthBySessionStart() {
    return await this.API({
      url: '/reordermonthbysessionstart',
      method: 'get'
    });
  }
}
export default BaseApi;