import BaseApi from "./baseApi";
class Concession extends BaseApi {
    async getConcession() {
        return await this.API(
            {
                url: "/concession/getconcessions",
                method:"get",
            }
        )
    }

    async createConcession(data) {
        return await this.API(
            {
                url: "/concession/createconcession",
                data: data,
                method:"post"
            }
        )
    }

    async editConcession(data) {
        return await this.API(
            {
                url: "/concession/updateconcession",
                data: data,
                method:"post"
            }
        )
    }

    async deleteConcession(data) {
        return await this.API(
            {
                url: "/concession/deleteconcession",
                data: { _id: data },
                method:"post"
            }
        )
    }
		
		async activateOrDactivate(data) {
				return await this.API(
						{
								url: "/concession/activeconcession",
								data: data,
								method:"post"
						}
				)
		}

}

export default Concession;