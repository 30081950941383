import BaseApi from "./baseApi";
class Coach extends BaseApi {

  async addCoach(data) {
    return await this.API({
      url: "/coach/addcoach",
      data: data,
      method: "post"
    })
  }

  async editCoach(data) {
    return await this.API(
      {
        url: "/coach/updatecoach",
        data: data,
        method: "post"
      }
    )
  }

  async deleteCoach(id) {
    return await this.API(
      {
        url: "/coach/deletecoach",
        data: { _id: id },
        method: "post"
      }
    )
  }

  async getCoaches() {
    return await this.API(
      {
        url: "/coach/getcoaches",
        method: "get"
      }
    )
  }

  async getCoach(id) {
    return await this.API({
      url: "/coach/getcoach/" + id,
      method: "get"
    })
  }

  async activateOrDactivate(data) {
    return await this.API(
      {
        url: "/coach/activeCoach",
        data: data,
        method: "post"
      }
    )
  }

  async getCoachleavesDetails(data) {
    return await this.API(
      {
        url: "/coach/leaveslist",
        data: data,
        method: "post"
      }
    )
  }

  async addCoachDetails(data) {
    return await this.API({
      url: "/coach/addcoachdetails",
      data: data,
      method: "post"
    })
  }

  async deleteSlot(id, coachId) {
    return await this.API(
      {
        url: "/coach/deleteslot",
        data: { _id: id, coachId: coachId },
        method: "post"
      }
    )
  }

  async getCoachDetails(coachId) {
    return await this.API({
      url: "/coach/getcoachdetails",
      data: coachId,
      method: "post",
    });
  }
  async updateCoachLeave(data) {
    return await this.API(
      {
        url: "/coach/acceptorrejectleave",
        data: data,
        method: "post"
      }
    )
  }

}
export default Coach;
