import BaseApi from "./baseApi";


class StudentApi extends BaseApi {

  async getStudentsList(limit, skip, filters, sreachKeyword, sortColName, sort) {
    return await this.API({
      url: "/student/getstudentslist",
      method: "post",
      data: {
        limit: limit,
        skip: skip,
        filters: filters,
        sreachKeyword: sreachKeyword,
        sortColName: sortColName,
        sort: sort
      }
    })
  }

  async exportStudents(filters) {
    return await this.API({
      url: "/student/exports",
      method: "post",
      data: {
        filters: filters
      }
    })
  }

  async saveStudentCsv(data) {
    return await this.API({
      url: "/student/savestudentscsv",
      method: "post",
      data: data
    })
  }

  async addStudent(data) {
    return await this.API({
      url: "/student/addstudent",
      data: data,
      method: "post"
    })
  }

  async getStudent(data) {
    return await this.API({
      url: "/student/getstudent/" + data,
      method: "get"
    })
  }

  async deleteStudent(data) {
    return await this.API({
      url: "/student/deletestudent",
      data: data,
      method: "post"
    })
  }

  async updateStudent(data) {
    return await this.API({
      url: "/student/updatestudent",
      data: data,
      method: "post"
    })
  }

  async activateOrDactivate(data) {
    return await this.API(
      {
        url: "/student/activeStudent",
        data: data,
        method: "post"
      }
    )
  }

  async removeUserData(data) {
    return await this.API({
      url: "/student/removeuserdata",
      data: data,
      method: "post"
    })
  }

}
export default StudentApi;
