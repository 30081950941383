import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import ActivitySchool from "../../../api/activitySchoolApi";
import StatusModal from "../../../components/alertPopup";
import Loader from "../../../components/loader";
import Grid from "../../../components/dataTable";
import DeleteModal from "../../../components/deleteAlertPopup";
import ExportActivitySchoolCSV from "../../../components/export/activitySchools";

const ManageSchoolForActivities = () => {
  let history = useHistory();
  const [data, setData] = useState({
    tooltipOpen: false,
    startstyle: {
      color: "red",
      fontSize: "14px",
    },
    isModalVisible2: false,
    isModalVisible3: false,
    rowPropsId: "",
  });

  const [mainRows, setMainRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [archiveListActive, setArchinveListActive] = useState(false);
  const [statusMsg, setStatusMsg] = useState({
    msg: "",
    buttonName: "",
  });
  const [selecteUser, setSelecteUser] = useState({});

  useEffect(() => {
    getList();
  }, []);


  // ======================================= coding part =======================================

  const getList = async () => {
    let list = [];
    setDataLoader(true);
    let ActivitySchoolApi = new ActivitySchool();
    await ActivitySchoolApi.getActivitySchoolList()
      .then(async (response) => {

        console.log("schools ==>",response.data);

        if (response.data && response.data.length) {
          await response.data.map((data) => {
            if (data.active && !archiveListActive) {
              list.push(data);
            } else if (!data.active && archiveListActive) {
              list.push(data);
            }
          });
          setMainRows(response.data);
          setRows(response.data);
          setSelectedRows(list);
        } else {

          setRows([]);
          setSelectedRows([]);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There is an error!", error);
      });
    setDataLoader(false);
  };

  const DeactieActionApi = async () => {
    let ActivitySchoolApi = new ActivitySchool();
    let da = { _id: selecteUser._id, activate: !selecteUser.active };
    await ActivitySchoolApi.activateOrDactivate(da)
      .then((response) => {
        if (response.success) {
          toast.success(response.msg);
        } else {
          toast.error(response.msg);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There is ERROR!", error);
      });
      handleClose();
    await getList();

  };

  const deleteActivitySchool = async () => {
    let ActivitySchoolApi = new ActivitySchool();
    isVisible2();
    await ActivitySchoolApi.deleteActivitySchool(data.rowPropsId)
      .then((response) => {
        toast.success(response.msg.toString());
      })
      .catch((error) => {
        console.error("There is ERROR!", error);
        toast.error(error.toString());
      });
    getList();
  };

  // ===================================/ coding part /=======================================

  const checkArchive = async (archive) => {
    let list = [];
    setArchinveListActive(archive);
    if (mainRows) {
      mainRows.map((data) => {
        if (data.active && !archive) {
          list.push(data);
        } else if (!data.active && archive) {
          list.push(data);
        }
      });
    }
    setSelectedRows(list);
  };

  const renderAction = (props) => {
    return (
      <span>
        <ReactTooltip id="Edit" type="warning" effect="solid">
          <span>Edit</span>
        </ReactTooltip>
        <button
          onClick={() =>
            history.push(`/manageactivityschool/addschooldetails?id=${props._id}`)
          }
          data-tip
          data-for="Edit"
          type="button"
          className="btn btn-outline-warning"
          style={{ padding: "8px" }}
        >
          <i className="mdi mdi-border-color" style={{ fontSize: "17px" }}></i>
        </button>
        <ReactTooltip id="Delete" type="error" effect="solid">
          <span>Delete</span>
        </ReactTooltip>
        <button
          onClick={() => {
            isVisible2(props._id);
            // deleteStaff(props._id)
          }}
          data-tip
          data-for="Delete"
          type="button"
          className="btn btn-outline-danger"
          style={{ padding: "8px" }}
        >
          <i className="mdi mdi-delete" style={{ fontSize: "17px" }}></i>
        </button>
      </span>
    );
  };

  const isVisible2 = (id = null) => {
    let d = { ...data };
    if (id) {
      d.rowPropsId = id;
    }
    setIsModalVisible2(!isModalVisible2);
    setData(d);
  };

  const DeactiveAction = (props, index) => {
    return (
      <span>
        <ReactTooltip
          id="activate"
          type={props.active ? "success" : "error"}
          effect="solid"
        >
          <span>Activate/Deactive</span>
        </ReactTooltip>
        <span
          data-tip
          data-for="activate"
          className={
            props.active
              ? "form-check form-check-success"
              : "form-check form-check-danger"
          }
          style={{ display: "inline-flex" }}
          onClick={() => {
            let d = { ...data };
            if (props.active) {
              setStatusMsg({
                msg: `Are you sure that you want to Deactivate ${props.schoolName}`,
                buttonName: "Deactivate",
              });
            } else {
              setStatusMsg({
                msg: `Are you sure that you want to Activate ${props.schoolName}`,
                buttonName: "Activate",
              });
            }
            d.isModalVisible3 = true;
            setData(d);
            setSelecteUser(props);
          }}
        >
          <label className="form-check-label">
            <input
              type="checkbox"
              className="form-check-input"
              checked={props.active}
            />
            <i className="input-helper"></i>
          </label>
        </span>
      </span>
    );
  };

  const handleClose = () => {
    let d = { ...data };
    d.selectedClass = "";
    d.isModalVisible2 = false;
    d.isModalVisible3 = false;
    setSelecteUser({});
    setStatusMsg({
      msg: "",
      buttonName: "",
    });
    setData(d);
  };

  const field = () => {
    const fields = [
      {
        name: "action",
        title: "",
        width: "0%",
        align: "center",
        render: DeactiveAction,
      },
      {
        title: "Activity School",
        field: "schoolName"
      },
      {
        title: "Location",
        field: "location"
      },
      {
        title: "Contact",
        field: "contact"
      },
      {
        title: "Mobile",
        field:"mobile"
      },
      {
        title: "Coaches",
        field: "coaches",
      },
      {
        name: "action",
        title: "Actions ",
        render: renderAction,
      },
    ];
    return fields;
  };

  return (
    <div>
      {dataLoader ? <Loader /> : null}
      <div className="page-header">
        <h3 className="page-title"> Manage Activity School</h3>
        <button
          className="btn btn-primary"
          onClick={() => {
            history.push("/manageactivityschool/addschooldetails");
          }}
        >
          Add Activity School
        </button>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="row mb-2" style={{justifyContent:"flex-end"}}>
              <div className="col-md-5 export">
                <ExportActivitySchoolCSV activitySchoolData={selectedRows} />
              </div>
              <div className="col-md-2 addBtn">
                <button
                  onClick={async () => {
                    checkArchive(!archiveListActive);
                  }}
                  type="button"
                  className="btn btn-outline-primary float-right"
                  style={{ padding: "8px" }}
                >
                  {archiveListActive ? "Active List" : "Archive List"}
                </button>
              </div>
            </div>
            <Grid columns={field()} rows={selectedRows} settings={{
                search: true,
                exportButton: false,
                sorting: true
              }} />
          </div>
        </div>
        {/* ============   Delete modal   ================ */}
        <DeleteModal
          show={isModalVisible2}
          isModalVisible2={isVisible2}
          deleteData={deleteActivitySchool}
        />
        {/* ============   Active/Inactive modal   ================ */}
        <StatusModal
          show={data.isModalVisible3}
          isModalVisible2={handleClose}
          deleteData={DeactieActionApi}
          body={statusMsg}
        />
      </div>

    </div>
  );
};

export default ManageSchoolForActivities;
