import React, { useEffect, useState } from 'react';
import MaterialTable from "material-table";
import { Link, useHistory } from "react-router-dom";
import { Form, Modal, Button } from 'react-bootstrap';
import ReactTooltip from "react-tooltip";
import Grid from "../../../components/dataTable";
import { toast } from "react-toastify";
import class4Employees from "../../../api/addclass4Employees";
import NonTeachingStaffRoles from "../../../api/nonTeachingStaffRolesApi";
import DeleteModal from "../../../components/deleteAlertPopup";
import StatusModal from '../../../components/alertPopup';
import Users from "../../../components/bulkupload/users";
import Loader from "../../../components/loader";
import ExportNonTeachingStaff from "../../../components/export/nonTeachingStaff";
const ManageClassForEmployees = () => {
  let history = useHistory();
  const [data, setData] = useState({
    records: [],
    tooltipOpen: false,
    startstyle: {
      color: 'red',
      fontSize: '14px'
    },
    isModalVisible: false,
    isModalVisible2: false,
    isModalVisible3: false,
    selectedClass: '',
    rowPropsId: "",
  })

  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dataLoader, setDataLoader] = useState([]);
  const [archiveListActive, setArchinveListActive] = useState(false)
  const [staffRoles, setStaffRoles] = useState([]);
  const [staffType, setStaffType] = useState([]);
  const [statusMsg, setStatusMsg] = useState({
    msg: "",
    buttonName: ""
  });
  const [selecteUser, setSelecteUser] = useState({})


  useEffect(() => {
    getList(archiveListActive);
    getStaffRoles();
  }, []);

  const getList = async (archive) => {
    setDataLoader(true);
    let Api = new class4Employees();
    let list = []
    await setArchinveListActive(archive)
    await Api.getClassEmployees().then(async (response) => {
      if (response.data && response.data.length) {
        if (!archive) {
          await response.data.map((data) => {
            if (data.active) {
              list.push(data)
            }
          })
        } else {
          await response.data.map((data) => {
            if (!data.active) {
              list.push(data)
            }
          })
        }
        setRows(list)
        setSelectedRows(list)
      } else {
        setRows([])
        setSelectedRows([])
      }
    }).catch((error) => {
      toast.error(error.toString());
    });
    setDataLoader(false);
  }

  const getStaffRoles = async (e) => {
    let staffRolesList = new NonTeachingStaffRoles();
    await staffRolesList.getRole().then(async (response) => {
        if (response.data && response.data.length) {
          setStaffRoles(response.data);
        }else{
          setStaffRoles([]);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  }

  const choosestafftype = async (e) => {
    let { name, value } = e.target;
    let selectedrow = [];
    await setStaffType(value);
    if (value) {
      await rows.map((datas) => datas.userRoles.subRole == value ? selectedrow.push(datas) : null)
    } else {
      selectedrow = rows
    }
    setSelectedRows(selectedrow)
  }

  const renderAction = (props) => {
    return (
      <span>
        <ReactTooltip id='Edit' type='warning' effect='solid'>
          <span>Edit</span>
        </ReactTooltip>
        <button onClick={() => history.push(`/addclass4employees?id=${props._id}`)} data-tip data-for="Edit" type="button" className="btn btn-outline-warning" style={{ padding: '8px' }}>
          <i className="mdi mdi-border-color" style={{ fontSize: '17px' }}></i>
        </button>

        <ReactTooltip id='Delete' type='error' effect='solid'>
          <span>Delete</span>
        </ReactTooltip>

        <button onClick={() => isVisible2(props._id)} data-tip data-for="Delete" type="button" className="btn btn-outline-danger" style={{ padding: '8px' }}>
          <i className="mdi mdi-delete" style={{ fontSize: '17px' }}></i>
        </button>
      </span>
    )
  }

  const isVisible2 = (id = null) => {
    let d = { ...data };
    if (id) {
      d.rowPropsId = id;
    }
    d.isModalVisible2 = !d.isModalVisible2;
    setData(d);
  }

  const DeactiveAction = (props, index) => {

    return (
      <span>
        <ReactTooltip id='activate' type={props.active ? 'success' : 'error'} effect='solid'>
          <span>Activate/Deactive</span>
        </ReactTooltip>
        <span
          data-tip data-for="activate"
          className={props.active ? "form-check form-check-success" : "form-check form-check-danger"}
          style={{ display: 'inline-flex' }}
          onClick={() => {
            let d = { ...data }
            if (props.active) {
              setStatusMsg({
                msg: `Are you sure that you want to Deactivate ${props.firstName}`,
                buttonName: "Deactivate"
              })
            } else {
              setStatusMsg({
                msg: `Are you sure that you want to Activate ${props.firstName}`,
                buttonName: "Activate"
              })
            }
            d.isModalVisible3 = true;
            setData(d);
            setSelecteUser(props)
          }}
        >
          <label className="form-check-label">
            <input type="checkbox" className="form-check-input" checked={props.active} />
            <i className="input-helper"></i>
          </label>
        </span>
      </span>
    )
  }

  const handleClose = () => {
    let d = { ...data };
    d.selectedClass = "";
    d.isModalVisible = false;
    d.isModalVisible2 = false;
    d.isModalVisible3 = false;
    setSelecteUser({});
    setStatusMsg({
      msg: "",
      buttonName: ""
    });
    setData(d)
  }

  const DeactieActionApi = async () => {
    let Api = new class4Employees();
    let da = { _id: selecteUser._id, activate: !selecteUser.active }
    handleClose();
    await Api.activateClassEmployees(da)
      .then((response) => {
        if (response.success) {
          toast.success(response.msg)
        } else {
          toast.error(response.msg)
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      })
    await getList(archiveListActive);
  }

  const NameHyperLink = (props) => {
    return (
      <span>
        <Link to={`/addclass4employees?id=${props._id}`}>
          {props.firstName} {props.lastName}
        </Link>
      </span>
    )
  }

  const UserRoles = (props) => {
    return (
      <span>
        {props.userRoles ? props.userRoles.subRole : null}
      </span>
    )
  }

  const deleteEmployee = async () => {
    let Api = new class4Employees();
    isVisible2()
    await Api.deleteClassEmploye(data.rowPropsId)
      .then((response) => {
        if (response.success) {
          toast.success(response.msg)
        } else {
          toast.error(response.msg)
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      })
    getList(archiveListActive)
  }
  const field = () => {
    const fields = [
      {
        name: "action",
        title: "",
        width: "0%",
        align: "center",
        render: DeactiveAction,
      },
      {
        title: "Name",
        render: NameHyperLink,
        field: "firstName"
      },
      {
        title: "Email",
        field: "email",
      },
      {
        title: "Moblie",
        field: "mobile",
      },
      {
        title: "Role",
        render: UserRoles
      },
      {
        name: "action",
        title: "Actions ",
        render: renderAction,
      },
    ];
    return fields;
  }

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Non-Teaching Staff </h3>
        <Link className="nav-link" to="/addclass4employees">
          <button type="submit" className="btn btn-primary">Add</button>
        </Link>
      </div>
      {dataLoader ? <Loader /> : null}
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="row">
                    <label className="col-sm-4 col-form-label">Select Role</label>
                    <div className="col-sm-8">
                      <select onChange={(e) => choosestafftype(e)} value={staffType} className="form-control">
                        <option value="">---------</option>
                        {staffRoles && staffRoles.map((datas) => {
                          return (
                            <option
                              key={datas.roleName}
                              value={datas.roleName}
                            >
                              {datas.roleName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="row mb-2">
              <div className="col-md-5 import">
                <Users />
              </div>
              <div className="col-md-5 export">
                <ExportNonTeachingStaff NonTeachingStaffData={selectedRows} />
              </div>
              <div className="col-md-2 addBtn">
                <button
                  onClick={async () => {
                    getList(!archiveListActive)
                  }}
                  type="button"
                  className="btn btn-outline-primary"
                  style={{ padding: "8px" }}
                >
                  {archiveListActive ? "Active List" : "Archive List"}
                </button>
              </div>
            </div>
            <Grid columns={field()} rows={selectedRows} />
          </div>
        </div>
      </div>
      <StatusModal show={data.isModalVisible3} isModalVisible2={handleClose} deleteData={DeactieActionApi} body={statusMsg} />
      <DeleteModal show={data.isModalVisible2} isModalVisible2={isVisible2} deleteData={deleteEmployee} />
    </div>
  )
}

export default ManageClassForEmployees;