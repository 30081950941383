let user = localStorage.getItem("user_info");
let addedByUserId = '';
if (user) {
  user = JSON.parse(user);
  addedByUserId = user.userId;
}

const address = {
  country: "India",
  state: "Punjab",
  city: "",
  pinCode: "",
  address: "",
  longitude: "",
  latitude: "",
  allowedRadius: "",
};

const SchoolFields = {
  _id: '',
  schoolName: "",
  address: address,
  schoolLogo: "",
  sportsActivityIds: [],
  adminInfo: {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
  },

};


module.exports = {
  SchoolFields,
}
