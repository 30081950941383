import React from "react";
import { CSVLink } from "react-csv";

const ExportActivityStudents = ({activityStudentData=null}) => {
    function downloadActivityStudentsCSVFormat() {
        let headers = [
            {label:"FirstName",key:"studentData.firstName"},
            {label:"LastName",key:"studentData.lastName"},
            {label:"Class",key:"studentData.className"},
            {label:"Mobile No",key:"studentData.mobile"},
            {label:"Activity",key:"activityData.activityName"},
            {label:"Fee status",key:"paymentStatus"}
          ];
          return (
            <CSVLink
              data={activityStudentData}
              filename={"Activity_Students.csv"}
              headers={headers}
            >
            <i className="mdi mdi-download"></i> Export Data
            </CSVLink>
          );
    }

    return (
      <div>{downloadActivityStudentsCSVFormat()}</div>
		);

}

export default ExportActivityStudents;