import React, { useEffect, useState } from "react";
import Grid from "../../../../components/dataTable";
import ReactTooltip from "react-tooltip";
import dummynonTeacherData from "../dummyData/nonTeacher";

const NonTeacherGrid = () => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    getDummyData()
  }, [])

  const getDummyData = () => {
    setRows(dummynonTeacherData);
  }

  const fields = () => {
    let fields = [
      {
        title: "Name",
        field: "name"
      },
      {
        title: "Designation",
        field: "designation",
      },
      {
        title: "Contact No",
        field: "contactNo"
      },
      {
        title: "Attendance",
        field: "atttendance"
      },
      {
        name: "action",
        title: "Actions ",
        render: renderAction,
      }
    ]
    return fields;
  }

  const renderAction = (props, index) => {
    return (
      <span>
        <ReactTooltip id="ViewReport" type="warning" effect="solid">
          <span>View Detail</span>
        </ReactTooltip>
        <button
          data-tip
          data-for="ViewReport"
          onClick={() => {
          }}
          type="button"
          className="btn btn-outline-warning mt-1"
          style={{ padding: "8px" }}
        >
          Detail
        </button>
      </span>
    )
  }

  return (
    <Grid columns={fields()} rows={rows} />
  )
}

export default NonTeacherGrid;