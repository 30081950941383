import React, { useEffect, useState } from 'react';
import Grid from "../../../components/dataTable";
import { Form, Modal, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from "react-toastify";
import SchoolUpdates from '../../../api/schoolUpdateApi';
import SimpleReactValidator from 'simple-react-validator';
import DeleteModal from "../../../components/deleteAlertPopup";
import StatusModal from '../../../components/alertPopup';
import Loader from "../../../components/loader";


const ManageUpdatesData = () => {
	const [data, setData] = useState({
		tooltipOpen: false,
		startstyle: {
			color: 'red',
			fontSize: '14px'
		},
		isModalVisible: false,
		rowPropsId: "",
		selectedClass: '',

		_id: '',
		title: '',
		description: ''
	})
	const [rows, setRows] = useState([]);
	const [dataLoader, setDataLoader] = useState(false);
	const [isModalVisible2, setIsModalVisible2] = useState(false);
	const [selecteUser, setSelecteUser] = useState({})
	const [statusMsg, setStatusMsg] = useState({
		msg: "",
		buttonName: ""
	});

	useEffect(() => {
		getList();
	}, []);

	const getList = async () => {
		setDataLoader(true);
		let SchoolUpdatesApi = new SchoolUpdates();
		await SchoolUpdatesApi.getUpdates().then(async (response) => {
			// console.log(response.data)
			if (response.data && response.data.length) {
				setRows(response.data)
			} else {
				setRows([])
			}
		}).catch((error) => {
			toast.error(error.toString());
			console.error("There is an error!", error);
		});
		setDataLoader(false)
	}

	const renderAction = (props) => {

		return (
			<span>
				<ReactTooltip id='Edit' type='warning' effect='solid'>
					<span>Edit</span>
				</ReactTooltip>
				<button onClick={() => editSchoolUpdates(props)} data-tip data-for="Edit" type="button" className="btn btn-outline-warning" style={{ padding: '8px' }}>
					<i className="mdi mdi-border-color" style={{ fontSize: '17px' }}></i>
				</button>

				<ReactTooltip id='Delete' type='error' effect='solid'>
					<span>Delete</span>
				</ReactTooltip>

				<button data-tip data-for="Delete" onClick={() => isVisible2(props._id)} type="button" className="btn btn-outline-danger" style={{ padding: '8px' }}>
					<i className="mdi mdi-delete" style={{ fontSize: '17px' }}></i>
				</button>
			</span>
		)
	}

	const isVisible2 = (id = null) => {
		let d = { ...data };
		if (id) {
			// console.log(id)
			d.rowPropsId = id;
		}
		setIsModalVisible2(!isModalVisible2);
		setData(d);
	}

	const DeactiveAction = (props, index) => {
		return (
			<span>
				<ReactTooltip id='activate' type={props.active ? 'success' : 'error'} effect='solid'>
					<span>Activate/Deactive</span>
				</ReactTooltip>
				{/* <span onClick={()=> deactieActionApi(props)} data-tip data-for="activate" className="form-check form-check-danger" style={{display:'inline-flex'}}> */}
				<span onClick={() => {
					let d = { ...data }
					if (props.active) {
						setStatusMsg({
							msg: `Are you sure that you want to Deactivate this update`,
							buttonName: "Deactivate"
						})
					} else {
						setStatusMsg({
							msg: `Are you sure that you want to Activate this update`,
							buttonName: "Activate"
						})
					}
					d.isModalVisible = true;
					setData(d);
					setSelecteUser(props);
				}} data-tip data-for="activate" className={props.active ? "form-check form-check-success" : "form-check form-check-danger"} style={{ display: 'inline-flex' }}>
					<label className="form-check-label">
						<input type="checkbox" className="form-check-input" checked={props.active} />
						<i className="input-helper"></i>
					</label>
				</span>
			</span>
		)
	}

	const editSchoolUpdates = (props) => {
		let c = { ...data }
		c._id = props._id;
		c.title = props.title;
		c.description = props.description;
		setData(c);
	}

	const deleteSchoolUpdates = async (id) => {
		let SchoolUpdatesApi = new SchoolUpdates();
		isVisible2();
		await SchoolUpdatesApi.deleteUpdate(data.rowPropsId)
			.then((response) => {
				toast.success(response.msg.toString());
				// console.log(response);
			})
			.catch((error) => {
				toast.error(error.toString());
				console.error("There is ERROR!", error)
			})
		getList();
	}

	const field = () => {
		const fields = [
			{
				name: "action",
				title: "",
				width: "0%",
				align: "center",
				render: DeactiveAction,
			},
			{
				title: "Title",
				field: "title",
			},
			{
				title: "Description",
				field: "description",
			},
			{
				name: "action",
				title: "Actions ",
				render: renderAction,
			},
		];
		return fields;
	}

	const handleClose = () => {
		let d = { ...data };
		d.isModalVisible = false;
		d.isModalVisible2 = false;
		setSelecteUser({});
		setStatusMsg({
			msg: "",
			buttonName: ""
		});
		setData(d)
	}

	// ================================================== coding part =========================================

	const handleChanges = (event) => {
		let c = { ...data }
		const { name, value } = event.target;
		if (name == "title") {
			c.title = value
		} else if (name == "description") {
			c.description = value
		}
		setData(c);
	}

	const handleSubmit = async (event) => {
		event.preventDefault();
		let {
			_id,
			title,
			description
		} = data;
		let SchoolUpdatesApi = new SchoolUpdates()
		if (data._id) {
			await SchoolUpdatesApi.editUpdate({
				_id,
				title,
				description
			}).then((data) => {
				if (data.success) {
					toast.success(data.msg.toString());
					cleanFields();
					getList();
				} else {
					toast.error(data.msg);
				}
			})
				.catch((error) => {
					toast.error(error.toString());
					console.error("There was an error!", error);
				});
		} else {
			await SchoolUpdatesApi.createUpdate({
				title,
				description
			}).then((data) => {
				if (data.success) {
					toast.success(data.msg.toString());
					cleanFields();
					getList();
				} else {
					toast.error(data.msg);
				}
			})
				.catch((error) => {
					toast.error(error.toString());
					console.error("There was an error!", error);
				});
		}
	}

	const cleanFields = () => {
		let d = { ...data }
		d._id = "";
		d.title = "";
		d.description = "";
		setData(d);
	}

	const setActiveStatus = async () => {
		let SchoolUpdatesApi = new SchoolUpdates()
		let da = { _id: selecteUser._id, activate: !selecteUser.active }
		await SchoolUpdatesApi.activateOrDactivate(da)
			.then((response) => {
				if (response.success) {
					toast.success(response.msg)
				} else {
					toast.error(response.msg)
				}
			})
			.catch((error) => {
				toast.error(error.toString());
				console.error("There is ERROR!", error)
			})
		handleClose();
		getList();
	}
	// ======================================= /coding part/ =====================================


	return (
		<div>
			<div className="page-header">
				<h3 className="page-title"> Master Data </h3>
			</div>
			{dataLoader ? <Loader /> : null}
			<div className="row">
				<div className="col-lg-12 grid-margin stretch-card">
					<div className="card">
						<div className="card-body">
							<form onSubmit={handleSubmit} className="forms-sample">
								{/* =================================== create concession============================== */}
								<h4 className="card-title">Latest Updates</h4>
								<div className="row">
									<div className="col-md-4">
										<Form.Group className="row">
											<label className="col-sm-12 col-form-label">Title<span style={data.startstyle}>*</span></label>
											<div className="col-sm-12">
												<Form.Control type="text" name="title" onChange={handleChanges} value={data.title} required />
											</div>
											{/*{validator.message('title', data.title, 'required', {className:"text-danger"})}*/}
										</Form.Group>
									</div>
									<div className="col-md-5">
										<Form.Group className="row">
											<label className="col-sm-12 col-form-label">Description<span style={data.startstyle}>*</span></label>
											<div className="col-sm-12">
												<Form.Control as="textarea" name="description" rows={3} onChange={handleChanges} value={data.description} type="text-area" required />
											</div>
											{/*{validator.message('description', data.description, 'required', {className:"text-danger"})}*/}
										</Form.Group>
									</div>
									<div className="col-md-2 mt-5">
										<button type="submit" className="btn btn-primary ml-2 btn-fw" style={{ lineHeight: 1.5 }}>{data._id ? "Update" : "Add"}</button>
									</div>
								</div>

								{/* ========================================================== */}
							</form>
						</div>
					</div>
				</div>

				<div className="col-lg-12 grid-margin stretch-card">
					<div className="card">
						<Grid columns={field()} rows={rows} />
					</div>
				</div>
				<DeleteModal show={isModalVisible2} isModalVisible2={isVisible2} deleteData={deleteSchoolUpdates} />
				<StatusModal show={data.isModalVisible} isModalVisible2={handleClose} deleteData={setActiveStatus} body={statusMsg} />
			</div>
		</div>

	)

}

export default ManageUpdatesData;