import React, { useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import moment from "moment";
import Loader from "../../../components/loader";
import StudentFeeApi from "../../../api/studentFeeApi";
import { toast } from "react-toastify";
const FeeReceiptModal = ({data, isLoader, handleClose}) => {
    const generateStudentFeePDF = (e) => {
        const studentId = data.selectedstudent._id;
        const _id = data.selectedstudent.feeInfo._id;
        const studentFeeApi = new StudentFeeApi();
        studentFeeApi.generateStudentFeePDF(studentId, _id).then((response) => {
          if (response && response.success) {
            window.open(
              "/api/download/" + _id,
              "_blank",
              "location=no,height=700,width=850,scrollbars=yes,status=yes"
            );
          } else {
            toast.error("error in PDF");
          }
        });
    };
    return (
        <Modal
        id="divcontents"
        show={data.isModalVisible}
        size="xl"
        onHide={() => handleClose()}>
            <Modal.Header closeButton>
            <h3 className="page-title">Fee Receipt</h3>
            </Modal.Header>
            {data.selectedstudent.feeInfo ? (
            <div className="card" id="cardcontents">
                {isLoader ? <Loader /> : null}
                <div className="card-body">
                <div
                    className="row cntr-cls"
                    style={{ justifyContent: "center" }}
                >
                    <h2>{localStorage.getItem("schoolInfo")}</h2>
                </div>
                <div
                    className="row cntr-cls"
                    style={{ justifyContent: "center" }}
                >
                    <h4>Address</h4>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                    <div>
                        Fee Invoice:{" "}
                        <span>{data.selectedstudent.feeInfo.invoiceNumber}</span>
                    </div>
                    </div>
                    <div className="col-sm-6">
                    <div className="float-right">
                        Date:{" "}
                        <span>
                        {moment(data.selectedstudent.feeInfo.paymentDate).format(
                            "DD-MM-YYYY"
                        )}
                        </span>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6" id="fee-rcpt-left-id">
                    <div className="row mt-3 ml-3">
                        <div className="col-sm-12">
                        Admission No.:{" "}
                        <span>{data.selectedstudent.admissionNo}</span>
                        </div>
                        <div className="col-sm-12">
                        Student:{" "}
                        <span>
                            {data.selectedstudent.firstName}{" "}
                            {data.selectedstudent.lastName}
                        </span>
                        </div>
                        <div className="col-sm-12">
                        Father:{" "}
                        <span>{data.selectedstudent.fatherProfile.name}</span>
                        </div>
                        <div className="col-sm-12">
                        Mother:{" "}
                        <span>{data.selectedstudent.motherProfile.name}</span>
                        </div>
                        <div className="col-sm-12">
                        Class:{" "}
                        <span>
                            {data.selectedstudent.classInfo.className}-
                            {data.selectedstudent.classInfo.section}
                        </span>
                        </div>
                        <div className="col-sm-12">
                        Payment Schedule:{" "}
                        <span>{data.selectedstudent.feeInfo.releaseDate}</span>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6" id="fee-rcpt-right-id">
                    <div
                        className="row mt-3 mr-3"
                        style={{ border: "solid 1px" }}
                    >
                        {data.selectedstudent.feeInfo.paidFeeDetails.map((d) => {
                        return (
                            <div className="col-sm-12" key={d._id}>
                            {d.particular}:{" "}
                            <span className="float-right">{d.amount}</span>
                            </div>
                        );
                        })}
                        {data.selectedstudent.feeInfo &&
                        data.selectedstudent.feeInfo.concessionAmount ? (
                        <div className="col-sm-12">
                            Concession Amount:{" "}
                            <span className="float-right">
                            {data.selectedstudent.feeInfo.concessionAmount}
                            </span>
                        </div>
                        ) : (
                        ""
                        )}
                        {data.selectedstudent.feeInfo &&
                        data.selectedstudent.feeInfo.lateFeeAmount ? (
                        <div className="col-sm-12">
                            Late Fee Amount:{" "}
                            <span className="float-right">
                            {data.selectedstudent.feeInfo.lateFeeAmount}
                            </span>
                        </div>
                        ) : (
                        ""
                        )}
                        <div className="col-sm-12">
                        Total Fees:{" "}
                        <span className="float-right">
                            {data.selectedstudent.feeInfo.paidAmount}
                        </span>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            ) : (
            ""
            )}
            <Modal.Footer
            id="hide-fee-rcpt-id"
            style={{ backgroundColor: "#ffffff" }}
            >
            <div className="row">
                <div className="col-sm-12">
                <span>FEE ONCE PAID IS NOT REFUNDABLE</span>
                </div>
            </div>
            <Button variant="secondary" onClick={() => handleClose()}>
                Close
            </Button>
            <Button
                className="button1"
                variant="primary"
                onClick={() => {
                handleClose();
                generateStudentFeePDF();
                }}
            >
                Print
            </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default FeeReceiptModal;