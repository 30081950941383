import React, { useEffect, useState } from 'react';
import Grid from "../../../components/dataTable";
import { Form, Modal, Button } from 'react-bootstrap';
import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from "react-toastify";
import Category from '../../../api/category';

const ManageCategoryData = () => {

    const [data, setData] = useState({
        tooltipOpen: false,
        startstyle: {
            color: 'red',
            fontSize: '14px'
        },
        isModalVisible: false,
        selectedClass: '',

        _id: "",
        categoryName: ""
    })
    const [rows, setRows] = useState([]);
    const [dataLoader, setDataLoader] = useState(false);

    useEffect(() => {
        getList();
    }, []);

    const getList = async () => {
        setDataLoader(true);
        let CategoryApi = new Category();
        await CategoryApi.getCategory().then(async (response) => {
            if (response.data && response.data.length) {
                setRows(response.data)
            }
        }).catch((error) => {
            toast.error(error.toString());
            console.error("There is an error!", error);
        });
        setDataLoader(false)
    }

    const renderAction = (props) => {

        return (
            <span>
                <ReactTooltip id='Edit' type='warning' effect='solid'>
                    <span>Edit</span>
                </ReactTooltip>
                <button onClick={() => editCategory(props)} data-tip data-for="Edit" type="button" className="btn btn-outline-warning" style={{ padding: '8px' }}>
                    <i className="mdi mdi-border-color" style={{ fontSize: '17px' }}></i>
                </button>

                <ReactTooltip id='Delete' type='error' effect='solid'>
                    <span>Delete</span>
                </ReactTooltip>

                <button data-tip data-for="Delete" onClick={() => deleteCategory(props._id)} type="button" className="btn btn-outline-danger" style={{ padding: '8px' }}>
                    <i className="mdi mdi-delete" style={{ fontSize: '17px' }}></i>
                </button>
            </span>
        )
    }

    const DeactiveAction = (props, index) => {

        return (
            <span>
                <ReactTooltip id='activate' type='error' effect='solid'>
                    <span>Activate/Deactive</span>
                </ReactTooltip>
                <span data-tip data-for="activate" className="form-check form-check-success" style={{ display: 'inline-flex' }}>
                    <label className="form-check-label">
                        <input type="checkbox" className="form-check-input" defaultChecked />
                        <i className="input-helper"></i>
                    </label>
                </span>
            </span>
        )
    }

    const ShowSubjects = (props) => {
        // console.log(props)
        return (
            <span>
                <button onClick={() => setData(data.selectedClass = props.classes, data.isModalVisible = !data.isModalVisible)} type="button" className="btn btn-outline-warning" style={{ padding: '8px' }}>
                    <i className="mdi mdi-link-variant" style={{ fontSize: '17px' }}></i>
                </button>
            </span>
        )
    }

    const field = () => {
        const fields = [
            {
                // name: "",
                // field: "Deactive",
                name: "action",
                title: "",
                width: "0%",
                align: "center",
                render: DeactiveAction,
            },
            {
                title: "Categories",
                field: "categoryName",
            },
            {
                title: "Students",
                field: "students",
            },
            {
                name: "action",
                title: "Actions ",
                render: renderAction,
            },
        ];
        return fields;
    }

    const handleClose = () => {
        setData(data.selectedClass = "", data.isModalVisible = false)
    }

    // ======================================= coding part =======================================
    const handleChanges = (event) => {
        let c = { ...data }
        const { name, value } = event.target;
        if (name == "categoryName") {
            c.categoryName = value
        }
        setData(c);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let {
            _id,
            categoryName
        } = data;

        let CategoryApi = new Category();

        if (data._id) {
            await CategoryApi.editCategory({
                _id,
                categoryName
            })
        } else {
            await CategoryApi.createCategory({
                categoryName
            })
        }
        cleanFields()
        getList()
    }

    const cleanFields = () => {
        setData(
            data._id = "",
            data.categoryName = ""
        )
    }
    const editCategory = (props) => {
        let {
            _id,
            categoryName
        } = props
        setData(
            data._id = _id,
            data.categoryName = categoryName
        )
    }

    const deleteCategory = async (id) => {
        let CategoryApi = new Category();
        await CategoryApi
            .deleteCategory(id)
            .then((response) => {
                // console.log(response);
            })
            .catch((error) => {
                console.error("There is ERROR!", error)
            })
        getList()
    }

    // ===================================/ coding part /=======================================

    return (
        <div>
            <div className="page-header">
                <h3 className="page-title"> Master Data </h3>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmit} className="forms-sample">
                                {/* =================================== Create Categories============================== */}
                                <h4 className="card-title">Create Categories</h4>
                                <div className="row">
                                    <div className="col-md-9">
                                        <Form.Group className="row">
                                            <label className="col-sm-2 col-form-label">Category<span style={data.startstyle}>*</span></label>
                                            <div className="col-sm-10">
                                                <Form.Control required={true} type="text" name="categoryName" onChange={handleChanges} value={data.categoryName} />
                                            </div>
                                        </Form.Group>
                                    </div>

                                    <div className="col-md-3">
                                        <button type="submit" className="btn btn-primary ml-2 btn-fw" style={{ lineHeight: 1.5 }}>{data._id ? "Update" : "Add"}</button>
                                    </div>
                                </div>

                                {/* ========================================================== */}
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <Grid columns={field()} rows={rows} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageCategoryData;