import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useHistory} from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { GlobalProvider } from "../contexts/globalContext";
import SchoolAdminLayout from "../layouts/schooladmin/schoolAdminLayout.jsx";
import SuperAdminLayout from "../layouts/superadmin/superAdminLayout";
import { UserContext } from "../contexts/userContext";
import { toast } from "react-toastify";
import AuthApi from "../api/authApi";

const SetNewPassword = () => {
  let history = useHistory();
  const [userContext] = useContext(UserContext);
  const [user, setUser] = useState({ email: "", otp:"", password: "", conformPassword:"", matched: ""});

  useEffect(() => {
    let c = { ...user };
    const email = new URLSearchParams(history.location.search).get("email");
    if(email){
        c.email = email;
    } else {
        toast.error("Invalid address");
        history.push("/");
    }
    setUser(c)
  },[])
  
    function handleChange(event) {
        const { name, value } = event.target;
        //here set the state and pass
        let c = { ...user };
        if (name == "otp") {
            c.otp = value;
        } else if(name == "conformPassword") {
            c.conformPassword = value;
            if(c.conformPassword != c.password){
                c.matched = "no"
            } else {
                c.matched = ""
            }
        } else if(name == "password"){
            c.password = value;
        }
        setUser(c);
    }

  async function handleSubmit(e) {
    //here send the request
    e.preventDefault()
    let {email, otp, password, conformPassword, matched } = user;
    let authApi = new AuthApi();
    if(email && otp && password && conformPassword){
        await authApi.resetPassword({email, otp, password})
            .then((response) => {
                if(response.success){
                    toast.success(response.msg);
                    history.push("/");
                } else {
                    toast.error(response.msg);
                }
            })
            .catch((error)=> {
                toast.error(error);
            })
    } else {
        toast.error("All fields are required");
    }
  }

  return (
    <Fragment>

        <div className="d-flex align-items-center auth px-0" style={{height: "45pc"}}>
            <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="brand-logo mb-3">
                <Link to ="/home">
                    <img
                    src={require("../assets/images/Edvantum.png")}
                    alt="logo"
                    style={{ width: "200px" }}
                    loading="lazy"
                    />
                    </Link>
                </div>
                <h4>Enter OTP with new password</h4>
                <Form className="pt-3" onSubmit={(e) => handleSubmit(e)}>
                    <Form.Group className="d-flex search-field">
                        <Form.Control
                            type="text"
                            onChange={(e) => handleChange(e)}
                            placeholder="OTP"
                            size="lg"
                            name="otp"
                            className="h-auto"
                            required={true}
                        />
                    </Form.Group>
                    <Form.Group className="d-flex search-field">
                        <Form.Control
                            type="password"
                            onChange={(e) => handleChange(e)}
                            placeholder="Password"
                            size="lg"
                            name="password"
                            className="h-auto"
                            required={true}
                        />
                    </Form.Group>
                    <Form.Group className="d-flex search-field">
                        <Form.Control
                            type="password"
                            onChange={(e) => handleChange(e)}
                            placeholder="conformPassword"
                            size="lg"
                            name="conformPassword"
                            className="h-auto"
                            required={true}
                        />
                    </Form.Group>
                    <span style={{color: "red", fontSize:"12px"}} hidden={user.matched == "no"? false:true}>
                        The password confirmation does not match
                    </span>
                    <div className="mt-3">
                    {/* <Link className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" to="/dashboard">SIGN IN</Link> */}
                    </div>
                    <div className="mt-3">
                    <Button
                        type="submit"
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                    >
                        {" "}
                        Submit{" "}
                    </Button>
                    </div>
                </Form>
                </div>
            </div>
            </div>
        </div>

    </Fragment>
  );
};

export default SetNewPassword;
