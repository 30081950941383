import React, { useEffect, useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import MaterialTable from "material-table";
import Loader from "../../../components/loader";
// import { toast } from "react-toastify";
import { Link, useHistory } from 'react-router-dom';
const RouteShowModal = ({data,handleClose, DAYS }) => {
    let history = useHistory();
    return (
        <Modal show={data.isModalVisible} onHide={() => handleClose()}>
            <Modal.Header closeButton>
            <h3 className="page-title"> {data.selectedRoute.routeNo ? data.selectedRoute.routeNo : ''} </h3>
            </Modal.Header>
            <div className="card">
            <div className="card-body">
                <form>
                <div className="row">
                    <span className="col-md-6">
                    Bus No./Id : {data.selectedRoute.busNo ? data.selectedRoute.busNo : ''}
                    </span>
                    <span className="col-md-6">
                    Time : {data.selectedRoute.arrivalTime ? data.selectedRoute.arrivalTime : ''} - {data.selectedRoute.departureTime ? data.selectedRoute.departureTime : ''}
                    </span>
                </div>
                <div className="row">
                    <span className="col-sm-12">
                    Operates on : {data.selectedRoute.daysOfWeek ? data.selectedRoute.daysOfWeek.map((x) => DAYS[x].key.substring(0, 3)).toString() : ''}
                    </span>
                </div>
                <div className="row">
                    <span className="col-md-12">
                    Bus Driver : <Link to={data && data.selectedRoute && data.selectedRoute.driver && data.selectedRoute.driver.userId ? "/class4employees?id=" + data.selectedRoute.driver.userId : ''}>{data.selectedRoute.driver && data.selectedRoute.driver.name ? data.selectedRoute.driver.name : ''}</Link>
                    </span>
                </div>
                <div className="row">
                    <span className="col-md-12">
                    Bus Monitor : <Link to={data && data.selectedRoute && data.selectedRoute.monitor && data.selectedRoute.monitor.userId ? "/class4employees?id=" + data.selectedRoute.monitor.userId : ''}>{data.selectedRoute.monitor && data.selectedRoute.monitor.name ? data.selectedRoute.monitor.name : ''}</Link>
                    </span>
                </div>
                <div className="row">
                    <span className="col-md-6">
                    Capacity : {data.selectedRoute.capacity ? data.selectedRoute.capacity : ''}
                    </span>
                </div>
                <div className="row">
                    <span className="col-md-12">
                    Start From : {data.selectedRoute.leaveFrom ? data.selectedRoute.leaveFrom : ''}
                    </span>
                </div>
                <div className="row">
                    <span className="col-md-12">
                    Stop At(Destinations) : {data.selectedRoute.stops ? data.selectedRoute.stops.map((x) => x.destination).toString() : ''}
                    </span>
                </div>
                </form>
            </div>
            </div>
            <Modal.Footer style={{ backgroundColor: '#ffffff' }}>
            <Button variant="primary" onClick={() => history.push(`/addbusroute?id=${data.selectedRoute._id}`)}>
                Edit
            </Button>
            <Button variant="secondary" onClick={() => handleClose()}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default RouteShowModal;