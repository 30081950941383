import React, { useEffect, useState } from "react";
import CSVReader from "react-csv-reader";
import { CSVLink } from "react-csv";
import moment from 'moment';
import FeeDataApi from "../../api/feeDataApi";
const ExportFeeData = ({feeData=null}) => {
    function downloadFeeDataCSVFormat() {
        let headers = [
            {label:"Class Name",key:"className"},
            {label:"Session",key:"session"},
            {label:"Bus Fee",key:"busFee"}
          ];
          return (
            <CSVLink
              data={feeData}
              filename={"School_Fee_Data_Export.csv"}
              headers={headers}
            >
              <i className="mdi mdi-download"></i> Export Fee Data
            </CSVLink>
          );
    }

    
		return (
        <div className="">
          <div className="col-4 mt-2 float-right">{downloadFeeDataCSVFormat()}</div>
        </div>
      );

}

export default ExportFeeData;