import React, { useEffect, useState } from 'react';
import Grid from '../../../components/dataTable';
import { Form } from 'react-bootstrap';
import ReactTooltip from "react-tooltip";
import { toast } from 'react-toastify';
// import moment from 'moment';
import FeeData from '../../../api/feeDataApi';
import Class from '../../../api/classApi';
import BaseApi from '../../../api/baseApi';
import SchoolApi from '../../../api/schoolApi';
import { useHistory} from 'react-router-dom';
import { MultiSelect } from 'react-multi-select-component';

const CreateFees = () => {
    let history = useHistory();
    const [data, setData] = useState({
        tooltipOpen: false,
        startstyle: {
            color: 'red',
            fontSize: '14px'
        },
        isModalVisible: false,
    })

    const [formData, setFormData] = useState({
        _id:"",
        classNames: [],
        classId: "",
        session: "2023-2024",
        months: [],
        feesDetails:[],
        lastFeeDate: "",
        particular: '',
        amount: 0,
        totalAmount: ""
    })
    const [rows, setRows] = useState([]);
    const [dataLoader, setDataLoader] = useState(false);
    const [classList, setClassList] =  useState([])
    const [classesName, setClassesName] =  useState([])
    const [selectedClassName, setSelectedClassName] =  useState([])
    const [sessionsData, setSessionsData] = useState([]);
    const [selectedSession, setSelectedSession] = useState();
    const [defaultFeeDetails, setDefaultFeeDetails] = useState([]);
    const [defaultFeeDetailInputBox, setDefaultFeeDetailInputBox] = useState([]);
    // const [resp, setGitData] = useState({ data: null, repos: null });

    const id = new URLSearchParams(history.location.search).get('id');
    useEffect(() => {
        // getClassList();
        getClassNames();
        if (localStorage.getItem('schoolId')) {
            getSchool(localStorage.getItem('schoolId'));
        }
        fetchData(id);
    },[]);

    const fetchData = async (id) => {
        /*if (id) {
            selectedFeeData(id)
        }
        getDefaultFeeFields();*/
        const slctdDefaultFees = id ? await selectedFeeData(id) : null;
        const actualDefaultFees = await getDefaultFeeFields();
        if (slctdDefaultFees && slctdDefaultFees.length && actualDefaultFees && actualDefaultFees.length) {
            const result = actualDefaultFees.filter(o1 => !slctdDefaultFees.some(o2 => o1.particular === o2.particular));
            setDefaultFeeDetailInputBox(result);
        } else if (actualDefaultFees && actualDefaultFees.length) {
            setDefaultFeeDetailInputBox(actualDefaultFees);
        }
    };

    const getClassNames = async (value) => {
        const ClassApi = new Class();
        await ClassApi.getClassNames(value).then( async (response) => {
            if (response.data && response.data.length) {
                const data = [];
                response.data.map((val) => {
                    data.push({label: val, value: val})
                })
                setClassesName(data);
            } else {
                setClassesName([])
            }
        });
    }

    const getDefaultFeeFields = async () => {
        const baseApi = new BaseApi();
        const response = await baseApi.getDefaultSchoolSettings();
        if (response.success && response.data) {
            setDefaultFeeDetails(response.data.feeDetails);
            const val = response.data.feeDetails;
            return JSON.parse(JSON.stringify(val));
        } else {
            return null;
        }
    }

    const getClassList = async () => {
        let clist = new Class();
        await clist.getClass().then( (response) =>{
            if(response.data && response.data.length){
                let d = [...new Set(response.data.map(x => x.className))];
                setClassList(d);
            } else {
                setClassList([])
            }
        }).catch((error) => {
            toast.error(error.toString());
        });
      }

    const selectedFeeData = async (id) => {
        const Api = new FeeData();
        const response = await Api.getFeeData(id);
        if (response.data && response.success) {
            response.data.classNames = [];
            response.data.classNames.push(response.data.className);
            response.data.particular = ''
            response.data.amount = '';
            setFormData(response.data)
            const data = [];
            response.data.classNames.map((val) => {
                data.push({label: val, value: val});
            })
            setSelectedClassName(data);
            setRows(response.data.feesDetails)
            return response.data.feesDetails;
        } else {
            toast.error(response.msg)
            return null;
        }
    }

    const getSchool = async (id) => {
        const schoolApi = new SchoolApi();
        await schoolApi.getSchool(id).then(async (response) => {
            if (response && response.data && response.data.session) {
                const session = response.data.session;
                if (session && session.startsFromMonth) {

                    const sessionData = response.data.session;
                    const currentYear = new Date().getFullYear();
                    let sessionEndyear = new Date().getFullYear();
                    if (sessionData.startsFromMonth > sessionData.endsOnMonth) {
                        sessionEndyear = currentYear + 1;
                    }
                    const sessionsData = [];

                    sessionsData.push({
                        display: (currentYear - 1) + '-' + (sessionEndyear - 1)
                    })
                    sessionsData.push({
                        display: currentYear + '-' + sessionEndyear
                    })
                    sessionsData.push({
                        display: (currentYear + 1) + '-' + (sessionEndyear + 1)
                    })
                    setSessionsData(sessionsData);
                    setSelectedSession(sessionsData[0].display);
                }
            }
            return response;
        }).catch((error) => {
            // toast.error(error.toString());
        });
    }

    const field = () => {
        const fields = [
            {
                title: "Particulars",
                field: "particular",
            },
            {
                title: "Amount(₹)",
                field: "amount",
            },
            {
                name: "action",
                title: "Actions ",
                render: renderAction,
            }
        ];
        return fields;
    }

    const renderAction = (props) => {
        return(
            <span>
                <ReactTooltip id='Delete' type='error' effect='solid'>
                    <span>Delete</span>
                </ReactTooltip>
              
                <button onClick={() => deleteAmount(props)} data-tip data-for="Delete" type="button" className="btn btn-outline-danger" style={{padding:'8px'}}>
                    <i className="mdi mdi-delete"style={{fontSize:'17px'}}></i>
                </button>
            </span>
        )
    }

    const handleChanges = (e) => {
        const FormData = { ...formData };

        const { name, value } = e.target;
        switch (name) {
            case 'className':
                FormData.classNames.push(value);
            break;

            case 'session':
                FormData.session = value;
            break;

            case 'particular':
                FormData.particular = value;
            break;

            case 'amount':
                FormData.amount = value;
            break;
            default:
                if (name.includes('fDtl-amount-')) {
                    const pName = name.split('fDtl-amount-');
                    // console.log(pName);
                    if (pName && pName.length) {
                        const dat = pName[1];
                        /*=========================================*/
                        const dfd = defaultFeeDetailInputBox.findIndex(item => {
                            return item.indexName === dat;
                        })
                        // console.log(dfd)
                        if (dfd > -1) {
                            defaultFeeDetailInputBox[dfd].amount = value;
                        }
                        /*=========================================*/
                    }
                }
        }
        setFormData(FormData)
    }

    const addDefaultFeeIntable = (indexName) => {
        let index = -1;
        const dfd = defaultFeeDetailInputBox.find(item => {
            index++;
            return indexName === item.indexName
        })
        if (dfd) {
            if (dfd.amount === 0 || dfd.amount === '0') {
                return toast.error('Amount can\'t be zero');
            } else if (!dfd.amount) {
                return toast.error('Amount can\'t be empty');
            }
            const data = rows;
            const formdata = { ...formData };
            const amount = (Math.floor(dfd.amount)) ? Number(dfd.amount).toFixed(2) : Number(dfd.amount);
            data.push({ particular: dfd.particular, amount: amount });
            formdata.totalAmount =  Number(formdata.totalAmount) + Number(amount);
            setRows(data);
            const def = defaultFeeDetailInputBox
            def.splice(index, 1);
            setDefaultFeeDetailInputBox(def);
            setFormData(formdata)
        }
    }

    const addIntable = () => {
        const formdata = { ...formData };
        const data = rows ;
        if (!formdata.particular) {
            return toast.error('Particular can\'t be empty');
        }
        if (!formdata.amount) {
            return toast.error('Amount can\'t be empty or zero');
        }
        const dft = defaultFeeDetails.find(item => {
            return item.particular === formdata.particular;
        });
        if (dft) {
            return toast.error('Default Fields can\'t be added');
        }
        if (formdata.particular && formdata.amount){
            data.push({ particular: formdata.particular, amount: formdata.amount })
            formdata.totalAmount =  Number(formdata.totalAmount) + Number(formdata.amount)
            formdata.particular = '';
            formdata.amount = '';
            setRows(data);
            setFormData(formdata);
        }
    }

    const deleteAmount = (value) => {
        const index = value.tableData.id;
        const formdata = { ...formData };
        const listData = rows;
        const returnedTarget = defaultFeeDetails.find(item => {
            return item.particular === value.particular;
        })
        if (returnedTarget) {
            defaultFeeDetailInputBox.push(returnedTarget);
        }
        formdata.totalAmount = Number(formdata.totalAmount) - Number(listData[index].amount);
        listData.splice(index, 1);
        if (id) {
            const feeApi = new FeeData();
            feeApi.updateFeeDetails(formdata).then(async (response) => {
                if (response.success) {
                    setFormData(formdata);
                    setRows(listData);
                    toast.success(response.msg.toString())
                } else {
                    toast.error(response.msg.toString())
                }
            }).catch((error) => {
                toast.error(error.toString());
            });
        } else {
            setFormData(formdata);
            setRows(listData);
        }
    }

    const cleanFields = () => {
        const clean = {
            _id: '',
            classNames: [],
            session: '',
            feesDetails:[],
            particular: '',
            amount: '',
            totalAmount: ''
        }
        setFormData(clean);
        setRows([])
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let suc;
        let Api = new FeeData();
        let data = { ...formData };
        data.feesDetails = rows;
        const classNames = [];
        selectedClassName.map((item) => {
            classNames.push(item.value);
        })
        data.classNames = classNames;
        if (rows.length !== 0) {
            if (formData._id) {
                suc = await Api.updateFeeData(data)
                if (suc.error) {
                    toast.error(suc.msg);
                } else {
                    toast.success(suc.msg);
                    history.push('/masterdata/feedata');
                }
            } else {
                suc = await Api.createFeeData(data)
                cleanFields();
                if (suc.error) {
                    toast.error(suc.msg);
                } else {
                    toast.success(suc.msg);
                    history.push('/masterdata/feedata');
                }
            }
        } else {
            toast.error('cannot submit empty fields');
        }
    }

    return(
        <div>
            <div className="page-header">
            <h3 className="page-title">{formData._id ? 'Update Fees':'Create Fees'}</h3>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">    
                    <div className="card">
                        <div className="card-body">

                            <form onSubmit={handleSubmit} className="forms-sample">
                                {/* ================================================ */}

                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Class:<span style={data.startstyle}>*</span></label>
                                            <div className="col-sm-9">
                                                <MultiSelect
                                                    options={classesName}
                                                    labelledBy="Select"
                                                    value={selectedClassName}
                                                    onChange={setSelectedClassName}
                                                    disabled={formData._id ? true : false}
                                                />
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Session:<span style={data.startstyle}>*</span></label>
                                            <div className="col-sm-9">
                                                <select
                                                    className="form-control"
                                                    name="session"
                                                    onChange={(e) => handleChanges(e)}
                                                    required={true}
                                                    value={formData.session}
                                                    disabled={formData._id ? true : false}
                                                >
                                                    <option value="">Select Term</option>
                                                    {
                                                        sessionsData.map((resp) => <option key={resp.display} value={resp.display}>{resp.display}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>

                                {/* ================== associate teacher class======================= */}
                                <h4 className="card-title">Fees Details</h4>
                                {
                                    defaultFeeDetailInputBox && defaultFeeDetailInputBox.map((value, index) =>
                                        <div className="row" key={value.indexName}>
                                            <div className="col-md-6">
                                                <Form.Group className="row">
                                                    <label className="col-sm-3 col-form-label">Particular<span style={data.startstyle}>*</span></label>
                                                    <div className="col-sm-9">
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control"
                                                            name={`feeDetails-particular-${value.indexName}`}
                                                            disabled={true}
                                                            onChange={(e) => handleChanges(e)}
                                                            value={value.particular}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-5">
                                                <Form.Group className="row">
                                                    <label className="col-sm-3 col-form-label">Amount (₹)<span style={data.startstyle}>*</span></label>
                                                    <div className="col-sm-9">
                                                        <Form.Control
                                                            type="number"
                                                            className="form-control"
                                                            name={`fDtl-amount-${value.indexName}`}
                                                            presicion={2}
                                                            onChange={(e) => handleChanges(e)}
                                                            value={value.amount}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </div>
                                            <div className="col-sm-1">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary ml-2"
                                                    style={{lineHeight:1.5, float:'right', borderRadius:'25px', width:'45px'}}
                                                    onClick={()=> addDefaultFeeIntable(value.indexName)}
                                                >+</button>
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Particular<span style={data.startstyle}>*</span></label>
                                            <div className="col-sm-9">
                                                <Form.Control
                                                    type="text"
                                                    className="form-control"
                                                    name="particular"
                                                    onChange={(e) => handleChanges(e)}
                                                    value={formData.particular}
                                                />
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-5">
                                        <Form.Group className="row">
                                            <label className="col-sm-3 col-form-label">Amount (₹)<span style={data.startstyle}>*</span></label>
                                            <div className="col-sm-9">
                                                <Form.Control
                                                    type="number"
                                                    className="form-control"
                                                    name="amount"
                                                    presicion={2}
                                                    onChange={(e) => handleChanges(e)}
                                                    value={formData.amount}
                                                />
                                            </div>
                                        </Form.Group>
                                    </div>

                                    <div className="col-sm-1">
                                        <button
                                            type="button"
                                            className="btn btn-primary ml-2"
                                            style={{lineHeight:1.5, float:'right', borderRadius:'25px', width:'45px'}}
                                            onClick={()=> addIntable()}
                                        >+</button>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <Grid 
                                            columns={field()} 
                                            rows={rows} 
                                            title={'Total Amount = ₹' + formData.totalAmount + ' -/-'}
                                            settings={{ search: false, paging: false, exportButton: false }}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-sm-12">
                                        <button
                                            type="submit"
                                            className="btn btn-primary ml-2 btn-fw"
                                            style={{lineHeight:1.5, float:'right'}}
                                        >{formData._id?"Update":"Submit"}</button>
                                    </div>
                                </div>
                                {/* ========================================================== */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateFees;
