import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import SubstituteTeacher from "../../../api/substituteTeacherApi";
import Grid from "../../../components/dataTable";
import Loader from "../../../components/loader";
import moment from "moment";

const ManageSubstituteTeachers = () => {
  const [rows, setRows] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);

  useEffect(() => {
    getList();
  }, []);

  // ======================================= coding part =======================================

  const getList = async () => {
    setDataLoader(true);
    let SubstituteTeacherApi = new SubstituteTeacher();
    await SubstituteTeacherApi.getSubstituteTeachers()
      .then(async (response) => {
        if (response.data && response.data.length) {
          setRows(response.data);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    setDataLoader(false);
  };

  const Show_Date = (props) => {
    if (props.dateFrom) {
      let d = props.dateFrom.slice(0, 10);
      return <span>{moment(props.dateFrom).format("DD-MM-YYYY , dddd")}</span>;
    }
  };

  const Show_Class = (props) => {
    if (props.classInfo.className) {
      return <span>{props.classInfo.className}{props.classInfo && props.classInfo.section ? "-" : ""}{props.classInfo.section ? props.classInfo.section : ""}</span>;
    }
  };

  const Show_SlotTime = (props) => {
    if (props.slot.timeFrom) {
      let d = props.slot.timeFrom.slice(0, 10);
      return <span>{moment(props.slot.timeFrom).format("h:mm a")} - {moment(props.slot.timeTo).format("h:mm a")}</span>;
    }
  };

  const field = () => {
    const fields = [
      {
        title: "Teacher On Leave",
        field: "staffReplacedName",
      },
      {
        title: "Substitute Teacher",
        field: "staffIdReplacingName",
      },
      {
        title: "Date/Day",
        render: Show_Date,
        field: "dateFrom",
      },
      {
        title: "Class",
        render: Show_Class,
        field: "classInfo.className",
      },
      {
        title: "Slot Time",
        render: Show_SlotTime,
        field: "slot.timeFrom",
      }
    ];
    return fields;
  };

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Substitute Teachers </h3>
      </div>
      {dataLoader ? <Loader /> : null}
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <Grid
              columns={field()}
              rows={rows}
              settings={{
                paging: false,
                exportButton: false
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageSubstituteTeachers;
