import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import SchoolApi from '../../../api/schoolApi';
import Loader from "../../../components/loader";
import ClassApi from '../../../api/classApi';
import ResultApi from '../../../api/resultApi';
import CourseStructure from '../../../api/courseStructureApi';
import { toast } from 'react-toastify';

const AddResults = () => {
  const [dataLoader, setDataLoader] = useState(false);
  const [terms, setTerms] = useState([]);
  const [selectedTermId, setSelectedTermId] = useState();
  const [selectedTerm, setSelectedTerm] = useState();
  const [sessionStartDate, setSessionStartDate] = useState();
  const [sessionEndDate, setSessionEndDate] = useState();
  const [selectedSession, setSelectedSession] = useState();
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState();
  const [selectedClassId, setSelectedClassId] = useState();
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState();
  const [resultId, setResultId] = useState([]);
  const [result, setResult] = useState([]);
  const [subjectMarks, setSubjectMarks] = useState([]);

  const initialResults = {
    _id: "",
    termId: "",
    classId: "",
    session: {
      startsFromMonth: "",
      endsOnMonth: "",
    },
    students: [{
      firstName: "",
      lastName: "",
      studentId: "",
      subjectId: "",
      marksObtained: "0",
      practicalMarks: "0",
      assignmentMarks: "0",
    }],
  }

  useEffect(() => {
    if (localStorage.getItem('schoolId')) {
      getSchool(localStorage.getItem('schoolId'));
    }
  }, []);

  useEffect(() => {
    getSelectedClasses();
  }, [selectedTermId]);

  useEffect(() => {
    if (selectedClass != '') {
      getSelectedSubject();
    }
  }, [selectedClass]);

  useEffect(() => {
    getResults();
  }, [selectedTermId, selectedClassId, selectedSubject]);

  useEffect(() => {
    getSelectedTermClassData();
  }, [selectedTermId, selectedClassId]);

  useEffect(() => {
    getCourseData();
  }, [selectedTerm, selectedClass]);

  useEffect(() => {
    let thirdArray = subjects.filter((elem) => {
      return subjectMarks.some((ele) => {
        return ele.subject == elem._id;
      });
    });
    setSubjects(thirdArray)
  }, [subjectMarks]);

  const getSelectedTermClassData = async () => {
    const resultApi = new ResultApi();
    if (selectedTermId && selectedClassId) {
      await resultApi.getResults({ selectedTerm: selectedTermId, selectedClassId }).then(async (response) => {
        if (response.success) {
          if (response.data != null && response.data != "") {
            setResultId(response.data[0]._id);
          } else {
            setResultId([]);
          }
        }
      })
    }
  }

  const getSelectedClasses = async () => {
    const classApi = new ClassApi();
    await classApi.getClassesByTerm(selectedTermId, "ArrayOfObjects").then(async (response) => {
      if (response.data && response.data.length) {
        setClasses(response.data);
        // setSelectedClass(response.data[0].className);
        // setSelectedClassId(response.data[0]._id)
      } else {
        setClasses([]);
      }
    })
  }

  const getSelectedSubject = async () => {
    const classApi = new ClassApi();
    if (selectedClass) {
      await classApi.getSubjectByClassName({ className: selectedClass }).then(async (response) => {
        if (response && response.data && response.data.subjectsData) {
          setSubjects(response.data.subjectsData);
        } else {
          setSubjects([]);
        }
      })
    }
  }

  const getSchool = async (id) => {
    setDataLoader(true);
    const schoolApi = new SchoolApi();
    await schoolApi.getSchool(id).then(async (response) => {
      if (response && response.data) {
        const termsExams = response.data.termsExams;
        const session = response.data.session;
        if (termsExams && termsExams.length && session && session.startsFromMonth) {
          setTerms(termsExams);
          setSelectedTermId(termsExams[0]._id);
          setSelectedTerm(termsExams[0].term);

          const sessionData = response.data.session;
          const currentMonth = new Date().getMonth() + 1;
          const currentYear = new Date().getFullYear();
          let sessionEndyear = new Date().getFullYear();
          if (sessionData.startsFromMonth > sessionData.endsOnMonth) {
            sessionEndyear = currentYear + 1;
          }
          const sessionStartDate = new Date(currentYear, sessionData.startsFromMonth - 1, 1);
          const sessionEndDate = new Date(sessionEndyear, sessionData.endsOnMonth, 0);
          const sessionsData = [];

          sessionsData.push({
            value: moment(sessionStartDate).subtract(1, 'y').format('DD-MM-YYYY') + ' to ' + moment(sessionEndDate).subtract(1, 'y').format('DD-MM-YYYY'),
            display: (currentYear - 1) + '-' + (sessionEndyear - 1)
          })
          sessionsData.push({
            value: moment(sessionStartDate).format('DD-MM-YYYY') + ' to ' + moment(sessionEndDate).format('DD-MM-YYYY'),
            display: currentYear + '-' + sessionEndyear
          })
          sessionsData.push({
            value: moment(sessionStartDate).add(1, 'y').format('DD-MM-YYYY') + ' to ' + moment(sessionEndDate).add(1, 'y').format('DD-MM-YYYY'),
            display: (currentYear + 1) + '-' + (sessionEndyear + 1)
          })

          if (currentMonth >= sessionData.startsFromMonth) {
            setSessionStartDate(moment(sessionStartDate).format('DD-MM-YYYY'))
            setSessionEndDate(moment(sessionEndDate).format('DD-MM-YYYY'))
            setSelectedSession(sessionsData[1].value);
          } else {
            setSessionStartDate(moment(sessionStartDate).format('DD-MM-YYYY'))
            setSessionEndDate(moment(sessionEndDate).format('DD-MM-YYYY'))
            setSelectedSession(sessionsData[0].value);
          }
        } else {
          setTerms([]);
        }
      } else {
        setTerms([]);
      }
      return response;
    })
    setDataLoader(false);
  }

  function onChange(event) {
    const { name, value } = event.target;
    switch (name) {
      case 'term':
        let trm = terms.length && terms.find((item) => item._id.toString() == value.toString());
        if (trm) {
          setSelectedTermId(trm._id)
          setSelectedTerm(trm.term)
          setSelectedClassId("")
          setSelectedClass("")
        }
        break;
      case 'class':
        let cls = classes.length && classes.find((item) => item._id.toString() == value.toString());
        if (cls) {
          setSelectedClassId(cls._id)
          setSelectedClass(cls.className)
        }
        break;
      case 'subject':
        setSelectedSubject(value)
    }
  }

  const saveResults = async () => {
    const resultApi = new ResultApi();
    if (selectedTermId && selectedClassId && selectedSubject) {
      // if (result.students.length == 0) {
      //   alert('Students Marks Are Required')
      //   return;
      // }
      // for (let i = 0; i < result.students.length; i++) {
      //   let str = ""
      //   if (i == 0) {
      //     str = "Ist Student"
      //   } else if (i == 1) {
      //     str = "IInd Student"
      //   } else if (i == 2) {
      //     str = "IIIrd Student"
      //   } else {
      //     str = (i + 1) + " th Student"
      //   }
      //   if (result.students[i].assignmentMarks == "" || result.students[i].marksObtained == "" || result.students[i].practicalMarks == "") {
      //     alert("Please Enter Values for " + str)
      //     return;
      //   }
      // }
      await resultApi.saveResults({ result }).then(async (response) => {
        if (response.success && response.data) {
          setResultId(response.data._id);
          toast.success(response.msg)
        } else {
          toast.error(response.msg)
        }
      })
    }
  }

  const getResults = async () => {
    const resultApi = new ResultApi();
    const classApi = new ClassApi();
    if (selectedTermId && selectedClassId && selectedSubject) {
      await resultApi.getResults({ selectedTerm: selectedTermId, selectedClassId, selectedSubject }).then(async (response) => {
        if (response.success && response.data.length) {
          let resultData = { ...result };
          resultData._id = response.data[0]._id;
          resultData.termId = response.data[0].termId;
          resultData.classId = response.data[0].classId;
          const studentsForUpdate = []
          response.data.map((res) => {
            let student = res.studentsData.some((stu) => {
              if (stu && res._id && res.students.studentId.toString() == stu._id.toString()) {
                studentsForUpdate.push({
                  firstName: stu.firstName,
                  lastName: stu.lastName,
                  studentId: stu._id,
                  subjectId: res.students.subjectId,
                  marksObtained: res.students.marksObtained.toString(),
                  practicalMarks: res.students.practicalMarks.toString(),
                  assignmentMarks: res.students.assignmentMarks.toString()
                })
              }
            });
            return student;
          });
          await classApi.getStudentsByClassId(selectedClassId).then(async (response1) => {
            if (response1.data && response1.data.length) {
              let studentsArr = response1.data[0].students.filter(val => {
                return !studentsForUpdate.some(val1 => val._id == val1.studentId)
              }).forEach((data) => {
                studentsForUpdate.push({
                  firstName: data.firstName,
                  lastName: data.lastName,
                  studentId: data._id,
                  subjectId: selectedSubject,
                  marksObtained: "",
                  practicalMarks: "",
                  assignmentMarks: ""
                })
              })
            }
          })
          resultData.students = studentsForUpdate;
          setResult(resultData);
          toast.success(response.msg)
        } else {
          await classApi.getStudentsByClassId(selectedClassId).then(async (response) => {
            if (response.data && response.data.length) {
              let studentObj = { ...initialResults };
              studentObj._id = resultId;
              studentObj.termId = selectedTermId;
              studentObj.classId = selectedClassId;
              studentObj.session.startsFromMonth = sessionStartDate;
              studentObj.session.endsOnMonth = sessionEndDate;
              let studentsArr = response.data[0].students.map((data) => {
                return {
                  firstName: data.firstName,
                  lastName: data.lastName,
                  studentId: data._id,
                  subjectId: selectedSubject,
                  marksObtained: "",
                  practicalMarks: "",
                  assignmentMarks: "",
                }
              })
              studentObj.students = studentsArr
              setResult(studentObj)
            } else {
              setResult([]);
            }
          })
        }
      })
    }
  }

  const getCourseData = async () => {
    let query = { className: selectedClass, term: selectedTerm };
    const CourseStructureApi = new CourseStructure();
    if (selectedTerm && selectedClass) {
      await CourseStructureApi.getFilteredCourses(query).then(async (response) => {
        if (response.data && response.data.length) {
          setSubjectMarks(response.data[0].subjects)
        } else {
          setSubjectMarks([])
        }
      })
    }
  }

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Results Data </h3>
      </div>
      {dataLoader ? <Loader /> : null}
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <form className="forms-sample">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="card-title"> Enter Results Data</h4>
                  </div>
                  <div className="col-md-6 text-right">
                    <Link className="nav-link" to="/results">
                      <button type="submit" className="btn btn-primary">Back</button>
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <Form.Group className="row">
                      <label className="col-sm-4 col-form-label">Term<span style={{ color: "red" }}>*</span></label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          id="term"
                          name="term"
                          onChange={(e) => onChange(e)}
                        >
                          <option>-------</option>
                          {terms.map((resp) => <option key={"term_" + resp._id} value={resp._id}>
                            {resp.term}</option>)}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group className="row">
                      <label className="col-sm-4 col-form-label">Session</label>
                      <label className="col-sm-8 col-form-label">{selectedSession}</label>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <Form.Group className="row">
                      <label className="col-sm-4 col-form-label">Class<span style={{ color: "red" }}>*</span></label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          id="class"
                          name="class"
                          onChange={(e) => onChange(e)}
                          value={selectedClassId}
                        >
                          <option value="">-------</option>
                          {classes.map((resp) => <option key={"className_" + resp._id} value={resp._id}>
                            {resp.className}{resp && resp.section ? "-" : ""}{resp.section}</option>)}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <Form.Group className="row">
                      <label className="col-sm-4 col-form-label">Subject<span style={{ color: "red" }}>*</span></label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          id="subject"
                          name="subject"
                          onChange={(e) => onChange(e)}
                        >
                          <option>-------</option>
                          {subjects.map((resp) => <option key={"subject_" + resp._id} value={resp._id}>{resp.subjectName}</option>)}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </form>

              {result.students && subjectMarks.length > 0 ? (
                <>
                  <table className='result-class'>
                    <tbody >
                      <tr>
                        <th>Students</th>
                        <th>Marks Obtained {' '}
                          {
                            subjectMarks.length > 0 && (
                              (() => {
                                const foundSubject = subjectMarks.find(
                                  (item) => item.subject === result.students[0].subjectId
                                );
                                return foundSubject && foundSubject.marks ? `(${foundSubject.marks})` : null;
                              })()
                            )
                          }
                        </th>
                        <th>Practical Obtained {' '}
                          {
                            subjectMarks.length > 0 && (
                              (() => {
                                const foundSubject = subjectMarks.find(
                                  (item) => item.subject === result.students[0].subjectId
                                );
                                return foundSubject && foundSubject.practical ? `(${foundSubject.practical})` : null;
                              })()
                            )
                          }
                        </th>
                        <th>Assignment Obtained {' '}
                          {
                            subjectMarks.length > 0 && (
                              (() => {
                                const foundSubject = subjectMarks.find(
                                  (item) => item.subject === result.students[0].subjectId
                                );
                                return foundSubject && foundSubject.assignment ? `(${foundSubject.assignment})` : null;
                              })()
                            )
                          }
                        </th>
                      </tr>
                      {result.students.map((data, index) => {
                        return (
                          <tr key={"res_" + index}>
                            <td>{data.firstName}</td>
                            <td>
                              <Form.Group className="row">
                                <div className="col-sm-6">
                                  <Form.Control
                                    type="text"
                                    name="marksObtained"
                                    value={data && data.marksObtained}
                                    onChange={(e) => {
                                      let stuRes = { ...result }
                                      if (isNaN((parseInt(e.target.value)))) {
                                        result.students[index].marksObtained = "";
                                        setResult(stuRes);
                                        return;
                                      }
                                      const foundSubject = subjectMarks.find(item => item.subject === data.subjectId);
                                      if (parseInt(e.target.value) <= foundSubject.marks) {
                                        result.students[index].marksObtained = parseInt(e.target.value);
                                        setResult(stuRes);
                                      }
                                    }}
                                  />
                                </div>
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group className="row">
                                <div className="col-sm-6">
                                  <Form.Control
                                    type="text"
                                    name="practicalMarks"
                                    value={data && data.practicalMarks}
                                    onChange={(e) => {
                                      let stuRes = { ...result };
                                      if (isNaN(parseInt(e.target.value))) {
                                        result.students[index].practicalMarks = "";
                                        setResult(stuRes);
                                        return;
                                      }
                                      const foundSubject = subjectMarks.find(item => item.subject === data.subjectId);
                                      if (parseInt(e.target.value) <= foundSubject.practical) {
                                        result.students[index].practicalMarks = parseInt(e.target.value);
                                        setResult(stuRes);
                                      }
                                    }}
                                    readOnly={
                                      (() => {
                                        const foundSubject = subjectMarks.find(
                                          (item) => item.subject === result.students[index].subjectId
                                        );
                                        return !(foundSubject && foundSubject.practical !== null);
                                      })()
                                    }
                                  />
                                </div>
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group className="row">
                                <div className="col-sm-6">
                                  <Form.Control
                                    type="text"
                                    name="assignmentMarks"
                                    value={data && data.assignmentMarks}
                                    onChange={(e) => {
                                      let stuRes = { ...result }
                                      if (isNaN((parseInt(e.target.value)))) {
                                        result.students[index].assignmentMarks = "";
                                        setResult(stuRes);
                                        return;
                                      }
                                      const foundSubject = subjectMarks.find(item => item.subject === data.subjectId);
                                      if (parseInt(e.target.value) <= foundSubject.assignment) {
                                        result.students[index].assignmentMarks = parseInt(e.target.value);
                                        setResult(stuRes);
                                      }
                                    }}
                                    readOnly={
                                      (() => {
                                        const foundSubject = subjectMarks.find(
                                          (item) => item.subject === result.students[index].subjectId
                                        );
                                        return !(foundSubject && foundSubject.assignment !== null);
                                      })()
                                    }
                                  />
                                </div>
                              </Form.Group>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="row mt-2">
                    <div className="col-sm-12">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ float: "right" }}
                        onClick={(e) => saveResults()}
                      >
                        UPDATE
                      </button>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddResults;