import React, { useState } from "react";
import CSVReader from "react-csv-reader";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import StudentApi from "../../api/studentApi";
import CSVERRORDATAModal from "../csvDownloadPopup";
import moment from "moment";
import Loader from "../loader";

const Students = () => {
  const [isChooseFileDisplay, setIsChooseFileDisplay] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [errorRecords, setErrorRecords] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  let headers = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Admission No", key: "admissionNo" },
    { label: "Admission Date", key: "admissionDate" },
    { label: "DOB", key: "dob" },
    { label: "Gender", key: "gender" },
    { label: "Image", key: "image" },
    { label: "Passed Out Date", key: "passOutDate" },
    { label: "F Name", key: "fatherName" },
    { label: "F Mobile", key: "fatherMobile" },
    { label: "F Email", key: "fatherEmail" },
    { label: "F Primary", key: "fatherIsPrimary" },
    { label: "M Name", key: "motherName" },
    { label: "M Mobile", key: "motherMobile" },
    { label: "M Email", key: "motherEmail" },
    { label: "M Primary", key: "motherIsPrimary" },
    { label: "Session Start", key: "sessionStart" },
    { label: "Address", key: "address" },
    { label: "Pin Code", key: "pinCode" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Country", key: "country" },
    { label: "Is Bus", key: "IsBus" },
    { label: "Route", key: "route" },
    { label: "Destination", key: "destination" },
    { label: "Class Name", key: "className" },
    { label: "Section", key: "section" },
    { label: "Error", key: "error" },
  ];

  function downloadStudentsCSVFormat() {
    let headers = [
      { label: "First Name", key: "firstName" },
      { label: "Last Name", key: "lastName" },
      { label: "Admission No", key: "admissionNo" },
      { label: "Admission Date", key: "admissionDate" },
      { label: "DOB", key: "dob" },
      { label: "Gender", key: "gender" },
      { label: "Image", key: "image" },
      { label: "Passed Out Date", key: "passOutDate" },
      { label: "F Name", key: "fatherName" },
      { label: "F Mobile", key: "fatherMobile" },
      { label: "F Email", key: "fatherEmail" },
      { label: "F Primary", key: "fatherIsPrimary" },
      { label: "M Name", key: "motherName" },
      { label: "M Mobile", key: "motherMobile" },
      { label: "M Email", key: "motherEmail" },
      { label: "M Primary", key: "motherIsPrimary" },
      { label: "Session Start", key: "sessionStart" },
      { label: "Address", key: "address" },
      { label: "Pin Code", key: "pinCode" },
      { label: "City", key: "city" },
      { label: "State", key: "state" },
      { label: "Country", key: "country" },
      { label: "Is Bus", key: "IsBus" },
      { label: "Route", key: "route" },
      { label: "Destination", key: "destination" },
      { label: "Class Name", key: "className" },
      { label: "Section", key: "section" },
    ];

    return (
      <CSVLink
        data={[
          {
            firstName: "Ahmed",
            lastName: "Tomi",
            email: "ah@smthing.co.com",
            admissionNo: "101",
            admissionDate: "2022-04-04T00:00:00.000Z",
            dob: "2018-02-14T00:00:00.000Z",
            gender: "Male",
            image: "1002.jpg",
            passOutDate: "2014-08-12",
            fatherName: "Joan",
            fatherMobile: "8989080000",
            fatherEmail: "Joan@gmail.com",
            fatherIsPrimary: "TRUE",
            motherName: "Clemente",
            motherMobile: "8989080001",
            motherEmail: "Clemente@gmail.com",
            motherIsPrimary: "FALSE",
            sessionStart: "2022-04-01T00:00:00.000Z",
            address: "1992 Maserati Ghibli",
            pinCode: "177001",
            city: "London",
            state: "London",
            country: "England",
            IsBus: "TRUE",
            route: "Rt-1003",
            destination: "home address",
            className: "I",
            section: "A",
          },
        ]}
        filename={"School_Sample_Students_Import.csv"}
        headers={headers}
      >
        (Download Sample CSV)
      </CSVLink>
    );
  }

  async function uploadCsvFile(studentsData) {
    console.log('studentsData',studentsData)
    if (studentsData.length > 1001) {
      toast.error("At one time maximum 1000 records can be uploaded");
      return;
    }
    let firstNameColumnIndex = -1;
    let lastNameColumnIndex = -1;
    let admissionNoColumnIndex = -1;
    let admissionDateColumnIndex = -1;
    let DOBColumnIndex = -1;
    let genderColumnIndex = -1;
    let photoUrlColumnIndex = -1;
    let passesOutDateColumnIndex = -1;
    let FNameColumnIndex = -1;
    let FMobileColumnIndex = -1;
    let FEmailColumnIndex = -1;
    let FPrimaryColumnIndex = -1;
    let MNameColumnIndex = -1;
    let MMobileColumnIndex = -1;
    let MEmailColumnIndex = -1;
    let MPrimaryColumnIndex = -1;
    let sessionStartColumnIndex = -1;
    let addressColumnIndex = -1;
    let pinCodeColumnIndex = -1;
    let cityColumnIndex = -1;
    let stateColumnIndex = -1;
    let countryColumnIndex = -1;
    let isBusColumnIndex = -1;
    let routeColumnIndex = -1;
    let destinationColumnIndex = -1;
    let classColumnIndex = -1;
    let sectionColumnIndex = -1;
    let firstRowIndex = -1;
    let imported = [];
    let admissionNoArray = [];
    let mobileNoArray = [];
    // let classArray = [];
    // let sectionArray = [];
    studentsData.map(async (row, index) => {
      if (firstNameColumnIndex == -1 && row.indexOf("First Name") > -1) {
        firstNameColumnIndex = row.indexOf("First Name");
      }
      if (lastNameColumnIndex == -1 && row.indexOf("Last Name") > -1) {
        lastNameColumnIndex = row.indexOf("Last Name");
      }
      if (admissionNoColumnIndex == -1 && row.indexOf("Admission No") > -1) {
        admissionNoColumnIndex = row.indexOf("Admission No");
      }
      if (
        admissionDateColumnIndex == -1 &&
        row.indexOf("Admission Date") > -1
      ) {
        admissionDateColumnIndex = row.indexOf("Admission Date");
      }
      if (DOBColumnIndex == -1 && row.indexOf("DOB") > -1) {
        DOBColumnIndex = row.indexOf("DOB");
      }
      if (genderColumnIndex == -1 && row.indexOf("Gender") > -1) {
        genderColumnIndex = row.indexOf("Gender");
      }
      if (photoUrlColumnIndex == -1 && row.indexOf("Image") > -1) {
        photoUrlColumnIndex = row.indexOf("Image");
      }
      if (
        passesOutDateColumnIndex == -1 &&
        row.indexOf("Passed Out Date") > -1
      ) {
        passesOutDateColumnIndex = row.indexOf("Passed Out Date");
      }
      if (FNameColumnIndex == -1 && row.indexOf("F Name") > -1) {
        FNameColumnIndex = row.indexOf("F Name");
      }
      if (FMobileColumnIndex == -1 && row.indexOf("F Mobile") > -1) {
        FMobileColumnIndex = row.indexOf("F Mobile");
      }
      if (FEmailColumnIndex == -1 && row.indexOf("F Email") > -1) {
        FEmailColumnIndex = row.indexOf("F Email");
      }
      if (FPrimaryColumnIndex == -1 && row.indexOf("F Primary") > -1) {
        FPrimaryColumnIndex = row.indexOf("F Primary");
      }
      if (MNameColumnIndex == -1 && row.indexOf("M Name") > -1) {
        MNameColumnIndex = row.indexOf("M Name");
      }
      if (MMobileColumnIndex == -1 && row.indexOf("M Mobile") > -1) {
        MMobileColumnIndex = row.indexOf("M Mobile");
      }
      if (MEmailColumnIndex == -1 && row.indexOf("M Email") > -1) {
        MEmailColumnIndex = row.indexOf("M Email");
      }
      if (MPrimaryColumnIndex == -1 && row.indexOf("M Primary") > -1) {
        MPrimaryColumnIndex = row.indexOf("M Primary");
      }
      if (sessionStartColumnIndex == -1 && row.indexOf("Session Start") > -1) {
        sessionStartColumnIndex = row.indexOf("Session Start");
      }
      if (addressColumnIndex == -1 && row.indexOf("Address") > -1) {
        addressColumnIndex = row.indexOf("Address");
      }
      if (pinCodeColumnIndex == -1 && row.indexOf("Pin Code") > -1) {
        pinCodeColumnIndex = row.indexOf("Pin Code");
      }
      if (cityColumnIndex == -1 && row.indexOf("City") > -1) {
        cityColumnIndex = row.indexOf("City");
      }
      if (stateColumnIndex == -1 && row.indexOf("State") > -1) {
        stateColumnIndex = row.indexOf("State");
      }
      if (countryColumnIndex == -1 && row.indexOf("Country") > -1) {
        countryColumnIndex = row.indexOf("Country");
      }
      if (isBusColumnIndex == -1 && row.indexOf("Is Bus") > -1) {
        isBusColumnIndex = row.indexOf("Is Bus");
      }
      if (routeColumnIndex == -1 && row.indexOf("Route") > -1) {
        routeColumnIndex = row.indexOf("Route");
      }
      if (destinationColumnIndex == -1 && row.indexOf("Destination") > -1) {
        destinationColumnIndex = row.indexOf("Destination");
      }
      if (classColumnIndex == -1 && row.indexOf("Class Name") > -1) {
        classColumnIndex = row.indexOf("Class Name");
      }
      if (sectionColumnIndex == -1 && row.indexOf("Section") > -1) {
        sectionColumnIndex = row.indexOf("Section");
      }

      if (
        firstNameColumnIndex > -1 &&
        //lastNameColumnIndex > -1 &&
        admissionNoColumnIndex > -1 &&
        //admissionDateColumnIndex > -1 &&
        DOBColumnIndex > -1 &&
        //genderColumnIndex > -1 &&
        //FNameColumnIndex > -1 &&
        //FMobileColumnIndex > -1 &&
        //MNameColumnIndex > -1 &&
        //MMobileColumnIndex > -1 &&
        addressColumnIndex > -1 &&
        //cityColumnIndex > -1 &&
        // pinCodeColumnIndex > -1 &&
        classColumnIndex > -1 &&
        sectionColumnIndex > -1
      ) {
        if (firstRowIndex === -1) {
          firstRowIndex = index + 1;
        }
        if (index >= firstRowIndex) {
          let firstName = row[firstNameColumnIndex];
          let lastName = row[lastNameColumnIndex];
          let admissionNo = row[admissionNoColumnIndex];
          let admissionDate = row[admissionDateColumnIndex];
          let dob = row[DOBColumnIndex];
          let gender = row[genderColumnIndex];
          let photoUrl = row[photoUrlColumnIndex];
          let passOutDate = row[passesOutDateColumnIndex];
          let fatherName = row[FNameColumnIndex];
          let fatherMobile = row[FMobileColumnIndex];
          let fatherEmail = row[FEmailColumnIndex];
          let fatherIsPrimary = row[FPrimaryColumnIndex];
          let motherName = row[MNameColumnIndex];
          let motherMobile = row[MMobileColumnIndex];
          let motherEmail = row[MEmailColumnIndex];
          let motherIsPrimary = row[MPrimaryColumnIndex];
          let sessionStart = row[sessionStartColumnIndex];
          let address = row[addressColumnIndex];
          let pinCode = row[pinCodeColumnIndex];
          let city = row[cityColumnIndex];
          let state = row[stateColumnIndex];
          let country = row[countryColumnIndex];
          let IsBus = row[isBusColumnIndex];
          let route = row[routeColumnIndex];
          let destination = row[destinationColumnIndex];
          let className = row[classColumnIndex];
          let section = row[sectionColumnIndex];
          if (IsBus == "BUS" || IsBus == "TRUE") {
            IsBus = true;
          } else {
            IsBus = false;
          }
          if (
            !firstName &&
            !admissionNo &&
            !className
          ) {
            return false;
          }
          let addressObj = {};
          let fatherObj = {};
          let motherObj = {};
          if (!firstName) {
            toast("First Name is missing in line number " + (index + 1), {
              type: "error",
            });
            //imported = [];
            return false;
          } else {
            firstName = firstName.trim();
          }

          if (lastName) {
            lastName = lastName.trim();
          }

          if (!admissionNo) {
            toast("Admission No is missing in line number " + (index + 1), {
              type: "error",
            });
            //imported = [];
            return false;
          } else {
            admissionNoArray.push(admissionNo.trim());
            admissionNo = admissionNo.trim();
          }

          if (!admissionDate) {
            let year = new Date().getFullYear();
            let date = "04-01-" + year;
            admissionDate = new Date(date);
          } else {
            admissionDate = new Date(admissionDate);
          }

          dob = dob.replaceAll(".", "-");
          if (dob && dob != "00000000") {
            let bDate = dob.split("-");
            if (bDate[0].length == 1) {
              dob = "0" + dob
            }
            if (bDate[1].length == 1) {
              dob = "0" + dob
            }
            if (bDate[2].length == 2) {
              dob = "20" + dob
            }
            dob = moment(dob, 'DD-MM-YYYY');
            dob = moment(dob).format('YYYY-MM-DD')
            dob = new Date(dob);
          }

          if (dob) {
            dob = new Date(dob);
          }

          if (!gender) {
            gender = "male";
          } else {
            gender = gender.trim();
            gender = gender.toLowerCase();
          }

          var regex = /^[0-9-+()]*$/;
          if (motherEmail) {
            motherEmail = motherEmail.trim();
            motherObj["email"] = motherEmail;
          }
          if (motherIsPrimary == "TRUE" || motherIsPrimary == "YES") {
            motherObj["primary"] = motherIsPrimary.toLowerCase();
            if (motherMobile) {
              motherMobile = motherMobile.trim();
              mobileNoArray.push(motherMobile.trim());
              motherObj["mobile"] = motherMobile;
              motherObj["name"] = motherName;
              motherObj["email"] = motherName;
            } else {
              motherObj["mobile"] = regex.test(admissionNo.trim()) ?  admissionNo.trim() : "";
              motherObj["name"] = motherName;
              motherObj["email"] = motherName;
            }
          }

          if (fatherIsPrimary = "TRUE" || fatherIsPrimary == "YES") {
            fatherObj["primary"] = fatherIsPrimary.toLowerCase();
            if (fatherMobile) {
              fatherMobile = fatherMobile.trim();
              mobileNoArray.push(fatherMobile.trim());
              fatherObj["mobile"] = fatherMobile;
              fatherObj["name"] = fatherName;
              fatherObj["email"] = fatherEmail;
            } else {
              fatherObj["mobile"] =  regex.test(admissionNo.trim()) ?  admissionNo.trim() : "";
              fatherObj["name"] = fatherName;
              fatherObj["email"] = fatherEmail;
            }
          }
          if (address) {
            addressObj["address"] = address;
          }
          if (pinCode) {
            pinCode = pinCode.trim();
            addressObj["pinCode"] = pinCode;
          }

          if (city) {
            city = city.trim();
            addressObj["city"] = city;
          }

          if (!sessionStart) {
            sessionStart = new Date().getFullYear();
          } else {
            sessionStart = sessionStart.trim();
          }

          if (!state) {
            state = "Punjab";
            addressObj["state"] = state;
          } else {
            state = state.trim();
            addressObj["state"] = state;
          }

          if (!country) {
            country = "India";
            addressObj["country"] = country;
          } else {
            country = country.trim();
            addressObj["country"] = country;
          }

          if (!className || className == "\N" || className == "\n") {
            toast("Class is missing in line number " + (index + 1), {
              type: "error",
            });
            //imported = [];
            return false;
          } else {
            if (Number.isNaN(parseInt(className))) {
              if (className == "NUR") {
                className = "Nursery";
              }
              className = className.trim();
              className = className.toString();
            } else {
              var digits = String(+className).split(""),
                key = [
                  "",
                  "C",
                  "CC",
                  "CCC",
                  "CD",
                  "D",
                  "DC",
                  "DCC",
                  "DCCC",
                  "CM",
                  "",
                  "X",
                  "XX",
                  "XXX",
                  "XL",
                  "L",
                  "LX",
                  "LXX",
                  "LXXX",
                  "XC",
                  "",
                  "I",
                  "II",
                  "III",
                  "IV",
                  "V",
                  "VI",
                  "VII",
                  "VIII",
                  "IX",
                ],
                roman_num = "",
                i = 3;
              while (i--)
                roman_num = (key[+digits.pop() + i * 10] || "") + roman_num;
              className = Array(+digits.join("") + 1).join("M") + roman_num;
              //className =  convert(className);
            }
            console.log('className',className)
            // classArray.push(new RegExp(className, "i"))
            // sectionArray.push(new RegExp(section, "i"))
          }
          if (photoUrl) {
            photoUrl = window.location.origin + "/public/students/" + photoUrl;
          }
          imported.push({
            firstName,
            lastName,
            admissionNo,
            admissionDate,
            dob,
            gender,
            photoUrl,
            passOutDate,
            fatherProfile: fatherObj,
            motherProfile: motherObj,
            sessionStart,
            address: addressObj,
            IsBus,
            route,
            destination,
            className,
            section,
          });
        }
      }
    });
    //let filterStudents = getUniqueBy("admissionNo", imported);
    console.log('admissionNoArray',admissionNoArray)
    console.log('mobileNoArray',mobileNoArray)
    let obj = {
      admissionNoArray: admissionNoArray,
      mobileNoArray: mobileNoArray,
      students: imported,
      // sectionArray: sectionArray,
      // classArray: classArray
    };
    saveStudentCsv(obj);
  }

  async function saveStudentCsv(imported) {
    if (window.confirm("Are your sure for upload the students csv file ?")) {
      setIsLoader(true);
      let Api = new StudentApi();
      await Api.saveStudentCsv(imported)
        .then(async (response) => {
          if (response.success) {
            toast.success("Csv file is save successfully");
            if (response.data && response.data.length) {
              setIsModal(true);
              setErrorRecords(response.data);
            } else if (response.data.length == 0) {
              window.location.reload(true);
            }
          } else if (response.error) {
            toast.error(response.msg.toString());
          }
          setIsLoader(false);
        })
        .catch((error) => {
          toast.error(error.toString());
        });
    }
  }

  function uploadStudentCsv() {
    return (
      <CSVReader
        onFileLoaded={(studentsData) => uploadCsvFile(studentsData)}
        inputStyle={{ fontSize: "12px" }}
      />
    );
  }

  async function isModal2() {
    setIsModal(!isModal);
  }

  return (
    <>
      <CSVERRORDATAModal
        show={isModal}
        isModal={isModal2}
        filename={"Data_Error_Students_Import.csv"}
        headers={headers}
        errorRecords={errorRecords}
      />
      {isLoader ? <Loader /> : null}
      <div className="col-md-12 textRight">
        <a href="javascript:void(0)" onClick={() => setIsChooseFileDisplay(!isChooseFileDisplay)}>
          <><i className="mdi mdi-apple-keyboard-caps"></i>Import Student Data </>
        </a>
        <a className="text-sm">
          {downloadStudentsCSVFormat()}
        </a>
      </div>
      <div className="col-md-5 text-sm">
        <div>{isChooseFileDisplay ? uploadStudentCsv() : null}</div>
      </div>
    </>
  );
};

export default Students;
