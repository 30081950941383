import BaseApi from "./baseApi";

class ActivityStudentApi extends BaseApi {

    async getActivityStudentById(id) {
        return await this.API(
            {
                url: `/activityStudent/getactivitystudent/${id}`,
                method: "get",
            }
        )
    }

    async getActivityStudentList(data) {
        return await this.API(
            {
                url: "activityStudent/getsactivitystudentlist",
                data: data,
                method: "post"
            }
        )
    }

    async createActivityStudent(data) {
        return await this.API(
            {
                url: "/activityStudent/createactivityschool",
                data: data,
                method: "post"
            }
        )
    }

    async updateActivityStudent(data) {
        return await this.API(
            {
                url: "/activityStudent/updateactivitystudent",
                data: data,
                method: "post"
            }
        )
    }

    async deleteActivityStudent(data) {
        console.log(data);
        return await this.API(
            {
                url: "activityStudent/deleteactivitystudent",
                data: { _id: data },
                method: "post"
            }
        )
    }

    async activateOrDactivate(data) {
        return await this.API(
            {
                url: "activityStudent/activateactivitystudent",
                data: data,
                method: "post"
            }
        )
    }

    async viewAttendaceMontlyCountByYear(id, monthYear) {
        return await this.API({
            url: `/activityStudentattendance/viewstudentattendancebymonthyear?studentId=${id}&monthYear=${monthYear}`,
            method: "get"
        })
    }

    async getActivityPerformances(id) {
        return await this.API(
            {
                url: "activityPerformance/getactivityperformances",
                data: { studentId: id },
                method: "post"
            }
        )
    }

}

export default ActivityStudentApi;