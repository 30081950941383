import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Modal, Button } from 'react-bootstrap';
import ReactTooltip from "react-tooltip";
import Grid from "../../../components/dataTable";
import StaffMember from "../../../api/staffMember";
import Class from "../../../api/classApi";
import Subject from "../../../api/subjectsApi";
import Floor from "../../../api/floorsApi";
import { toast } from "react-toastify";
import DeleteModal from "../../../components/deleteAlertPopup";
import StatusModal from '../../../components/alertPopup';
import Loader from "../../../components/loader";
import ExportStaffMembers from "../../../components/export/staffMembers";
import Users from "../../../components/bulkupload/users";
import AssociateTeacherModal from './AssociateTeacherModal';
const ManageStaffData = () => {
  let history = useHistory();
  const [data, setData] = useState({
    tooltipOpen: false,
    startstyle: {
      color: 'red',
      fontSize: '14px'
    },
    isModalVisible: false,
    isModalVisible2: false,
    isModalVisible3: false,
    selectedTeacher: '',
    rowPropsId: "",
  })
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [archiveListActive, setArchinveListActive] = useState(false)
  const [dataLoader, setDataLoader] = useState(false);
  const [staffType, setStaffType] = useState();
  const [classList, setClassList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [classAndSubjects, setClassAndSubjects] = useState({
    _id: "",
    classes: []
  });
  const [subjectheadList, setSubjectHeadList] = useState([]);
  const [classteacherList, setClassTeachterList] = useState([]);
  const [floorList, setFloorList] = useState([]);

  const [selectedClassId, setSelectedClassId] = useState()
  const [selectedSubjectId, setSelectedSubjectId] = useState()
  const [selectedClassSubjectList, setSelectedClassSubjectList] = useState([]);
  const [selectedSubjectHeadId, setSelectedSubjectHeadId] = useState()
  const [selectedClassTeacherId, setSelectedClassTeacherId] = useState()
  const [selectedFloorId, setSelectedFloorId] = useState()

  const [isAdminFilter, setIsAdminFilter] = useState(false);
  const [timeTableFilter, setTimeTableFilter] = useState(false);
  const [feeCollectorFilter, setFeeCollectorFilter] = useState(false);


  const [statusMsg, setStatusMsg] = useState({
    msg: "",
    buttonName: ""
  });
  const [selecteUser, setSelecteUser] = useState({})

  const subjectHead = (props) => {
    return (
      <span>
        {props.HeadData ? props.HeadData.subjectHeadName : null}
      </span>
    )
  }

  const classTeacher = (props) => {
    return (
      <span>
        {props.HeadData ? (props.HeadData.classHeadName) : null}{props.HeadData && props.HeadData.classHeadSection ? "-" : ""}{props.HeadData ? (props.HeadData.classHeadSection) : null}
      </span>
    )
  }

  const floorIncharge = (props) => {
    return (
      <span>
        {props.floor ? props.floor.floorName : null}
      </span>
    )
  }

  const UserRoles = (props) => {
    return (
      <span>
        {props.userRoles ? props.userRoles.userRole : null}
      </span>
    )
  }

  useEffect(() => {
    getList(archiveListActive);
    getclass();
    getsubject();
    getfloor();
  }, []);
  // =================================

  const getList = async (archive) => {
    setDataLoader(true);
    let Api = new StaffMember();
    await setArchinveListActive(archive)
    let list = []
    await Api.getStaffMembers().then(async (response) => {
      if (response.data && response.data.length) {
        if (!archive) {
          await response.data.map((data) => {
            if (data.active) {
              list.push(data)
            }
          })
        } else {
          await response.data.map((data) => {
            if (!data.active) {
              list.push(data)
            }
          })
        }
        setRows(list)
        setSelectedRows(list)
      } else {
        setRows([])
        setSelectedRows([])
      }
    }).catch((error) => {
      toast.error(error.toString());
      console.error("There is an error!", error);
    });
    setDataLoader(false);
  }

  const getclass = async () => {
    let ClassApi = new Class();
    await ClassApi.getClass().then(async (response) => {
      if (response.data && response.data.length) {
        setClassList(response.data)
        setClassTeachterList(response.data)
      } else {
        setClassList([])
        setClassTeachterList([])
      }
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  const getsubject = async () => {
    let SubjectApi = new Subject();
    await SubjectApi.getSubject().then(async (response) => {
      if (response.data && response.data.length) {
        setSubjectList(response.data)
        setSubjectHeadList(response.data)
      } else {
        setSubjectList([])
        setSubjectHeadList([])
      }
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  const getSubjectHead = async () => {
    let SubjectHeadApi = new Subject();
    await SubjectHeadApi.getSubject().then(async (response) => {
      if (response.data && response.data.length) {
        setSubjectHeadList(response.data)
      } else {
        setSubjectHeadList([])
      }
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  const getClassTeacher = async () => {
    let ClassTeacherApi = new Class();
    await ClassTeacherApi.getClass().then(async (response) => {
      if (response.data && response.data.length) {
        setClassTeachterList(response.data)
      } else {
        setClassTeachterList([])
      }
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  const getfloor = async () => {
    let FloorApi = new Floor();
    await FloorApi.getFloor().then(async (response) => {
      if (response.data && response.data.length) {
        setFloorList(response.data)
      } else {
        setFloorList([])
      }
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  const deleteStaff = async () => {
    let Api = new StaffMember();
    await isVisible2()
    await Api.deleteStaffMember(data.rowPropsId)
      .then((response) => {
        if (response.success) {
          toast.success(response.msg)
        } else {
          toast.error(response.msg)
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      })
    getList(archiveListActive)
  }

  const DeactieActionApi = async () => {
    let Api = new StaffMember();
    handleClose();
    let da = { _id: selecteUser._id, activate: !selecteUser.active }
    await Api.activateStaffMember(da)
      .then((response) => {
        if (response.success) {
          toast.success(response.msg)
        } else {
          toast.error(response.msg)
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      })
    await getList(archiveListActive);
  }

  const choosestafftype = async (e) => {
    let { name, value } = e.target;
    let selectedrow = [];
    await setStaffType(value);
    if (value) {
      await rows.map((datas) => datas.userRoles.userRole == value ? selectedrow.push(datas) : null)
    } else {
      selectedrow = rows
    }
    setSelectedRows(selectedrow)
  }

  const chooseclasses = async (e) => {
    let { name, value } = e.target;
    let selectedrow = [];
    setSelectedClassId(value)
    if (value) {
      await rows.map((datas) =>
        datas.classes.length && datas.classes[0].id.toString() == value.toString() ? selectedrow.push(datas) : null)
    } else {
      selectedrow = rows
    }
    setSelectedRows(selectedrow)
  }

  const choosesubjects = async (e) => {
    let { name, value } = e.target;
    let selectedrow = [];
    setSelectedSubjectId(value);
    if (value) {
      await rows.map((datas) => {
        if (datas.classes.length) {
          return datas.classes.map((cls) => {
            return cls.subject.filter((sub) => sub.value.toString() === value.toString() && selectedrow.length == 0 ? selectedrow.push(datas) : null)
          });
        }
      })
    } else {
      selectedrow = rows
    }
    setSelectedRows(selectedrow)
  }
  /////////////////////////////////////////////////

  const choosesubjecthead = async (e) => {
    let { name, value } = e.target;
    let selectedrow = [];
    setSelectedSubjectHeadId(value)
    if (value) {
      const subHead = subjectheadList.find(datas => {
        return datas._id === value;
      })
      await rows.map((datas) => datas._id == subHead.subjectHeadUserId ? selectedrow.push(datas) : null)
    } else {
      selectedrow = rows
    }
    setSelectedRows(selectedrow)
  }

  const chooseclassteacher = async (e) => {
    let { name, value } = e.target;
    let selectedrow = [];
    setSelectedSubjectId(value)
    if (value) {
      const classTeacher = classteacherList.find(datas => {
        return datas._id === value;
      })
      await rows.map((datas) => datas._id == classTeacher.classTeacherUserId ? selectedrow.push(datas) : null)
    } else {
      selectedrow = rows
    }
    setSelectedRows(selectedrow)
  }

  const choosefloors = async (e) => {
    let { name, value } = e.target;
    let selectedrow = [];
    setSelectedFloorId(value)
    if (value) {
      const floorIncharge = floorList.find(datas => {
        return datas._id === value;
      })
      await rows.map((datas) => datas._id == floorIncharge.floorIncharge ? selectedrow.push(datas) : null)
    } else {
      selectedrow = rows
    }
    setSelectedRows(selectedrow)
  }

  async function handleChanges(e) {
    const { name, value } = e.target;
    let selectedrow = [];
    if (name === "asAdmin") {
      setIsAdminFilter(!isAdminFilter);
    }

    if (name === "timeTable") {
      setTimeTableFilter(!timeTableFilter);
    }

    if (name === "feeCollector") {
      setFeeCollectorFilter(!feeCollectorFilter);
    }

    if (value == 'true') {
      rows.map((datas) => datas.accessToModules && datas.accessToModules[name].toString() === value ? selectedrow.push(datas) : [])
    } else {
      selectedrow = [...rows];
    }
    setSelectedRows(selectedrow);
  }

  // =================================

  const renderAction = (props) => {
    return (
      <span>
        <ReactTooltip id='Edit' type='warning' effect='solid'>
          <span>Edit</span>
        </ReactTooltip>
        <button onClick={() => history.push(`/addstaff?id=${props._id}`)} data-tip data-for="Edit" type="button" className="btn btn-outline-warning" style={{ padding: '8px' }}>
          <i className="mdi mdi-border-color" style={{ fontSize: '17px' }}></i>
        </button>
        <ReactTooltip id='Delete' type='error' effect='solid'>
          <span>Delete</span>
        </ReactTooltip>
        <button onClick={() => {
          isVisible2(props._id)
        }} data-tip data-for="Delete" type="button" className="btn btn-outline-danger" style={{ padding: '8px' }}>
          <i className="mdi mdi-delete" style={{ fontSize: '17px' }}></i>
        </button>
      </span>
    )
  }

  const isVisible2 = (id = null) => {
    let d = { ...data };
    if (id) {
      d.rowPropsId = id;
    }
    d.isModalVisible2 = !d.isModalVisible2;
    setData(d);
  }

  const DeactiveAction = (props, index) => {
    return (
      <span>
        <ReactTooltip id='activate' type={props.active ? 'success' : 'error'} effect='solid'>
          <span>Activate/Deactive</span>
        </ReactTooltip>
        <span
          data-tip
          data-for="activate"
          className={props.active ? "form-check form-check-success" : "form-check form-check-danger"}
          style={{ display: 'inline-flex' }}
          onClick={() => {
            let d = { ...data }
            if (props.active) {
              setStatusMsg({
                msg: `Are you sure that you want to Deactivate ${props.firstName}`,
                buttonName: "Deactivate"
              })
            } else {
              setStatusMsg({
                msg: `Are you sure that you want to Activate ${props.firstName}`,
                buttonName: "Activate"
              })
            }
            d.isModalVisible3 = true;
            setData(d);
            setSelecteUser(props)
          }}
        >
          <label className="form-check-label">
            <input type="checkbox" className="form-check-input" checked={props.active} />
            <i className="input-helper"></i>
          </label>
        </span>
      </span>
    )
  }

  const NameHyperLink = (props) => {
    return (
      <span>
        <Link to={`/addstaff?id=${props._id}`}>
          {props.firstName} {props.lastName}
        </Link>
      </span>
    )
  }

  const field = () => {
    const fields = [
      {
        name: "action",
        title: "",
        width: "0%",
        align: "center",
        render: DeactiveAction,
      },
      {
        title: "Name",
        render: NameHyperLink,
        field: "firstName"
      },
      {
        title: "Email",
        field: "email",
      },
      {
        title: "Moblie",
        field: "mobile",
      },
      {
        title: "Subject Head",
        render: subjectHead
      },
      {
        title: "Class Teacher",
        render: classTeacher
      },
      {
        title: "Floor Incharge",
        render: floorIncharge
      },
      {
        title: "Role",
        render: UserRoles
      },
      {
        title: "Class & Subjects",
        render: studentHyperlink
      },
      {
        name: "action",
        title: "Actions ",
        render: renderAction,
      },
    ];
    return fields;
  }

  const studentHyperlink = (props) => {
    return (
      <span>
        <ReactTooltip id='subjects' type='warning' effect='solid'>
          <span>show Subjects</span>
        </ReactTooltip>
        <button data-tip data-for="subjects" onClick={() => {
          let d = { ...data };
          let c = { ...classAndSubjects };
          d.selectedTeacher = props.firstName
          d.isModalVisible = !d.isModalVisible
          c._id = props._id
          c.classes = props.classes
          setClassAndSubjects(c)
          setData(d)
        }} type="button" className="btn btn-outline-warning" style={{ padding: '8px' }}>
          <i className="mdi mdi-link-variant" style={{ fontSize: '17px' }}></i>
        </button>
      </span>
    )
  }

  const handleClose = () => {
    let d = { ...data };
    d.selectedClass = "";
    d.isModalVisible = false;
    d.isModalVisible2 = false;
    d.isModalVisible3 = false;
    setSelecteUser({});
    setStatusMsg({
      msg: "",
      buttonName: ""
    });
    setData(d)
  }

  const updateClassSubjects = (subjectId) => {
  }

  const selectClassByModal = (e) => {
    let { name, value } = e.target;
    classAndSubjects.classes.map((data) => {
      if (value == data.id) {
        setSelectedClassSubjectList(data.subject);
        setSelectedClassId(value)
      }
    })
  }

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Staff Data </h3>
        <Link className="nav-link" to="/addstaff">
          <button type="submit" className="btn btn-primary">Add</button>
        </Link>
      </div>
      {dataLoader ? <Loader /> : null}
      <div className="row">

        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="row">
                    <label className="col-sm-4 col-form-label">Select Role</label>
                    <div className="col-sm-8">
                      <select onChange={(e) => choosestafftype(e)} value={staffType} className="form-control">
                        <option value="">---------</option>
                        <option value="principal">Principal</option>
                        <option value="teacher">Teacher</option>
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="row">
                    <label className="col-sm-4 col-form-label">Select Class</label>
                    <div className="col-sm-8">
                      <select onChange={(e) => chooseclasses(e)} value={selectedClassId} className="form-control">
                        <option value="">---------</option>
                        {classList && classList.map((data) => {
                          if (data.section != undefined && data.section) {
                            return (
                              <option
                                key={data._id}
                                value={data._id}
                              >
                                {data.className}{data && data.section ? "-" : ""}{data.section}
                              </option>
                            );
                          } else {
                            return (
                              <option
                                key={data._id}
                                value={data._id}
                              >
                                {data.className}
                              </option>
                            );
                          }
                        })}
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="row">
                    <label className="col-sm-4 col-form-label">Select Subject</label>
                    <div className="col-sm-8">
                      <select onChange={(e) => choosesubjects(e)} value={selectedSubjectId} className="form-control">
                        <option value="">---------</option>
                        {subjectList && subjectList.map((data) => {
                          return (
                            <option
                              key={data._id}
                              value={data._id}
                            >
                              {data.subjectName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="row">
                    <label className="col-sm-4 col-form-label">Select Subject Head</label>
                    <div className="col-sm-8">
                      <select onChange={(e) => choosesubjecthead(e)} value={selectedSubjectHeadId} className="form-control">
                        <option value="">---------</option>
                        {subjectheadList && subjectheadList.map((data) => {
                          return (
                            <option
                              key={data._id}
                              value={data._id}
                            >
                              {data.subjectName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="row">
                    <label className="col-sm-4 col-form-label">Select Class Teacher</label>
                    <div className="col-sm-8">
                      <select onChange={(e) => chooseclassteacher(e)} value={selectedClassTeacherId} className="form-control">
                        <option value="">---------</option>
                        {classteacherList && classteacherList.map((data) => {
                          return (
                            <option
                              key={data._id}
                              value={data._id}
                            >
                              {data.className}{data && data.section ? "-" : ""}{data.section}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="row">
                    <label className="col-sm-4 col-form-label">Select Floor Incharge</label>
                    <div className="col-sm-8">
                      <select onChange={(e) => choosefloors(e)} value={selectedFloorId} className="form-control">
                        <option value="">---------</option>
                        {floorList && floorList.map((data) => {
                          return (
                            <option
                              key={data._id}
                              value={data._id}
                            >
                              {data.floorName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-2">
                  <Form.Group>
                    <div className="form-check form-check-success">
                      <label className="form-check-label">
                        <input
                          name="asAdmin"
                          type="checkbox"
                          className="form-check-input"
                          onChange={(e) => handleChanges(e)}
                          checked={isAdminFilter}
                          value={!isAdminFilter}
                        />{" "}
                        As Admin
                        <i className="input-helper"></i>
                      </label>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-2">
                  <Form.Group>
                    <div className="form-check form-check-success">
                      <label className="form-check-label">
                        <input
                          name="timeTable"
                          type="checkbox"
                          className="form-check-input"
                          onChange={(e) => handleChanges(e)}
                          checked={timeTableFilter}
                          value={!timeTableFilter}
                        />{" "}
                        TimeTable
                        <i className="input-helper"></i>
                      </label>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-2">
                  <Form.Group>
                    <div className="form-check form-check-success">
                      <label className="form-check-label">
                        <input
                          name="feeCollector"
                          type="checkbox"
                          className="form-check-input"
                          onChange={(e) => handleChanges(e)}
                          checked={feeCollectorFilter}
                          value={!feeCollectorFilter}
                        />{" "}
                        Fee Collector
                        <i className="input-helper"></i>
                      </label>
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="row mb-2">
              <div className="col-md-5 import">
                <Users />
              </div>
              <div className="col-md-5 export">
                <ExportStaffMembers staffMemberData={selectedRows} />
              </div>
              <div className="col-md-2 addBtn">
                <button
                  onClick={async () => {
                    getList(!archiveListActive)
                  }}
                  type="button"
                  className="btn btn-outline-primary"
                  style={{ padding: "8px" }}
                >
                  {archiveListActive ? "Active List" : "Archive List"}
                </button>
              </div>
            </div>
            <Grid columns={field()} rows={selectedRows} />
          </div>
        </div>
      </div >

      {/* ================================= */}
      < DeleteModal show={data.isModalVisible2} isModalVisible2={isVisible2} deleteData={deleteStaff} />
      <StatusModal show={data.isModalVisible3} isModalVisible2={handleClose} deleteData={DeactieActionApi} body={statusMsg} />

      <AssociateTeacherModal data={data} handleClose={handleClose} selectClassByModal={selectClassByModal} classAndSubjects={classAndSubjects} selectedClassSubjectList={selectedClassSubjectList}  updateClassSubjects={updateClassSubjects}/>
      {/* ================================= */}

    </div >
  )
}

export default ManageStaffData;
