const dummynonTeacherData = [
  {
    name: "Chanchal",
    designation: "Driver",
    contactNo: 8915796335,
    atttendance: "present"
  },
  {
    name: "Manju",
    designation: "Clerk",
    contactNo: 8493496335,
    atttendance: "present"
  },
  {
    name: "Manika",
    designation: "Clerk",
    contactNo:5691557345,
    atttendance: "present"
  },
  {
    name: "Arun",
    designation: "Clerk",
    contactNo: 9455796335,
    atttendance: "absent"
  },
  {
    name: "Harpreet",
    designation: "Clerk",
    contactNo: 6315796335,
    atttendance: "present"
  },
  {
    name: "Arun",
    designation: "Driver",
    contactNo:4565796335,
    atttendance: "present"
  },
  {
    name: "Suresh",
    designation: "Clerk",
    contactNo: 8765796335,
    atttendance: "absent"
  },
  {
    name: "Dinesh" ,
    designation: "Peon",
    contactNo: 6755796335,
    atttendance: "present"
  },
  {
    name: "Rohit",
    designation: "Driver",
    contactNo: 6235796335,
    atttendance: "absent"
  },
  {
    name: "Kamlesh",
    designation: "Guard",
    contactNo: 700796335,
    atttendance: "present"

  },
  {
    name: "Atif",
    designation: "Driver",
    contactNo: 8907796335,
    atttendance: "present"
  },
  {
    name: "Manthan",
    designation: "Peon",
    contactNo: 5675796335,
    atttendance: "present"
  },
  {
    name: "Vishal",
    designation: "Guard",
    contactNo: 8955796335,
    atttendance: "absent"

  },
  {
    name: "Arjun",
    designation: "Driver",
    contactNo: 8005796335,
    atttendance: "present"
  },
  {
    name: "Manjit",
    designation: "Guard",
    contactNo: 8775796335,
    atttendance: "leave"
  },
  {
    name: "Mahesh",
    designation: "Guard",
    contactNo: 7665796335,
    atttendance: "present"
  },
  {
    name: "Abhijit",
    designation: "Driver",
    contactNo: 2345796335,
    atttendance: "present"
  },
  {
    name: "Komal",
    designation: "Guard",
    contactNo: 7895796335,
    atttendance: "leave"
  },
  {
    name: "Monu",
    designation: "Driver",
    contactNo: 8005796335,
    atttendance: "present"
  },
  {
    name: "Sonu",
    designation: "Peon",
    contactNo: 700796335,
    atttendance: "leave"
  },
  {
    name: "Mahesh",
    designation: "Guard",
    contactNo: 600796335,
    atttendance: "present"
  },
  {
    name: "Sunaina",
    designation: "Driver",
    contactNo: 4565796335,
    atttendance: "present"
  },
  {
    name: "Sonali",
    designation: "Guard",
    contactNo: 9005796335,
    atttendance: "leave"
  },
  {
    name: "Ayushman",
    designation: "Driver",
    contactNo: 9895796335,
    atttendance: "present"
  },
  {
    name: "Varun",
    designation: "Peon",
    contactNo: 9345796335,
    atttendance: "present"
  }
];

export default dummynonTeacherData;