import React, { useEffect, useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import CourseStructure from '../../../api/courseStructureApi';
import Class from '../../../api/classApi';
import Grid from "../../../components/dataTable";
import DeleteModal from "../../../components/deleteAlertPopup";
import moment from "moment";
import Loader from "../../../components/loader";
import PrintCourseStructureModal from './PrintCourseStructureModal';

const ManageCourseStructure = () => {
  let history = useHistory();
  const [data, setData] = useState({
    tooltipOpen: false,
    startstyle: {
      color: 'red',
      fontSize: '14px'
    },
    isModalVisible: false,
    isModalVisible2: false,
    selectedCourseStructure: {},
    rowPropsId: ""
  })
  const [selectedClass, setSelectedClass] = useState();
  const [selectedTerm, setSelectedTerm] = useState();
  const [classList, setClassList] = useState([]);
  const [termLst, setTermList] = useState([]);
  const [rows, setRows] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [dataLoader, setDataLoader] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);

  useEffect(() => {
    getFilteredCourses();
    getTermslist();
    getClassList();
  }, []);

  // ======================================= coding part =======================================

  const getList = async () => {
    setDataLoader(true);
    let CourseStructureApi = new CourseStructure();
    await CourseStructureApi.getCourseStructures().then(async (response) => {
      if (response.data && response.data.length) {
        setRows(response.data[0])
      } else {
        setRows([]);
      }
    })
    setDataLoader(false)
  }

  const getFilteredCourses = async (name, value) => {
    setDataLoader(true);
    let query = { className: selectedClass, term: selectedTerm };
    query[name] = value;

    let CourseStructureApi = new CourseStructure();
    await CourseStructureApi.getFilteredCourses(query).then(async (response) => {
      if (response.data && response.data.length) {
        setRows(response.data[0])
      } else {
        setRows([])
      }
    })
    setDataLoader(false)
  }

  const getTermslist = async () => {
    setDataLoader(true);
    let CourseStructureApi = new CourseStructure();
    await CourseStructureApi.getTermslist().then(async (response) => {
      if (response.data && response.data.length) {
        setTermList(response.data)
        setSelectedTerm(response.data[0])
      }
    })
    setDataLoader(false)
  }

  const getClassList = async () => {
    setDataLoader(true);
    let ClassApi = new Class();
    await ClassApi.getClassNames().then(async (response) => {
      if (response.data && response.data.length) {
        setClassList(response.data)
        setSelectedClass(response.data[0])
      }
    })
    setDataLoader(false)
  }

  const deleteCourse = async (id) => {
    let CourseStructureApi = new CourseStructure();
    isVisible2();
    await CourseStructureApi.deleteCourseStructure(data.rowPropsId)
      .then((response) => {
        if (response.success) {
          toast.success(response.msg)
          getList();
          getTermslist();
          getClassList();
        } else {
          toast.error(response.msg)
        }
      })
  }


  const renderAction = (props) => {

    return (
      <span>
        <ReactTooltip id='Edit' type='warning' effect='solid'>
          <span>Edit</span>
        </ReactTooltip>
        <button onClick={() => history.push(`/addcoursestructure?id=${props._id}`)} data-tip data-for="Edit" type="button" className="btn btn-outline-warning" style={{ padding: '8px' }}>
          <i className="mdi mdi-border-color" style={{ fontSize: '17px' }}></i>
        </button>

        <ReactTooltip id='Delete' type='error' effect='solid'>
          <span>Delete</span>
        </ReactTooltip>

        <button onClick={() => isVisible2(rows._id)} data-tip data-for="Delete" type="button" className="btn btn-outline-danger" style={{ padding: '8px' }}>
          <i className="mdi mdi-delete" style={{ fontSize: '17px' }}></i>
        </button>
      </span>
    )
  }

  const isVisible2 = (id = null) => {
    let d = { ...data };
    if (id) {
      d.rowPropsId = id;
    }
    setIsModalVisible2(!isModalVisible2);
    setData(d);
  }

  const showSubjects = (props) => {
    let d = "";
    if (rows && rows.subjectsData) {
      let sub = rows.subjectsData.find(o => o._id == props.subject);
      let subName;
      if (sub && sub.subjectName)
        subName = sub.subjectName
      return (
        <span>{sub && sub.subjectName ? sub.subjectName : ''}</span>
      )
    }
  }

  const field = () => {
    const fields = [
      {
        title: "Subject",
        render: showSubjects,
      },
      {
        title: "Marks",
        field: "marks",
      },
      {
        title: "Practical",
        field: "practical",
      },
      {
        title: "Assignment",
        field: "assignment",
      },
      {
        title: "Syllabus",
        field: "syllabus",
      },
    ];
    return fields;
  }

  const handleClose = () => {
    setData(data.selectedClass = "", data.isModalVisible = false)
  }

  const handleChanges = (event) => {
    const { name, value } = event.target;
    if (name == "className") {
      setSelectedClass(value);
    } else if (name == "term") {
      setSelectedTerm(value);
    }
    getFilteredCourses(name, value);
  }

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Course Structure </h3>
        <Link className="nav-link" to="/addcoursestructure">
          <button type="submit" className="btn btn-primary ml-2 btn-fw" style={{ lineHeight: 1.5 }}>Add</button>
        </Link>
      </div>
      {dataLoader ? <Loader /> : null}
      <div className="row">
        <div className="col-md-3">
          <Form.Group className="row">
            <label className="col-sm-3 col-form-label">Class: </label>
            <div className="col-sm-9">
              <select className="form-control" name="className" onChange={handleChanges} >
                <option>---</option>
                {
                  classList.map((className) => <option key={className} value={className}>{className}</option>)
                }
              </select>
            </div>
          </Form.Group>
        </div>
        <div className="col-md-3">
          <Form.Group className="row">
            <label className="col-sm-3 col-form-label">Term: </label>
            <div className="col-sm-9">
              <select className="form-control" name="term" onChange={handleChanges}>
                <option>---</option>
                {
                  termLst.map((term) => <option key={term} value={term}>{term}</option>)
                }
              </select>
            </div>
          </Form.Group>
        </div>
        <div className="col-md-6 text-right">
          <button
            data-tip
            data-for="Print"
            onClick={() => {
              let d = { ...data };
              d.isModalVisible = true;
              d.isModalVisible2 = false;
              setData(d);
            }}
            type="button"
            className="btn btn-outline-warning mt-1"
            style={{ padding: "8px" }}
          >
            Print
          </button>
        </div>
      </div>
      <div className="row col-12">
        <div className="d-sm-flex justify-content-xl-between align-items-center mb-2 col-10">
          <label className="col-sm-4 col-form-label">Class: {rows && rows.className ? rows.className : "--"} - Term: {rows && rows.term ? rows.term : "--"}</label>
          <label className="col-sm-6 col-form-label">Period: {rows && rows.fromDate ? moment(rows.fromDate).tz('Asia/Kolkata').format('DD-MM-YYYY') : 'mm:dd:yyyy'} To {rows && rows.toDate ? moment(rows.toDate).tz('Asia/Kolkata').format('DD-MM-YYYY') : "mm:dd:yyyy"}</label>
          {rows && rows._id ? renderAction(rows) : ''}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <Grid columns={field()} rows={rows && rows.subjects ? rows.subjects : []} />
          </div>
        </div>
        <DeleteModal show={isModalVisible2} isModalVisible2={isVisible2} deleteData={deleteCourse} />
      </div>
      <PrintCourseStructureModal data={data} rows={rows} handleClose={handleClose} isLoader={isLoader} selectedTerm={selectedTerm} selectedClass={selectedClass} field={field} />
    </div>
  )

}

export default ManageCourseStructure;