import React, { useEffect, useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';

const DeleteModal = ({ show = false, isModalVisible2, deleteData }) => {

    return (
        <>
            <Modal show={show} onHide={() => isModalVisible2()}>
                <Modal.Header style={{ backgroundColor: "#af0000" }} closeButton>
                    <Modal.Title style={{ color: "beige" }}>Warning!</Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ minHeight: "133px", textAlign: "center", backgroundColor: "white" }}>
                    <span >Are you sure that you want to permanently delete the selected Data </span>
                </Modal.Body>

                <Modal.Footer style={{ backgroundColor: "white" }}>
                    <button type="button" className="btn btn-outline-danger" onClick={() => deleteData()} style={{ padding: '16px' }}>
                        <i className="mdi mdi-delete"></i> Delete
                    </button>
                    <button type="button" className="btn btn-outline-secondary" onClick={() => isModalVisible2()} style={{ padding: '16px' }}>
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DeleteModal;