import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { Form, Modal, Button, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from "react-toastify";
import Activity from "../../../api/activityApi";
import Grid from "../../../components/dataTable";
import DeleteModal from "../../../components/deleteAlertPopup";

const ManageActivities = () => {
  const [data, setData] = useState({
    tooltipOpen: false,
    startstyle: {
      color: "red",
      fontSize: "14px",
    },
    isModalVisible: false,
    rowPropsId: "",
    selectedClass: "",

    _id: "",
    activityName: "",
    activityDescription: "",
    trialDays: "",
    photoUrl: "",
  });

  const [rows, setRows] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [fileProgress, setFileProgress] = useState({});

  useEffect(() => {
    getList();
  }, []);

  // ======================================= coding part =======================================

  const getList = async () => {
    setDataLoader(true);
    let ActivityApi = new Activity();
    await ActivityApi.getActivities()
      .then(async (response) => {
        // console.log(response.data)
        if (response.data && response.data.length) {
          setRows(response.data);
        } else {
          setRows([]);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        console.error("There is an error!", error);
      });
    setDataLoader(false);
  };

  const handleChanges = (event) => {
    let c = { ...data };
    const { name, value } = event.target;
    if (name == "activityName") {
      c.activityName = value;
    } else if (name == "activityDescription") {
      c.activityDescription = value;
    } else if (name == "photo") {
      c.photoUrl = value;
    } else if (name == "trialDays") {
      c.trialDays = value;
    }
    setData(c);
  };

  const uploadImage = async (value) => {
    let ActivityApi = new Activity();
    const fData = new FormData();
    fData.append("file", value[0]);
    await ActivityApi.uploadFile(fData, (upload) => {
      let pg = { ...fileProgress };
      pg["file"] = Math.round((100 * upload.loader) / upload.total);
      setFileProgress(pg);
    })
      .then((result) => {
        if (result.fileUrl && result.success) {
          let form = { ...data };
          form.photoUrl = result.fileUrl;
          setData(form);
          toast.success("Photo is uploaded");
          delete fileProgress["file"];
        }
      })
      .catch(() => {
        toast.error("Upload failed");
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let ActivityApi = new Activity();
    let { _id, activityName, activityDescription, photoUrl, trialDays } = data;

    if (_id) {
      await ActivityApi.editActivity({
        _id,
        activityName,
        activityDescription,
        photoUrl,
        trialDays
      })
        .then((data) => {
          if (data.success) {
            toast.success(data.msg.toString());
            cleanFields();
            getList();
          } else {
            toast.error(data.msg.toString());
          }
        })
        .catch((error) => {
          toast.error(error.toString());
          console.error("There was an error!", error);
        });
    } else {
      await ActivityApi.createActivity({
        activityName,
        activityDescription,
        photoUrl,
        trialDays
      })
        .then((data) => {
          if (data.success) {
            toast.success(data.msg.toString());
            cleanFields();
            getList();
          } else {
            toast.error(data.msg);
          }
        })
        .catch((error) => {
          toast.error(error.toString());
          console.error("There was an error!", error);
        });
    }
  };

  const cleanFields = () => {
    let d = { ...data };
    d._id = "";
    d.activityName = "";
    d.activityDescription = "";
    d.trialDays = "";
    d.photoUrl = "";
    setData(d);
  };

  const editActivity = (props) => {
    let c = { ...data };
    c._id = props._id;
    c.activityName = props.activityName;
    c.activityDescription = props.activityDescription;
    c.trialDays = props.trialDays;
    c.photoUrl = props.photoUrl;
    setData(c);
  };

  const deleteActivity = async () => {
    let ActivityApi = new Activity();
    isVisible2();
    await ActivityApi.deleteActivity(data.rowPropsId)
      .then((response) => {
        // console.log(response);
        toast.success(response.msg.toString());
      })
      .catch((error) => {
        console.error("There is ERROR!", error);
        toast.error(error.toString());
      });
    getList();
  };
  // ===================================/ coding part /=======================================

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">{props}</Tooltip>
  );

  const activityDescription = (props) => {
    return (
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(props.activityDescription)}
      >
        <span>{(props.activityDescription && props.activityDescription.slice(0, 70) + (props.activityDescription.length > 70 ? "..." : ""))}</span>
      </OverlayTrigger>
    )
  }

  const renderAction = (props) => {
    return (
      <span>
        <ReactTooltip id="Edit" type="warning" effect="solid">
          <span>Edit</span>
        </ReactTooltip>
        <button
          data-tip
          data-for="Edit"
          onClick={() => editActivity(props)}
          type="button"
          className="btn btn-outline-warning"
          style={{ padding: "8px" }}
        >
          <i className="mdi mdi-border-color" style={{ fontSize: "17px" }}></i>
        </button>

        <ReactTooltip id="Delete" type="error" effect="solid">
          <span>Delete</span>
        </ReactTooltip>

        <button
          data-tip
          data-for="Delete"
          onClick={() => isVisible2(props._id)}
          type="button"
          className="btn btn-outline-danger"
          style={{ padding: "8px" }}
        >
          <i className="mdi mdi-delete" style={{ fontSize: "17px" }}></i>
        </button>
      </span>
    );
  };

  const isVisible2 = (id = null) => {
    let d = { ...data };
    if (id) {
      // console.log(id)
      d.rowPropsId = id;
    }
    setIsModalVisible2(!isModalVisible2);
    setData(d);
  };

  const field = () => {
    const fields = [
      {
        title: "Activities",
        field: "activityName",
      },
      {
        title: "Activity Description",
        field: "activityDescription",
        render: activityDescription
      },
      {
        title: "Free Trial Days",
        field: "trialDays"
      },
      {
        name: "action",
        title: "Actions ",
        render: renderAction,
      },
    ];
    return fields;
  };

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Manages Activities </h3>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit} className="forms-sample">
                <div className="row">
                  <div className="col-md-9">
                    <Form.Group className="row">
                      <label className="col-sm-2 col-form-label">
                        Activity<span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-10">
                        <Form.Control
                          type="text"
                          required={true}
                          name="activityName"
                          onChange={handleChanges}
                          value={data.activityName}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-9">
                    <Form.Group className="row">
                      <label className="col-sm-2 col-form-label">
                        Activity Description
                        <span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-10">
                        <Form.Control
                          type="text"
                          required={true}
                          name="activityDescription"
                          onChange={handleChanges}
                          value={data.activityDescription}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-9">
                    <Form.Group className="row">
                      <label className="col-sm-2 col-form-label">
                        Free Trial Days<span style={data.startstyle}>*</span>
                      </label>
                      <div className="col-sm-10">
                        <Form.Control
                          type="number"
                          required={true}
                          name="trialDays"
                          onChange={handleChanges}
                          value={data.trialDays}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="row m-0">
                      <label className="col-sm-3 col-form-label"> Photo<span style={data.startstyle}>*</span> </label>
                      <div className="custom-file col-sm-9">
                        <Form.Control
                          type="file"
                          className="form-control visibility-hidden"
                          id="customFileLang"
                          lang="es"
                          name="photo"
                          onChange={(e) => uploadImage(e.target.files)}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFileLang"
                        >
                          Browse
                        </label>
                      </div>
                    </Form.Group>
                    <Form.Group className="row">
                      <label className="col-sm-3"></label>
                      {data.photoUrl ? (
                        <span className="col-sm-9">
                          <Image
                            src={`${data.photoUrl}`}
                            style={{ height: "200px" }}
                            thumbnail
                          />
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <button
                      type="submit"
                      className="btn btn-primary ml-2 btn-fw"
                      style={{ lineHeight: 1.5 }}
                    >
                      {data._id ? "Update" : "Add"}
                    </button>
                  </div>
                </div>

                {/* ========================================================== */}
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <Grid
              columns={field()}
              rows={rows}
            />
          </div>
        </div>
        <DeleteModal
          show={isModalVisible2}
          isModalVisible2={isVisible2}
          deleteData={deleteActivity}
        />
      </div>
    </div>
  );
};

export default ManageActivities;
