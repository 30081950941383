import React, { Fragment, useState } from "react";
import { toast } from "react-toastify";
import Contact from "../../api/contactApi";

const Home = () => {
  let dis = "Edvantum is an online platform for Schools to manage the Teachers, Students, Fees data online. Track online attendance, timetable, courses, students results. School Principal, Teachers and Parents use Edvantum mobile app to manage and see the school data.";
  let keyword = "Edvantum, Educational Institutions, Digital Solution, School Management, Online Attendance";
  let tt = "EDVANTUM - DIGITAL SOLUTIONS FOR ACADEMIC INSTITUTIONS"

  const [data, setData] = useState({
    tooltipOpen: false,
    startstyle: {
      color: 'red',
      fontSize: '14px'
    },
    isModalVisible: false,
    rowPropsId: "",

    name: "",
    email: '',
    message: '',
    subject: "",
  })

  const handleSubmit = async (event) => {
    event.preventDefault()
    let ContactApi = new Contact();
    let {
      name,
      email,
      message,
      subject
    } = data;
    await ContactApi.createContact({ name, email, message, subject })
      .then((response) => {
        console.log(response);
        toast.success(response.msg.toString());
        cleanFields();
      })
      .catch((error) => {
        console.error("There is ERROR!", error)
        toast.error(error.toString());
      })
  }

  function handleChange(event) {
    const { name, value } = event.target;
    //here set the state and pass
    let c = { ...data };
    if (name == "name") {
      c.name = value;
    } else if (name == "email") {
      c.email = value;
    } else if (name == "message") {
      c.message = value;
    } else if (name == "subject") {
      c.subject = value;
    }
    setData(c);
  }

  const cleanFields = () => {
    let d = { ...data }
    d.name = "";
    d.email = "";
    d.message = "";
    d.subject = "";
    setData(d);
  }
  return (
    <Fragment>

      <div className="banner-text">
        <div className="intro-para">
          <div>
            DIGITAL SOLUTIONS FOR <br />
            ACADEMIC INSTITUTIONS
          </div>
          <div className="border-white">
            <a href="schools" title="Schools">
              School Management
            </a>
          </div>
          <div className="border-white">
            <a href="teachers" title="Teachers">
              Teachers
            </a>
          </div>
          <div className="border-white">
            <a href="parents" title="Parents">
              Parents
            </a>
          </div>
        </div>
      </div>

      <section id="about" className="edvantum-section seTitle">
        <div className="container">
          <div className="">
            <div className="header-section text-center post-heading">
              <h2 className="title__line1">Why Edvantum?</h2>
            </div>
            <div className="container">
              <div className="Maximizing collection">
                <div className="tansfer">
                  <div>
                    <img src={require("../../assets/images/web/fee-collection.png")} alt="img1" srcSet="" loading="lazy" />
                    <h4>Fee Collection and regular reminders through the APP</h4>
                    <hr />
                  </div>
                  <div>
                    <img src={require("../../assets/images/web/transparency.png")} alt="img1" srcSet="" loading="lazy" />
                    <h4>Full transparency</h4>
                    <hr />
                  </div>
                  <div>
                    <img src={require("../../assets/images/web/trainers.png")} alt="img1" srcSet="" loading="lazy" />
                    <h4>Verified and Competent trainers</h4>
                  </div>
                </div>
                <div className="">
                  <img
                    className="why-edvImg"
                    src={require("../../assets/images/web/why-edvantum.png")}
                    alt=""
                    srcSet=""
                    loading="lazy"
                  />
                </div>
                <div className="visibilities">
                  <div className="academic">
                    <img src={require("../../assets/images/web/visibility.png")} alt="img1" srcSet="" loading="lazy" />
                    <h4>
                      More visibility through all the events organized by Edvantum
                    </h4>
                    <hr />
                  </div>
                  <div className="Digitally">
                    <img
                      className="acedmImg"
                      src={require("../../assets/images/web/academics.png")}
                      alt="img1"
                      srcSet=""
                      loading="lazy"
                    />
                    <h4>
                      Digitally transforming the school which will help them to focus
                      more on Academics{" "}
                    </h4>
                    <hr />
                  </div>
                  <div>
                    <img src={require("../../assets/images/web/curriculum.png")} alt="img1" srcSet="" loading="lazy" />
                    <h4>Defined Curriculum</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="Delivering section-padding">
        <div className="container">
          <div className="">
            <div className="header-section text-center margin-btm">
              <h2 className="title__line1">
                How We are Delivering the Edvantum experience
              </h2>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                  <div className="box box-back">
                    <div className="blog_card_content">
                      <h1>We Are...</h1>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                  <div className="box box-white">
                    <div className="blog_card_content">
                      <img src={require("../../assets/images/web/part-eduction.png")} alt="" srcSet="" loading="lazy" />
                      <h3>Part of Education ecosystem for &gt; 8 years</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 abox">
                  <div className="box box-white">
                    <div className="blog_card_content">
                      <img src={require("../../assets/images/web/shaping-innovation.png")} alt="" srcSet="" loading="lazy" />
                      <h3>Shaping innovation as entrepreneurs ourselves</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 abox">
                  <div className="box box-white">
                    <div className="blog_card_content">
                      <img src={require("../../assets/images/web/practitioners.png")} alt="" srcSet="" loading="lazy" />
                      <h3>Practitioners who focus on evolving needs and trends</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container second-cont">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                  <div className="box box-white">
                    <div className="blog_card_content">
                      <img src={require("../../assets/images/web/engaging.png")} alt="" srcSet="" loading="lazy" />
                      <h3>Engaging with Schools, teachers and parents</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                  <div className="box box-white">
                    <div className="blog_card_content">
                      <img src={require("../../assets/images/web/investing.png")} alt="" srcSet="" loading="lazy" />
                      <h3>Investing in Relationships</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 abox">
                  <div className="box box-white">
                    <div className="blog_card_content">
                      <img src={require("../../assets/images/web/focused.png")} alt="" srcSet="" loading="lazy" />
                      <h3>Focused on outcomes</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 abox">
                  <div className="box box-white">
                    <div className="blog_card_content">
                      <img src={require("../../assets/images/web/people.png")} alt="" srcSet="" loading="lazy" />
                      <h3>People who care about making a difference!</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="edvantum-section">
        <div className="container">
          <div className="">
            <div className="header-section text-center margin-btm">
              <h2 className="title__line1">
                Edvantum – solutions for academic institutions
              </h2>
            </div>
            <div className="impact-text">
              <h2 className="new_line">BREAKTHROUGH IMPACT</h2>
            </div>

            <div className="container">
              <div className="row our">
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                  <div className="solutions">
                    <div className="setImg">
                      {" "}
                      <img src={require("../../assets/images/web/our-belief.png")} alt="" srcSet="" loading="lazy" />
                    </div>
                    <h2>our</h2>
                    <h1>Belief</h1>
                    <p>
                      Academics, sports, and the performing arts all contribute to{" "}
                      <b>holistic development</b>
                    </p>
                    <p>
                      <b>Development outcomes </b> can be enhanced through use of
                      technology
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                  <div className="solutions">
                    <div className="setImg">
                      {" "}
                      <img src={require("../../assets/images/web/proposition.png")} alt="" srcSet="" loading="lazy" />
                    </div>
                    <h2>our</h2>
                    <h1>proposition</h1>
                    <p>
                      Re-focus Schools and Institutes on their core activity
                      -academics
                    </p>
                    <p>
                      Aid student development through complementary
                      <b>extracurricular activities</b>
                    </p>
                    <p>
                      Encourage engagement of all stakeholders -{" "}
                      <b>parents, teachers and management </b> for successful outcomes
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 abox">
                  <div className="solutions">
                    <div className="setImg">
                      {" "}
                      <img src={require("../../assets/images/web/our-people.png")} alt="" srcSet="" loading="lazy" />
                    </div>
                    <h2>our</h2>
                    <h1>people</h1>
                    <p>
                      <b> Founding team</b>
                      with significant experience in <b>managing schools </b> and
                      providing <b>extracurricular activities</b>
                    </p>
                    <p>
                      <b>Highly collaborative: </b> working <i>with</i> you
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 abox">
                  <div className="solutions">
                    <div className="setImg">
                      {" "}
                      <img src={require("../../assets/images/web/relationships.png")} alt="" srcSet="" loading="lazy" />
                    </div>
                    <h2>our</h2>
                    <h1>relationships</h1>
                    <p>
                      <b>Impact at the core of our relationship</b>
                    </p>
                    <p>
                      <b>Trusted partners </b>who challenge and support
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="blogs" className="section-padding ">
        <div className="container">
          <div className="">
            <div className="header-section text-center margin-btm">
              <h2 className="title__line">Blogs</h2>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="blog_card">
                  <div className="blog_card_content">
                    <h3>Here Comes The Blog Title.</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Mollitia provident voluptatem.
                    </p>
                    <a href="">
                      <h6>Read More</h6>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="blog_card">
                  <div className="blog_card_content">
                    <h3>Here Comes The Blog Title.</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Mollitia provident voluptatem.
                    </p>
                    <a href="">
                      <h6>Read More</h6>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="blog_card">
                  <div className="blog_card_content">
                    <h3>Here Comes The Blog Title.</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Mollitia provident voluptatem.
                    </p>
                    <a href="">
                      <h6>Read More</h6>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact*/}
      <section id="contact1" className="section-padding contact-us">
        <div className="container">
          <div className="">
            <div className="header-section text-center margin-btm">
              <h2 className="title__line">Contact Us</h2>
              <p>For any query, contact us through below form.</p>
            </div>
            <div id="sendmessage">Your message has been sent. Thank you!</div>
            <div id="errormessage"></div>
            <form method="post" role="form" className="contactForm" onSubmit={handleSubmit}>
              <div className="col-md-6 col-sm-6 col-xs-12 left">
                <div className="form-group">
                  <input type="text" name="name" className="form-control form" id="name" placeholder="Your Name"
                    data-rule="minlen:4" data-msg="Please enter at least 4 chars" onChange={handleChange}
                    value={data.name}
                    required />
                  <div className="validation"></div>
                </div>
                <div className="form-group">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Your Email"
                    data-rule="email" data-msg="Please enter a valid email" onChange={handleChange}
                    value={data.email}
                    required />
                  <div className="validation"></div>
                </div>
                <div className="form-group">
                  <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject"
                    data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" onChange={handleChange}
                    value={data.subject}
                    required />
                  <div className="validation"></div>
                </div>
              </div>

              <div className="col-md-6 col-sm-6 col-xs-12 right">
                <div className="form-group">
                  <textarea className="form-control" name="message" rows="5" data-rule="required"
                    data-msg="Please write something for us" placeholder="Message" onChange={handleChange}
                    value={data.message}
                    required></textarea>
                  <div className="validation"></div>
                </div>
              </div>

              <div className="col-xs-12 col-md-12">
                {/* Button */}
                <button type="submit" id="submit" name="submit"
                  className="form contact-form-button light-form-button oswald light">SEND EMAIL</button>
              </div>
            </form>

          </div>
        </div>
      </section>


    </Fragment>

  );
};
export default Home;
