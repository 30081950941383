import BaseApi from "./baseApi";

class ActivityClass extends BaseApi {

  async getActivityClassList() {
    return await this.API({
      url: "/activityclass/getactivityclasslist",
      method: "get",
    });
  }

  async createActivityClass(data) {
    return await this.API({
      url: "/activityclass/createactivityclass",
      data: data,
      method: "post",
    });
  }

  async editActivityClass(data) {
    return await this.API({
      url: "/activityclass/updateactivityclass",
      data: data,
      method: "post",
    });
  }

  async deleteActivityClass(data) {
    return await this.API({
      url: "activityclass/deleteactivityclass",
      data: { _id: data },
      method: "post",
    });
  }
}

export default ActivityClass;
