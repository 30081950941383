import React, { useEffect, useState } from "react";
import { Form, Modal, Row, Col, Button } from 'react-bootstrap';
import { toast } from "react-toastify";
import CoachApi from "../../../../api/coachApi";
const AcceptRejectModal = (props) => {
// Pending,Approved,Rejected 
    const [data, setData] = useState({
        _id:"",
        inSchool: {
            inSchool: false,
            leaveRejectComment: null,
            statusOfLeave: "Pending"
        },
        outSchool: {
            outSchool: false,
            leaveRejectComment: null,
            statusOfLeave: "Pending"
        }
    });
    const [propsData, setPropsData] = useState({})
    useEffect(() => {
        let d = {...data};
        d._id = props.data._id
        if(props.data && props.data.inSchool){
            d.inSchool.inSchool = props.data.inSchool.inSchool;
            d.inSchool.leaveRejectComment = props.data.inSchool.leaveRejectComment;
            d.inSchool.statusOfLeave = props.data.inSchool.statusOfLeave;
        }

        if( props.data && props.data.outSchool){
            d.outSchool.outSchool = props.data.outSchool.outSchool;
            d.outSchool.leaveRejectComment = props.data.outSchool.leaveRejectComment;
            d.outSchool.statusOfLeave = props.data.outSchool.statusOfLeave;
        }
        setData(d)
        setPropsData(props.data)
    },[props.data])

    const handleChanges = (e, type) => {
        const { value, name} = e.target;
        const d = {...data};
        if(type == "inSchool"){
            d.inSchool[name] = value
        } else if(type == "outSchool") {
            d.outSchool[name] = value
        }
        setData(d);
    }

    const cleaning = () => {
        setData({
            inSchool: {
                inSchool: false,
                leaveRejectComment: null,
                statusOfLeave: "Pending"
            },
            outSchool: {
                outSchool: false,
                leaveRejectComment: null,
                statusOfLeave: "Pending"
            }
        })
        props.clean();
    }

    const handleSubmit = async () => {
        let Api = new CoachApi();
        await Api.updateCoachLeave(data).then((res)=>{
            if(res.success){
                toast.success(res.msg);
                props.reload();
                props.clean();
            } else {
                toast.error(res.msg);
            }

        }).catch((error) => {
            toast.error(error.toString());
            console.error("There is an error!", error);
        });
    }

return (
    <Modal show={props.show} onHide={() => cleaning()}>
    <Modal.Header  style={{backgroundColor:"#ffc542"}} closeButton>
        <Modal.Title style={{color: "beige"}}>{propsData.userDetails?.firstName} {propsData.userDetails?.lastName} applied for {propsData.leaveType} leave</Modal.Title>
    </Modal.Header>
    
    <Modal.Body style={{minHeight:"133px", textAlign:"left", backgroundColor:"white"}}>
        <span><span style={{fontWeight:"bold"}}>Reason:</span> {propsData.comment}</span>
    </Modal.Body>
    
    <Modal.Body>
        {
            data.inSchool && data.inSchool.inSchool?
            <Row>
                <Col style={{marginBottom: "10px"}}>
                    <Row>
                        <Col>
                            In-school leave is {data.inSchool && data.inSchool.statusOfLeave}
                        </Col>
                        <Col>
                            <span style={{float: "right"}}>
                                <Button variant="success" onClick={()=> handleChanges({
                                    target:{
                                        name:"statusOfLeave",
                                        value:"Approved"
                                    }
                                }, "inSchool")}>Approve</Button>
                                <Button variant="danger" onClick={()=> handleChanges({
                                    target:{
                                        name:"statusOfLeave",
                                        value:"Rejected"
                                    }
                                }, "inSchool")}>Reject</Button>
                            </span>
                        </Col>
                    </Row>
                    {
                        data.inSchool && data.inSchool.statusOfLeave == "Rejected"?
                        <Row>
                            <Col>
                                <Form.Group controlId="exampleForm.ControlTextarea12">
                                    <Form.Label>Reason for rejection</Form.Label>
                                    <Form.Control 
                                        as="textarea"
                                        name="leaveRejectComment" 
                                        rows={3} 
                                        onChange={(e) => handleChanges(e, "inSchool")}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        :''
                    }
                </Col>
            </Row>
            :""
        }
        {
            data.outSchool && data.outSchool.outSchool?
            <Row>
                <Col>
                    <Row>
                        <Col>
                            Out-school leave is {data.outSchool && data.outSchool.statusOfLeave}
                        </Col>
                        <Col>
                            <span style={{float: "right"}}>
                                <Button variant="success" onClick={()=> handleChanges({
                                    target:{
                                        name:"statusOfLeave",
                                        value:"Approved"
                                    }
                                }, "outSchool")}>Approve</Button>
                                <Button variant="danger" onClick={()=> handleChanges({
                                    target:{
                                        name:"statusOfLeave",
                                        value:"Rejected"
                                    }
                                }, "outSchool")}>Reject</Button>
                            </span>
                        </Col>
                    </Row>
                    {
                        data.outSchool && data.outSchool.statusOfLeave == "Rejected"?
                        <Row>
                            <Col>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Reason for rejection</Form.Label>
                                    <Form.Control 
                                        as="textarea"
                                        name="leaveRejectComment" 
                                        rows={3}
                                        onChange={(e) => handleChanges(e, "outSchool")}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        :''
                    }
                </Col>
            </Row>
            :""
        }

    </Modal.Body>

    <Modal.Footer style={{backgroundColor:"white"}}>
        <button type="button" className="btn btn-outline-danger" onClick={()=> handleSubmit()}>
            Submit
        </button>
        <button type="button" className="btn btn-outline-secondary"  onClick={() => cleaning()}>
            Cancel
        </button>
    </Modal.Footer>
</Modal>
)

}

export default AcceptRejectModal;